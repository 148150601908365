import { useLazyQuery } from '@apollo/client';
import { PIECEWORK_ASSIGNABLE_GROUPS } from '../api';

export const useAssignableGroups = () => {
  const [getAssignableGroups, { data, loading, error }] = useLazyQuery(PIECEWORK_ASSIGNABLE_GROUPS);

  return {
    getAssignableGroups,
    pieceworkGroups: data?.groups,
    pieceworkGroupsLoading: loading,
    pieceworkGroupsError: error,
  };
};
