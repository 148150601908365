import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import * as classNames from 'classnames';
import * as React from 'react';
import './DataTable.scss';

export type DataTableHeader = {
  label?: string;
  sort?: string;
  size?: string;
};

export type DataTableProps = React.TableHTMLAttributes<HTMLTableElement> & {
  headers?: DataTableHeader[];
  onSortChange?: (sort: string) => void;
  sort?: string;
};

export const DataTable = ({ children, className, headers, onSortChange, sort, ...rest }: DataTableProps) => {
  function sortChange(e: string) {
    if (!onSortChange) {
      return;
    }
    onSortChange(sort === e ? `-${e}` : e);
  }

  function renderHeaders() {
    if (!headers) {
      return <></>;
    }

    return headers.map((header, index: number) => {
      const headerClass = classNames({
        'DataTable__header--sortable': header.sort,
        [`DataTable__header--${header.size}`]: header.size,
      });

      if (!header.sort) {
        return (
          <th key={index} className={headerClass}>
            {header.label}
          </th>
        );
      }

      return (
        <th
          key={index}
          className={headerClass}
          onClick={() => {
            if (header.sort) {
              sortChange(header.sort);
            }
          }}
        >
          {sort && sort.includes(header.sort) ? (
            <div className="DataTable__header__container">
              {header.label}

              {sort === header.sort ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          ) : (
            header.label
          )}
        </th>
      );
    });
  }

  return (
    <table className={classNames('DataTable', className)} {...rest}>
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
