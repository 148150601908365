import { LinkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Modal } from '~/src/components';
import { renderURL } from '~/src/utils/render';
import './ProductPreview.scss';

type ProductPreviewProps = {
  isOpen: boolean;
  onClose: () => void;
  product: any;
};

export const ProductPreview = (props: ProductPreviewProps) => {
  if (!props.product) {
    return null;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} style={{ width: 'fit-content' }}>
      <div className="ProductPreview">
        <img src={renderURL(props.product.pk)} />
        <div className="ProductPreview__info">
          <p>{props.product.name}</p>
          <p>{props.product.description}</p>
          <hr className="divider" />
          <label>Order in multiples of:</label>
          <div>{props.product.minQty}</div>
          <label>Unit price:</label>
          <div>{props.product.price}</div>
          <Button color="primary" iconLeading={<LinkIcon />}>
            View product information
          </Button>
        </div>
      </div>
    </Modal>
  );
};
