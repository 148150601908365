import * as React from 'react';
import { Button, Input, Modal, PaymentReqSelect, Textarea, Toggle } from '~/src/components';
import './CreditModal.scss';

type CreditModalProps = {
  account: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
};

type CreditFormData = {
  creditApp: boolean;
  creditAppHow: string;
  termsDays: number;
  paymentReq: any;
};

const defaultFormData: CreditFormData = {
  creditApp: false,
  creditAppHow: '',
  termsDays: 0,
  paymentReq: null,
};

export const CreditModal = (props: CreditModalProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData({
      creditApp: props.account.creditApp,
      creditAppHow: props.account.creditAppHow,
      termsDays: props.account.termsDays,
      paymentReq: props.account.paymentReq?.pk,
    });
  }, [props.isOpen]);

  function onCloseHandler() {
    props.onClose();
  }

  function onSubmitHandler(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSubmit(formData);
    onCloseHandler();
  }

  if (!props.account) {
    return <></>;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="CreditModal">
      <div className="CreditModal__header">
        <div className="font-size-18 color-gray-900">Credit and Terms</div>
      </div>
      <form onSubmit={onSubmitHandler}>
        <div className="CreditModal__body">
          <div className="CreditModal__field">
            <label className="CreditModal__field__label">Payment Requirement</label>
            <PaymentReqSelect
              value={formData.paymentReq}
              onChange={(e) => setFormData((prev) => ({ ...prev, paymentReq: e.target.value }))}
            />
          </div>

          <div className="CreditModal__field">
            <label className="CreditModal__field__label">Term (Days)</label>
            <Input
              onChange={(e) => setFormData({ ...formData, termsDays: parseInt(e.target.value) })}
              value={formData.termsDays || ''}
            />
          </div>

          <div className="CreditModal__field">
            <label className="CreditModal__field__label">Approved for Credit</label>
            <Toggle
              checked={formData.creditApp}
              onChange={() => setFormData({ ...formData, creditApp: !formData.creditApp })}
            />
          </div>

          <div className="CreditModal__field">
            <label className="CreditModal__field__label">How Approved</label>
            <Textarea
              cols={30}
              onChange={(e) => setFormData({ ...formData, creditAppHow: e.target.value })}
              value={formData.creditAppHow || ''}
            />
          </div>
        </div>

        <div className="CreditModal__footer">
          <Button color="light" onClick={props.onClose} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
