import * as React from 'react';
import { Button, Modal, Textarea } from '~/src/components';
import './EditAccountNotesModal.scss';

type EditAccountNotesModalProps = {
  isOpen: boolean;
  notes: string;
  onClose: () => void;
  onSubmit: (formData: any) => void;
};

type EditAccountNotesFormData = {
  notes: string;
};

const defaultFormData: EditAccountNotesFormData = {
  notes: '',
};

export const EditAccountNotesModal = (props: EditAccountNotesModalProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData({
      notes: props.notes,
    });
  }, [props.isOpen]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSubmit(formData);
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="EditAccountNotesModal">
      <div className="EditAccountNotesModal__header">
        <div className="font-size-18 color-gray-900">Edit Account Notes</div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="EditAccountNotesModal__body">
          <Textarea
            className="modalTextArea"
            fluid
            onChange={(e) => {
              setFormData({
                ...formData,
                notes: e.target.value,
              });
            }}
            rows={6}
            value={formData.notes || ''}
          />
        </div>
        <div className="EditAccountNotesModal__footer">
          <Button color="light" onClick={props.onClose} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
