import * as classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from '~/src/components';
import { formatDateString, toFixed2 } from '~/src/utils/formatting';
import './OpenOrdersTable.scss';

type OpenOrdersTableProps = {
  data: any;
  sort: string;
  onSortChange: (sort: string) => void;
};

export const OpenOrdersTable = (props: OpenOrdersTableProps) => {
  const tableHeaders = [
    { label: 'Invoice #', sort: 'id', size: 'sm' },
    { label: 'Account', sort: 'account', size: 'md' },
    { label: 'Ship date', sort: 'ship_date', size: 'sm' },
    { label: 'Order terms' },
    { label: 'Days past' },
    { label: 'Invoice' },
    { label: 'Payments' },
    { label: 'Remaining' },
  ];

  function daysPastClassNames(daysPast: number) {
    return classNames({
      'OpenOrdersTable__daysPast--orange': daysPast === 14,
      'OpenOrdersTable__daysPast--red': daysPast > 14,
    });
  }

  function paymentsClassNames(paymentsTotal: string) {
    const totalAsNumber = parseFloat(paymentsTotal);
    return classNames({
      'OpenOrdersTable__daysPast--green': totalAsNumber > 0,
    });
  }

  function renderTableData() {
    return props.data.edges?.map((edge: any, index: number) => (
      <tr key={index}>
        <td>
          <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
        </td>
        <td>{edge.node.account.name}</td>
        <td>{formatDateString(edge.node.shipDate)}</td>
        <td>{edge.node.termsDays}</td>
        <td className={daysPastClassNames(edge.node.daysPastDue)}>{edge.node.daysPastDue}</td>
        <td>${toFixed2(edge.node.invoiceTotal)}</td>
        <td className={paymentsClassNames(edge.node.paymentsTotal)}>${toFixed2(edge.node.paymentsTotal)}</td>
        <td>${toFixed2(edge.node.remainingTotal)}</td>
      </tr>
    ));
  }

  return (
    <DataTable headers={tableHeaders} sort={props.sort} onSortChange={props.onSortChange}>
      {renderTableData()}
    </DataTable>
  );
};
