import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Select, SelectProps } from '../@shared/Select/Select';

export const ACTIVE_SHIPPINGS_QUERY = gql`
  query activeShippings {
    shippings(active: true) {
      edges {
        node {
          id
          pk
          method
          active
          sortOrder
          trackingUrl
        }
      }
    }
  }
`;

type ShippingMethodSelectProps = Omit<SelectProps, 'options'> & {};

export const ShippingMethodSelect = (props: ShippingMethodSelectProps) => {
  const { data, loading } = useQuery(ACTIVE_SHIPPINGS_QUERY);

  if (loading || !data) return <div>Loading...</div>;

  const sortedShippings = [...data.shippings.edges].sort((a: any, b: any) => {
    return a.node.sortOrder - b.node.sortOrder;
  });

  const options = [
    {
      value: '',
      label: '',
    },
    ...sortedShippings.map((edge: any) => ({
      value: edge.node.pk,
      label: edge.node.method,
    })),
  ];

  return <Select options={options} {...props} />;
};
