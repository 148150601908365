import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '~/src/features/auth';
import { ToastProvider } from '~/src/features/toast';

const httpLink = new HttpLink({ uri: `${process.env.API_HOST}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  if (localStorage.getItem('token')) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    }));
  }
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: offsetLimitPagination(),
        },
      },
    },
  }),
  link: concat(authMiddleware, httpLink),
});

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ApolloProvider client={client}>
      <ToastProvider>
        <AuthProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </AuthProvider>
      </ToastProvider>
    </ApolloProvider>
  );
};
