import { gql } from '@apollo/client';

export const CREATE_AVAILABILITY_MUTATION = gql`
  mutation createAvailability($availabilityDatetime: DateTime!, $notes: String, $preferredTime: Time) {
    createAvailability(availabilityDatetime: $availabilityDatetime, notes: $notes, preferredTime: $preferredTime) {
      availability {
        id
      }
    }
  }
`;

export const UPDATE_AVAILABILITY_MUTATION = gql`
  mutation updateAvailability($id: ID!, $availabilityDatetime: DateTime!, $notes: String, $preferredTime: Time) {
    updateAvailability(
      id: $id
      availabilityDatetime: $availabilityDatetime
      notes: $notes
      preferredTime: $preferredTime
    ) {
      availability {
        id
      }
    }
  }
`;

export const REMOVE_AVAILABILITY_MUTATION = gql`
  mutation removeAvailability($pk: String!) {
    pieceworkerRemoveAvailability(availPk: $pk, remove: true) {
      removed
    }
  }
`;

export const ACCEPT_JOB_MUTATION = gql`
  mutation acceptJob($id: ID!) {
    acceptJob(id: $id) {
      job {
        id
        pk
        pickedUpDatetime
      }
    }
  }
`;

export const DECLINE_JOB_MUTATION = gql`
  mutation declineJob($id: String!) {
    pieceworkerDeclineJob(jobPk: $id, decline: true) {
      declined
    }
  }
`;

export const USER_PROFILE_UDPATE_MUTATION = gql`
  mutation updateProfilePicture($profilePk: String, $updates: UserProfileInput) {
    userProfileUpdate(profilePk: $profilePk, updates: $updates) {
      profile {
        id
        pk
        profilePicture {
          name
          file
          type
        }
      }
    }
  }
`;
