import { useLazyQuery } from '@apollo/client';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Spinner, TabItem, Tabs } from '~/src/components';
import { formatDateString } from '~/src/utils/formatting';
import { MY_JOBS_QUERY } from '../../api';
import './Jobs.scss';

export const Jobs = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [loadJobs, { loading, data }] = useLazyQuery(MY_JOBS_QUERY, {
    variables: {
      isOpen: isOpen,
    },
  });

  React.useEffect(() => {
    loadJobs();
  }, [isOpen]);

  function renderJobs() {
    return data?.jobs.edges.map((edge: any, idx: number) => {
      return (
        <div key={idx} className="job">
          <div className="job__header">
            <div className="job__header__title">Job ID# {edge.node.jobNum}</div>
            <Link to={`/jobs/${edge.node.pk}`}>
              <IconButton color="primary">
                <ChevronRightIcon />
              </IconButton>
            </Link>
          </div>
          {isOpen && (
            <>
              <div className="job__body__row">
                <div>Pick up date:</div>
                <div>{format(new Date(edge.node.pickupDatetime), 'MM/dd/yyyy')}</div>
              </div>
              <div className="job__body__row">
                <div>Due date:</div>
                <div>{formatDateString(edge.node.dueDate)}</div>
              </div>
            </>
          )}
          {!isOpen && (
            <div className="job__body__row job__body__row--completed">
              <div>Completed:</div>
              <div>{format(new Date(edge.node.returnedDatetime), 'MM/dd/yyyy')}</div>
            </div>
          )}
        </div>
      );
    });
  }

  if (loading) {
    return <Spinner message="Loading jobs..." />;
  }

  return (
    <div className="PWMyJobs">
      <h1 className="h1-mobile">My Jobs</h1>
      <Tabs>
        <TabItem active={isOpen} onClick={() => setIsOpen(true)}>
          Open
        </TabItem>
        <TabItem active={!isOpen} onClick={() => setIsOpen(false)}>
          Completed
        </TabItem>
      </Tabs>
      {renderJobs()}
    </div>
  );
};
