import * as React from 'react';
import { useQuery } from '@apollo/client';
import { AVAILABLE_PIECEWORKERS_QUERY } from '../api';

export const useAssigneeOptions = () => {
  const [assigneeOptions, setAssigneeOptions] = React.useState<any>([]);

  useQuery(AVAILABLE_PIECEWORKERS_QUERY, {
    onCompleted: (data) => {
      let unassigned = [
        {
          label: '',
          value: '',
        },
      ];
      let options = data.pieceworkers.edges.map((edge: any) => {
        return {
          label: `${edge.node.firstName} ${edge.node.lastName}`,
          value: edge.node.pk,
        };
      });
      setAssigneeOptions([...unassigned, ...options]);
    },
  });

  return {
    assigneeOptions,
  };
};
