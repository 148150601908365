import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { AccountDetail, AccountHistorySnapshot, AccountList, AccountProductSummary } from '~/src/features/accounts';
import { BillingDetails, BillingList } from '~/src/features/billing';
import { CreditMemosList } from '~/src/features/credit-memos';
import { EverynameOrderDetails, EverynameOrdersList } from '~/src/features/everyname-orders';
import { Home } from '~/src/features/misc';
import { OrderDetail, Orders } from '~/src/features/orders';
import { PayrollSummary } from '~/src/features/payroll';
import { Availability, CreateJob, JobDetail, Jobs, PrintSummary } from '~/src/features/piecework-coordinator';
import { ProductDetails, ProductHistory, ProductsList } from '~/src/features/products';
import { ProductionReports, ProductsReports, ReportsSplash, SalesReports } from '~/src/features/reports';
import { RepDetails, RepOrdersSummary, RepSalesHistory, RepsList } from '~/src/features/reps';
import {
  DisplayIndex,
  DisplayInstanceDetails,
  DisplayInstances,
  DisplayTemplateDetails,
  DisplayTemplates,
  DisplayModuleDetails,
  DisplayModules,
} from '~/src/features/displays';

export const adminRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  // accounts
  {
    path: '/accounts',
    element: <AccountList />,
  },
  {
    path: '/accounts/:accountId',
    element: <AccountDetail />,
  },
  {
    path: '/accounts/:accountId/reports/product-summary',
    element: <AccountProductSummary />,
  },
  {
    path: '/accounts/:accountId/reports/history-snapshot',
    element: <AccountHistorySnapshot />,
  },
  // billing
  {
    path: '/billing',
    element: <BillingList />,
  },
  {
    path: '/billing/:billingId',
    element: <BillingDetails />,
  },
  // credit-memos
  {
    path: '/credit-memos',
    element: <CreditMemosList />,
  },
  // everyname-orders
  {
    path: '/everyname-orders',
    element: <EverynameOrdersList />,
  },
  {
    path: '/everyname-orders/:orderId',
    element: <EverynameOrderDetails />,
  },
  // orders
  {
    path: '/orders',
    element: <Orders />,
  },
  {
    path: '/orders/:orderId',
    element: <OrderDetail />,
  },
  // payroll
  {
    path: '/piecework/jobs/:jobId',
    element: <JobDetail />,
  },
  {
    path: '/piecework/payroll-summary',
    element: <PayrollSummary />,
  },
  {
    path: '/piecework/print-summary/:jobId',
    element: <PrintSummary />,
  },
  // piecework-coordinator
  {
    path: '/piecework',
    element: <Navigate to="/piecework/jobs" />,
  },
  {
    path: '/piecework/create-job',
    element: <CreateJob />,
  },
  {
    path: '/piecework/edit-job/:jobId',
    element: <CreateJob />,
  },
  {
    path: '/piecework/availability',
    element: <Availability />,
  },
  {
    path: '/piecework/jobs',
    element: <Jobs />,
  },
  // products
  {
    path: '/products',
    element: <ProductsList />,
  },
  {
    path: '/products/:productId',
    element: <ProductDetails />,
  },
  {
    path: '/products/:productId/history',
    element: <ProductHistory />,
  },
  // reports
  {
    path: '/reports',
    element: <ReportsSplash />,
  },
  {
    path: '/reports/production/:type',
    element: <ProductionReports />,
  },
  {
    path: '/reports/products/:type',
    element: <ProductsReports />,
  },
  {
    path: '/reports/sales/:type',
    element: <SalesReports />,
  },
  // reps
  {
    path: '/reps',
    element: <RepsList />,
  },
  {
    path: '/reps/:repPk',
    element: <RepDetails />,
  },
  {
    path: '/reps/:repPk/reports/sales-history',
    element: <RepSalesHistory />,
  },
  {
    path: '/reps/:repPk/reports/orders-summary',
    element: <RepOrdersSummary />,
  },
  {
    path: '/displays',
    element: <DisplayIndex />,
  },
  {
    path: '/displays/templates',
    element: <DisplayTemplates />,
  },
  {
    path: '/displays/templates/:templateId',
    element: <DisplayTemplateDetails />,
  },
  {
    path: '/displays/instances',
    element: <DisplayInstances />,
  },
  {
    path: '/displays/instances/:instanceId',
    element: <DisplayInstanceDetails />,
  },
  {
    path: '/displays/modules',
    element: <DisplayModules />,
  },
  {
    path: '/displays/modules/:moduleId',
    element: <DisplayModuleDetails />,
  },
];
