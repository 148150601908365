import { useLazyQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Badge,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Spinner,
  Toggle,
} from '~/src/components';
import { formatDateString, formatPhoneNumber } from '~/src/utils/formatting';
import { ACCOUNT_SEARCH } from '../../api';
import './AccountList.scss';

const tableHeaders = [
  { label: 'Account #', sort: 'id', size: 'sm' },
  { label: 'Account', sort: 'name', size: 'md' },
  { label: 'City, State', sort: 'city', size: 'md' },
  { label: 'Billing', sort: 'billing', size: 'md' },
  { label: 'Primary Contact', sort: 'contact', size: 'md' },
  { label: 'Phone', sort: 'phone', size: 'sm' },
  { label: 'Last Order', sort: 'order_date', size: 'sm' },
  { label: 'Status', sort: 'status', size: 'sm' },
];

const PAGE_SIZE = 100;
const DEFAULT_SORT = 'name';

export const AccountList = () => {
  const [searchInput, setSearchInput] = React.useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchAccounts, { data, loading }] = useLazyQuery(ACCOUNT_SEARCH, {
    variables: {
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      search: searchParams.get('search') || '',
      sort: searchParams.get('sort') || DEFAULT_SORT,
      status: searchParams.get('status'),
    },
  });

  React.useEffect(() => {
    const search = searchParams.get('search');
    if (search) {
      setSearchInput(search);
    }
    searchAccounts();
  }, [searchParams]);

  function updateSearchParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  }

  function renderStatusBadge(status: string) {
    switch (status) {
      case 'Active':
        return <Badge color="success" label={status} />;
      case 'Inactive':
        return <Badge color="warning" label={status} />;
      default:
        return status ? <Badge color="light" label={status} /> : null;
    }
  }

  function renderTableItems() {
    return data.accounts.edges.map((account: any, index: number) => (
      <tr key={index}>
        <td>
          <Link to={`/accounts/${account.node.pk}`}>{account.node.pk}</Link>
        </td>
        <td>{account.node.name}</td>
        <td>
          {account.node.city}, {account.node.state}
        </td>
        <td>{account.node.billing.name}</td>
        <td>{account.node.contact}</td>
        <td>{account.node.phone && <a href={`tel:${account.node.phone}`}>{formatPhoneNumber(account.node.phone)}</a>}</td>
        <td>{account.node.lastOrderDate && formatDateString(account.node.lastOrderDate)}</td>
        <td>{renderStatusBadge(account.node.status?.status)}</td>
      </tr>
    ));
  }

  return (
    <>
      <Nav />
      <Container className="AccountList">
        <ListPageHeader title="All Accounts" />
        <div className="AccountList__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => setSearchInput(e.target.value)}
            onDebounce={(value) => updateSearchParams({ search: value, page: 1 })}
            placeholder="Search"
            value={searchInput}
          />
          <Toggle
            checked={!searchParams.get('status')}
            label={'View All'}
            onChange={() => {
              if (!searchParams.get('status')) {
                updateSearchParams({ status: 'Active', page: 1 });
                return;
              }
              updateSearchParams({ status: null, page: 1 });
              setSearchParams(searchParams);
            }}
          />
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading accounts..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={searchParams.get('sort') || DEFAULT_SORT}
              onSortChange={(sort) => {
                updateSearchParams({ sort, page: 1 });
              }}
            >
              {renderTableItems()}
            </DataTable>
            <Pagination
              page={parseInt(searchParams.get('page') || '1')}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => {
                updateSearchParams({ page });
              }}
              hasNextPage={data.accounts.pageInfo.hasNextPage}
              totalNodes={data.accounts.totalNodes}
              totalNodesOnPage={data.accounts.totalNodesOnPage}
            />
          </>
        )}
      </Container>
    </>
  );
};
