import { gql } from '@apollo/client';

export const ORDER_CREATE_MUTATION = gql`
  mutation orderCreate($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      order {
        id
        pk
      }
    }
  }
`;

export const ORDER_UPDATE_MUTATION = gql`
  mutation orderUpdate($orderPk: Int!, $input: OrderInput!) {
    orderUpdate(orderPk: $orderPk, input: $input) {
      order {
        id
        pk
      }
    }
  }
`;

export const ORDER_DETAIL_CREATE_MUTATION = gql`
  mutation orderDetailCreate($details: OrderDetailCreateInput!) {
    orderDetailCreate(details: $details) {
      orderDetail {
        id
        pk
      }
    }
  }
`;

export const ORDER_DETAIL_UPDATE_MUTATION = gql`
  mutation orderDetailUpdate($orderDetailPk: Int!, $details: OrderDetailInput!) {
    orderDetailUpdate(orderDetailPk: $orderDetailPk, details: $details) {
      orderDetail {
        id
        pk
      }
    }
  }
`;

export const ORDER_DETAIL_DELETE_MUTATION = gql`
  mutation orderDetailDelete($id: Int!) {
    orderDetailDelete(id: $id) {
      success
    }
  }
`;

export const PAYMENT_CREATE_MUTATION = gql`
  mutation paymentCreate($payment: PaymentInput!) {
    paymentCreate(payment: $payment) {
      payment {
        pk
      }
    }
  }
`;

export const PAYMENT_UPDATE_MUTATION = gql`
  mutation paymentUpdate($paymentPk: Int!, $payment: PaymentInput!) {
    paymentUpdate(payment: $payment, paymentPk: $paymentPk) {
      payment {
        pk
      }
    }
  }
`;

export const PRODUCTION_NOTE_CREATE_MUTATION = gql`
  mutation productionNoteCreate($input: ProductionNoteInput!) {
    productionNoteCreate(input: $input) {
      productionNote {
        pk
      }
    }
  }
`;
