import { ChevronDownIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, Popover } from '~/src/components';
import './RepNav.scss';

type RepNavProps = {
  rep: any;
};

export const RepNav = (props: RepNavProps) => {
  const [reportsPopoverOpen, setReportsPopoverOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Nav title="Reps">
      <div className="Nav__item">
        <div className="Nav__item__link">
          <Link to={{ pathname: '/accounts', search: `?search=${encodeURIComponent(props.rep?.user?.username)}` }}>
            Accounts
          </Link>
        </div>
      </div>
      <div className="Nav__item">
        <div className="Nav__item__link">
          <Link to={{ pathname: '/orders', search: `?search=${encodeURIComponent(props.rep?.user?.username)}` }}>
            Orders
          </Link>
        </div>
      </div>
      <div className="Nav__item">
        <div
          className="Nav__item__link"
          onClick={() => {
            setReportsPopoverOpen(true);
          }}
        >
          <a>Reports</a>
          <ChevronDownIcon />
        </div>
        <Popover
          isOpen={reportsPopoverOpen}
          onClose={() => setReportsPopoverOpen(false)}
          className="RepDetailsComponent__nav__popover"
        >
          <div className="flex">
            <div className="flex-1 RepDetailsComponent__nav__popover__column">
              <p className="font-size-16 font-medium color-gray-900">Displays</p>
              <a
                onClick={() => {
                  navigate(`/reps/${props.rep.pk}/?tab=reorders`);
                  setReportsPopoverOpen(false);
                }}
              >
                Displays needing Reorder
              </a>
            </div>
            <div className="flex-1 RepDetailsComponent__nav__popover__column">
              <p className="font-size-16 font-medium color-gray-900">Sales</p>
              <Link to={`/reps/${props.rep.pk}/reports/sales-history/`}>Sales History</Link>
              <Link to={`/reps/${props.rep.pk}/reports/orders-summary/`}>Orders Summary</Link>
            </div>
          </div>
        </Popover>
      </div>
    </Nav>
  );
};
