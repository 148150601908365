import * as React from 'react';
import { Button, DatePicker, Drawer, DrawerHeader } from '~/src/components';
import './EditApprovalDate.scss';

type EditApprovalDateProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditApprovalDate = (props: EditApprovalDateProps) => {
  return (
    <>
      <Drawer isOpen={props.isOpen} onClose={props.onClose}>
        <div className="EditApprovalDate">
          <DrawerHeader onClose={props.onClose} title="Edit" />
          <div className="EditApprovalDate__content">
            <div className="EditApprovalDate__field">
              <label>Pay approval</label>
              <DatePicker />
            </div>
            <div className="EditApprovalDate__field">
              <label>Paid</label>
              <DatePicker />
            </div>
          </div>
          <div className="EditApprovalDate__actions">
            <Button color="light" onClick={() => props.onClose()} variant="raised">
              Cancel
            </Button>
            <Button color="primary" onClick={() => props.onClose()} variant="raised">
              Approve
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};
