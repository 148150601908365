import * as classNames from 'classnames';
import * as React from 'react';
import { Link, To } from 'react-router-dom';
import './Button.scss';

export type ButtonColor = 'default' | 'primary' | 'accent' | 'warn' | 'light' | 'dark';

export type ButtonSize = 'sm' | 'md' | 'lg';

export type ButtonVariant = 'default' | 'raised' | 'outlined';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  color?: ButtonColor;
  fluid?: boolean;
  iconLeading?: React.ReactNode;
  iconTrailing?: React.ReactNode;
  navigateTo?: To;
  rounded?: boolean;
  size?: ButtonSize;
  testId?: string;
  variant?: ButtonVariant;
};

export const Button = ({
  className,
  children,
  color = 'default',
  fluid,
  iconLeading,
  iconTrailing,
  navigateTo,
  rounded,
  size = 'md',
  testId,
  type = 'button',
  variant = 'default',
  ...rest
}: ButtonProps) => {
  let buttonClass = classNames(
    {
      'Button--fluid': fluid,
      'Button--iconLeading': iconLeading,
      'Button--iconTrailing': iconTrailing,
      'Button--rounded': rounded,
      [`Button--color--${color}`]: color,
      [`Button--size--${size}`]: size,
      [`Button--variant--${variant}`]: variant,
    },
    className
  );

  if (navigateTo) {
    return (
      <Link className={buttonClass} data-testid={testId} to={navigateTo} {...rest}>
        {iconLeading && <span className="iconLeading">{iconLeading}</span>}
        {children}
        {iconTrailing && <span className="iconTrailing">{iconTrailing}</span>}
      </Link>
    );
  }

  return (
    <button className={buttonClass} data-testid={testId} type={type} {...rest}>
      {iconLeading && <span className="iconLeading">{iconLeading}</span>}
      {children}
      {iconTrailing && <span className="iconTrailing">{iconTrailing}</span>}
    </button>
  );
};
