import { gql } from '@apollo/client';

export const CUSTOM_ORDERS_UPDATE = gql`
  mutation customOrdersUpdate($pk: Int!, $values: CustomOrderInput!) {
    customOrdersUpdate(customOrderPk: $pk, values: $values) {
      customOrder {
        id
        pk
      }
    }
  }
`;

export const ORDER_DETAIL_DELETE = gql`
  mutation orderDetailsDelete($id: Int!) {
    orderDetailDelete(id: $id) {
      success
    }
  }
`;

export const CUSTOM_ORDER_DETAIL_DELETE = gql`
  mutation customOrderDetailDelete($id: Int!) {
    customOrderDetailDelete(id: $id) {
      customOrder {
        itemTotal
        shippingCost
        orderTotal
      }
    }
  }
`;
