import { useQuery } from '@apollo/client';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Checkbox, Input, Modal, Textarea } from '~/src/components';
import { GROUP_SHAPES_QUERY, PRODUCT_GROUPS_QUERY } from '../../api';
import { TypeaheadDropdown } from '../@shared';
import './ProductDetailsModal.scss';

type ProductDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  onSave: (updates: any) => void;
};

const productDefaults = {
  name: '',
  group: -1,
  shape: -1,
  upc: '',
  description: '',
  active: false,
  browsable: false,
  acrInventoried: false,
};

export const ProductDetailsModal = (props: ProductDetailsModalProps) => {
  const [product, setProduct] = React.useState(productDefaults);
  const [groupsPopoverOpen, setGroupsPopoverOpen] = React.useState(false);
  const [shapesPopoverOpen, setShapesPopoverOpen] = React.useState(false);
  const [cancelProduct, setCancelProduct] = React.useState(false);
  const [cancelledReason, setCancelledReason] = React.useState('');

  const { data: productGroupsData } = useQuery(PRODUCT_GROUPS_QUERY);
  const { data: groupShapesData } = useQuery(GROUP_SHAPES_QUERY);

  React.useEffect(() => {
    if (!props.product) return;
    setProduct({
      name: props.product?.name,
      upc: props.product?.upc,
      description: props.product?.description,
      active: props.product?.active,
      browsable: props.product?.browsable,
      acrInventoried: props.product?.acrInventoried,
      group: props.product?.group?.pk,
      shape: props.product?.shape?.pk,
    });
  }, [props.isOpen]);

  function onSubmitHandler() {
    props.onSave(product);
    onCloseHandler();
  }

  function onCloseHandler() {
    setProduct(productDefaults);
    setGroupsPopoverOpen(false);
    setShapesPopoverOpen(false);
    setCancelProduct(false);
    setCancelledReason('');
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ padding: '0', width: '700px', overflow: 'visible' }}>
      <div className="ProductDetails__detailsModal">
        <div className="ProductDetails__detailsModal__header">
          <p>Edit product details</p>
        </div>
        <div className="ProductDetails__detailsModal__body">
          {!cancelProduct ? (
            <>
              <div className="ProductDetails__detailsModal__body__productInfo">
                <p>Product information</p>
                <span className="ProductDetails__keyValueSpan">
                  <p>Name:</p>
                  <p>{product.name}</p>
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Group ID:</p>
                  <div className="relative">
                    <Input
                      readOnly
                      style={{ width: '225px' }}
                      onClick={() => setGroupsPopoverOpen(true)}
                      value={
                        productGroupsData?.groups?.edges.find((edge: any) => edge.node.pk === product.group)?.node.name
                      }
                    />
                    <TypeaheadDropdown
                      style={{ maxHeight: '500px' }}
                      isOpen={groupsPopoverOpen}
                      onClose={() => setGroupsPopoverOpen(false)}
                      onChange={(option) => setProduct((prev) => ({ ...prev, group: option.value }))}
                      options={productGroupsData?.groups?.edges.map((edge: any) => ({
                        label: edge.node.name,
                        value: edge.node.pk,
                      }))}
                    />
                  </div>
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Group Shape:</p>
                  <div className="relative">
                    <Input
                      readOnly
                      style={{ width: '225px' }}
                      onClick={() => setShapesPopoverOpen(true)}
                      value={
                        groupShapesData?.groupShapes?.edges.find((edge: any) => edge.node.pk === product.shape)?.node
                          .name
                      }
                    />
                    <TypeaheadDropdown
                      style={{ maxHeight: '500px' }}
                      isOpen={shapesPopoverOpen}
                      onClose={() => setShapesPopoverOpen(false)}
                      onChange={(option) => setProduct((prev) => ({ ...prev, shape: option.value }))}
                      options={groupShapesData?.groupShapes?.edges.map((edge: any) => ({
                        label: edge.node.name,
                        value: edge.node.pk,
                      }))}
                    />
                  </div>
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Product UPC:</p>
                  {product.upc ? (
                    <p>{product.upc}</p>
                  ) : (
                    <Button color="primary" size="sm" variant="raised">
                      Assign UPC
                    </Button>
                  )}
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Description:</p>
                  <Input
                    style={{ width: '300px' }}
                    value={product.description}
                    onChange={(e) => setProduct((prev) => ({ ...prev, description: e.target.value }))}
                  />
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Production Ready:</p>
                  <p>
                    {(() => {
                      if (props.product?.readyForProduction) {
                        let date = new Date(props.product.readyForProduction);
                        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                      }
                      return <span className="font-italic">Not ready for production</span>;
                    })()}
                  </p>
                </span>
              </div>
              <div className="ProductDetails__detailsModal__body__productCheckboxes">
                <p>Product Checkboxes</p>
                <div>
                  <div className="ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer">
                    <Checkbox
                      checked={product.active}
                      label="Active"
                      onChange={() => setProduct((prev) => ({ ...prev, active: !product.active }))}
                    />
                    <p>Get notified when someone posts a comment on a posting.</p>
                  </div>
                  <div className="ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer">
                    <Checkbox
                      checked={product.browsable}
                      label="Browsable"
                      onChange={() => setProduct((prev) => ({ ...prev, browsable: !product.browsable }))}
                    />
                    <p>Get notified when someone posts a comment on a posting.</p>
                  </div>
                  <div className="ProductDetails__detailsModal__body__productCheckboxes__checkboxContainer">
                    <Checkbox
                      checked={product.acrInventoried}
                      label="Inventoried"
                      onChange={() => setProduct((prev) => ({ ...prev, acrInventoried: !product.acrInventoried }))}
                    />
                    <p>Get notified when someone posts a comment on a posting.</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="ProductDetails__detailsModal__body__cancelProduct">
              <p>Cancel product</p>
              <div className="ProductDetails__warningBanner">
                <span>
                  <ExclamationTriangleIcon />
                  <p>Warning</p>
                </span>
                <p>Are you sure you want to cancel product {product.name}?</p>
              </div>
              <Textarea
                fluid
                onChange={(e) => setCancelledReason(e.target.value)}
                placeholder="Reason for cancellation"
                value={cancelledReason}
              />
            </div>
          )}
        </div>
        <div className="ProductDetails__detailsModal__buttons">
          {!cancelProduct ? (
            <>
              {props.product.cancelledDate ? (
                <p>{/* intentionally empty */}</p>
              ) : (
                <Button
                  className="ProductDetails__warningButton"
                  color="warn"
                  onClick={() => setCancelProduct(true)}
                  variant="raised"
                >
                  Cancel Product
                </Button>
              )}
              <span>
                <Button color="light" onClick={onCloseHandler} variant="raised">
                  Cancel
                </Button>
                <Button color="primary" onClick={onSubmitHandler} variant="raised">
                  Save
                </Button>
              </span>
            </>
          ) : (
            <>
              <p>{/* intentionally empty */}</p>
              <span style={{ alignSelf: 'flex-end' }}>
                <Button
                  color="light"
                  onClick={() => (setCancelProduct(false), setCancelledReason(''))}
                  variant="raised"
                >
                  Back
                </Button>
                <Button
                  className="ProductDetails__warningButton"
                  color="warn"
                  onClick={() => {
                    let cancelledDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`;
                    props.onSave({ active: false, cancelledDate, cancelledReason });
                    onCloseHandler();
                  }}
                  variant="raised"
                >
                  Cancel Product
                </Button>
              </span>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
