import { gql } from '@apollo/client';

export const PAYROLL_PENDING_APPROVAL_QUERY = gql`
  query payrollPendingApproval($search: String, $returnedDatetime_Lte: DateTime, $returnedDatetime_Gte: DateTime) {
    pieceworkers(search: $search, paymentStatus: "pending") {
      edges {
        node {
          id
          pk
          firstName
          lastName
          assignedJobs(
            status: COMPLETED
            returnedDatetime_Gte: $returnedDatetime_Gte
            returnedDatetime_Lte: $returnedDatetime_Lte
          ) {
            edges {
              node {
                id
                pk
                jobNum
                totalPrice
                pickupDatetime
                returnedDatetime
                tasks {
                  edges {
                    node {
                      id
                      pk
                      subtotal
                      orderDetails {
                        id
                        pk
                        quantity
                        order {
                          id
                          pk
                        }
                        product {
                          id
                          pk
                          group {
                            prefix
                          }
                        }
                      }
                      assignedProductGroupTasks {
                        edges {
                          node {
                            id
                            pk
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PAYROLL_PENDING_PAYMENT_QUERY = gql`
  query payrollPendingPayments($approvedDate_Lte: DateTime, $approvedDate_Gte: DateTime) {
    pieceworkPayments(paidDate_Isnull: true, approvedDate_Lte: $approvedDate_Lte, approvedDate_Gte: $approvedDate_Gte) {
      edges {
        node {
          id
          pk
          approvedDate
          paidDate
          amount
          pieceworker {
            id
            pk
            firstName
            lastName
          }
          jobs {
            edges {
              node {
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;

export const PAYROLL_PAID_QUERY = gql`
  query payrollPaid($approvedDate_Lte: DateTime, $approvedDate_Gte: DateTime) {
    pieceworkPayments(
      paidDate_Isnull: false
      approvedDate_Lte: $approvedDate_Lte
      approvedDate_Gte: $approvedDate_Gte
    ) {
      edges {
        node {
          id
          pk
          approvedDate
          paidDate
          amount
          pieceworker {
            id
            pk
            firstName
            lastName
          }
          jobs {
            edges {
              node {
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;
