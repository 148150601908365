import * as classNames from 'classnames';
import * as React from 'react';
import './Popover.scss';

type PopoverProps = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose?: () => void;
  testId?: string;
};

export const Popover = ({ children, className, isOpen, onClose, testId, ...rest }: PopoverProps) => {
  const popoverClass = classNames('Popover', className);

  return (
    <>
      {isOpen && (
        <>
          <div className={popoverClass} data-testid={testId} {...rest}>
            {children}
          </div>
          <div
            className="Popover__overlay"
            data-testid={`${testId}-overlay`}
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          ></div>
        </>
      )}
    </>
  );
};

export default Popover;
