import { useLazyQuery, useQuery } from '@apollo/client';
import { PrinterIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Button, Container, TabItem, Tabs } from '~/src/components';
import { ACCOUNT_HISTORY_QUERY, HISTORY_SUMMARY_PDF_QUERY } from '../../../api';
import { AccountNav } from '../../../components';
import './AccountHistorySnapshot.scss';
import { format } from 'date-fns';

type AccountHistorySnapshotProps = {};

export const AccountHistorySnapshot = (props: AccountHistorySnapshotProps) => {
  const [activeTab, setActiveTab] = React.useState('overview');

  const [dollarsSpent, setDollarsSpent] = React.useState<any>({});
  const [orderStats, setOrderStats] = React.useState<any>({});
  const [units, setUnits] = React.useState<any>({});

  const { accountId } = useParams();
  const { data, loading } = useQuery(ACCOUNT_HISTORY_QUERY, {
    variables: {
      id: accountId,
    },
    onCompleted: (data) => {
      setDollarsSpent(JSON.parse(data.account.accountHistory?.dollarsSpent));
      setOrderStats(JSON.parse(data.account.accountHistory?.orderStats));
      setUnits(JSON.parse(data.account.accountHistory?.units));
    },
  });

  const [loadSummaryPdf] = useLazyQuery(HISTORY_SUMMARY_PDF_QUERY);

  function handlePrintReport() {
    loadSummaryPdf({
      variables: {
        accountPk: Number(accountId),
      },
    }).then((res) => {
      res.data && downloadPdf(res.data.accountSummaryHistoryPdf, data.account.name);
    });
  }

  function downloadPdf(base64Pdf: string, filename: string) {
    base64Pdf = `data:application/pdf;base64,${base64Pdf}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Pdf;
    downloadLink.download = `${filename}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  }

  function renderUnits() {
    return Object.keys(units).map((key, index) => {
      return (
        <tr key={index}>
          <td>{key}:</td>
          <td>{units[key]['3']}</td>
          <td>{units[key]['6']}</td>
          <td>{units[key]['12']}</td>
          <td>{units[key]['0']}</td>
        </tr>
      );
    });
  }

  function renderDollarsSpent() {
    return Object.keys(dollarsSpent).map((key, index) => {
      return (
        <tr key={index}>
          <td>{key}:</td>
          <td>
            {(Number(dollarsSpent[key]['3']) || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </td>
          <td>
            {(Number(dollarsSpent[key]['6']) || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </td>
          <td>
            {(Number(dollarsSpent[key]['12']) || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </td>
          <td>
            {(Number(dollarsSpent[key]['0']) || 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </td>
        </tr>
      );
    });
  }

  function overview() {
    return (
      <>
        <div className="HistorySnapshot__data">
          <div className="HistorySnapshot__data__card">
            <span>
              <p>First Order:</p>
              <p>
                {data.account.accountHistory?.firstOrderDate &&
                  format(new Date(data.account.accountHistory?.firstOrderDate), 'M/d/yyyy')}
              </p>
            </span>
            <span>
              <p>Recent Order:</p>
              <p>
                {data.account.accountHistory?.recentOrderDate &&
                  format(new Date(data.account.accountHistory?.recentOrderDate), 'M/d/yyyy')}
              </p>
            </span>
            <span>
              <p>Avg. Order cost:</p>
              <p>${data.account.accountHistory?.averageOrderCost}</p>
            </span>
            <span>
              <p>Avg. Pay:</p>
              <p>{data.account.accountHistory?.averagePay}</p>
            </span>
            <span>
              <p>Terms (Days):</p>
              <p>{data.account.termsDays}</p>
            </span>
          </div>
          <div className="HistorySnapshot__data__table">
            {orderStats.months && (
              <table className="data-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>3 months</th>
                    <th>6 months</th>
                    <th>1 year</th>
                    <th>lifetime</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Orders:</td>
                    <td>{orderStats.months['3'].total_orders}</td>
                    <td>{orderStats.months['6'].total_orders}</td>
                    <td>{orderStats.months['12'].total_orders}</td>
                    <td>{orderStats.months['0'].total_orders}</td>
                  </tr>
                  <tr>
                    <td>Dollars Spent:</td>
                    <td>
                      {Number(orderStats.months['3'].dollars_spent).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                    <td>
                      {Number(orderStats.months['6'].dollars_spent).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                    <td>
                      {Number(orderStats.months['12'].dollars_spent).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                    <td>
                      {Number(orderStats.months['0'].dollars_spent).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div className="HistorySnapshot__tables">
          <div className="HistorySnapshot__tables__table">
            <p>Units</p>
            <table className="data-table">
              <thead>
                <tr>
                  <th>group</th>
                  <th>3 months</th>
                  <th>6 months</th>
                  <th>1 year</th>
                  <th>lifetime</th>
                </tr>
              </thead>
              <tbody>{renderUnits()}</tbody>
            </table>
          </div>
          <div className="HistorySnapshot__tables__table">
            <p>Dollars Spent</p>
            <table className="data-table">
              <thead>
                <tr>
                  <th>group</th>
                  <th>3 months</th>
                  <th>6 months</th>
                  <th>1 year</th>
                  <th>lifetime</th>
                </tr>
              </thead>
              <tbody>{renderDollarsSpent()}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function orderTable() {
    return (
      <div className="HistorySnapshot__orderTable">
        <div className="HistorySnapshot__orderTable__header">
          <div style={{ display: 'flex' }}>
            <span>
              <p>Invoice #</p>
              <a>99706</a>
            </span>
            <span>
              <p>Ship Date:</p>
              <p>05/28/2019</p>
            </span>
            <span>
              <p>Paid Date:</p>
              <p>05/28/2019</p>
            </span>
            <span>
              <p>Paid days:</p>
              <p>58</p>
            </span>
          </div>
          <span>
            <p>Total:</p>
            <p>$937.19</p>
          </span>
        </div>
        <div className="HistorySnapshot__orderTable__tableContainer">
          <table className="data-table">
            <colgroup>
              <col style={{ width: '10%' }}></col>
              <col style={{ width: '30%' }}></col>
              <col style={{ width: '10%' }}></col>
              <col style={{ width: '25%' }}></col>
              <col style={{ width: '25%' }}></col>
            </colgroup>
            <thead>
              <tr>
                <th>item number</th>
                <th>description</th>
                <th>qty</th>
                <th>unit price</th>
                <th>sub-total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a>11758</a>
                </td>
                <td>9/24/2022</td>
                <td>30</td>
                <td>$1875.00</td>
                <td>$1875.00</td>
              </tr>
              <tr>
                <td>
                  <a>Ship 140</a>
                </td>
                <td>9/24/2022</td>
                <td>30</td>
                <td>$1875.00</td>
                <td>$1875.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  if (!data || loading) {
    return null;
  }

  return (
    <div className="HistorySnapshot">
      <AccountNav account={data?.account} />
      <Container className="HistorySnapshot__container" style={{ padding: '24px' }}>
        <div className="HistorySnapshot__body">
          <div className="HistorySnapshot__header">
            <p>History Snapshot</p>
            <Tabs className="HistorySnapshot__header__tabs">
              <TabItem
                active={activeTab === 'overview'}
                onClick={() => setActiveTab('overview')}
                className="HistorySnapshot__header__tabs__wideTab"
              >
                <p>Overview</p>
              </TabItem>
              <div className="HistorySnapshot__header__tabSpacer"></div>
              <TabItem active={activeTab === 'pending'} onClick={() => setActiveTab('pending')}>
                <p>Pending</p>
                <Badge color="light" label="1" />
              </TabItem>
              <TabItem active={activeTab === 'open'} onClick={() => setActiveTab('open')}>
                <p>Open</p>
                <Badge color="light" label="3" />
              </TabItem>
              <TabItem active={activeTab === 'past'} onClick={() => setActiveTab('past')}>
                <p>Past</p>
                <Badge color="light" label="5" />
              </TabItem>
              <div className="HistorySnapshot__header__tabsButton">
                <Button iconLeading={<PrinterIcon />} size="sm" variant="outlined" onClick={handlePrintReport}>
                  Print History
                </Button>
              </div>
            </Tabs>
          </div>
          {activeTab === 'overview' && overview()}
          {activeTab === 'pending' && [...Array(1)].map((el, index) => orderTable())}
          {activeTab === 'open' && [...Array(3)].map((el, index) => orderTable())}
          {activeTab === 'past' && [...Array(5)].map((el, index) => orderTable())}
        </div>
      </Container>
    </div>
  );
};
