import * as React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useLazyQuery } from '@apollo/client';
import { ChartBarIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Container, DataTable, DataTableHeader, DatePicker, Pagination, Select, Spinner } from '~/src/components';
import { REP_ID_QUERY, REP_ORDERS_SUMMARY_QUERY } from '../../../api';
import { RepNav } from '../../../components';
import './RepOrdersSummary.scss';

type RepOrdersSummaryFilters = {
  dateType: 'completionDate' | 'shipDate' | 'orderDate' | 'releaseDate' | 'approvedDate';
  startDate: Date | undefined;
  endDate: Date | undefined;
  page: number;
  pageSize: number;
};

const RepOrdersSummaryFiltersDefaults: RepOrdersSummaryFilters = {
  dateType: 'completionDate',
  startDate: undefined,
  endDate: undefined,
  page: 1,
  pageSize: 100,
};

const RepOrdersSummaryTableHeaders: DataTableHeader[] = [
  { label: 'invoice', sort: 'invoice', size: 'sm' },
  { label: 'account', sort: 'account' },
  { label: 'city', sort: 'city' },
  { label: 'state', sort: 'state', size: 'sm' },
  { label: 'po number', sort: 'po_number' },
  { label: 'order date', sort: 'order_date' },
  { label: 'ship date', sort: 'ship_date' },
  { label: 'paid date', sort: 'paid_date' },
  { label: 'invoice total', sort: 'total' },
];

export const RepOrdersSummary = () => {
  const { repPk } = useParams();
  const [repId, setRepId] = React.useState('');
  const [filters, setFilters] = React.useState(RepOrdersSummaryFiltersDefaults);
  const [loadRepId, { data: repIdData }] = useLazyQuery(REP_ID_QUERY);
  const [loadOrders, { data, loading }] = useLazyQuery(REP_ORDERS_SUMMARY_QUERY);

  React.useEffect(() => {
    if (repPk) {
      repId
        ? loadOrders({
            variables: {
              rep: repId,
              first: filters.pageSize,
              offset: (filters.page - 1) * filters.pageSize,
              [`${filters.dateType}_Gte`]: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : undefined,
              [`${filters.dateType}_Lte`]: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
            },
          })
        : loadRepId({ variables: { repPk: Number(repPk) }, onCompleted: (data) => setRepId(data.rep.id) });
    }
  }, [repId, filters]);

  if (!repIdData) return <Spinner message="Loading..." />;

  return (
    <div className="RepOrdersSummary">
      <RepNav rep={repIdData?.rep || { pk: repPk }} />
      <Container className="RepOrdersSummary__container" style={{ padding: '24px' }}>
        <div className="RepOrdersSummary__body">
          <div className="RepOrdersSummary__backLink">
            <Link to={`/reps/${repPk}`} className="RepOrdersSummary__backLink__link">
              <ChevronLeftIcon />
              <p>Orders Summary</p>
            </Link>
          </div>
          <div className="RepOrdersSummary__navigation">
            <div className="RepOrdersSummary__navigation__content">
              <div className="RepOrdersSummary__navigation__content__title">
                <div className="RepOrdersSummary__navigation__content__title__symbol">
                  <ChartBarIcon />
                </div>
                <div>
                  <p>Total Sales</p>
                  <p>$8,805.10</p>
                </div>
              </div>
              <div className="RepOrdersSummary__navigation__content__column">
                <span>
                  <p>Display:</p>
                  <p>$7,897.00</p>
                </span>
                <span>
                  <p>Non-display:</p>
                  <p>$908.10</p>
                </span>
              </div>
              <div className="RepOrdersSummary__navigation__content__column">
                <span>
                  <p>Orders:</p>
                  <p>68</p>
                </span>
                <span>
                  <p>Avg. Sale:</p>
                  <p>$908.10</p>
                </span>
              </div>
            </div>
            <div className="RepOrdersSummary__navigation__inputs">
              <div className="RepOrdersSummary__navigation__inputs__inputGroup">
                <p>Time frame</p>
                <Select
                  value={filters.dateType}
                  onChange={(e) => setFilters((prev) => ({ ...prev, dateType: e.target.value }))}
                  disabled={!filters.startDate && !filters.endDate}
                  options={[
                    { label: 'Shipped', value: 'shipDate' },
                    { label: 'Ordered', value: 'orderDate' },
                    { label: 'Approved', value: 'approvedDate' },
                    { label: 'Released', value: 'releaseDate' },
                    { label: 'Completed', value: 'completionDate' },
                  ]}
                />
              </div>
              <div className="RepOrdersSummary__navigation__inputs__inputGroup">
                <span
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  onClick={() => setFilters((p) => ({ ...p, endDate: undefined, startDate: undefined }))}
                >
                  <p>Date range</p>
                  <a>Reset</a>
                </span>
                <div className="RepOrdersSummary__navigation__inputs__inputGroup__dateRange">
                  <DatePicker
                    value={filters.startDate}
                    onChange={(date) => setFilters((prev) => ({ ...prev, startDate: date }))}
                    style={{ right: 0 }}
                  />
                  <DatePicker
                    value={filters.endDate}
                    onChange={(date) => setFilters((prev) => ({ ...prev, endDate: date }))}
                    style={{ right: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!data || loading ? (
            <Spinner message="Loading orders..." />
          ) : (
            <>
              <DataTable className={'RepOrdersSummary__table'} headers={RepOrdersSummaryTableHeaders}>
                {data.orders.edges.map((edge: any) => (
                  <tr key={edge.node.id}>
                    <td>
                      <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
                    </td>
                    <td>
                      <Link to={`/accounts/${edge.node.account?.pk}`}>{edge.node.account?.name}</Link>
                    </td>
                    <td>{edge.node.account?.city}</td>
                    <td>{edge.node.account?.state}</td>
                    <td>{edge.node.poNumber}</td>
                    <td>{edge.node.orderDate && format(new Date(edge.node.orderDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.shipDate && format(new Date(edge.node.shipDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.paidDate && format(new Date(edge.node.paidDate), 'M/d/yyyy')}</td>
                    <td>
                      {edge.node.invoiceTotal &&
                        Number(edge.node.invoiceTotal).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                    </td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                hasNextPage={data.orders.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((prev) => ({ ...prev, page }))}
                page={filters.page}
                pageSize={filters.pageSize}
                totalNodes={data.orders.totalNodes}
                totalNodesOnPage={data.orders.totalNodesOnPage}
              ></Pagination>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
