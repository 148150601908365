import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Select, SelectProps } from '../@shared/Select/Select';

export const PRIORITY_TYPES_QUERY = gql`
  query activePriorityTypes {
    priorityTypes(priorityActive: true) {
      edges {
        node {
          id
          pk
          priorityName
          priorityNumber
          priorityDescription
          priorityActive
        }
      }
    }
  }
`;

type PriorityTypeSelectProps = Omit<SelectProps, 'options'> & {};

export const PriorityTypeSelect = (props: PriorityTypeSelectProps) => {
  const { data, loading } = useQuery(PRIORITY_TYPES_QUERY);

  if (loading || !data) return <div>Loading...</div>;

  const options = [
    {
      value: '',
      label: '',
    },
    ...data.priorityTypes.edges.map((edge: any) => ({
      value: edge.node.pk,
      label: edge.node.priorityName,
    })),
  ];

  return <Select options={options} {...props} />;
};
