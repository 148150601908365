import * as classNames from 'classnames';
import * as React from 'react';
import './Input.scss';

export type InputSize = 'sm' | 'md' | 'lg';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  fluid?: boolean;
  filled?: boolean;
  iconButtonTrailing?: {
    icon: React.ReactNode;
    onClick?: (e: any) => void;
  };
  iconLeading?: React.ReactNode;
  iconTrailing?: React.ReactNode;
  inputSize?: InputSize; // "size" conflicts with InputHTMLAttributes.size
  rounded?: boolean;
  testId?: string;
};

export const Input = React.forwardRef(
  (
    {
      className,
      filled,
      fluid,
      iconButtonTrailing,
      iconLeading,
      iconTrailing,
      inputSize = 'md',
      rounded,
      testId,
      ...rest
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const containerClass = classNames('Input__container', {
      'Input__container--fluid': fluid,
    });

    const inputClass = classNames(
      {
        'Input': !filled,
        'Input--filled': filled,
        'Input--fluid': fluid,
        'Input--iconLeading': iconLeading,
        'Input--iconTrailing': iconTrailing || iconButtonTrailing,
        'Input--rounded': rounded,
        [`Input--size--${inputSize}`]: inputSize,
      },
      className
    );

    return (
      <div className={containerClass}>
        {iconLeading && <span className="iconLeading">{iconLeading}</span>}
        {iconTrailing && <span className="iconTrailing">{iconTrailing}</span>}
        {iconButtonTrailing && (
          <span className="iconButtonTrailing" onClick={iconButtonTrailing.onClick}>
            {iconButtonTrailing.icon}
          </span>
        )}
        <input className={inputClass} data-testid={testId} ref={ref} {...rest} />
      </div>
    );
  }
);
