import { CalendarIcon, CurrencyDollarIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PieceworkIcon from '~/src/assets/img/icon-piecework.svg';
import './PieceworkerFooter.scss';

export const PieceworkerFooter = () => {
  const navigate = useNavigate();

  return (
    <div className="PieceworkerFooter">
      <div
        className="PieceworkerFooter__link"
        onClick={() => {
          navigate('/jobs');
        }}
      >
        <div className="PieceworkerFooter__link__icon">
          <img src={PieceworkIcon} />
        </div>
        <div className="PieceworkerFooter__link__text">My Jobs</div>
      </div>
      <div
        className="PieceworkerFooter__link"
        onClick={() => {
          navigate('/availability');
        }}
      >
        <div className="PieceworkerFooter__link__icon">
          <CalendarIcon />
        </div>
        <div className="PieceworkerFooter__link__text">Availability</div>
      </div>
      <div
        className="PieceworkerFooter__link"
        onClick={() => {
          navigate('/payments');
        }}
      >
        <div className="PieceworkerFooter__link__icon">
          <CurrencyDollarIcon />
        </div>
        <div className="PieceworkerFooter__link__text">Payments</div>
      </div>
      <div
        className="PieceworkerFooter__link"
        onClick={() => {
          navigate('/profile');
        }}
      >
        <div className="PieceworkerFooter__link__icon">
          <UserCircleIcon />
        </div>
        <div className="PieceworkerFooter__link__text">Profile</div>
      </div>
    </div>
  );
};
