import { useLazyQuery } from '@apollo/client';
import { PIECEWORKER_PROFILE_QUERY } from '../api';

export const usePieceworkerProfile = () => {
  const [getPieceworker, { data, loading }] = useLazyQuery(PIECEWORKER_PROFILE_QUERY);

  return {
    getPieceworker,
    pieceworker: data?.user,
    pieceworkerLoading: loading,
  };
};
