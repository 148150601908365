import * as React from 'react';
import { SideNav } from '~/src/components';
import { useAuth } from '~/src/features/auth';
import { Toaster } from '~/src/features/toast';
import { AppRoutes } from '~/src/routes';
import './AdminLayout.scss';

export const AdminLayout = () => {
  const [sideNavClass, setSideNavClass] = React.useState('AdminLayout__sidebar');
  const { user } = useAuth();

  return (
    <div className="AdminLayout">
      <Toaster />
      {user && (
        <div className={sideNavClass}>
          <SideNav
            onCollapseChange={(isCollapsed) => {
              setSideNavClass(
                isCollapsed ? 'AdminLayout__sidebar AdminLayout__sidebar--collapsed' : 'AdminLayout__sidebar'
              );
            }}
          />
        </div>
      )}
      <div className="AdminLayout__routes">
        <AppRoutes />
      </div>
    </div>
  );
};
