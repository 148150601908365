import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Availability, AvailabilityEdit, JobDetail, Jobs, Payments, Profile } from '~/src/features/pieceworker';

export const pieceworkerRoutes = [
  {
    path: '/',
    element: <Navigate to="/jobs" />,
  },
  {
    path: '/jobs',
    element: <Jobs />,
  },
  {
    path: '/jobs/:jobId',
    element: <JobDetail />,
  },
  {
    path: '/availability',
    element: <Availability />,
  },
  {
    path: '/availability/create',
    element: <AvailabilityEdit />,
  },
  {
    path: '/availability/update/:editPk',
    element: <AvailabilityEdit />,
  },
  {
    path: '/payments',
    element: <Payments />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
];
