import * as classNames from 'classnames';
import * as React from 'react';
import './Menu.scss';

type MenuProps = React.HTMLAttributes<HTMLDivElement> & {
  testId?: string;
};

export const Menu = ({ className, testId, ...rest }: MenuProps) => {
  const menuClass = classNames('Menu', className);

  return (
    <>
      <div className={menuClass} data-testid={testId} {...rest} />
    </>
  );
};
