import * as React from 'react';
import './Tabs.scss';

type TabsProps = {
  className?: string;
  children?: any;
  style?: React.CSSProperties;
  testId?: string;
};

export const Tabs = (props: TabsProps) => {
  let tabsClass = 'Tabs';

  if (props.className) {
    tabsClass += ` ${props.className}`;
  }

  return (
    <>
      <div className={tabsClass} style={props.style} data-testid={props.testId}>
        {props.children}
      </div>
    </>
  );
};
