import * as React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Badge, Button, Input, Modal, Toggle } from '~/src/components';
import './EditContact.scss';

type EditContactProps = {
  contact: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (val: any) => void;
};

type EditContactFormData = {
  email?: string;
  name?: string;
  phone?: string;
  primary?: boolean;
  sendInvoices?: boolean;
  title?: string;
};

const defaultFormData: EditContactFormData = {
  email: '',
  name: '',
  phone: '',
  primary: false,
  sendInvoices: false,
  title: '',
};

export const EditContact = (props: EditContactProps) => {
  const [formData, setFormData] = React.useState<EditContactFormData>(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) return;
    setFormData({
      email: props.contact.email,
      name: props.contact.name,
      phone: props.contact.phone,
      primary: props.contact.primary,
      sendInvoices: props.contact.sendInvoices,
      title: props.contact.title,
    });
  }, [props.isOpen]);

  function onCloseHandler() {
    setFormData(defaultFormData);
    props.onClose();
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.onSubmit(formData);
  }

  if (!props.contact) {
    return <></>;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="EditContact">
      <div className="EditContact__header">
        <div className="font-size-18 color-gray-900">Edit contact</div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="EditContact__body">
          <div className="EditContact__user">
            <div className="flex align-center gap-4">
              {/* <img src="https://via.placeholder.com/104" width={104} height={104} alt="user profile" /> */}
              <div className="flex flex-column gap-2">
                <span className="font-size-24 font-semi-bold">{props.contact.name}</span>
                {props.contact.title && <span className="mb-8">{props.contact.title}</span>}
                {props.contact.primary && <Badge color="success" label="Primary Contact" />}
              </div>
            </div>
          </div>
          <div className="flex flex-column contactInfoRow">
            <span className="font-size-18 contactInfoRow__header">Contact Information</span>
            <div className="flex justify-between">
              <div className="flex flex-column w-full">
                <div className="contactInfo">
                  <span className="contactPropertyText">Full name</span>
                  <Input
                    fluid
                    placeholder="Name"
                    type="text"
                    value={formData.name || ''}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </div>
                <div className="contactInfo">
                  <span className="contactPropertyText">Title</span>
                  <Input
                    fluid
                    placeholder="Title"
                    type="text"
                    value={formData.title || ''}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  />
                </div>
                <div className="contactInfo">
                  <span className="contactPropertyText">Phone</span>
                  <Input
                    fluid
                    placeholder="Phone"
                    type="tel"
                    value={formData.phone || ''}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  />
                </div>
                <div className="contactInfo">
                  <span className="contactPropertyText">Email</span>
                  <Input
                    fluid
                    type="email"
                    placeholder="Email"
                    value={formData.email || ''}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </div>
                <div className="contactInfo">
                  <span>Primary contact</span>
                  <Toggle
                    checked={formData.primary}
                    onChange={() => setFormData({ ...formData, primary: !formData.primary })}
                  />
                </div>
                {!props.contact.primary && formData.primary && (
                  <div className="EditContact__warning">
                    <div className="EditContact__warning__icon">
                      <ExclamationTriangleIcon />
                    </div>
                    <div className="EditContact__warning__message">
                      <p className="EditContact__warning__message__title">Warning</p>
                      <p>This will remove the Primary Contact status from all other contacts.</p>
                    </div>
                  </div>
                )}
                <div className="contactInfo">
                  <span>Send invoices</span>
                  <Toggle
                    checked={formData.sendInvoices}
                    onChange={() => setFormData({ ...formData, sendInvoices: !formData.sendInvoices })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="EditContact__footer">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
