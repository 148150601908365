import * as React from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { DebouncedInput, IconButton } from '../@shared';
import './Pagination.scss';

export type PaginationProps = {
  hasNextPage: boolean;
  onPageChange: (page: number) => void;
  page: number;
  pageSize: number;
  totalNodes: number;
  totalNodesOnPage: number;
};

export const Pagination = (props: PaginationProps) => {
  const [page, setPage] = React.useState('');

  React.useEffect(() => {
    setPage(props.page.toString());
  }, [props.page]);

  function totalPages() {
    return Math.ceil(props.totalNodes / props.pageSize);
  }

  function pageInputChange(val: any) {
    if (val === '' || isNaN(parseInt(val)) || parseInt(val) < 1) {
      props.onPageChange(1);
      setPage('1');
    } else if (parseInt(val) > totalPages()) {
      props.onPageChange(totalPages());
      setPage(totalPages().toString());
    } else {
      props.onPageChange(parseInt(val));
      setPage(parseInt(val).toString());
    }
  }

  function renderPageNodes() {
    const firstNode = props.pageSize * (props.page - 1) + 1;
    let lastNode = props.pageSize * props.page;

    if (lastNode > props.totalNodes) {
      lastNode = props.totalNodes;
    }

    return (
      <div>
        Showing {firstNode}—{lastNode} of {props.totalNodes} results
      </div>
    );
  }

  return (
    <div className="Pagination">
      {renderPageNodes()}
      <div className="Pagination__spacer"></div>
      <div className="Pagination__buttons">
        <IconButton disabled={props.page <= 1} onClick={() => props.onPageChange(1)}>
          <ChevronDoubleLeftIcon />
        </IconButton>
        <IconButton disabled={props.page <= 1} onClick={() => props.onPageChange(props.page - 1)}>
          <ChevronLeftIcon />
        </IconButton>
        <DebouncedInput
          onChange={(e) => setPage(e.target.value)}
          onDebounce={(val) => pageInputChange(val)}
          value={page}
          debounceTimer={1000}
        />
        <div>of {totalPages()}</div>
        <IconButton disabled={!props.hasNextPage} onClick={() => props.onPageChange(props.page + 1)}>
          <ChevronRightIcon />
        </IconButton>
        <IconButton disabled={!props.hasNextPage} onClick={() => props.onPageChange(totalPages())}>
          <ChevronDoubleRightIcon />
        </IconButton>
      </div>
    </div>
  );
};
