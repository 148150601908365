import * as React from 'react';
import { useMutation } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/solid';
import { http } from '~/src/http';
import { Button, Spinner } from '~/src/components';
import {
  DISPLAY_DETAIL_APPLY_NAMELIST_MUTATION,
  DISPLAY_DETAIL_ADD_PRODUCT_TO_ALL_MUTATION,
  DISPLAY_SECTION_CREATE_MUTATION,
  DISPLAY_SECTION_DELETE_MUTATION,
  DISPLAY_MODULE_CLONE_MUTATION,
  DISPLAY_MODULE_DELETE_MUTATION,
  DISPLAY_POSITION_UPDATE_MUTATION,
} from '../../api';
import { DisplayModule, AddModuleModal, ConfirmationModal, ApplyNamelistModal, AddProductToAllModal } from '..';
import './DisplayPanel.scss';

type DisplayPanelProps = {
  displayId: number | string | undefined;
  panel: any;
  queryOptions?: any;
  onPanelClone: () => void;
  onPanelDelete: () => void;
  isTemplate?: boolean;
};

export const DisplayPanel = (props: DisplayPanelProps) => {
  const [addModuleData, setAddModuleData] = React.useState<{ isOpen: boolean; section: number | null }>({
    isOpen: false,
    section: null,
  });
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });
  const [addProductToAllModal, setAddProductToAllModal] = React.useState(false);
  const [applyNamelistModal, setApplyNamelistModal] = React.useState(false);

  const [addSection, { loading: load1 }] = useMutation(DISPLAY_SECTION_CREATE_MUTATION, props.queryOptions);
  const [deleteSection, { loading: load2 }] = useMutation(DISPLAY_SECTION_DELETE_MUTATION, props.queryOptions);
  const [cloneModule, { loading: load3 }] = useMutation(DISPLAY_MODULE_CLONE_MUTATION, props.queryOptions);
  const [deleteModule, { loading: load4 }] = useMutation(DISPLAY_MODULE_DELETE_MUTATION, props.queryOptions);
  const [updatePosition, { loading: load5 }] = useMutation(DISPLAY_POSITION_UPDATE_MUTATION, props.queryOptions);
  const [applyNamelist, { loading: load6 }] = useMutation(DISPLAY_DETAIL_APPLY_NAMELIST_MUTATION, props.queryOptions);
  const [addProductToAll, { loading: load7 }] = useMutation(
    DISPLAY_DETAIL_ADD_PRODUCT_TO_ALL_MUTATION,
    props.queryOptions
  );

  if (load1 || load2 || load3 || load4 || load5 || load6 || load7) {
    return <Spinner message="Saving changes..." />;
  }

  return (
    <>
      <div className="DisplayPanel__content">
        <div className="DisplayPanel__content__header">
          <div className="DisplayPanel__content__header__panelInfo"></div>
          <span className="DisplayPanel__content__header__links">
            <a onClick={() => props.onPanelClone()}>Clone panel</a>|
            <a
              onClick={() =>
                setConfirmationModal({
                  isOpen: true,
                  title: 'Delete panel',
                  message: 'Are you sure you want to delete this panel?',
                  onSubmit: () => props.onPanelDelete(),
                })
              }
            >
              Delete Panel
            </a>
          </span>
        </div>
        <div className="DisplayPanel__sectionTitle">
          <h4 className="DisplayPanel__sectionTitle__label">Sections:</h4>
          <span className="DisplayPanel__sectionTitle__buttons">
            <Button
              className="DisplayPanel__sectionTitle__button"
              variant="raised"
              color="primary"
              onClick={() => {
                addSection({
                  variables: {
                    [props.isTemplate ? 'templatePk' : 'displayPk']: props.displayId,
                    panel: props.panel.order,
                    input: {},
                  },
                });
              }}
            >
              Add section
            </Button>
            <Button
              className="DisplayPanel__sectionTitle__button"
              color="primary"
              variant="raised"
              onClick={() => setAddProductToAllModal(true)}
            >
              Apply Product to All
            </Button>
            <Button color="primary" variant="raised" onClick={() => setApplyNamelistModal(true)}>
              Apply Namelist
            </Button>
          </span>
        </div>
        <div className="DisplayPanel__sections">
          {props.panel.sections.edges?.length ? (
            props.panel.sections.edges
              .map((edge: any) => edge.node)
              .map((section: any, index: number) => (
                <div className="DisplayPanel__section" key={index}>
                  <div className="DisplayPanel__sections__title">
                    <p>Section {section?.order}</p>
                    <span>
                      <a
                        onClick={() => {
                          setConfirmationModal({
                            isOpen: true,
                            title: 'Delete section',
                            message: 'Are you sure you want to delete this section?',
                            onSubmit: () => {
                              deleteSection({
                                variables: {
                                  [props.isTemplate ? 'templatePk' : 'displayPk']: props.displayId,
                                  panel: props.panel.order,
                                  section: section.order,
                                },
                              });
                            },
                          });
                        }}
                      >
                        Delete Section
                      </a>
                    </span>
                  </div>
                  <div className="DisplayPanel__modules">
                    {section.modules.edges
                      .map((edge: any) => edge.node)
                      .map((module: any, index: number) => (
                        <DisplayModule
                          key={index}
                          module={module}
                          onRemoveModule={() => deleteModule({ variables: { pk: module.pk } })}
                          onUpdatePosition={({ product, variables, positioning }) => {
                            updatePosition({
                              variables: {
                                modulePk: module.pk,
                                ...positioning,
                                input: { product, variables },
                              },
                            });
                          }}
                        />
                      ))}
                    <Button
                      color="primary"
                      variant="raised"
                      onClick={() => setAddModuleData((p) => ({ section: section.pk, isOpen: true }))}
                      iconLeading={<PlusIcon />}
                      style={{ width: 'fit-content' }}
                    >
                      Add module
                    </Button>
                  </div>
                </div>
              ))
          ) : (
            <p style={{ fontStyle: 'italic' }}>No sections added</p>
          )}
        </div>
      </div>
      <AddModuleModal
        isOpen={addModuleData.isOpen}
        onClose={() => setAddModuleData({ isOpen: false, section: null })}
        onSubmit={(data) => {
          cloneModule({ variables: { module: data.module, section: addModuleData.section } });
        }}
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
        onSubmit={confirmationModal.onSubmit}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
      <AddProductToAllModal
        isOpen={addProductToAllModal}
        onClose={() => setAddProductToAllModal(false)}
        onSubmit={(data) => {
          addProductToAll({ variables: { ...data, pk: props.displayId } });
        }}
        displaySections={props.panel.sections?.edges.map((edge: any) => edge.node)}
        // should be all panels' sections, not just the active one, should put this component on parent
      />
      <ApplyNamelistModal
        isOpen={applyNamelistModal}
        onClose={() => setApplyNamelistModal(false)}
        onSubmit={({ variableName, values, section, csv }) => {
          if (csv) {
            http
              .post(
                '/api/display_model/list',
                { display_pk: props.displayId, file: csv },
                { headers: { 'Content-Type': 'multipart/form-data' } }
              )
              .then(() => props.queryOptions?.onCompleted?.());
          }
          if (variableName && values) {
            applyNamelist({ variables: { pk: props.displayId, section, values, variableName } });
          }
        }}
      />
    </>
  );
};
