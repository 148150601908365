import { gql } from '@apollo/client';

export const CREATE_JOB_MUTATION = gql`
  mutation createJob($assignee: ID!, $dueDate: Date!, $pickupDatetime: DateTime!, $tasks: [CreateJobTaskInput]!) {
    createJob(assignee: $assignee, dueDate: $dueDate, pickupDatetime: $pickupDatetime, tasks: $tasks) {
      job {
        id
        pk
      }
    }
  }
`;

export const UPDATE_JOB_MUTATION = gql`
  mutation updateJob($jobPk: String!, $updates: JobInput) {
    updateJob(jobPk: $jobPk, updates: $updates) {
      job {
        id
        pk
      }
    }
  }
`;

export const DELETE_JOB_MUTATION = gql`
  mutation deleteJob($confirm: Boolean!, $jobPk: String!) {
    deleteJob(confirm: $confirm, jobPk: $jobPk) {
      success
    }
  }
`;

export const CREATE_PIECEWORKER_TRAINING = gql`
  mutation createPieceworkerTraining($groupPk: Int!, $pieceworkerPk: Int!) {
    pieceworkerProductGroupTrainingCreate(groupPk: $groupPk, pieceworkerPk: $pieceworkerPk) {
      pieceworkerProductGroupTraining {
        id
        pk
      }
    }
  }
`;

export const DELETE_PIECEWORKER_TRAINING = gql`
  mutation deletePieceworkerTraining($pk: String!) {
    pieceworkerProductGroupTrainingDelete(confirm: true, pk: $pk) {
      deleted
    }
  }
`;

export const JOB_TASK_CREATE_MUTATION = gql`
  mutation jobTaskCreate($jobPk: UUID!, $task: SingleCreateJobTaskInput!) {
    jobTaskCreate(jobPk: $jobPk, task: $task) {
      jobTask {
        id
        pk
        created
        modified
      }
    }
  }
`;

export const JOB_TASK_UPDATE_MUTATION = gql`
  mutation jobTaskUpdate($jobTaskPk: UUID!, $task: JobTaskInput!) {
    jobTaskUpdate(jobTaskPk: $jobTaskPk, task: $task) {
      jobTask {
        id
        pk
        created
        modified
      }
    }
  }
`;

export const JOB_TASK_DELETE_MUTATION = gql`
  mutation jobTaskDelete($jobTaskPk: String!) {
    jobTaskDelete(jobTaskPk: $jobTaskPk, confirm: true) {
      success
    }
  }
`;

export const JOB_TASK_VERIFY_MUTATION = gql`
  mutation jobTaskVerify($jobTaskPk: UUID!, $returnedQuantity: Int!) {
    jobTaskVerify(jobTaskPk: $jobTaskPk, returnedQuantity: $returnedQuantity) {
      jobTask {
        id
        pk
      }
    }
  }
`;
