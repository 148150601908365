import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Input, Modal, Toggle } from '~/src/components';
import './AddContactModal.scss';

type AddContactModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
};

type AddContactFormData = {
  name: string;
  title?: string;
  phone?: string;
  email: string;
  primary: boolean;
  sendInvoices: boolean;
};

const defaultFormData: AddContactFormData = {
  name: '',
  title: undefined,
  phone: undefined,
  email: '',
  primary: false,
  sendInvoices: false,
};

export const AddContactModal = (props: AddContactModalProps) => {
  const inputList = [
    {
      label: 'Full Name',
      placeholder: 'Name',
      inputType: 'text',
      name: 'name',
    },
    {
      label: 'Title',
      placeholder: 'Job Title',
      inputType: 'text',
      name: 'title',
    },
    {
      label: 'Phone',
      placeholder: 'Phone',
      inputType: 'phone',
      name: 'phone',
    },
    {
      label: 'Email',
      placeholder: 'Email',
      inputType: 'email',
      name: 'email',
    },
  ];

  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData(defaultFormData);
  }, [props.isOpen]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.onSubmit(formData);
  }

  function inputChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function renderInputs() {
    return inputList.map((input, idx) => (
      <div className="AddContactModal__field" key={idx}>
        <label className="AddContactModal__field__label">{input.label}</label>
        <Input name={input.name} onChange={inputChangeHandler} placeholder={input.placeholder} />
      </div>
    ));
  }
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="AddContactModal">
      <div className="AddContactModal__header">
        <div className="font-size-18 color-gray-900">Add Contact</div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="AddContactModal__body">
          <div className="font-size-18 color-gray-700 mb-6">Contact Information</div>

          {renderInputs()}

          <div className="AddContactModal__field">
            <label className="AddContactModal__field__label">Primary Contact</label>
            <Toggle
              checked={formData.primary}
              onChange={() => {
                setFormData({ ...formData, primary: !formData.primary });
              }}
            />
          </div>

          {formData.primary && (
            <div className="AddContactModal__warning">
              <div className="AddContactModal__warning__icon">
                <ExclamationTriangleIcon />
              </div>
              <div className="AddContactModal__warning__message">
                <div className="AddContactModal__warning__message__title">Warning</div>
                <div>This will remove the Primary Contact status from all other contacts.</div>
              </div>
            </div>
          )}

          <div className="AddContactModal__field">
            <label className="AddContactModal__field__label">Send Invoices</label>
            <Toggle
              checked={formData.sendInvoices}
              onChange={() => setFormData({ ...formData, sendInvoices: !formData.sendInvoices })}
            />
          </div>
        </div>

        <div className="AddContactModal__footer">
          <Button color="light" onClick={props.onClose} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={!formData.name || !formData.email} variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
