import * as React from 'react';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useAuth } from '~/src/features/auth';
import { Button, Container, Input } from '../@shared';
import logo from '~/src/assets/img/logo.png';
import './Nav.scss';

type NavProps = {
  title?: string;
  children?: React.ReactNode;
  showSearch?: boolean;
  searchPlaceholder?: string;
  // onSearch?: () => void;
};

export const Nav = (props: NavProps) => {
  const { user } = useAuth();

  if (user) {
    return (
      <div className="Nav Nav--loggedIn">
        <Container>
          <div className="Nav__content">
            {props.title && <div className="Nav__title">{props.title}</div>}
            {/* links/popovers will go here */}
            {props.children}
            {props.showSearch && (
              <>
                <div className="Nav__spacer"></div>
                <Input iconLeading={<MagnifyingGlassIcon />} placeholder={props.searchPlaceholder} />
              </>
            )}
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className="Nav">
      <Container>
        <div className="Nav__content">
          <Link to="/">
            <img className="Nav__logo" src={logo} />
          </Link>
          <div className="Nav__spacer"></div>
          <Link to="/login">
            <Button color="primary" testId="login-button" variant="raised">
              Login
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};
