import * as React from 'react';
import { Container, Nav } from '~/src/components';

export const Home = () => {
  return (
    <>
      <Nav />
      <Container>
        <div className="center-page">
          <h3 className="headspace">Welcome to BW Portal</h3>
        </div>
      </Container>
    </>
  );
};
