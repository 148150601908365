import * as React from 'react';
import { BuildingLibraryIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { useMutation } from '@apollo/client';
import { useAuth } from '~/src/features/auth';
import avatar from '~/src/assets/img/Avatar_Placeholder.png';
import { convertBase64, resizeImage } from '~/src/utils/base64';
import { formatProfilePicture } from '~/src/features/reps';
import { USER_PROFILE_UDPATE_MUTATION } from '../../api';
import './Profile.scss';

export const Profile = () => {
  const { user, getUser } = useAuth();
  const [updateProfile] = useMutation(USER_PROFILE_UDPATE_MUTATION, { onCompleted: () => getUser() });

  return (
    <div className="PWProfile">
      <div className="PWProfile__imageHeader">
        <img src={formatProfilePicture(user.profile?.profilePicture) || avatar} alt="Profile Picture" />
      </div>
      <div className="PWProfile__body">
        <div className="PWProfile__info">
          <p className="PWProfile__info__name">{`${user.firstName} ${user.lastName}`}</p>
          {user.profile.phone && <p>{user.profile.phone}</p>}
          {user.profile.cell && <p>{user.profile.cell}</p>}
          {user.email && <p>{user.email}</p>}
          {user.address && (
            <span>
              <p>{user.profile.address}</p>
              {user.profile.city && user.profile.state && user.profile.zip && (
                <p>{`${user.profile.city}, ${user.profile.state} ${user.profile.zip}`}</p>
              )}
            </span>
          )}
        </div>
        <div className="PWProfile__picture">
          <span>
            <img src={formatProfilePicture(user.profile?.profilePicture) || avatar} alt="Profile Picture" />
            <p>Update picture</p>
          </span>
          <div className="PWProfile__picture__fileInput">
            <label htmlFor="profile_picture">Change</label>
            <input
              id="profile_picture"
              type="file"
              accept="image/*"
              onChange={async (e) => {
                const file = e.target.files?.[0];
                file &&
                  (await convertBase64(e.target.files?.[0])?.then(async (base64: string) => {
                    await resizeImage(base64, { contentType: file.type }).then((res) => {
                      updateProfile({
                        variables: {
                          profilePk: user.profile.pk,
                          updates: {
                            profilePicture: {
                              file: res.b64,
                              name: file.name,
                              type: file.type,
                            },
                          },
                        },
                      });
                    });
                  }));
              }}
            />
          </div>
        </div>
        <div className="PWProfile__paymentMethod">
          <p>Payment Method</p>
          <div className="PWProfile__paymentMethod__banner">
            {false ? (
              <>
                <div className="PWProfile__paymentMethod__banner__icon">
                  <BuildingLibraryIcon />
                </div>
                <p>Direct Deposit</p>
              </>
            ) : (
              <>
                <div className="PWProfile__paymentMethod__banner__icon">
                  <EnvelopeIcon />
                </div>
                <p>Paper check</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
