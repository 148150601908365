import * as React from 'react';
import { format } from 'date-fns';
import { useLazyQuery, useMutation } from '@apollo/client';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useToast } from '~/src/features/toast';
import { Badge, Button, DatePicker, Drawer, Select, Spinner, Textarea } from '~/src/components';
import { DISPLAY_FOLLOWUPS_QUERY, DISPLAY_FOLLOWUP_CREATE_MUTATION } from '../../api';
import './DisplayFollowupDrawer.scss';
import { useParams } from 'react-router';

type DisplayFollowupDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  displays: any[];
};

type FormData = {
  display: string;
  actionNotes: string;
  followupDate?: Date;
};

const formDataDefaults: FormData = {
  display: '',
  actionNotes: '',
};

export const DisplayFollowupDrawer = (props: DisplayFollowupDrawerProps) => {
  const { repPk } = useParams();
  const [formData, setFormData] = React.useState<FormData>(formDataDefaults);
  const [createDisplayFollowup] = useMutation(DISPLAY_FOLLOWUP_CREATE_MUTATION);
  const [loadDisplayFollowups, { data, loading }] = useLazyQuery(DISPLAY_FOLLOWUPS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const { error } = useToast();

  React.useEffect(() => {
    repPk && loadDisplayFollowups({ variables: { rep: Number(repPk) } });
  }, [props.isOpen]);

  function graphqlDateFormatting(date: Date) {
    return `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ssXXXX')}`;
  }

  function handleCreateDisplayFollowup() {
    createDisplayFollowup({
      variables: {
        input: {
          rep: Number(repPk),
          display: Number(formData.display),
          actionNotes: formData.actionNotes,
          followupDate: formData.followupDate && graphqlDateFormatting(formData.followupDate),
        },
      },
      onCompleted: () => {
        loadDisplayFollowups({ variables: { rep: Number(repPk) } });
        setFormData(formDataDefaults);
      },
      onError: () => error('An error has occured.'),
    });
  }

  function handleOnClose() {
    setFormData(formDataDefaults);
    props.onClose();
  }

  return (
    <Drawer backdrop isOpen={props.isOpen} onClose={handleOnClose} className="DisplayFollowupDrawer">
      <div className="DisplayFollowupDrawer__header">
        <p>Display Follow-up</p>
        <XMarkIcon onClick={handleOnClose} />
      </div>
      <div className="DisplayFollowupDrawer__content">
        <div className="DisplayFollowupDrawer__content__left">
          <div className="DisplayFollowupDrawer__content__left__action">
            <p>Action</p>
          </div>
          <span className="DisplayFollowupDrawer__content__left__span">
            <p>Display ID</p>
            <Select
              fluid
              value={formData.display}
              onChange={(e: any) => setFormData({ ...formDataDefaults, display: e.target.value })}
              options={[
                { label: '', value: '' },
                ...props.displays.map((display: any) => ({ value: display.pk, label: display.pk })),
              ]}
            />
          </span>
          <span className="DisplayFollowupDrawer__content__left__span">
            <p>Action notes</p>
            <Textarea
              fluid
              placeholder="Action notes here..."
              value={formData.actionNotes}
              onChange={(e) => setFormData((p) => ({ ...p, actionNotes: e.target.value }))}
            />
          </span>
          <span className="DisplayFollowupDrawer__content__left__span">
            <p>Follow-up date</p>
            <DatePicker
              value={formData.followupDate}
              onChange={(date) => setFormData((p) => ({ ...p, followupDate: date }))}
            />
          </span>
          <Button
            variant="raised"
            color="primary"
            className="DisplayFollowupDrawer__content__left__button"
            disabled={!formData.display || !formData.actionNotes}
            onClick={handleCreateDisplayFollowup}
          >
            Create note
          </Button>
        </div>
        <div className="DisplayFollowupDrawer__content__right">
          {!data || loading ? (
            <Spinner message="Loading reps..." />
          ) : data.displayFollowUps.edges.length ? (
            data.displayFollowUps.edges
              .sort((a: any, b: any) => {
                const date1 = new Date(a.node.created);
                const date2 = new Date(b.node.created);
                return date1 < date2 ? 1 : date1 > date2 ? -1 : 0;
              })
              .map((edge: any, index: number) => (
                <div className="DisplayFollowupDrawer__content__right__note" key={index}>
                  <span className="DisplayFollowupDrawer__content__right__note__span">
                    <p className="DisplayFollowupDrawer__content__right__note__span__title">{edge.node.display.pk}</p>
                    <p className="DisplayFollowupDrawer__content__right__note__span__time">
                      {format(new Date(edge.node.created), 'M/d/yyyy h:mm aa')}
                    </p>
                  </span>
                  <p>{edge.node.actionNotes}</p>
                  {edge.node.followupDate && (
                    <Badge color="light" label={format(new Date(edge.node.followupDate), 'M/d/yyyy')} />
                  )}
                </div>
              ))
          ) : (
            <p className="DisplayFollowupDrawer__content__right__info">No display follow-up notes.</p>
          )}
        </div>
      </div>
      <div className="DisplayFollowupDrawer__buttons">
        <Button variant="raised" color="light" onClick={handleOnClose}>
          Close
        </Button>
      </div>
    </Drawer>
  );
};
