import * as React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid';
import {
  ArrowLeftOnRectangleIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  // PhotoIcon,
  // PresentationChartLineIcon,
  ShoppingCartIcon,
  ReceiptRefundIcon,
  UserIcon,
  UserGroupIcon,
  // PresentationChartBarIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import { useAuth } from '~/src/features/auth';
import bucketLogo from '~/src/assets/img/bucket.png';
import EverynameOrdersIcon from '~/src/assets/img/icon-everyname-orders.svg';
import PieceworkIcon from '~/src/assets/img/icon-piecework-solid.svg';
import avatar from '~/src/assets/img/Avatar_Placeholder.png';
import logo from '~/src/assets/img/logo.png';
import './SideNav.scss';

const sideNavLinks = [
  { to: '/orders', label: 'Orders', icon: <DocumentTextIcon /> },
  { to: '/accounts', label: 'Accounts', icon: <FolderOpenIcon /> },
  { to: '/billing', label: 'Billing', icon: <CurrencyDollarIcon /> },
  { to: '/reps', label: 'Reps', icon: <UserGroupIcon /> },
  { to: '/credit-memos', label: 'Credit Memos', icon: <ReceiptRefundIcon /> },
  // { to: '/sales', label: 'Sales', icon: <PresentationChartLineIcon /> },
  { to: '/products', label: 'Products', icon: <ShoppingCartIcon /> },
  // { to: '/art-jobs', label: 'Art Jobs', icon: <PhotoIcon /> },
  { to: '/everyname-orders', label: 'Everyname Orders', icon: <img src={EverynameOrdersIcon} /> },
  { to: '/reports', label: 'Reports', icon: <ChartBarIcon /> },
  { to: "/displays", label: "Display Builder", icon: <WrenchScrewdriverIcon /> },
];

type SideNavProps = {
  onCollapseChange?: (isCollapsed: boolean) => void;
};

export const SideNav = (props: SideNavProps) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  function renderNavLink(to: string, label: string, icon: React.ReactNode) {
    return (
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? 'SideNav__link SideNav__link--active' : 'SideNav__link')}
        title={label}
      >
        {icon}
        <div>{label}</div>
      </NavLink>
    );
  }

  return (
    <div className="SideNav">
      <div className={`SideNav__content${isCollapsed ? ' SideNav__contentCollapsed' : ''}`}>
        <div className="SideNav__logoContainer">
          <Link to="/">
            <img className="SideNav__logo" src={isCollapsed ? bucketLogo : logo} data-testid="bw-logo"></img>
          </Link>
        </div>
        {isCollapsed ? (
          <div className="SideNav__userCollapsed">
            <div className="SideNav__userCollapsed__avatar">
              <UserIcon />
            </div>
            <ArrowLeftOnRectangleIcon className="SideNav__userCollapsed__icon" onClick={logout} />
          </div>
        ) : (
          <div className="SideNav__user">
            <img src={avatar} className="SideNav__user__avatar" />
            <div className="SideNav__user__text">
              <p>
                {user.firstName} {user.lastName}
              </p>
              <a onClick={logout}>Logout</a>
            </div>
          </div>
        )}
        <div className="SideNav__links">
          {sideNavLinks.map((link, idx) => (
            <div key={idx}>{renderNavLink(link.to, link.label, link.icon)}</div>
          ))}
          {user?.isPieceworkCoordinator &&
            renderNavLink('/piecework', 'Piecework', <img src={PieceworkIcon} style={{ color: 'white' }} />)}
        </div>
        {isCollapsed ? (
          <ChevronDoubleRightIcon
            onClick={() => {
              if (!props.onCollapseChange) {
                return;
              }
              setIsCollapsed(false);
              props.onCollapseChange(false);
            }}
          />
        ) : (
          <ChevronDoubleLeftIcon
            onClick={() => {
              if (!props.onCollapseChange) {
                return;
              }
              setIsCollapsed(true);
              props.onCollapseChange(true);
            }}
          />
        )}
      </div>
    </div>
  );
};
