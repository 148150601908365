import * as React from 'react';
import { Button, Modal, Textarea } from '~/src/components';

type CancelOrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (reason: string) => void;
};

export const CancelOrderModal = (props: CancelOrderModalProps) => {
  const [cancelReason, setCancelReason] = React.useState('');

  function onCloseHandler() {
    setCancelReason('');
    props.onClose();
  }

  function onSaveHandler() {
    props.onSave(cancelReason);
    onCloseHandler();
  }

  return (
    <div className="EverynameDetails__modal EverynameDetails__cancelOrderModal">
      <Modal isOpen={props.isOpen} onClose={onCloseHandler}>
        <div className="EverynameDetails__modal__header EverynameDetails__cancelOrderModal__header">
          <p>Cancel Order</p>
        </div>
        <div className="EverynameDetails__cancelOrderModal__content">
          <Textarea
            fluid
            placeholder="Reason for cancellation?"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </div>
        <div className="EverynameDetails__modal__buttons">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="primary" variant="raised" onClick={onSaveHandler}>
            Save & Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};
