import * as React from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { DISPLAY_PRODUCT_GROUPS_QUERY, DISPLAY_GROUP_VARIATIONS_QUERY } from '~/src/features/displays/api';
import { Modal, Button, Input, SearchPopover } from '~/src/components';
import './CreateModuleModal.scss';

type CreateModuleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { name: string; allowedGroupVariations: string[]; limitToGroups: string[] }) => void;
};

export const CreateModuleModal = (props: CreateModuleModalProps) => {
  const [moduleName, setModuleName] = React.useState('');
  const [limitToGroups, setLimitToGroups] = React.useState<any[]>([]);
  const [groupsPopoverOpen, setGroupsPopoverOpen] = React.useState(false);
  const [allowedGroupVariations, setAllowedGroupVariations] = React.useState<any[]>([]);
  const [variationsPopoverOpen, setVariationsPopoverOpen] = React.useState(false);

  function onCloseHandler() {
    setModuleName('');
    setLimitToGroups([]);
    setAllowedGroupVariations([]);
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSubmit({
      name: moduleName,
      limitToGroups: limitToGroups.map((group) => group.pk),
      allowedGroupVariations: allowedGroupVariations.map((variation) => variation.pk),
    });
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="CreateModuleModal">
      <p>Create a new module</p>
      <div className="CreateModuleModal__input">
        <p>Name</p>
        <Input placeholder="Enter module name..." value={moduleName} onChange={(e) => setModuleName(e.target.value)} />
      </div>
      <div className="CreateModuleModal__input">
        <p>Limit groups</p>
        <div style={{ position: 'relative' }} className="CreateModuleModal__input__limitToGroups">
          <Input readOnly placeholder="Select product group..." onClick={() => setGroupsPopoverOpen(true)} />
          <SearchPopover
            isOpen={groupsPopoverOpen}
            onClose={() => setGroupsPopoverOpen(false)}
            onChange={(group) => setLimitToGroups((p) => [...p, group])}
            query={DISPLAY_PRODUCT_GROUPS_QUERY}
            queryArgument="search"
            queryField="groups"
            renderMatch={(group) => (
              <>
                {group.pk} - {group.name}
              </>
            )}
          />
          {limitToGroups.map((group) => (
            <div className="CreateModuleModal__input__limitToGroups__group" key={group.id}>
              <p>
                - {group.pk} - {group.name}
              </p>
              <TrashIcon onClick={() => setLimitToGroups((p) => p.filter((g) => g.pk != group.pk))} />
            </div>
          ))}
        </div>
      </div>
      <div className="CreateModuleModal__input">
        <p>Limit group variations</p>
        <div style={{ position: 'relative' }} className="CreateModuleModal__input__groupVariations">
          <Input readOnly placeholder="Select group variation..." onClick={() => setVariationsPopoverOpen(true)} />
          <SearchPopover
            isOpen={variationsPopoverOpen}
            onClose={() => setVariationsPopoverOpen(false)}
            onChange={(variation) => setAllowedGroupVariations((p) => [...p, variation])}
            query={DISPLAY_GROUP_VARIATIONS_QUERY}
            queryArgument="search"
            queryField="groupVariations"
            renderMatch={(variation) => (
              <>
                {variation.pk} - {variation.shortName}
              </>
            )}
          />
          {allowedGroupVariations.map((variation) => (
            <div className="CreateModuleModal__input__groupVariations__variation" key={variation.id}>
              <p>
                - {variation.pk} - {variation.shortName}
              </p>
              <TrashIcon onClick={() => setAllowedGroupVariations((p) => p.filter((v) => v.pk != variation.pk))} />
            </div>
          ))}
        </div>
      </div>
      <div className="CreateModuleModal__buttons">
        <Button variant="raised" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button color="primary" variant="raised" disabled={!moduleName} onClick={onSubmitHandler}>
          Create module
        </Button>
      </div>
    </Modal>
  );
};
