import * as React from 'react';
import { ACCOUNT_SEARCH } from '~/src/features/accounts';
import { Button, Input, Modal, SearchPopover } from '~/src/components';
import './EditInstanceModal.scss';
import { DISPLAY_TEMPLATES_QUERY } from '~src/features/displays/api';

type EditInstanceModalProps = {
  instance: any;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { account: any; template: any }) => void;
};

export const EditInstanceModal = (props: EditInstanceModalProps) => {
  const [account, setAccount] = React.useState<any>(null);
  const [accountPopoverOpen, setAccountPopoverOpen] = React.useState(false);
  const [template, setTemplate] = React.useState<any>(null);
  const [templatePopoverOpen, setTemplatePopoverOpen] = React.useState(false);

  React.useEffect(() => {
    setAccount(props.instance?.account);
    setTemplate(props.instance?.template);
  }, [props.isOpen]);

  function onCloseHandler() {
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSave({ account, template });
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="EditInstanceModal">
      <p className="EditInstanceModal__title">Edit Instance</p>
      <form>
        <div className="EditInstanceModal__input">
          <p>Account:</p>
          <div style={{ position: 'relative' }}>
            <Input
              value={account?.name || ''}
              placeholder="Set account..."
              readOnly
              onClick={() => setAccountPopoverOpen(true)}
            />
            <SearchPopover
              isOpen={accountPopoverOpen}
              onChange={(account) => setAccount(account)}
              onClose={() => setAccountPopoverOpen(false)}
              query={ACCOUNT_SEARCH}
              queryArgument={'search'}
              queryField={'accounts'}
              renderMatch={(acc) => (
                <>
                  {acc.pk} - {acc.name}
                </>
              )}
            />
          </div>
        </div>
        <div className="EditInstanceModal__input">
          <p>Apply Template:</p>
          <div style={{ position: 'relative' }}>
            <Input
              value={template?.name || ''}
              placeholder="Choose a template..."
              readOnly
              onClick={() => setTemplatePopoverOpen(true)}
            />
            <SearchPopover
              isOpen={templatePopoverOpen}
              onChange={(template) => setTemplate(template)}
              onClose={() => setTemplatePopoverOpen(false)}
              query={DISPLAY_TEMPLATES_QUERY}
              queryArgument={'search'}
              queryField={'displayTemplates'}
              renderMatch={(template) => <>{template.name}</>}
              popoverStyle={{ maxHeight: '400px', overflowY: 'auto' }}
            />
          </div>
        </div>
        <div className="EditInstanceModal__buttons">
          <Button onClick={() => onCloseHandler()}>Cancel</Button>
          <Button variant="raised" color="primary" disabled={!account} onClick={() => onSubmitHandler()}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
