import * as classNames from 'classnames';
import * as React from 'react';

type DrawerContentProps = React.HTMLAttributes<HTMLDivElement> & {};

export const DrawerContent = ({ children, className, ...rest }: DrawerContentProps) => {
  const contentClass = classNames('Drawer__content', className);

  return (
    <div className={contentClass} {...rest}>
      {children}
    </div>
  );
};
