import { ChevronDownIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Popover } from '~/src/components';
import './ReportsNav.scss';

type ReportsNavProps = {
  active?: 'sales' | 'bookkeeping' | 'products' | 'production' | 'everyname' | 'analysis';
};

export enum SalesSlugs {
  REP_SALES = 'rep-sales-by-date-range',
  REPS_DISPLAYS = 'reps-displays-without-reorders',
  ACCOUNTS_YTD = 'accounts-ytd-comp',
  ACCOUNTS_12_MONTH = 'accounts-12-month-by-sales',
  LAST_ORDER = 'last-order',
}

export enum BookkeepingSlugs {
  COLLECTIONS = 'collections',
  COMMISSION = 'commission',
  FINANCES = 'finances',
}

export enum ProductsSlugs {
  PRODUCTS = 'products-sold-by-date',
  GROUPS = 'product-groups-sold-by-date',
}

export enum ProductionSlugs {
  SUMMARY = 'production-summary-by-product-groups',
  COMPLETED = 'completed-orders-not-shipped',
  APPROVED = 'approved-orders-not-released',
  AWAITING_APPROVAL = 'orders-awaiting-approval',
}

export const ReportsNav = (props: ReportsNavProps) => {
  const [salesOpen, setSalesOpen] = React.useState(false);
  const [bookkeepingOpen, setBookkeepingOpen] = React.useState(false);
  const [productsOpen, setProductsOpen] = React.useState(false);
  const [productionOpen, setProductionOpen] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    setSalesOpen(false);
    setBookkeepingOpen(false);
    setProductionOpen(false);
    setProductsOpen(false);
  }, [location]);

  return (
    <Nav title="Reports">
      <div className="ReportsNav">
        <p>/</p>
        <div style={{ position: 'relative' }}>
          <a onClick={() => setSalesOpen(true)} className={props.active === 'sales' ? 'ReportsNav__activeLink' : ''}>
            Sales
            <ChevronDownIcon />
          </a>
          <Popover isOpen={salesOpen} onClose={() => setSalesOpen(false)} className={'ReportsNav__popover'}>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">Reps</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/sales/${SalesSlugs.REP_SALES}`}>Rep Sales by Date Range</Link>
              <Link to={`/reports/sales/${SalesSlugs.REPS_DISPLAYS}`}>Reps Displays Without Reorder</Link>
            </div>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">Accounts</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/sales/${SalesSlugs.ACCOUNTS_YTD}`}>Accounts YTD Comp</Link>
              <Link to={`/reports/sales/${SalesSlugs.ACCOUNTS_12_MONTH}`}>Accounts 12-month by Sales</Link>
            </div>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">House Accounts</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/sales/${SalesSlugs.LAST_ORDER}`}>Last Order</Link>
            </div>
          </Popover>
        </div>
        <div style={{ position: 'relative' }}>
          <a
            onClick={() => setBookkeepingOpen(true)}
            className={props.active === 'bookkeeping' ? 'ReportsNav__activeLink' : ''}
          >
            Bookkeeping
            <ChevronDownIcon />
          </a>
          <Popover isOpen={bookkeepingOpen} onClose={() => setBookkeepingOpen(false)} className={'ReportsNav__popover'}>
            <div className="ReportsNav__popover__col" style={{width: "max-content"}}>
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.COLLECTIONS}`}>Collections</Link>
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.COMMISSION}`}>Commission</Link>
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.FINANCES}`}>Finances</Link>
            </div>
          </Popover>
        </div>
        <div style={{ position: 'relative' }}>
          <a
            onClick={() => setProductsOpen(true)}
            className={props.active === 'products' ? 'ReportsNav__activeLink' : ''}
          >
            Products
            <ChevronDownIcon />
          </a>
          <Popover isOpen={productsOpen} onClose={() => setProductsOpen(false)} className={'ReportsNav__popover'}>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">By Product Numbers</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/products/${ProductsSlugs.PRODUCTS}`}>Products Sold by Date</Link>
            </div>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">By Product Groups</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/products/${ProductsSlugs.GROUPS}`}>Product Groups Sold by Date</Link>
            </div>
          </Popover>
        </div>
        <div style={{ position: 'relative' }}>
          <a
            onClick={() => setProductionOpen(true)}
            className={props.active === 'production' ? 'ReportsNav__activeLink' : ''}
          >
            Production
            <ChevronDownIcon />
          </a>
          <Popover isOpen={productionOpen} onClose={() => setProductionOpen(false)} className={'ReportsNav__popover'}>
            <div className="ReportsNav__popover__col">
              <p className="ReportsNav__popover__col__title">Order Status Queues</p>
              <p className="ReportsNav__popover__col__subtitle">Description of report group</p>
              <Link to={`/reports/production/${ProductionSlugs.SUMMARY}`}>Production Summary by Product Groups</Link>
              <Link to={`/reports/production/${ProductionSlugs.APPROVED}`}>Approved Orders Not Released</Link>
              <Link to={`/reports/production/${ProductionSlugs.COMPLETED}`}>Completed Orders Not Shipped</Link>
              <Link to={`/reports/production/${ProductionSlugs.AWAITING_APPROVAL}`}>Orders Awaiting Approval</Link>
            </div>
          </Popover>
        </div>
        <Link to={''} className={props.active === 'everyname' ? 'ReportsNav__activeLink' : ''}>
          Everyname Orders
        </Link>
        <Link to={''} className={props.active === 'analysis' ? 'ReportsNav__activeLink' : ''}>
          Analysis
        </Link>
      </div>
    </Nav>
  );
};
