import * as React from 'react';
import { Button, Input, Modal, Textarea } from '~/src/components';
import './ApplyNamelistModal.scss';

type ApplyNamelistModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { variableName: string; values: string[]; section?: number; csv?: File }) => void;
};

type FormData = {
  variableName: string;
  namelist: string;
  section?: string;
  csv?: File;
};

export const ApplyNamelistModal = (props: ApplyNamelistModalProps) => {
  const [form, setForm] = React.useState<FormData>({
    variableName: '',
    namelist: '',
  });

  function onCloseHandler() {
    setForm({ variableName: '', namelist: '' });
    props.onClose();
  }

  function submitHandler(data: FormData) {
    const values = data.namelist?.split('\n').filter((line: string) => line.trim() !== '');
    props.onSubmit({ ...data, values, section: data.section ? parseInt(data.section) : undefined });
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={() => onCloseHandler()} className="DisplayInstance__applyNamelistModal">
      <p>Apply Namelist</p>
      <form>
        <div className="DisplayInstance__applyNamelistModal__input">
          <p>Variable name (case sensitive!)</p>
          <Input value={form.variableName} onChange={(e) => setForm((p) => ({ ...p, variableName: e.target.value }))} />
        </div>
        <div className="DisplayInstance__applyNamelistModal__input">
          <p>Section (optional)</p>
          <Input
            type="number"
            min={1}
            value={form.section || ''}
            onChange={(e) => setForm((p) => ({ ...p, section: e.target.value }))}
          />
        </div>
        <div className="DisplayInstance__applyNamelistModal__input">
          <p>NameList</p>
          <Textarea
            fluid
            value={form.namelist}
            onChange={(e) => setForm((p) => ({ ...p, namelist: e.target.value }))}
          />
        </div>
        <div className="DisplayInstance__applyNamelistModal__input">
          <p>Alternatively, CSV file</p>
          <Input
            type="file"
            accept=".csv"
            onChange={(e) => {
              setForm((p) => ({ ...p, csv: e.target.files?.[0] }));
            }}
          />
        </div>
        <div className="DisplayInstance__applyNamelistModal__buttons">
          <Button variant="raised" onClick={() => onCloseHandler()}>
            Cancel
          </Button>
          <Button
            variant="raised"
            color="primary"
            onClick={() => submitHandler(form)}
            disabled={(!form.variableName || !form.namelist) && !form.csv}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
