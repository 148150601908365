import { useMutation, useQuery } from '@apollo/client';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Modal, Spinner } from '~/src/components';
import { useJobMaterials } from '~/src/features/piecework-coordinator';
import { useToast } from '~/src/features/toast';
import { formatDateString } from '~/src/utils/formatting';
import { ACCEPT_JOB_MUTATION, JOB_DETAIL_QUERY } from '../../api';
import './JobDetail.scss';

export const JobDetail = () => {
  const [confirmJobModalOpen, setConfirmJobModalOpen] = React.useState(false);

  const navigate = useNavigate();
  const { jobId } = useParams();

  const [acceptJob] = useMutation(ACCEPT_JOB_MUTATION);
  const { data, loading } = useQuery(JOB_DETAIL_QUERY, {
    variables: {
      id: jobId,
    },
  });
  const { jobMaterials, jobMaterialsLoading } = useJobMaterials({ jobPk: jobId as string });

  const { info: infoToast } = useToast();

  function clickAcceptJob() {
    acceptJob({
      variables: {
        id: jobId,
      },
    }).then(() => {
      navigate('/');
      infoToast(`Job #${jobId?.substring(0, 8)} has been confirmed`);
    });
  }

  function renderTaskBadges(tasks: any[]) {
    return tasks.map((item, idx) => <Badge key={idx} label={item.node.name} style={{ backgroundColor: '#5699de' }} />);
  }

  function renderTasks() {
    return data.job.tasks.edges.map((edge: any, idx: number) => {
      return (
        <div key={idx} className="PWJobDetail__card">
          <div className="PWJobDetail__task">
            <div className="PWJobDetail__task__row">
              <div className="PWJobDetail__task__field">
                <label>Sub-total:</label>
                <div>${edge.node.subtotal}</div>
              </div>
            </div>
            <div className="PWJobDetail__task__row mt-2">
              <div className="PWJobDetail__task__field">
                <label>Invoice:</label>
                <div>{edge.node.orderDetails.order.pk}</div>
              </div>
              <div className="PWJobDetail__task__field">
                <label>Product:</label>
                <div>{edge.node.orderDetails.product.group.prefix}</div>
              </div>
            </div>
            <div className="PWJobDetail__task__row mt-2">
              <div className="PWJobDetail__task__field">
                <label>Order detail ID:</label>
                <div>{edge.node.orderDetails.pk}</div>
              </div>
              <div className="PWJobDetail__task__field">
                <label>Quantity:</label>
                <div>{edge.node.quantity}</div>
              </div>
            </div>
            <label>Tasks</label>
            <div className="PWJobDetail__task__badges">
              {renderTaskBadges(edge.node.assignedProductGroupTasks.edges)}
            </div>
          </div>
        </div>
      );
    });
  }

  function renderMaterials() {
    if (jobMaterialsLoading) {
      return <Spinner message="Loading materials..." />;
    }
    return (
      <>
        <p className="color-gray-600">Provided Materials</p>
        <ul>
          {jobMaterials.map((m: any, index: number) => (
            <li key={index}>
              {m.material} - {m.total}
            </li>
          ))}
        </ul>
      </>
    );
  }

  if (loading) {
    return <Spinner message="Loading job..." />;
  }

  return (
    <div className="PWJobDetail">
      <h1 className="h1-mobile">Job# {data.job.jobNum}</h1>
      <div className="PWJobDetail__total">
        <div className="PWJobDetail__total__icon">
          <div className="icon-wrapper icon-wrapper--green">
            <CurrencyDollarIcon />
          </div>
        </div>
        <div>
          <label>Job total</label>
          <h4>${data.job.totalPrice}</h4>
        </div>
      </div>
      <div className="PWJobDetail__card">
        <div className="PWJobDetail__dates">
          <div>
            <label>Pick up date</label>
            <div>{format(new Date(data.job.pickupDatetime), 'MM/dd/yyyy')}</div>
          </div>
          <div>
            <label>Due date</label>
            <div>{formatDateString(data.job.dueDate)}</div>
          </div>
        </div>
      </div>
      {renderTasks()}
      {renderMaterials()}
      {!data.job.pickedUpDatetime && (
        <div className="PWJobDetail__actions">
          <Button
            color="primary"
            fluid
            onClick={() => {
              setConfirmJobModalOpen((prev) => !prev);
            }}
            variant="raised"
          >
            Confirm pickup
          </Button>
        </div>
      )}
      <Modal
        isOpen={confirmJobModalOpen}
        onClose={() => setConfirmJobModalOpen((prev) => !prev)}
        style={{ maxWidth: '320px' }}
      >
        <div className="PWJobDetail__modal">
          <h4 className="PWJobDetail__modal__label">Confirm pickup</h4>
          <div className="PWJobDetail__modal__actions">
            <Button
              color="light"
              onClick={() => {
                setConfirmJobModalOpen((prev) => !prev);
              }}
              variant="raised"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                clickAcceptJob();
              }}
              variant="raised"
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
