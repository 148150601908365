import * as React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/solid';
import bucket from '~/src/assets/img/bucket.png';
import { Button, Container, Form, FormGroup, FormLabel, Input } from '~/src/components';
import { SEND_PASSWORD_RESET_MUTATION } from '../../api';

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [linkSent, setLinkSent] = React.useState<boolean>(false);

  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_MUTATION);

  function emailChange(event: React.FormEvent<HTMLInputElement>) {
    setEmail(event.currentTarget.value);
  }

  function sendLink(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    sendPasswordResetEmail({
      variables: { email },
    })
      .then(() => {
        setLinkSent(true);
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  return (
    <Container>
      <div className="center-page">
        <img className="center-page-img" src={bucket} alt="" />
        <h2 className="center-page-title">Forgot Password</h2>
        <p className="center-page-sub">
          {linkSent
            ? 'Please check your email for a link to reset your password.'
            : 'Don’t worry, we’ll send you a recovery link.'}
        </p>
        {!linkSent && (
          <>
            <Form onSubmit={sendLink}>
              <FormGroup>
                <FormLabel>Email address</FormLabel>
                <Input
                  fluid
                  type="email"
                  name="email"
                  placeholder="Email address"
                  iconTrailing={<UserIcon />}
                  value={email}
                  onChange={emailChange}
                />
              </FormGroup>
              {error && <p className="error">{error}</p>}
              <Button color="primary" disabled={!email} fluid type="submit" variant="raised">
                Send Recovery Link
              </Button>
            </Form>
            <Link to="/login">
              <Button fluid color="primary" className="center-page-link">
                Back to Login
              </Button>
            </Link>
          </>
        )}
      </div>
    </Container>
  );
};
