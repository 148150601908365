import { gql } from '@apollo/client';

export const ME_QUERY = gql`
  query {
    me {
      id
      pk
      username
      email
      firstName
      lastName
      dateJoined
      lastLogin
      isPieceworker
      isPieceworkCoordinator
      profile {
        id
        pk
        initials
        phone
        fax
        cell
        address
        city
        state
        zip
        profilePicture {
          file
          type
          name
        }
      }
    }
  }
`;
