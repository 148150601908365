import { useMutation } from '@apollo/client';
import { BILLING_UPDATE_MUTATION } from '../api';

export const useBillingUpdate = () => {
  const [billingUpdate, { data, loading, error }] = useMutation(BILLING_UPDATE_MUTATION);

  return {
    billingUpdate,
    billingUpdateData: data?.billingUpdate,
    billingUpdateLoading: loading,
    billingUpdateError: error,
  };
};
