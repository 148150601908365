import { gql } from '@apollo/client';

export const CREDIT_MEMOS_SEARCH_QUERY = gql`
  query creditMemosSearch($first: Int, $offset: Int, $search: String, $sort: String, $account: ID) {
    creditMemos(first: $first, offset: $offset, search: $search, sort: $sort, account: $account) {
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          pk
          account {
            id
            pk
            name
          }
          createdBy {
            firstName
            lastName
          }
          approvedBy {
            user {
              firstName
              lastName
            }
          }
          date
          amount
          reason
          notes
          comm
          zero
        }
      }
    }
  }
`;

export const EMPLOYEE_SEARCH_QUERY = gql`
  query employees($search: String) {
    employees(search: $search) {
      edges {
        node {
          id
          pk
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const CREDIT_MEMO_PRINT_QUERY = gql`
  query creditMemoPDF($pk: Int!) {
    reportCreditMemoPdf(pk: $pk)
  }
`;
