import * as React from 'react';
import { Button, Input, Modal, Select } from '~src/components';
import './AddItemModal.scss';

type AddItemModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: {positionType: string, amount: number, editing?: boolean}) => void;
  data?: { positionType: string };
};

export const AddItemModal = (props: AddItemModalProps) => {
  const [form, setForm] = React.useState<{ positionType: string; quantity: number }>({ positionType: '', quantity: 1 });

  React.useEffect(() => {
    if (props.data) setForm((p) => ({ ...p, positionType: props.data?.positionType || '' }));
  }, [props.isOpen]);

  function onCloseHandler() {
    setForm({ positionType: '', quantity: 1 });
    props.onClose();
  }

  return (
    <Modal className="DisplayModule__addItemModal" isOpen={props.isOpen} onClose={() => onCloseHandler()}>
      <p>{props.data ? 'Edit Item' : 'Add Item'}</p>
      <p>Add an item to this module.</p>
      <span className="DisplayModule__addItemModal__input">
        <p>Position Type:</p>
        <Select
          options={[
            { label: '', value: '' },
            { label: 'Hook', value: 'HOOK' },
            { label: 'Tray', value: 'TRAY' },
            { label: 'Slot', value: 'SLOT' },
          ]}
          value={form.positionType}
          onChange={(e) => setForm((p) => ({ ...p, positionType: e.target.value }))}
        />
      </span>
      {!props.data && (
        <span className="DisplayModule__addItemModal__input">
          <p>Quantity</p>
          <Input
            type="number"
            min={1}
            value={form.quantity || ''}
            onChange={(e) => setForm((p) => ({ ...p, quantity: parseInt(e.target.value) }))}
          />
        </span>
      )}
      <div className="DisplayModule__addItemModal__buttons">
        <Button onClick={() => onCloseHandler()}>Cancel</Button>
        <Button
          variant="raised"
          color="primary"
          disabled={form.positionType === '' || !form.quantity || form.quantity < 1}
          onClick={() => {
            props.onSubmit({positionType: form.positionType, amount: form.quantity, editing: !!props.data});
            onCloseHandler();
          }}
        >
          {props.data ? 'Update Item' : 'Add Item'}
        </Button>
      </div>
    </Modal>
  );
};
