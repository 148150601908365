import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import * as React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { DataTable, DatePicker, Pagination, Spinner } from '~/src/components';
import { REP_DISPLAYS_WITHOUT_REORDER_QUERY, REP_SALES_BY_DATE_QUERY } from '../../api';
import { ReportsNav, SalesSlugs } from '../../components';
import './SalesReports.scss';

type SalesReportsProps = {};

type SalesFilters = {
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
  page: number;
};

const salesFiltersDefaults: SalesFilters = {
  dateStart: undefined,
  dateEnd: undefined,
  page: 1,
};

const PAGE_SIZE = 100;

export const SalesReports = (props: SalesReportsProps) => {
  const { type } = useParams();
  const [filters, setFilters] = React.useState<SalesFilters>(salesFiltersDefaults);

  const [searchRepSales, { data: repData, loading: repLoading }] = useLazyQuery(REP_SALES_BY_DATE_QUERY);
  const [searchRepDisplays, { data: displaysData, loading: displaysLoading }] = useLazyQuery(
    REP_DISPLAYS_WITHOUT_REORDER_QUERY
  );

  React.useEffect(() => {
    setFilters(salesFiltersDefaults);
    executeLazyQuery();
  }, [type]);

  React.useEffect(() => executeLazyQuery(), [filters]);

  function executeLazyQuery() {
    const variables = {
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      start: filters.dateStart ? format(new Date(filters.dateStart), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      end: filters.dateEnd ? format(new Date(filters.dateEnd), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
    };

    type === SalesSlugs.REP_SALES && searchRepSales({ variables });
    type === SalesSlugs.REPS_DISPLAYS && searchRepDisplays({ variables });
  }

  function renderRepSalesPage() {
    const headers = [
      { label: 'Rep', sort: 'name', size: 'md' },
      { label: 'Type', sort: 'type', size: 'sm' },
      { label: 'Company', sort: 'company', size: 'lg' },
      { label: 'Rep area', sort: 'rep_area', size: 'md' },
      { label: 'Orders', sort: 'orders', size: 'sm' },
      { label: 'Avg. Sale', sort: 'average_sale', size: 'sm' },
      { label: 'Total sales', sort: 'total_sales', size: 'sm' },
    ];

    return (
      <div className="SalesReports__body">
        <div className="SalesReports__body__header">
          <div className="SalesReports__body__header__title">
            <h3>Rep Sales by Date Range</h3>
            <p>Compare sales figures of each representative to gain valuable insights into their performance.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="SalesReports__body__table">
          {!repData || repLoading ? (
            <Spinner message="Loading sales..." />
          ) : (
            <>
              <DataTable headers={headers}>
                {repData.reportRepSalesByDate.edges.map((edge: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/`}>{edge.node.name}</Link>
                    </td>
                    <td>
                      {edge.node.type === 'REP' && 'Rep'}
                      {edge.node.type === 'REP_GROUP_LEADER' && 'Rep Group Leader'}
                      {edge.node.type === 'COMPANY_SUB_REP' && 'Company Sub Rep'}
                    </td>
                    <td>{edge.node.company}</td>
                    <td>{edge.node.area}</td>
                    <td>{edge.node.orders}</td>
                    <td>
                      {Number(edge.node.averageSale).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                    <td>
                      {Number(edge.node.totalSales).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                hasNextPage={repData.reportRepSalesByDate.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
                totalNodes={repData.reportRepSalesByDate.totalNodes}
                totalNodesOnPage={repData.reportRepSalesByDate.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderRepDisplaysPage() {
    const headers = [
      { label: 'Display ID', sort: 'id' },
      { label: 'Account', sort: 'account' },
      { label: 'Display Type', sort: 'type' },
      { label: 'List Template', sort: 'template' },
      { label: 'Program type', sort: 'program_type' },
      { label: 'Label', sort: 'label' },
      { label: 'Featured products', sort: 'featured_products' },
      { label: 'Start Date', sort: 'start_date' },
    ];

    return (
      <div className="SalesReports__body">
        <div className="SalesReports__body__header">
          <div className="SalesReports__body__header__title">
            <h3>Rep Displays Without Reorders</h3>
            <p>Provides insights into displays that have not received any reorders within a specified date range.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="SalesReports__body__table">
          {!displaysData || displaysLoading ? (
            <Spinner message="Loading displays..." />
          ) : (
            <>
              <DataTable headers={headers}>
                {displaysData.reportRepsDisplaysWithoutReorders.edges.map((edge: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/displays/instances/${edge.node.displayId}`}>{edge.node.displayId}</Link>
                    </td>
                    <td>
                      <Link to={`/accounts/${edge.node.accountId}`}>{edge.node.accountName}</Link>
                    </td>
                    <td>{edge.node.displayType}</td>
                    <td>{edge.node.listTemplate}</td>
                    <td>{edge.node.programType}</td>
                    <td>{edge.node.label}</td>
                    <td>{edge.node.featuredProd}</td>
                    <td>{edge.node.startDate && format(new Date(edge.node.startDate), 'M/d/yyyy')}</td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                hasNextPage={displaysData.reportRepsDisplaysWithoutReorders.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
                totalNodes={displaysData.reportRepsDisplaysWithoutReorders.totalNodes}
                totalNodesOnPage={displaysData.reportRepsDisplaysWithoutReorders.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="SalesReports__body__header__filters">
        <div>
          <p>Start Date</p>
          <DatePicker onChange={(d) => setFilters((p) => ({ ...p, dateStart: d }))} value={filters.dateStart} />
        </div>
        <div>
          <p>End Date</p>
          <DatePicker
            onChange={(d) => setFilters((p) => ({ ...p, dateEnd: d }))}
            value={filters.dateEnd}
            style={{ right: '0' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="SalesReports">
      <ReportsNav active="sales" />
      <>{type === SalesSlugs.REP_SALES && renderRepSalesPage()}</>
      <>{type === SalesSlugs.REPS_DISPLAYS && renderRepDisplaysPage()}</>
    </div>
  );
};
