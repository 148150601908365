type ResizeImageResult = {
  url: string;
  contentType: string;
  b64: string;
  size: number;
};

type ResizeImageOptions = {
  maxWidth?: number;
  maxHeight?: number;
  contentType?: string;
  quality?: number;
};

// reduce file size
export const resizeImage = async (url: string, options?: ResizeImageOptions): Promise<ResizeImageResult> => {
  options = {
    maxWidth: 1024,
    maxHeight: 768,
    contentType: 'image/jpeg',
    quality: 0.7,
    ...options,
  };

  const calculateSize = (img: HTMLImageElement, maxWidth: number = 1024, maxHeight: number = 768) => {
    let w = img.width,
      h = img.height;
    if (w > h) {
      if (w > maxWidth) {
        h = Math.round((h * maxWidth) / w);
        w = maxWidth;
      }
    } else {
      if (h > maxHeight) {
        w = Math.round((w * maxHeight) / h);
        h = maxHeight;
      }
    }
    return [w, h];
  };

  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onerror = function () {
      URL.revokeObjectURL(url);
    };
    img.onload = function () {
      URL.revokeObjectURL(url);
      const [newWidth, newHeight] = calculateSize(img, options?.maxWidth, options?.maxHeight);
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);

      const resultUrl = canvas.toDataURL(options?.contentType, options?.quality),
        result = {
          url: resultUrl,
          contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)?.[1] || '',
          b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, ''),
          size: 0,
        };

      canvas.toBlob(
        (blob) => {
          result.size = blob?.size || 0;
          resolve(result);
        },
        options?.contentType,
        options?.quality
      );
    };
  });
};

export const convertBase64 = (file: File | undefined): Promise<string> | null => {
  if (!file) return null;
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result as string);
    fileReader.onerror = (error) => reject(error);
  });
};
