import * as React from 'react';
import { Modal, Button, SearchPopover, Input } from '~/src/components';
import { DISPLAY_MODULES_QUERY } from '~src/features/displays/api/queries';
import './AddModuleModal.scss';

type AddModuleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
};

export const AddModuleModal = (props: AddModuleModalProps) => {
  const [module, setModule] = React.useState<any>();
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  // const getHeight = (json: string): number => {
  //   const data = JSON.parse(json);
  //   return data.rows.reduce((prev: any, curr: any) => prev + curr.row_properties?.full_height || 0, 0);
  // };

  function onCloseHandler() {
    setModule(null);
    props.onClose();
  }

  return (
    <Modal className="DisplayModel__addModuleModal" isOpen={props.isOpen} onClose={() => onCloseHandler()}>
      <p>Add module to section</p>
      <div style={{ position: 'relative' }}>
        <Input
          readOnly
          value={module?.name || ''}
          placeholder="Search for a module..."
          onClick={() => setPopoverOpen(true)}
        />
        <SearchPopover
          isOpen={popoverOpen}
          onChange={(module) => setModule(module)}
          onClose={() => setPopoverOpen(false)}
          query={DISPLAY_MODULES_QUERY}
          queryArgument={'search'}
          queryField={'displayModules'}
          queryVariables={{ section_Isnull: true }}
          renderMatch={(mod) => <>{mod.name}</>}
        />
      </div>
      {/* {module && <p>Module height: {getHeight(module.positionData || '{}')}</p>} */}
      <span>
        <Button variant="raised" onClick={() => onCloseHandler()}>
          Cancel
        </Button>
        <Button
          variant="raised"
          color="primary"
          onClick={() => {
            props.onSubmit({ module: module.pk });
            onCloseHandler();
          }}
        >
          Add module
        </Button>
      </span>
    </Modal>
  );
};
