import { useLazyQuery, useQuery } from '@apollo/client';
import { ChartBarIcon } from '@heroicons/react/24/solid';
import { format, sub } from 'date-fns';
import * as React from 'react';
import { DatePicker, Spinner } from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';
import { MY_PAYMENTS_YTD_QUERY, PIECEWORK_PAYMENTS_QUERY } from '../../api';
import './Payments.scss';

type PaymentsFilters = {
  startDate?: Date;
  endDate?: Date;
};

const defaultFilters: PaymentsFilters = {
  startDate: sub(new Date(), { days: 14 }),
  endDate: new Date(),
};

export const Payments = () => {
  const [filters, setFilters] = React.useState(defaultFilters);
  const [payments, setPayments] = React.useState<any[]>([]);

  const { data: ytdData } = useQuery(MY_PAYMENTS_YTD_QUERY);

  const [getPayments, { loading }] = useLazyQuery(PIECEWORK_PAYMENTS_QUERY, {
    variables: {
      approvedDate_Gte: filters.startDate,
      approvedDate_Lte: filters.endDate,
    },
    onCompleted: (data) => {
      setPayments(data.pieceworkPayments.edges);
    },
  });

  React.useEffect(() => {
    getPayments();
  }, [filters]);

  function renderPayments() {
    return payments.map((edge: any, idx: number) => (
      <div className="PWPayments__list__item" key={idx}>
        <div>
          <div className="color-gray-600">Paid on</div>
          {!edge.node.paidDate ? (
            <div className="color-gray-600 font-italic">Pending</div>
          ) : (
            <div className="color-primary">{format(new Date(edge.node.paidDate), 'MM/dd/yyyy')}</div>
          )}
        </div>
        <div className="PWPayments__list__item__total">
          <div className="color-gray-600">Total</div>
          {!edge.node.paidDate ? (
            <div className="color-gray-600">${toFixed2(edge.node.amount)}</div>
          ) : (
            <div className="color-primary">${toFixed2(edge.node.amount)}</div>
          )}
        </div>
      </div>
    ));
  }

  if (loading) {
    return <Spinner message="Loading payments..." />;
  }

  return (
    <div className="PWPayments">
      <h1 className="h1-mobile">Payments</h1>
      <p className="color-gray-600 font-size-12">Filter by</p>
      <div className="PWPayments__dateRange">
        <div className="flex-1">
          <div className="color-gray-600 font-size-12">Start date</div>
          <DatePicker
            inputProps={{ fluid: true }}
            onChange={(date) => {
              date &&
                setFilters({
                  ...filters,
                  startDate: date,
                });
            }}
            value={filters.startDate}
          />
        </div>
        <div className="flex-1">
          <div className="color-gray-600 font-size-12">End date</div>
          <DatePicker
            inputProps={{ fluid: true }}
            onChange={(date) => {
              date &&
                setFilters({
                  ...filters,
                  endDate: date,
                });
            }}
            style={{ right: '0' }}
            value={filters.endDate}
          />
        </div>
      </div>
      <div className="PWPayments__cards">
        <div className="PWPayments__card">
          <div className="icon-wrapper">
            <ChartBarIcon />
          </div>
          <div>
            <div className="color-gray-600">YTD</div>
            <div className="font-size-18">${ytdData && toFixed2(ytdData.me.pieceworkPaymentYtd)}</div>
          </div>
        </div>
        <div className="PWPayments__card">
          <div>
            <div className="color-gray-600">Latest paycheck</div>
            <div className="color-success-700 font-size-18">
              ${ytdData && toFixed2(ytdData.me.pieceworkPaymentLatest)}
            </div>
          </div>
        </div>
      </div>
      <div className="PWPayments__list">{renderPayments()}</div>
    </div>
  );
};
