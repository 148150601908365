import * as React from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Button, Input, Modal, SearchPopover, Select } from '~/src/components';
import { DISPLAY_PRODUCTS_QUERY } from '~/src/features/displays/api/queries';
import './AddProductToAllModal.scss';

type AddProductToAllModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: {
    productPk: number;
    section: number | undefined;
    module: string | undefined;
    variables: string;
  }) => void;
  displaySections: any[];
};

export const AddProductToAllModal = (props: AddProductToAllModalProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [productForm, setProductForm] = React.useState<{
    product: any;
    section?: number;
    module?: string;
  }>({ product: undefined });
  const variableForm = useForm();
  const variableFormFields = useFieldArray({
    control: variableForm.control,
    name: 'variables',
  });

  React.useEffect(() => {
    if (!productForm.product) return;
    variableFormFields.replace([]);
    // distinguishing between artFront and artBack layers may be useful at some point
    const artFrontLayers = productForm.product.artFront?.layers?.edges.map((layer: any) => ({
      ...layer.node,
      artFront: true,
    }));
    const artBackLayers = productForm.product.artBack?.layers?.edges.map((layer: any) => ({
      ...layer.node,
      artBack: true,
    }));
    const variableLayers = [...(artFrontLayers || []), ...(artBackLayers || [])];
    // reversing and prepending will end with first input focused instead of last
    variableLayers.reverse().map((layer) => {
      variableFormFields.prepend({
        attributeName: layer.name,
        value: '',
        type: layer.type.name,
        variableImagePaths: layer.variableImagePaths,
      });
    });
  }, [productForm.product]);

  function onCloseHandler() {
    setProductForm({ product: undefined });
    variableFormFields.replace([]);
    props.onClose();
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const variables = data.variables.reduce(
      (acc: any, variable: any) => (variable.value ? { ...acc, [variable.attributeName]: variable.value } : acc),
      {}
    );
    props.onSubmit({
      productPk: productForm.product.pk,
      section: productForm.section,
      module: productForm.module,
      variables: JSON.stringify(variables),
    });
    onCloseHandler();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={() => onCloseHandler()} className="DisplayInstance__addProductToAllModal">
      <p>Set product at all positions</p>
      <form onSubmit={variableForm.handleSubmit(onSubmit)} className="DisplayInstance__addProductToAllModal__form">
        <div style={{ position: 'relative' }}>
          <Input
            readOnly
            value={productForm.product ? `${productForm.product?.pk} ${productForm.product?.name}` : ''}
            placeholder="Search for a product..."
            onClick={() => setPopoverOpen(true)}
          />
          <SearchPopover
            popoverStyle={{ maxHeight: '400px', overflowY: 'auto' }}
            isOpen={popoverOpen}
            onChange={(product) => setProductForm((prev) => ({ ...prev, product }))}
            onClose={() => setPopoverOpen(false)}
            query={DISPLAY_PRODUCTS_QUERY}
            queryArgument={'search'}
            queryField={'products'}
            renderMatch={(product) => <>{product.name}</>}
          />
        </div>
        {/* <div>
          <p>Section</p>
          <Select
            value={productForm.section}
            onChange={(e) => setProductForm((prev) => ({ ...prev, section: Number(e.target.value) }))}
            options={[
              { value: undefined, label: '' },
              ...(props.displaySections?.map((section) => ({ value: section.order, label: section.order })) || []),
            ]}
          />
        </div>
        <div>
          <p>Module</p>
          <Select
            value={productForm.module}
            disabled={!productForm.section}
            onChange={(e) => setProductForm((prev) => ({ ...prev, module: e.target.value }))}
            options={[
              { value: undefined, label: '' },
              // find modules that belong to the selected section
              ...(props.displaySections
                ?.find((section) => section.order === productForm.section)
                ?.modules.edges.map((edge: any) => edge.node)
                .map((module: any) => ({ value: module.pk, label: module.name })) || []),
            ]}
          />
        </div> */}
        {!!variableFormFields.fields.length && (
          <div className="DisplayInstance__addProductToAllModal__variables">
            <p>Variables</p>
            {variableFormFields.fields.map((field, index) => (
              <div key={field.id} className="DisplayInstance__addProductToAllModal__variable">
                <Controller
                  control={variableForm.control}
                  name={`variables.${index}.attributeName`}
                  render={({ field }) => <p style={{ textTransform: 'capitalize' }}>{field.value}</p>}
                />
                <Controller
                  control={variableForm.control}
                  name={`variables.${index}.type`}
                  render={({ field }) =>
                    field.value === 'text' ? (
                      <Input
                        className="DisplayInstance__addProductToAllModal__variable__input"
                        {...variableForm.register(`variables.${index}.value` as const)}
                      />
                    ) : (
                      <div className="DisplayInstance__addProductToAllModal__variable__radio">
                        <Controller
                          control={variableForm.control}
                          name={`variables.${index}.variableImagePaths`}
                          render={({ field: imgField }) =>
                            !!imgField.value.length &&
                            imgField.value.map((pathStr: string, idxKey: number) => {
                              const path = JSON.parse(pathStr);
                              // gets other key besides file_path, should be the same as layer.attributeName
                              const attrName = Object.keys(path).filter((key) => key !== 'file_path')[0];
                              return (
                                <label
                                  key={idxKey}
                                  className="DisplayInstance__addProductToAllModal__variable__radio__option"
                                  htmlFor={path[attrName]}
                                >
                                  <input
                                    type="radio"
                                    id={path[attrName]}
                                    value={path[attrName]}
                                    {...variableForm.register(`variables.${index}.value` as const)}
                                  />
                                  <img src={path['file_path']} alt={path[attrName]} />
                                </label>
                              );
                            })
                          }
                        />
                      </div>
                    )
                  }
                />
              </div>
            ))}
          </div>
        )}
        <span className="DisplayInstance__addProductToAllModal__form__buttons">
          <Button variant="raised" onClick={() => onCloseHandler()}>
            Cancel
          </Button>
          <Button variant="raised" color="primary" disabled={!productForm.product} type="submit">
            Set product
          </Button>
        </span>
      </form>
    </Modal>
  );
};
