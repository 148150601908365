import { useMutation } from '@apollo/client';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ChevronDoubleRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Input, SearchPopover } from '~/src/components';
import { useToast } from '~/src/features/toast';
import { TAGS_QUERY, TAG_CREATE_MUTATION } from '../../api';
import { Tag } from '../@shared';
import { RemoveTagModal } from '../RemoveTagModal';
import './TagCard.scss';

type TagCardProps = {
  tagsEdges: any[];
  onSave: (tagPks: number[]) => void;
};

export const TagCard = (props: TagCardProps) => {
  const [tagToRemove, setTagToRemove] = React.useState<any>({});
  const [childPopoverOpen, setChildPopoverOpen] = React.useState(false);
  const [parentPopoverOpen, setParentPopoverOpen] = React.useState(false);
  const [addNew, setAddNew] = React.useState(false);
  const [tag, setTag] = React.useState<any>({});

  const [createTag] = useMutation(TAG_CREATE_MUTATION, {
    onCompleted: (res) =>
      res.tagCreate?.tag?.pk &&
      props.onSave([...props.tagsEdges.map((edge: any) => edge.node.pk), res.tagCreate?.tag?.pk]),
    onError: (error) => error.message.includes('tag_unique_name') && errorToast('Tag name already exists.'),
  });

  const { error: errorToast } = useToast();

  return (
    <>
      <div className="ProductDetails__tagCard">
        <p className="mb-1">Tags</p>
        <div className="ProductDetails__tagCard__card">
          <div className="ProductDetails__tagCard__card__search">
            <div className="ProductDetails__tagCard__card__inputGroup">
              <span>
                <p>Tag</p>
                <QuestionMarkCircleIcon />
              </span>
              <div className="relative">
                <Input
                  fluid
                  value={tag.name || ''}
                  onClick={() => !addNew && setChildPopoverOpen(true)}
                  readOnly={!addNew}
                  placeholder={addNew ? 'Enter new tag name' : 'Search tags'}
                  onChange={(e) => setTag((prev: any) => ({ ...prev, name: e.target.value }))}
                />
                <SearchPopover
                  isOpen={childPopoverOpen}
                  onChange={(tag) => setTag(tag)}
                  onClose={() => setChildPopoverOpen(false)}
                  query={TAGS_QUERY}
                  queryArgument="name"
                  queryField="tags"
                  renderMatch={(match) => <>{match.name}</>}
                  popoverStyle={{ height: '300px', maxHeight: '300px', top: '-300px', overflow: 'auto' }}
                />
              </div>
            </div>
            <ChevronDoubleRightIcon />
            <div className="ProductDetails__tagCard__card__inputGroup">
              <span>
                <p>Parent Tag</p>
                <QuestionMarkCircleIcon />
              </span>
              <div className="relative">
                <Input
                  fluid
                  readOnly
                  disabled={!addNew}
                  iconLeading={<MagnifyingGlassIcon />}
                  placeholder="Search parent tags"
                  value={tag.parent?.name || ''}
                  onClick={() => addNew && setParentPopoverOpen(true)}
                />
                <SearchPopover
                  isOpen={parentPopoverOpen}
                  onChange={(tag) => setTag((prev: any) => ({ ...prev, parent: tag }))}
                  onClose={() => setParentPopoverOpen(false)}
                  query={TAGS_QUERY}
                  queryArgument="name"
                  queryField="tags"
                  renderMatch={(match) => <>{match.name}</>}
                  popoverStyle={{ height: '300px', maxHeight: '300px', top: '-300px', overflow: 'auto' }}
                />
              </div>
            </div>
          </div>
          <div className="ProductDetails__tagCard__card__content">
            {props.tagsEdges.map((edge: any, index: number) => {
              return (
                <Tag
                  key={index}
                  label={edge.node.name}
                  secondaryLabel={edge.node.parent.name}
                  onDelete={() => setTagToRemove(edge.node)}
                />
              );
            })}
          </div>
          <div className="ProductDetails__tagCard__card__buttons">
            {!addNew ? (
              <Button
                color="light"
                onClick={() => {
                  setAddNew(true);
                  setTag({});
                }}
                variant="raised"
              >
                Create New Tag
              </Button>
            ) : (
              <Button
                className="ProductDetails__warningButton"
                color="warn"
                onClick={() => {
                  setAddNew(false);
                  setTag({});
                }}
                variant="raised"
              >
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              disabled={!tag?.name || !tag?.parent?.pk || props.tagsEdges.find((edge: any) => edge.node.pk === tag.pk)}
              onClick={() => {
                Object.keys(tag).length &&
                  (addNew
                    ? createTag({
                        variables: { details: { name: tag.name, parent: tag.parent?.pk } },
                      })
                    : props.onSave([...props.tagsEdges.map((edge: any) => edge.node.pk), tag?.pk]));
                setTag({});
                setAddNew(false);
                setChildPopoverOpen(false);
                setParentPopoverOpen(false);
              }}
              variant="raised"
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      <RemoveTagModal
        isOpen={tagToRemove.pk}
        onClose={() => setTagToRemove({})}
        tag={tagToRemove}
        onSave={(tag) => props.onSave(props.tagsEdges.map((edge: any) => edge.node.pk).filter((pk) => pk !== tag.pk))}
      />
    </>
  );
};
