import * as classNames from 'classnames';
import * as React from 'react';
import './FormGroup.scss';

type FormGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  testId?: string;
};

export const FormGroup = ({ className, testId, ...rest }: FormGroupProps) => {
  let formGroupClass = classNames('FormGroup', className);

  return (
    <>
      <div className={formGroupClass} data-testid={testId} {...rest} />
    </>
  );
};
