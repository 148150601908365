import { useQuery } from '@apollo/client';
import { CalendarIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '~/src/components';
import { MY_AVAILABILITIES_QUERY } from '../../api';
import './Availability.scss';

export const Availability = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(MY_AVAILABILITIES_QUERY, {
    fetchPolicy: 'no-cache',
  });

  // Users can not add more than 5 availabilites. At 5 Availabilities, disable the button.
  function disableAddButton(): boolean {
    if (data?.pieceworkerAvailabilities.edges.length >= 5) {
      return true;
    }
    return false;
  }

  function renderAvailabilities() {
    return data?.pieceworkerAvailabilities.edges.map((edge: any, idx: number) => (
      <div className="availability" key={idx}>
        <div className="availability__date">
          <div>{format(new Date(edge.node.availabilityDatetime), 'EEEE, MMMM do')}</div>
          <div>{format(new Date(edge.node.availabilityDatetime), 'h:mm a')}</div>
        </div>
        <p>{edge.node.notes}</p>
        <a onClick={() => navigate(`/availability/update/${edge.node.pk}`)}>Edit</a>
      </div>
    ));
  }

  function renderListView() {
    return (
      <>
        <div className="icon-wrapper">
          <CalendarIcon />
        </div>
        <p className="font-size-18">Your Availability</p>
        {data?.pieceworkerAvailabilities.edges.length >= 1 ? (
          renderAvailabilities()
        ) : (
          <p className="color-gray-600">
            If you availability matches available work, you will be contacted by a Piecework Coordinator.
          </p>
        )}
        <Button
          color="primary"
          disabled={disableAddButton()}
          fluid
          onClick={() => {
            navigate('/availability/create');
          }}
          variant="raised"
        >
          {data?.pieceworkerAvailabilities.edges.length >= 1 ? 'Add another' : 'Add'}
        </Button>
      </>
    );
  }

  if (loading) {
    return <Spinner message="Loading availabilities..." />;
  }

  return (
    <>
      <div className="PWAvailability">
        <h1 className="h1-mobile">Availability</h1>
        {renderListView()}
      </div>
    </>
  );
};
