import { gql } from '@apollo/client';

export const CUSTOM_ORDERS_QUERY = gql`
  query customOrders(
    $first: Int
    $offset: Int
    $sort: String
    $search: String
    $orderDate_Gte: DateTime
    $orderDate_Lte: DateTime
    $shipDate_Isnull: Boolean
    $cancelledDate_Isnull: Boolean
  ) {
    customOrders(
      first: $first
      offset: $offset
      sort: $sort
      orderDate_Gte: $orderDate_Gte
      orderDate_Lte: $orderDate_Lte
      search: $search
      shipDate_Isnull: $shipDate_Isnull
      cancelledDate_Isnull: $cancelledDate_Isnull
    ) {
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          pk
          orderDate
          accessCode {
            code
            account {
              name
              billing {
                name
              }
              address
              city
              state
              postal
              phone
              email
            }
          }
          firstName
          lastName
          email
          city
          state
          shipDate
          orderTotal
        }
      }
    }
  }
`;

export const CUSTOM_ORDER_QUERY = gql`
  query customOrder($orderId: Int!) {
    customOrder(pk: $orderId) {
      id
      pk
      orderDate
      shipDate
      tracking
      creditAppliedDate
      shippingCost
      itemTotal
      orderTotal
      notes
      cancelledDate
      cancelledReason
      accessCode {
        id
        code
        account {
          name
        }
      }
      orderDetails {
        edges {
          node {
            id
            pk
            quantity
            creditMemo {
              amount
            }
            product {
              name
              pk
              minQty
              price
              description
              id
            }
            unitPrice
            storeMarkup
            variation {
              shortName
              longName
            }
          }
        }
      }
      email
      firstName
      lastName
      address1
      address2
      city
      state
      country
      zipcode
    }
  }
`;

export const ASSOCIATED_ORDERS_EMAIL = gql`
  query ordersByEmail($email: String) {
    customOrders(email_Iexact: $email) {
      edges {
        node {
          id
          pk
          shipDate
          shippingCost
          orderTotal
          orderDetails {
            edges {
              node {
                quantity
                creditMemo {
                  amount
                }
                product {
                  name
                  pk
                }
                unitPrice
                storeMarkup
                variation {
                  shortName
                  longName
                }
              }
            }
          }
        }
      }
    }
  }
`;
