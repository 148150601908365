import * as React from 'react';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader } from '~/src/components';
import './GroupListDrawer.scss';

type GroupListDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GroupListDrawer = (props: GroupListDrawerProps) => {
  return (
    <Drawer
      backdrop
      className="Reps__groupListDrawer"
      isOpen={props.isOpen}
      onClose={props.onClose}
      style={{ width: '300px' }}
    >
      <DrawerHeader onClose={props.onClose} title="Rep Group Members" />
      <DrawerContent>
        {[...Array(3)].map((el, index) => (
          <div className="Reps__groupListDrawer__content__rep" key={index}>
            <div className="Reps__groupListDrawer__content__rep__image"></div>
            <div>
              <a>Mya Philips</a>
              <p>my.philips@example.com</p>
            </div>
          </div>
        ))}
      </DrawerContent>
      <DrawerActions>
        <div className="flex-1"></div>
        <Button color="light" onClick={props.onClose} variant="raised">
          Close
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
