import { XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import './Tag.scss';

type TagProps = {
  label: string;
  secondaryLabel: string;
  onDelete: () => void;
};

export const Tag = (props: TagProps) => {
  return (
    <div className="Products__tag">
      <p>
        <span>{props.label}</span>
      </p>
      <p className="my-0 mx-1">/</p>
      <p>{props.secondaryLabel}</p>
      <XMarkIcon onClick={props.onDelete} />
    </div>
  );
};
