import * as classNames from 'classnames';
import * as React from 'react';

type DrawerActionsProps = React.HTMLAttributes<HTMLDivElement> & {};

export const DrawerActions = ({ children, className, ...rest }: DrawerActionsProps) => {
  const actionsClass = classNames('Drawer__actions', className);

  return (
    <div className={actionsClass} {...rest}>
      {children}
    </div>
  );
};
