import { useLazyQuery, useMutation } from '@apollo/client';
import { CalendarIcon, ClockIcon, PencilIcon } from '@heroicons/react/24/solid';
import { format, set, subDays } from 'date-fns';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, DatePicker, Input, Modal, Spinner, Textarea } from '~/src/components';
import { useToast } from '~/src/features/toast';
import {
  CREATE_AVAILABILITY_MUTATION,
  MY_AVAILABILITY_QUERY,
  REMOVE_AVAILABILITY_MUTATION,
  UPDATE_AVAILABILITY_MUTATION,
} from '../../api';
import './AvailabilityEdit.scss';

type AvailabilityFormData = {
  availabilityDatetime: Date;
  preferredTime: string;
  notes: string;
};

const defaultFormData: AvailabilityFormData = {
  availabilityDatetime: set(new Date(), {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }),
  preferredTime: '09:00',
  notes: '',
};

export const AvailabilityEdit = () => {
  const [formData, setFormData] = React.useState(defaultFormData);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const { editPk } = useParams();
  const navigate = useNavigate();
  const { info: infoToast, error: errorToast } = useToast();

  const [createAvailability] = useMutation(CREATE_AVAILABILITY_MUTATION, {
    onCompleted: () => {
      infoToast('Availability created');
    },
  });
  const [updateAvailability] = useMutation(UPDATE_AVAILABILITY_MUTATION, {
    onCompleted: () => {
      infoToast('Availability updated');
    },
  });
  const [removeAvailability] = useMutation(REMOVE_AVAILABILITY_MUTATION, {
    onCompleted: () => {
      infoToast('Availability deleted');
    },
  });
  const [loadAvailability, { loading }] = useLazyQuery(MY_AVAILABILITY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: editPk },
    onCompleted: (data) => {
      if (data) {
        let node = data.pieceworkerAvailability;
        setFormData({
          ...formData,
          availabilityDatetime: new Date(node?.availabilityDatetime),
          preferredTime: node?.preferredTime,
          notes: node?.notes,
        });
      }
    },
  });

  React.useEffect(() => {
    if (!editPk) {
      return;
    }
    loadAvailability();
  }, [editPk]);

  function clickCancel() {
    navigate('/availability');
  }

  function clickRemove() {
    setDeleteModalOpen((prev) => !prev);
  }

  function deleteAvailability() {
    removeAvailability({
      variables: {
        pk: editPk,
      },
    }).then(() => {
      navigate('/availability');
    });
  }

  function clickAddOrUpdate() {
    if (editPk) {
      updateAvailability({
        variables: { ...formData, id: editPk },
      })
        .then(() => {
          navigate('/availability');
        })
        .catch((err) => {
          errorToast(err.message);
        });
      return;
    }
    createAvailability({
      variables: formData,
    })
      .then(() => {
        navigate('/availability');
      })
      .catch((err) => {
        errorToast(err.message);
      });
  }

  function renderEditView() {
    return (
      <>
        <div className="icon-wrapper">
          <CalendarIcon />
        </div>
        <p className="font-size-18">Set Your Availability</p>
        <div className="AvailabilityEdit__availability">
          <div className="flex-1">
            <DatePicker
              disabledBefore={subDays(new Date(), 1)}
              inputProps={{
                fluid: true,
              }}
              onChange={(value) => {
                if (!value) {
                  return;
                }

                setFormData({
                  ...formData,
                  availabilityDatetime: set(formData.availabilityDatetime, {
                    year: value?.getFullYear(),
                    month: value?.getMonth(),
                    date: value?.getDate(),
                  }),
                });
              }}
              value={formData.availabilityDatetime}
            />
          </div>
          <div className="flex-1">
            <Input
              fluid
              onChange={(e) => {
                setFormData({
                  ...formData,
                  availabilityDatetime: set(formData.availabilityDatetime, {
                    hours: parseInt(e.target.value.split(':')[0]),
                    minutes: parseInt(e.target.value.split(':')[1]),
                  }),
                });
              }}
              type="time"
              value={format(formData.availabilityDatetime, 'HH:mm')}
            />
          </div>
        </div>
        <div className="icon-wrapper">
          <ClockIcon />
        </div>
        <p className="font-size-16 color-gray-600">What is your desired pickup time?</p>
        <p className="color-gray-500">*Pickup time needs to be approved by a Piecework Coordinator.</p>
        <Input
          fluid
          onChange={(e) => {
            setFormData({
              ...formData,
              preferredTime: e.target.value,
            });
          }}
          type="time"
          value={formData.preferredTime}
        />
        <div className="icon-wrapper">
          <PencilIcon />
        </div>
        <p className="font-size-18">Add note</p>
        <p className="color-gray-600">If you're available for extra work or are limited on time, let us know.</p>
        <Textarea
          fluid
          onChange={(e) => {
            setFormData({
              ...formData,
              notes: e.target.value,
            });
          }}
          placeholder="Type your message here."
          rows={5}
          value={formData.notes}
        />
        <div className="AvailabilityEdit__actions">
          <Button
            color="primary"
            disabled={!formData.availabilityDatetime || !formData.preferredTime}
            fluid
            onClick={() => {
              clickAddOrUpdate();
            }}
            variant="raised"
          >
            {editPk ? 'Update' : 'Add'}
          </Button>
          <Button
            color="light"
            fluid
            onClick={() => {
              clickCancel();
            }}
            variant="raised"
          >
            Cancel
          </Button>
          {editPk && (
            <>
              <div className="AvailabilityEdit__actions__spacer"></div>
              <Button
                color="warn"
                fluid
                onClick={() => {
                  clickRemove();
                }}
                variant="raised"
                className='AvailabilityEdit__actions__warnButton'
              >
                Remove
              </Button>
            </>
          )}
        </div>
      </>
    );
  }

  if (loading) {
    return <Spinner message="Loading availability..." />;
  }

  return (
    <div className="AvailabilityEdit">
      <h1 className="h1-mobile">Availability</h1>
      {renderEditView()}
      <Modal isOpen={deleteModalOpen} onClose={() => setDeleteModalOpen((prev) => !prev)}>
        <p className="font-size-20">Are you sure you want to delete this availability?</p>
        <div className="AvailabilityEdit__actions">
          <Button color="light" fluid onClick={() => setDeleteModalOpen((prev) => !prev)} variant="raised">
            Cancel
          </Button>
          <Button color="warn" fluid onClick={() => deleteAvailability()} variant="raised">
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};
