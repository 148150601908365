import * as classNames from 'classnames';
import * as React from 'react';
import './Spinner.scss';

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  message?: string;
  testId?: string;
};

export const Spinner = ({ className, message, testId, ...rest }: SpinnerProps) => {
  const containerClass = classNames('Spinner__container');
  const spinnerClass = classNames('Spinner', className);

  return (
    <div className={containerClass} data-testid={testId && `${testId}-container`}>
      <div className={spinnerClass} data-testid={testId} {...rest}></div>
      {message && (
        <div className="Spinner__message" data-testid={testId && `${testId}-message`}>
          {message}
        </div>
      )}
    </div>
  );
};
