import { useLazyQuery } from '@apollo/client';
import { PrinterIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader } from '~/src/components';
import { formatPhoneNumber } from '~/src/utils/formatting';
import { REPS_QUERY } from '../../api';

type PhoneListDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PhoneListDrawer = (props: PhoneListDrawerProps) => {
  const [reps, setReps] = React.useState<any[]>([]);

  const [getReps] = useLazyQuery(REPS_QUERY);

  React.useEffect(() => {
    setReps([]);
    if (props.isOpen) {
      getRepsRecursive(0);
    }
  }, [props.isOpen]);

  function getRepsRecursive(offset: number) {
    getReps({
      variables: {
        first: 100,
        offset: offset,
      },
    }).then((res) => {
      setReps((prevReps) => [...prevReps, ...res.data.reps.edges.map((edge: any) => edge.node)]);
      if (res.data.reps.pageInfo.hasNextPage) {
        getRepsRecursive(offset + 100);
      }
    });
  }

  return (
    <Drawer
      backdrop
      className="RepsList__phoneList"
      isOpen={props.isOpen}
      onClose={props.onClose}
      style={{ width: '70vw' }}
    >
      <DrawerHeader onClose={props.onClose} title="Reps - Phone List" />
      <DrawerContent className="RepsList__phoneList__table">
        <table className="data-table">
          <thead>
            <tr>
              <th>Rep Name</th>
              <th>Cell</th>
              <th>Phone</th>
              <th>Rep Company</th>
              <th>State</th>
              <th>Area</th>
            </tr>
          </thead>
          <tbody>
            {reps.map((rep: any, index: number) => {
              return (
                <tr key={index}>
                  <td className="RepsList__phoneList__table__name">
                    <Link to={`/reps/${rep.pk}`}>
                      {rep.user.firstName} {rep.user.lastName}
                    </Link>
                  </td>
                  <td className="RepsList__phoneList__table__cell">
                    <a href={`tel:${rep.user.profile?.cell}`}>{formatPhoneNumber(rep.user.profile?.cell)}</a>
                  </td>
                  <td className="RepsList__phoneList__table__companyNumber">
                    <a href={`tel:${rep.user.profile?.phone}`}>{formatPhoneNumber(rep.user.profile?.phone)}</a>
                  </td>
                  <td className="RepsList__phoneList__table__company">{rep.company}</td>
                  <td className="RepsList__phoneList__table__state">{rep.shipState}</td>
                  <td className="RepsList__phoneList__table__area">{rep.areaDescription}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DrawerContent>
      <DrawerActions>
        <div className="flex-1"></div>
        <Button color="light" onClick={props.onClose} variant="raised">
          Close
        </Button>
        <Button
          iconLeading={<PrinterIcon />}
          onClick={() => {
            window.print();
          }}
          variant="outlined"
        >
          Print
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
