import { useLazyQuery } from '@apollo/client';
import {
  ChevronDownIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import * as React from 'react';
import {
  Button,
  DatePicker,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  Input,
  SearchPopover,
  Textarea,
  Toggle,
} from '~/src/components';
import { ACCOUNT_SEARCH } from '~/src/features/accounts';
import { useAuth } from '~/src/features/auth';
import { CREDIT_MEMOS_SEARCH_QUERY, CREDIT_MEMO_PRINT_QUERY, EMPLOYEE_SEARCH_QUERY } from '../../api/queries';
import './AddMemoDrawer.scss';

type AddMemoDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  memo?: any;
  onCreate: (memo: any) => void;
  onUpdate: (pk: number, memo: any) => void;
  onDelete: () => void;
};

type memoDrawerData = {
  account: number;
  approvedBy?: number;
  amount?: number;
  notes?: string;
  date?: Date;
  reason?: string;
  comm?: number;
  zero?: boolean;
};

const memoDrawerDataDefaults: memoDrawerData = {
  account: -1,
  approvedBy: -1,
  amount: 0,
  notes: '',
  date: undefined,
  reason: '',
  comm: 0,
  zero: false,
};

export const AddMemoDrawer = (props: AddMemoDrawerProps) => {
  const [formData, setFormData] = React.useState(memoDrawerDataDefaults);
  const [account, setAccount] = React.useState<any>({});
  const [accountPopoverOpen, setAccountPopoverOpen] = React.useState(false);
  const [employee, setEmployee] = React.useState<any>({});
  const [employeePopoverOpen, setEmployeePopoverOpen] = React.useState(false);
  const [cancelWarningOpen, setCancelWarningOpen] = React.useState(false);

  const { user } = useAuth();
  const [searchAccountMemos, { data: accountMemosData }] = useLazyQuery(CREDIT_MEMOS_SEARCH_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [getPrintMemo, { data: printMemoData }] = useLazyQuery(CREDIT_MEMO_PRINT_QUERY);

  React.useEffect(() => {
    if (props.memo) {
      const { amount, notes, date, reason, comm, zero } = props.memo;
      setFormData((prev) => ({
        ...prev,
        amount: amount && parseFloat(amount).toFixed(2),
        notes,
        date: date ? new Date(date) : undefined,
        reason,
        comm,
        zero,
      }));
      setAccount(props.memo.account || {});
      setEmployee(props.memo.approvedBy || {});
    }
  }, [props.isOpen]);

  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, account: account.pk }));
    account.id && searchAccountMemos({ variables: { account: account.id } });
  }, [account]);

  React.useEffect(() => setFormData((prev) => ({ ...prev, approvedBy: employee.pk })), [employee]);

  function onClose() {
    setFormData(memoDrawerDataDefaults);
    setAccount({});
    setEmployee({});
    setCancelWarningOpen(false);
    props.onClose();
  }

  function onSubmit() {
    !props.memo
      ? props.onCreate({
          ...formData,
          date: formData.date
            ? `${formData.date.getFullYear()}-${(formData.date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}-${formData.date.getDate().toString().padStart(2, '0')}`
            : undefined,
        })
      : props.onUpdate(props.memo.pk, {
          ...formData,
          date: formData.date
            ? `${formData.date.getFullYear()}-${(formData.date.getMonth() + 1)
                .toString()
                .padStart(2, '0')}-${formData.date.getDate().toString().padStart(2, '0')}`
            : undefined,
        });
    onClose();
  }

  function onDelete() {
    props.onDelete();
    setCancelWarningOpen(false);
    onClose();
  }

  function handlePrint() {
    getPrintMemo({
      variables: { pk: props.memo.pk },
      onCompleted: (res) => {
        downloadPdf(res.reportCreditMemoPdf, `CreditMemo_${props.memo.pk}`);
      },
    });
  }

  function downloadPdf(base64Pdf: string, filename: string) {
    // base64pdf is coming in with wierd format, "b'.....'" instead of just string
    base64Pdf = base64Pdf.replace("b'", 'data:application/pdf;base64,');
    base64Pdf = base64Pdf.replace("'", '');
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Pdf;
    downloadLink.download = `${filename}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  }

  return (
    <Drawer backdrop className="AddMemoDrawer" isOpen={props.isOpen} onClose={onClose} style={{ width: '576px' }}>
      <DrawerHeader onClose={onClose} title={props.memo ? 'Edit Credit Memo' : 'New Credit Memo'} />
      <DrawerContent>
        <div className="AddMemoDrawer__body__searchContainer">
          {!props.memo && (
            <div className="AddMemoDrawer__body__labelledInput">
              <p>Select an Account</p>
              <div style={{ position: 'relative' }}>
                <Input
                  fluid
                  iconLeading={<MagnifyingGlassIcon />}
                  iconTrailing={<XMarkIcon />}
                  placeholder="Search"
                  readOnly
                  value={account.pk ? `${account.pk} - ${account.name}` : ''}
                  onClick={() => setAccountPopoverOpen(true)}
                />
                <SearchPopover
                  isOpen={accountPopoverOpen}
                  onChange={(selectedAccount) => setAccount(selectedAccount)}
                  onClose={() => setAccountPopoverOpen(false)}
                  query={ACCOUNT_SEARCH}
                  queryArgument="search"
                  queryField="accounts"
                  renderMatch={(match) => (
                    <>
                      {match.pk} - {match.name}
                    </>
                  )}
                  popoverStyle={{ maxHeight: '400px', overflowY: 'auto' }}
                />
              </div>
            </div>
          )}
          <div className="AddMemoDrawer__body__row">
            <div className="AddMemoDrawer__body__labelledInput">
              <p>Created By</p>
              <Input fluid disabled value={`${user.firstName} ${user.lastName}`} />
            </div>
            <div className="AddMemoDrawer__body__labelledInput">
              <p>Created On</p>
              <DatePicker value={new Date()} inputProps={{ disabled: true }} />
            </div>
          </div>
        </div>
        <div className="AddMemoDrawer__body__divider"></div>
        <div className="AddMemoDrawer__body__creditContainer">
          <div className="AddMemoDrawer__body__inputSpan">
            <p>Credit Date:</p>
            <DatePicker
              inputProps={{ placeholder: 'mm/dd/yyyy' }}
              value={formData.date}
              onChange={(date) => setFormData((prev) => ({ ...prev, date }))}
            />
          </div>
          <div className="AddMemoDrawer__body__inputSpan">
            <p>Credit Amount:</p>
            <Input
              iconLeading={<CurrencyDollarIcon />}
              placeholder="0.00"
              style={{ textAlign: 'right' }}
              inputMode="decimal"
              value={formData.amount || ''}
              onChange={(e) => setFormData((prev) => ({ ...prev, amount: parseFloat(e.target.value) }))}
            />
          </div>
          <div className="AddMemoDrawer__body__inputSpan">
            <p>Commission Rate:</p>
            <Input
              placeholder="0%"
              inputMode="decimal"
              min={0}
              max={100}
              value={formData.comm || ''}
              onChange={(e) =>
                parseFloat(e.target.value) <= 100 &&
                setFormData((prev) => ({ ...prev, comm: parseFloat(e.target.value) }))
              }
            />
          </div>
          <div className="AddMemoDrawer__body__inputSpan">
            <p>Reason for Credit:</p>
            <Input
              onChange={(e) => setFormData((prev) => ({ ...prev, reason: e.target.value }))}
              placeholder="Placeholder"
              value={formData.reason}
            />
          </div>
        </div>
        <div className="AddMemoDrawer__body__divider"></div>
        <div className="AddMemoDrawer__body__approvalContainer">
          <div className="AddMemoDrawer__body__inputSpan">
            <p>Approved By:</p>
            <div style={{ position: 'relative' }}>
              <Input
                fluid
                readOnly
                iconTrailing={<ChevronDownIcon />}
                value={employee?.user ? `${employee.user?.firstName} ${employee.user?.lastName}` : ''}
                onClick={() => setEmployeePopoverOpen(true)}
              />
              <SearchPopover
                isOpen={employeePopoverOpen}
                onChange={(selectedEmployee) => setEmployee(selectedEmployee)}
                onClose={() => setEmployeePopoverOpen(false)}
                query={EMPLOYEE_SEARCH_QUERY}
                queryArgument="search"
                queryField="employees"
                renderMatch={(match) => (
                  <>
                    {match.user.firstName} {match.user.lastName}
                  </>
                )}
                popoverStyle={{ maxHeight: '250px', overflowY: 'auto' }}
              />
            </div>
          </div>
          <div className="AddMemoDrawer__body__inputSpan AddMemoDrawer__body__inputSpan--textarea">
            <p>Approval Notes:</p>
            <Textarea
              onChange={(e) => setFormData((prev) => ({ ...prev, notes: e.target.value }))}
              value={formData.notes}
            />
          </div>
          {!props.memo && (
            <div className="AddMemoDrawer__body__inputSpan">
              <p>Amount:</p>
              <Input
                iconLeading={<CurrencyDollarIcon />}
                placeholder="0.00"
                style={{ textAlign: 'right' }}
                inputMode="decimal"
                min={0}
                value={formData.amount || ''}
                onChange={(e) => setFormData((prev) => ({ ...prev, amount: parseFloat(e.target.value) }))}
              />
            </div>
          )}
        </div>
        <div className="AddMemoDrawer__body__divider"></div>
        {!!props.memo && (
          <>
            <div className="AddMemoDrawer__body__row AddMemoDrawer__body__amountsContainer">
              <div className="AddMemoDrawer__body__labelledInput">
                <p>Amount</p>
                <Input
                  fluid
                  disabled
                  iconLeading={<CurrencyDollarIcon />}
                  value={formData.amount}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <div className="AddMemoDrawer__body__labelledInput">
                <p>Applied</p>
                <Input
                  fluid
                  disabled
                  iconLeading={<CurrencyDollarIcon />}
                  value={'0.00'}
                  style={{ textAlign: 'right' }}
                />
              </div>
              <div className="AddMemoDrawer__body__labelledInput">
                <p>Remaining</p>
                <Input
                  fluid
                  disabled
                  iconLeading={<CurrencyDollarIcon />}
                  value={formData.amount}
                  style={{ textAlign: 'right' }}
                />
              </div>
            </div>
            <div className="AddMemoDrawer__body__divider"></div>
          </>
        )}
        <div className="AddMemoDrawer__body__tableContainer">
          <div className="AddMemoDrawer__body__labelledToggle">
            <p>Zero Balance?</p>
            <Toggle checked={formData.zero} onChange={() => setFormData((prev) => ({ ...prev, zero: !prev.zero }))} />
          </div>
          {account.id && (
            <table className="data-table">
              <colgroup>
                <col style={{ width: '20%' }}></col>
                <col style={{ width: '40%' }}></col>
                <col style={{ width: '20%' }}></col>
                <col style={{ width: '20%' }}></col>
              </colgroup>
              <thead>
                <tr>
                  <th>invoice #</th>
                  <th></th>
                  <th>amount</th>
                  <th>comm</th>
                </tr>
              </thead>
              <tbody>
                {accountMemosData?.creditMemos.edges.length ? (
                  accountMemosData.creditMemos.edges.map((edge: any, index: number) => (
                    <tr key={index}>
                      <td>{edge.node.account.pk}</td>
                      <td>{edge.node.reason}</td>
                      <td>{edge.node.amount && `($${Number.parseFloat(edge.node.amount).toFixed(2)})`}</td>
                      <td>{typeof edge.node.comm === 'number' && `${edge.node.comm}%`}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ fontStyle: 'italic' }}>
                      No credit memos exist on this account.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </DrawerContent>
      {!cancelWarningOpen && (
        <DrawerActions className="AddMemoDrawer__actions">
          {props.memo && (
            <Button color="warn" onClick={() => setCancelWarningOpen(true)} variant="raised">
              Cancel Credit Memo
            </Button>
          )}
          <div className="flex-1"></div>
          {props.memo && (
            <Button variant="outlined" onClick={handlePrint}>
              Print
            </Button>
          )}
          <Button color="primary" disabled={!account.id} onClick={onSubmit} variant="raised">
            {props.memo ? 'Update' : 'Create'}
          </Button>
        </DrawerActions>
      )}
      {cancelWarningOpen && (
        <div className="AddMemoDrawer__cancelWarning">
          <XCircleIcon />
          <div className="AddMemoDrawer__cancelWarning__content">
            <p className="AddMemoDrawer__cancelWarning__header">Cancel Credit Memo</p>
            <p className="AddMemoDrawer__cancelWarning__text">
              Are you sure you want cancel this Credit Memo? This will delete the credit from the account.
            </p>
            <div className="AddMemoDrawer__cancelWarning__options">
              <a onClick={() => setCancelWarningOpen(false)}>Take me back</a>
              <Button onClick={onDelete} size="sm" variant="outlined">
                Yes, I'm sure
              </Button>
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
};
