import * as React from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useToast } from '~/src/features/toast';
import { Button, DataTable, DebouncedInput, IconButton, Pagination, Spinner } from '~/src/components';
import {
  DISPLAY_MODULES_QUERY,
  DISPLAY_MODULE_QUERY,
  DISPLAY_MODULE_CREATE_MUTATION,
  DISPLAY_MODULE_UPDATE_MUTATION,
  DISPLAY_MODULE_DELETE_MUTATION,
  DISPLAY_ROW_CREATE_MUTATION,
  DISPLAY_ROW_CLONE_MUTATION,
  DISPLAY_ROW_DELETE_MUTATION,
  DISPLAY_POSITION_CREATE_MUTATION,
  DISPLAY_POSITION_UPDATE_MUTATION,
  DISPLAY_POSITION_DELETE_MUTATION,
} from '../../api';
import { AddItemModal, ConfirmationModal, CreateModuleModal, EditModuleModal } from '../../components';
import './DisplayModules.scss';

const PAGE_SIZE = 20;

export const DisplayModules = () => {
  const { error } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = React.useState('');
  const [createModuleModalOpen, setCreateModuleModalOpen] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });

  const [loadModules, { data, loading }] = useLazyQuery(DISPLAY_MODULES_QUERY, {
    variables: {
      section_Isnull: true,
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      search: searchParams.get('search'),
    },
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadModules({ fetchPolicy: 'network-only' }),
    onError: (err: any) => error(err.message),
  };
  const [createModule] = useMutation(DISPLAY_MODULE_CREATE_MUTATION, options);
  const [deleteModule] = useMutation(DISPLAY_MODULE_DELETE_MUTATION, options);

  React.useEffect(() => {
    searchParams.get('search') && setSearch(searchParams.get('search') || '');
    loadModules();
  }, [searchParams]);

  if (!data || loading) {
    return (
      <div className="Displays__listPage">
        <Spinner message="Loading modules..." />
      </div>
    );
  }

  return (
    <div className="Displays__listPage">
      <div className="Displays__listPage__header">
        <h4 className="Displays__listPage__header__title">Display Modules</h4>
        <Button
          variant="raised"
          color="primary"
          onClick={() => setCreateModuleModalOpen(true)}
          iconLeading={<PlusIcon />}
        >
          Add module
        </Button>
      </div>
      <DebouncedInput
        fluid
        placeholder="Search for an instance..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onDebounce={(val) => {
          searchParams.delete('page');
          if (val) {
            searchParams.set('search', val);
          } else {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        className="mb-4"
      />
      <div className="Displays__listPage__list">
        <DataTable
          headers={[{ label: 'name' }, { label: 'rows' }, { label: 'positions' }, { label: '' }, { label: '' }]}
        >
          {data.displayModules.edges.map((edge: any) => (
            <tr key={edge.node.pk}>
              <td>{edge.node.name}</td>
              <td>{edge.node.rows?.edges.length}</td>
              <td></td>
              <td>
                <Link to={`/displays/modules/${edge.node.pk}`}>View</Link>
              </td>
              <td>
                <IconButton
                  color="warn"
                  onClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      title: 'Delete module',
                      message: 'Are you sure you want to delete this module?',
                      onSubmit: () => deleteModule({ variables: { pk: edge.node.pk } }),
                    });
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </DataTable>
      </div>
      <Pagination
        page={parseInt(searchParams.get('page') || '1')}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        }}
        hasNextPage={data.displayModules.pageInfo.hasNextPage}
        totalNodes={data.displayModules.edges.length}
        totalNodesOnPage={data.displayModules.edges.length}
      />
      <CreateModuleModal
        isOpen={createModuleModalOpen}
        onClose={() => setCreateModuleModalOpen(false)}
        onSubmit={({ name, limitToGroups, allowedGroupVariations }) => {
          createModule({ variables: { input: { name, limitToGroups, allowedGroupVariations } } });
        }}
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
        onSubmit={confirmationModal.onSubmit}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
    </div>
  );
};

export const DisplayModuleDetails = () => {
  const { moduleId } = useParams();
  const { error } = useToast();

  const [itemModal, setItemModal] = React.useState<{
    isOpen: boolean;
    row?: number;
    position?: number;
    data?: { positionType: string };
  }>({ isOpen: false });
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });
  const [editModuleModalOpen, setEditModuleModalOpen] = React.useState(false);
  const [createPositionsLoading, setCreatePositionsLoading] = React.useState(false);

  const [loadModule, { data, loading }] = useLazyQuery(DISPLAY_MODULE_QUERY, {
    variables: { pk: moduleId },
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadModule(),
    onError: (err: any) => error(err.message),
  };
  const [updateModule] = useMutation(DISPLAY_MODULE_UPDATE_MUTATION, options);
  const [addRow, { loading: load1 }] = useMutation(DISPLAY_ROW_CREATE_MUTATION, options);
  const [deleteRow, { loading: load2 }] = useMutation(DISPLAY_ROW_DELETE_MUTATION, options);
  const [cloneRow, { loading: load3 }] = useMutation(DISPLAY_ROW_CLONE_MUTATION, options);
  // manually handling onCompleted and onError for createPosition because mutation is executed multiple times
  const [createPosition, { loading: load4 }] = useMutation(DISPLAY_POSITION_CREATE_MUTATION);
  const [editPosition, { loading: load5 }] = useMutation(DISPLAY_POSITION_UPDATE_MUTATION, options);
  const [deleteItem, { loading: load6 }] = useMutation(DISPLAY_POSITION_DELETE_MUTATION, options);

  React.useEffect(() => {
    if (!moduleId) return;
    loadModule();
  }, [moduleId]);

  function renderRow(row: any, index: number) {
    return (
      <div className="DisplayModuleDetails__row" key={index}>
        <div className="DisplayModuleDetails__row__info">
          <span className="DisplayModuleDetails__row__info__title">
            <p>Row {row.order}</p>
            <span className="DisplayModuleDetails__row__info__links">
              <a onClick={() => cloneRow({ variables: { row: row.order, modulePk: moduleId } })}>Clone</a>
              <p>|</p>
              <a
                onClick={() => {
                  setConfirmationModal({
                    isOpen: true,
                    title: 'Delete row',
                    message: 'Are you sure you want to delete this row?',
                    onSubmit: () => {
                      deleteRow({
                        variables: {
                          modulePk: moduleId,
                          row: row.order,
                        },
                      });
                    },
                  });
                }}
              >
                Delete
              </a>
            </span>
          </span>
        </div>
        <div className="DisplayModuleDetails__row__items">
          {row.positions.edges.map((edge: any, idx: number) => renderItem(row, edge.node, idx))}
          <IconButton color="primary" onClick={() => setItemModal({ isOpen: true, row: row.order })}>
            <PlusIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  function renderItem(row: any, position: any, idx: number) {
    return (
      <div className="DisplayModuleDetails__row__item" key={idx}>
        <p>Type: {position.positionType.charAt(0) + position.positionType.slice(1).toLowerCase()}</p>
        <div className="DisplayModuleDetails__row__item__links">
          <a
            onClick={() =>
              setItemModal({
                isOpen: true,
                row: row.order,
                position: position.order,
                data: { positionType: position.positionType },
              })
            }
          >
            Edit
          </a>
          <p>|</p>
          <a
            onClick={() =>
              setConfirmationModal({
                isOpen: true,
                title: 'Delete position',
                message: 'Are you sure you want to delete this position?',
                onSubmit: () => {
                  deleteItem({
                    variables: {
                      modulePk: moduleId,
                      row: row.order,
                      position: position.order,
                    },
                  });
                },
              })
            }
          >
            Delete
          </a>
        </div>
      </div>
    );
  }

  if (!data || loading) {
    return (
      <div className="DisplayModuleDetails">
        <Spinner message="Loading module details..." />
      </div>
    );
  }

  return (
    <div className="DisplayModuleDetails">
      <div className="DisplayModuleDetails__header">
        <h4 className="DisplayModuleDetails__header__title">{data.displayModule.name}</h4>
        <Button variant="raised" onClick={() => setEditModuleModalOpen(true)}>
          Edit Module
        </Button>
      </div>
      {load1 || load2 || load3 || load4 || load5 || load6 || createPositionsLoading ? (
        <Spinner message="Saving changes..." />
      ) : (
        <div className="DisplayModuleDetails__content">
          <div className="DisplayModuleDetails__content__title">
            <p>Rows</p>
            <Button
              iconLeading={<PlusIcon />}
              onClick={() => {
                addRow({ variables: { modulePk: moduleId, input: {} } });
              }}
              variant="raised"
              color="primary"
            >
              Add Row
            </Button>
          </div>
          <div className="DisplayModuleDetails__rows">
            {data.displayModule.rows.edges.map((edge: any, index: number) => renderRow(edge.node, index))}
          </div>
          <EditModuleModal
            isOpen={editModuleModalOpen}
            onClose={() => setEditModuleModalOpen(false)}
            module={data.displayModule}
            onSave={({ name }) => updateModule({ variables: { pk: moduleId, input: { name } } })}
          />
          <AddItemModal
            isOpen={itemModal.isOpen}
            onClose={() => setItemModal({ isOpen: false })}
            data={itemModal.data}
            onSubmit={({ positionType, amount, editing }) => {
              if (editing) {
                editPosition({
                  variables: {
                    modulePk: moduleId,
                    row: itemModal.row,
                    position: itemModal.position,
                    input: { positionType },
                  },
                });
              } else {
                setCreatePositionsLoading(true);
                Promise.all(
                  [...Array(amount)].map((_) =>
                    createPosition({
                      variables: {
                        modulePk: moduleId,
                        row: itemModal.row,
                        input: { positionType },
                      },
                    })
                  )
                )
                  .then(() => {
                    options.onCompleted();
                    setCreatePositionsLoading(false);
                  })
                  .catch((err) => {
                    options.onError(err);
                    setCreatePositionsLoading(false);
                  });
              }
            }}
          />
          <ConfirmationModal
            isOpen={confirmationModal.isOpen}
            onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
            onSubmit={confirmationModal.onSubmit}
            title={confirmationModal.title}
            message={confirmationModal.message}
          />
        </div>
      )}
    </div>
  );
};
