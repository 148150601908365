import { useLazyQuery } from '@apollo/client';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  DataTable,
  DatePicker,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Popover,
  Select,
  Spinner,
  Toggle,
} from '~/src/components';
import { formatPhoneNumber } from '~/src/utils/formatting';
import { CUSTOM_ORDERS_QUERY } from '../../api';
import { formatPriceString } from '../EverynameOrderDetails';
import './EverynameOrdersList.scss';

function formatDateTimeString(string?: string) {
  if (!string) return undefined;
  const date = new Date(string);
  const correctedHours = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
  // MM/dd/yyyy hh/mm AM
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${correctedHours}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
}

type EverynameOrdersListFilters = {
  active: boolean | null; // not implemented
  page: number;
  pageSize: number;
  search: string; // not implemented
  sort: string;
  orderDate_Gte: string | undefined;
  orderDate_Lte: string | undefined;
  cancelled: boolean;
};

const EverynameDataTableHeaders = [
  {
    label: 'order no',
    sort: 'id',
    size: 'md',
  },
  {
    label: 'order date / time',
    sort: 'order_date',
    size: 'md',
  },
  {
    label: 'access code',
    sort: 'access_code',
    size: 'md',
  },
  {
    label: 'customer name',
    sort: 'last_name',
    size: 'md',
  },
  {
    label: 'email',
    sort: 'email',
    size: 'md',
  },
  {
    label: 'city',
    sort: 'city',
    size: 'md',
  },
  {
    label: 'state',
    sort: 'state',
    size: 'md',
  },
  {
    label: 'ship date',
    sort: 'ship_date',
    size: 'md',
  },
  {
    label: 'order total',
    sort: 'order_total',
    size: 'md',
  },
];

export const EverynameOrdersList = () => {
  const [reportsPopoverOpen, setReportsPopoverOpen] = React.useState(false);
  const [accessCodePopover, setAccessCodePopover] = React.useState(-1);
  const [searchInput, setSearchInput] = React.useState('');
  const [filters, setFilters] = React.useState<EverynameOrdersListFilters>({
    active: true,
    page: 1,
    pageSize: 100,
    search: '',
    sort: '-id',
    orderDate_Gte: undefined,
    orderDate_Lte: undefined,
    cancelled: false,
  });

  const [searchEverynameOrders, { data, loading }] = useLazyQuery(CUSTOM_ORDERS_QUERY, {
    variables: {
      first: filters.pageSize,
      offset: (filters.page - 1) * filters.pageSize,
      sort: filters.sort,
      search: filters.search,
      orderDate_Gte: filters.orderDate_Gte,
      orderDate_Lte: filters.orderDate_Lte,
      shipDate_Isnull: filters.active,
      cancelledDate_Isnull: !filters.cancelled,
    },
  });

  React.useEffect(() => {
    searchEverynameOrders();
  }, [filters]);

  function renderTableData() {
    return data.customOrders.edges.map((edge: any, index: number) => (
      <tr key={edge.node.id}>
        <td>
          <Link to={`/everyname-orders/${edge.node.pk}`}>{edge.node.pk}</Link>
        </td>
        <td>{formatDateTimeString(edge.node.orderDate)}</td>
        <td>
          <div className="relative">
            <a onClick={() => setAccessCodePopover(index)}>{edge.node.accessCode?.code}</a>
            <Popover
              className="EverynameList__accessCode"
              isOpen={accessCodePopover === index}
              onClose={() => setAccessCodePopover(-1)}
            >
              <div className="EverynameList__accessCode__content">
                <span>
                  Account:
                  <a>{edge.node.accessCode?.account?.name}</a>
                </span>
                <span>
                  Billing:
                  <a>{edge.node.accessCode?.account?.billing?.name}</a>
                </span>
                <span>
                  Address:
                  <div className="EverynameList__accessCode__content__address">
                    <p>{edge.node.accessCode?.account?.address}</p>
                    <p>
                      {edge.node.accessCode?.account?.city}, {edge.node.accessCode?.account?.state}{' '}
                      {edge.node.accessCode?.account?.postal}
                    </p>
                  </div>
                </span>
                <span>
                  Phone:
                  <a>{formatPhoneNumber(edge.node.accessCode?.account?.phone)}</a>
                </span>
                <span>
                  Email:
                  <a>{edge.node.accessCode?.account?.email}</a>
                </span>
              </div>
            </Popover>
          </div>
        </td>
        <td>
          {edge.node.firstName} {edge.node.lastName}
        </td>
        <td>{edge.node.email && <a href={`mailto:${edge.node.email}`}>{edge.node.email}</a>}</td>
        <td>{edge.node.city}</td>
        <td>{edge.node.state}</td>
        <td>
          {(() => {
            if (!edge.node.shipDate) return '';
            const date = new Date(edge.node.shipDate);
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          })()}
        </td>
        <td>{formatPriceString(edge.node.orderTotal)}</td>
      </tr>
    ));
  }

  return (
    <>
      <Nav>
        <div className="relative">
          <div className="EverynameList__reportsLink" onClick={() => setReportsPopoverOpen(true)}>
            <div>Reports</div>
            <ChevronDownIcon />
          </div>
          <Popover isOpen={reportsPopoverOpen} onClose={() => setReportsPopoverOpen(false)}>
            <div className="EverynameList__reportsPopover">
              <div className="EverynameList__reportsPopover__column">
                <h4>Reports</h4>
                <p>Analytics for everyname.com</p>
                <a href="" target="_blank">
                  Everyname Analytics
                </a>
              </div>
            </div>
          </Popover>
        </div>
      </Nav>
      <Container className="EverynameList__container">
        <ListPageHeader title="Everyname Orders" />
        <div className="EverynameList__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => setSearchInput(e.target.value)}
            onDebounce={(value) => setFilters((p) => ({ ...p, search: value, page: 1 }))}
            placeholder="Search"
            value={searchInput}
          />
          <Select
            options={[
              { value: 'OPEN_ORDERS', label: 'Open orders' },
              { value: 'COMPLETED_ORDERS', label: 'Completed orders' },
            ]}
            value={filters.active ? 'OPEN_ORDERS' : 'COMPLETED_ORDERS'}
            style={{ width: '150px' }}
            onChange={(e) => setFilters((p) => ({ ...p, active: e.target.value === 'OPEN_ORDERS', page: 1 }))}
          />
          <div>
            <label>Date range</label>
            <DatePicker
              value={filters.orderDate_Gte ? new Date(filters.orderDate_Gte) : undefined}
              onChange={(date) => {
                setFilters((prev) => ({ ...prev, orderDate_Gte: date ? date.toISOString() : undefined }));
              }}
            />
          </div>
          <DatePicker
            value={filters.orderDate_Lte ? new Date(filters.orderDate_Lte) : undefined}
            onChange={(date) => {
              setFilters((prev) => ({ ...prev, orderDate_Lte: date ? date.toISOString() : undefined }));
            }}
          />
          <div className="flex-1"></div>
          <Toggle
            label="View Cancelled"
            checked={filters.cancelled}
            onChange={(e) => setFilters((p) => ({ ...p, cancelled: e.target.checked, page: 1 }))}
          />
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading order data..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={EverynameDataTableHeaders}
              sort={filters.sort}
              onSortChange={(sort: any) => setFilters((prev) => ({ ...prev, sort }))}
            >
              {renderTableData()}
            </DataTable>
            <Pagination
              page={filters.page}
              pageSize={filters.pageSize}
              onPageChange={(page) => {
                setFilters((prev) => ({
                  ...prev,
                  page,
                }));
              }}
              hasNextPage={data?.customOrders.pageInfo.hasNextPage}
              totalNodes={data?.customOrders.totalNodes}
              totalNodesOnPage={data?.customOrders.totalNodesOnPage}
            />
          </>
        )}
      </Container>
    </>
  );
};
