import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, DatePicker, Input, Modal, PaymentTypeSelect } from '~/src/components';
import './AddPaymentModal.scss';

type AddPaymentModalProps = {
  isEditing?: boolean;
  isOpen: boolean;
  onClose: () => void;
  callback: (paymentInfo: any) => void;
  paymentToEdit: any;
};

type AddPaymentFormData = {
  paymentType?: number;
  payDate?: Date;
  payLastFour?: string;
  payAmount?: number;
};

const AddPaymentDefaultData: AddPaymentFormData = {
  paymentType: -1,
  payDate: undefined,
  payLastFour: '',
  payAmount: 0,
};

export const AddPaymentModal = (props: AddPaymentModalProps) => {
  const [paymentForm, setPaymentForm] = React.useState(AddPaymentDefaultData);

  React.useEffect(() => {
    if (!props.paymentToEdit) return;
    setPaymentForm({
      paymentType: props.paymentToEdit.paymentType.pk,
      payDate: timezoneAdjuster(props.paymentToEdit.payDate),
      payLastFour: props.paymentToEdit.payLastFour,
      payAmount: Math.round(props.paymentToEdit.payAmount * 100) / 100,
    });
  }, [props.paymentToEdit, props.isOpen]);

  function onCloseHandler() {
    setPaymentForm(AddPaymentDefaultData);
    props.onClose();
  }

  function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.callback({
      paymentPk: props.paymentToEdit?.pk,
      payment: { ...paymentForm, payDate: dateStringer(paymentForm.payDate) },
    });
    onCloseHandler();
  }

  function timezoneAdjuster(dateString: string | null) {
    if (!dateString) return undefined;
    let date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }

  function dateStringer(date: Date | undefined) {
    if (!date) return null;
    let year = date.getFullYear();
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <div className="AddPaymentModal">
      <Modal isOpen={props.isOpen} onClose={onCloseHandler}>
        <div className="modal-header text-center">{true ? 'Edit Payment' : 'Add Payment'}</div>
        <form onSubmit={onSubmitHandler}>
          <div className="modal-body flex flex-column gap-4">
            <div className="flex align-center justify-between">
              <label htmlFor="paymentTypeInput">Payment Type</label>
              <PaymentTypeSelect
                value={paymentForm.paymentType}
                onChange={(e) => setPaymentForm((prev) => ({ ...prev, paymentType: Number(e.target.value) }))}
              />
            </div>
            <div className="flex align-center justify-between">
              <label htmlFor="paymentTypeInput">Date</label>
              <DatePicker
                value={paymentForm.payDate}
                onChange={(date) => setPaymentForm((prev) => ({ ...prev, payDate: date }))}
              />
            </div>
            <div className="flex align-center justify-between">
              <label htmlFor="paymentTypeInput">Check # or Last 4</label>
              <Input
                type="text"
                placeholder="Last Four"
                value={paymentForm.payLastFour}
                onChange={(e) => setPaymentForm((prev) => ({ ...prev, payLastFour: e.target.value }))}
              />
            </div>
            <div className="flex align-center justify-between">
              <label htmlFor="paymentTypeInput">Amount</label>
              <Input
                className="AddPaymentModal__amount__input"
                type="number"
                placeholder="0.00"
                iconLeading={<CurrencyDollarIcon />}
                value={paymentForm.payAmount}
                onChange={(e) => setPaymentForm((prev) => ({ ...prev, payAmount: Number(e.target.value) }))}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button color="light" onClick={onCloseHandler} type="button" variant="raised">
              Close
            </Button>
            <Button color="primary" type="submit" variant="raised">
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
