import * as React from 'react';
import { Button, Modal } from '~/src/components';
import './PaymentConfirmation.scss';

type PaymentConfirmationProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PaymentConfirmation = (props: PaymentConfirmationProps) => {
  return (
    <Modal
      className="text-center"
      isOpen={props.isOpen}
      onClose={props.onClose}
      style={{
        width: '384px',
      }}
    >
      <div className="PaymentConfirmation">
        <h5>Payment Confirmation</h5>
        <p>Please confirm that the payment has been sent to the Pieceworker.</p>
        <div className="buttonGroup">
          <Button
            color="light"
            onClick={() => {
              props.onClose();
            }}
            variant="raised"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.onClose();
            }}
            variant="raised"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
