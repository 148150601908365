import * as React from 'react';
import '../Input/Input.scss';
import './Select.scss';

export type SelectOption = {
  label: string;
  value: any;
};

export type SelectProps = {
  className?: string;
  defaultValue?: any;
  disabled?: boolean;
  filled?: boolean;
  fluid?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: any) => void;
  options?: SelectOption[];
  style?: React.CSSProperties;
  testId?: string;
  value?: any;
};

export const Select = (props: SelectProps) => {
  let selectClass = props.filled ? 'Input--filled' : 'Input';
  let wrapperClass = 'Input__container';

  if (props.fluid) {
    selectClass += ' Input--fluid';
    wrapperClass += ' Input__container--fluid';
  }

  if (props.className) {
    selectClass += ` ${props.className}`;
  }

  return (
    <>
      <div className={wrapperClass}>
        <select
          id={props.id}
          name={props.name}
          className={selectClass}
          style={props.style}
          disabled={props.disabled}
          value={props.value}
          onChange={props.onChange}
          defaultValue={props.defaultValue}
          data-testid={props.testId}
        >
          {props.options &&
            props.options.map((option, idx) => {
              return (
                <option key={idx} value={option.value} label={option.label}>
                  {option.label}
                </option>
              );
            })}
        </select>
      </div>
    </>
  );
};
