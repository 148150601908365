import { useMutation } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { isEqual } from 'lodash';
import * as React from 'react';
import { flushSync } from 'react-dom';
import { Button, Drawer, IconButton, Input, TabItem, Tabs } from '~/src/components';
import { VariableFieldsLayer } from '..';
import { LAYER_CREATE_MUTATION, LAYER_DELETE_MUTATION, LAYER_UPDATE_MUTATION } from '../../api';
import './VariableFieldsDrawer.scss';

type ImageLayer = any;
type TextLayer = any;

export type Layer = ImageLayer | TextLayer;

const imageLayerDefaults: ImageLayer = {
  id: '',
  pk: -1,
  name: 'Image layer name',
  type: { pk: 2, name: 'image' },
  parameters: {},
};

const textLayerDefaults: TextLayer = {
  id: '',
  pk: -1,
  name: 'Text layer name',
  type: { pk: 1, name: 'text' },
  parameters: {},
};

type ObservedTabItemProps = {
  active: boolean;
  className?: string;
  onClick: () => void;
  onInView: (inView: boolean | null) => void;
  options?: IntersectionObserverInit;
  children: any;
};

const ObservedTabItem = React.forwardRef((props: ObservedTabItemProps, ref: (node: HTMLDivElement | null) => void) => {
  const tabRef = React.useRef<any>(null);
  const callback = (entries: any) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      // when removing elements from DOM, send null instead of false
      const boundingRectEmpty = !entry.boundingClientRect.width && !entry.boundingClientRect.height;
      boundingRectEmpty ? props.onInView(null) : props.onInView(entry.isIntersecting);
    });
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(callback, props.options);
    tabRef.current && observer.observe(tabRef.current);
    return () => tabRef.current && observer.unobserve(tabRef.current);
  }, [tabRef.current]);

  return (
    <div ref={(node) => (ref(node), (tabRef.current = node))}>
      <TabItem active={props.active} onClick={props.onClick} children={props.children} className={props.className} />
    </div>
  );
});

type VariableFieldsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  frontOrBack: 'artFront' | 'artBack';
  productRefetch: () => void;
};

export const VariableFieldsDrawer = (props: VariableFieldsDrawerProps) => {
  const rawLayerEdges = props.product?.[props.frontOrBack]?.layers.edges;
  const rawLayers = rawLayerEdges
    ?.map((edge: any) => ({
      ...edge.node,
      parameters: JSON.parse(edge.node?.parameters || ''),
    }))
    .sort((layer1: any, layer2: any) => {
      return layer1.drawOrder < layer2.drawOrder ? -1 : layer1.drawOrder > layer2.drawOrder ? 1 : 0;
    });

  const [layers, setLayers] = React.useState<Layer[]>([]);
  const [isTextBounding, setIsTextBounding] = React.useState(false);
  const [activeLayerIndex, setActiveLayerIndex] = React.useState(-1);
  const [tabsOverlay, setTabsOverlay] = React.useState<{ next: boolean; prev: boolean }>({ next: false, prev: false });
  const tabsRef = React.useRef(new Map());

  const [layerCreate] = useMutation(LAYER_CREATE_MUTATION);
  const [layerUpdate] = useMutation(LAYER_UPDATE_MUTATION);
  const [layerDelete] = useMutation(LAYER_DELETE_MUTATION);

  React.useEffect(() => {
    if (!props.isOpen) return;
    setAllToDefaults();
    setActiveLayerIndex(0);
  }, [props.isOpen, props.product]);

  function setAllToDefaults() {
    setLayers(rawLayers ? rawLayers : []);
    setActiveLayerIndex(0);
    setTabsOverlay((p) => ({ prev: false, next: false }));
    setIsTextBounding(false);
  }

  function addLayer(type: 'text' | 'image') {
    flushSync(() => setLayers((prev) => [...prev, type === 'text' ? textLayerDefaults : imageLayerDefaults]));
    scrollToTabIndex(layers.length);
  }

  function removeLayer(indexToRemove: number) {
    flushSync(() => setLayers((prev) => prev.filter((el, idx) => idx !== indexToRemove)));
    scrollToTabIndex(indexToRemove > 0 ? indexToRemove - 1 : 0);
  }

  function scrollToTabIndex(index: number) {
    tabsRef.current.get(index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    setActiveLayerIndex(index);
  }

  function handleOnInViewChange(inView: boolean | null, index: number) {
    if (index >= tabsRef.current.size - 1) setTabsOverlay((p) => ({ ...p, next: inView === null ? false : !inView }));
    if (index === 0) setTabsOverlay((p) => ({ ...p, prev: inView === null ? false : !inView }));
  }

  function handleInputChange(e: any, indexToChange: number) {
    const newLayers = layers.map((layer, idx) => {
      if (idx !== indexToChange) return layer;
      if (e.target.name === 'name') return { ...layer, name: e.target.value };
      return { ...layer, parameters: { ...layer.parameters, [e.target.name]: e.target.value } };
    });
    if (!isEqual(newLayers, layers)) setLayers(newLayers);
  }

  function getImageURL(): string {
    const arcedFields = ['radius', 'angle_start', 'angle_end', 'orientation'];
    const obj = {
      [props.product?.[props.frontOrBack]?.artNumber]: layers.reduce(
        (prev, curr, index) => ({
          ...prev,
          [curr.name]:
            (curr.type.name === 'text' ? curr.parameters?.preview_text : curr.parameters?.default_image) || '',
          [`${curr.name}_parameters`]: {
            ...Object.keys(curr.parameters).reduce(
              (p, c) => ({
                ...p,
                [c]:
                // if field is "arced" field and arced_text is unchecked, or if field is color and color is invalid, return undefined
                  (arcedFields.includes(c) && !curr.parameters['arced_text']) ||
                  (c.includes('color') && !curr.parameters[c].match(/^#[0-9a-f]{6}$/i))
                    ? undefined
                    : // convert strings to numbers where necessary
                    isNaN(Number(curr.parameters[c]))
                    ? curr.parameters[c]
                    : Number(curr.parameters[c]),
              }),
              {}
            ),
            type: curr.type.name,
            draw_order: index,
          },
        }),
        { show_debug_lines: isTextBounding }
      ),
    };
    return encodeURIComponent(JSON.stringify(obj));
  }

  async function savedownHandler() {
    const newLayerObjs = layers.map((layer, index) => ({ layer, index })).filter((obj) => !obj.layer.id);
    const preexistingLayerObjs = layers.map((layer, index) => ({ layer, index })).filter((obj) => obj.layer.id);
    const deletedLayers = rawLayers.filter((rawLayer: any) => !layers.find((layer) => layer.id === rawLayer.id));
    const formatToGraphql = (objArr: any[]) => {
      return objArr.map((obj) => ({
        layer: obj.layer.id ? obj.layer.pk : undefined,
        type: obj.layer.type.pk,
        artNumber: props.product?.[props.frontOrBack]?.pk,
        name: obj.layer.name,
        drawOrder: obj.index,
        variable: true,
        parameters: JSON.stringify(obj.layer.parameters),
      }));
    };
    await deletedLayers.forEach(
      async (deletedLayer: any) =>
        await layerDelete({
          variables: { pk: deletedLayer.pk },
        })
    );
    Promise.all([
      newLayerObjs.length && layerCreate({ variables: { layers: formatToGraphql(newLayerObjs) } }),
      preexistingLayerObjs.length && layerUpdate({ variables: { layers: formatToGraphql(preexistingLayerObjs) } }),
    ])
      .then(() => props.productRefetch())
      .catch((err) => console.log(err.message));
  }

  function onCloseHandler() {
    setAllToDefaults();
    props.onClose();
  }

  return (
    <Drawer
      backdrop
      isOpen={props.isOpen}
      onClose={onCloseHandler}
      style={{ width: 'max-content', maxWidth: '1400px' }}
    >
      <div className="VariableFields">
        <IconButton className="VariableFields__closeButton" onClick={onCloseHandler}>
          <XMarkIcon />
        </IconButton>
        <div className="VariableFields__content">
          <div className="VariableFields__fields">
            <div className="VariableFields__fields__header">
              <span className="VariableFields__keyValueSpan">
                <p>Product</p>
                <Input disabled value={props.product?.name} />
              </span>
              <div className="VariableFields__fields__header__tabs">
                <Tabs>
                  {layers.map((layer, idx) => (
                    <ObservedTabItem
                      key={idx}
                      ref={(node) => (node ? tabsRef.current.set(idx, node) : tabsRef.current.delete(idx))}
                      active={activeLayerIndex === idx}
                      onClick={() => scrollToTabIndex(idx)}
                      onInView={(inView) => handleOnInViewChange(inView, idx)}
                      options={{ threshold: 0.9 }}
                    >
                      <p>{layer.name}</p>
                      <p>{`${(() => {
                        const name: string | undefined = layer.type?.name;
                        return `${name?.slice(0, 1).toUpperCase()}${name?.slice(1)}`;
                      })()} - ${idx + 1}`}</p>
                    </ObservedTabItem>
                  ))}
                </Tabs>
                <div className="VariableFields__fields__header__tabs__buttonOverlay">
                  {tabsOverlay.prev && (
                    <div
                      className="VariableFields__fields__header__tabs__buttonOverlay__prev"
                      onClick={() => scrollToTabIndex(activeLayerIndex > 0 ? activeLayerIndex - 1 : activeLayerIndex)}
                    >
                      <ChevronLeftIcon />
                    </div>
                  )}
                  {tabsOverlay.next && (
                    <div
                      className="VariableFields__fields__header__tabs__buttonOverlay__next"
                      onClick={() =>
                        scrollToTabIndex(activeLayerIndex < layers.length - 1 ? activeLayerIndex + 1 : activeLayerIndex)
                      }
                    >
                      <ChevronRightIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {layers[activeLayerIndex] && (
              <VariableFieldsLayer
                layer={layers[activeLayerIndex]}
                onInputChange={(e) => handleInputChange(e, activeLayerIndex)}
              />
            )}
          </div>
          <div className="VariableFields__image">
            <div className="VariableFields__image__header">
              <p
                className={isTextBounding ? '' : 'VariableFields__image__header__activeTab'}
                onClick={() => setIsTextBounding(false)}
              >
                Product image
              </p>
              <p
                className={isTextBounding ? 'VariableFields__image__header__activeTab' : ''}
                onClick={() => setIsTextBounding(true)}
              >
                Text bounding box
              </p>
            </div>
            <div className="VariableFields__image__container">
              <img
                src={`${process.env.OPS_API}/images/${
                  props.product?.pk
                }/render/?&data=${getImageURL()}&cache=false&rescale=false&view=product`}
                alt="Image not found"
              />
            </div>
          </div>
        </div>
        <div className="VariableFields__buttons">
          <span>
            <Button color="warn" onClick={() => removeLayer(activeLayerIndex)} variant="raised">
              Delete layer
            </Button>
            <Button iconLeading={<PlusIcon />} onClick={() => addLayer('text')} variant="outlined">
              Text layer
            </Button>
            <Button iconLeading={<PlusIcon />} onClick={() => addLayer('image')} variant="outlined">
              Image layer
            </Button>
          </span>
          <span>
            <Button color="light" onClick={setAllToDefaults} variant="raised">
              Reset
            </Button>
            <Button color="primary" onClick={savedownHandler} variant="raised">
              Savedown
            </Button>
          </span>
        </div>
      </div>
    </Drawer>
  );
};
