import { useLazyQuery, useMutation } from '@apollo/client';
import { CheckIcon, ChevronRightIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Button, Checkbox, IconButton, Input, Modal, SearchPopover } from '~/src/components';
import {
  ACCESS_CODES_QUERY,
  ACCESS_CODE_UPDATE,
  FEATURED_CREATE,
  FEATURED_DELETE,
  FEATURED_PRODUCTS_QUERY,
  FEATURED_UPDATE,
} from '../../api';
import './AccessCodeModal.scss';

type AccessCodeModalProps = {
  isOpen: boolean;
  accountPk: number;
  onClose: () => void;
  refetch: () => void;
};

export const AccessCodeModal = (props: AccessCodeModalProps) => {
  const [adding, setAdding] = React.useState<'code' | 'product' | undefined>(undefined);
  const [codes, setCodes] = React.useState<any[]>([]);
  const [selectedCode, setSelectedCode] = React.useState(-1);

  const [loadAccessCodes, { data }] = useLazyQuery(ACCESS_CODES_QUERY, {
    fetchPolicy: 'network-only',
    variables: { accountPk: props.accountPk },
    onCompleted: (res) => setCodes(res.accessCodes.edges),
  });
  const reloadAccessCodes = () => {
    props.refetch();
    return loadAccessCodes();
  };
  const [accessCodeUpdate] = useMutation(ACCESS_CODE_UPDATE, { onCompleted: reloadAccessCodes });
  const [featuredCreate] = useMutation(FEATURED_CREATE, { onCompleted: reloadAccessCodes });
  const [featuredUpdate] = useMutation(FEATURED_UPDATE, { onCompleted: reloadAccessCodes });
  const [featuredDelete] = useMutation(FEATURED_DELETE, { onCompleted: reloadAccessCodes });

  React.useEffect(() => {
    if (!props.accountPk || !props.isOpen) return;
    loadAccessCodes({
      onCompleted: (res) => {
        setCodes(res.accessCodes.edges);
        setSelectedCode(res.accessCodes.edges[0]?.node.pk);
      },
    });
  }, [props.isOpen]);

  const refCallback = React.useCallback((node: any) => {
    if (node) node.scrollIntoView(false);
  }, []);

  function onCloseHandler() {
    setAdding(undefined);
    setCodes([]);
    setSelectedCode(-1);
    props.onClose();
  }

  function productRow(edge: any) {
    return (
      <div className="AccessCodeModal__products__row" key={edge.node?.pk}>
        <div className="AccessCodeModal__products__row__productId">
          <p>{edge.node?.product ? 'Product ID' : 'New Product ID'}</p>
          <div>
            <Input
              fluid
              readOnly
              value={edge?.node?.product?.name}
              placeholder="Product ID"
              onClick={() => {
                if (!edge.node?.product?.name) setAdding('product');
              }}
            />
            {!edge.node.product && (
              <SearchPopover
                isOpen={adding === 'product'}
                onChange={(newProduct) => {
                  const accessCode = codes.find((code) => code.node.pk === selectedCode)?.node.pk;
                  accessCode &&
                    featuredCreate({
                      variables: { featured: { accessCode, product: newProduct.pk, active: true } },
                    });
                }}
                onClose={() => setAdding(undefined)}
                query={FEATURED_PRODUCTS_QUERY}
                queryArgument="search"
                queryField="products"
                renderMatch={(match) => <>{match.name}</>}
              />
            )}
          </div>
        </div>
        {edge.node?.product && (
          <>
            <div className="AccessCodeModal__products__row__active">
              <p>Active</p>
              <Checkbox
                checked={edge.node?.active}
                onChange={() => {
                  if (edge.node?.pk)
                    featuredUpdate({
                      variables: {
                        pk: edge.node.pk,
                        featured: {
                          active: !edge.node?.active,
                        },
                      },
                    });
                }}
              />
            </div>
            <div className="AccessCodeModal__products__row__photos">
              <div className="AccessCodeModal__products__row__photos__photo">
                {edge.node?.product?.pk ? (
                  <img
                    src={`https://staging.everyname.com/ops-api/images/${edge.node?.product?.pk}/render/`}
                    style={{ height: '80px' }}
                  />
                ) : (
                  <img src="https://via.placeholder.com/150x76" />
                )}
              </div>
              <IconButton
                style={!edge.node?.pk ? { visibility: 'hidden' } : {}}
                onClick={() => {
                  featuredDelete({ variables: { pk: edge.node.pk } });
                }}
                testId="delete-featured-product"
              >
                <TrashIcon />
              </IconButton>
            </div>
          </>
        )}
      </div>
    );
  }

  if (!data) return null;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={onCloseHandler}
      style={{ width: '1270px', height: '516px', padding: '0', maxWidth: '1270px', overflow: 'visible' }}
    >
      <div className="AccessCodeModal">
        <div className="AccessCodeModal__header">
          <p>Featured Products</p>
        </div>
        <div className="AccessCodeModal__body">
          <div className="AccessCodeModal__codes">
            <div className="AccessCodeModal__codes__container">
              <span className="AccessCodeModal__codes__header">
                <p>Access Code</p>
                <p>Notes</p>
                <p>Products</p>
              </span>
              <>
                {[...(adding === 'code' ? [{ node: { pk: '' } }] : []), ...codes]?.map((edge: any, index: number) => (
                  <span
                    key={edge.node.pk}
                    className={`AccessCodeModal__codes__row${
                      selectedCode === edge.node.pk ? ' AccessCodeModal__codes__row--selected' : ''
                    }`}
                    ref={refCallback}
                    onClick={() => edge.node.pk && setSelectedCode(edge.node.pk)}
                  >
                    <div>
                      <Input
                        readOnly
                        value={edge.node.code}
                        placeholder="Access code"
                        onClick={() => {
                          if (!edge.node.code) setAdding('code');
                        }}
                      />
                      {!edge.node.pk && (
                        <SearchPopover
                          isOpen={adding === 'code'}
                          onChange={(newAccessCode) => {
                            accessCodeUpdate({
                              variables: { pk: newAccessCode.pk, updates: { account: props.accountPk } },
                              onCompleted: (res) => {
                                reloadAccessCodes().then(() => {
                                  setSelectedCode((res as any)?.accessCodeUpdate?.accessCode?.pk);
                                });
                              },
                            });
                          }}
                          onClose={() => setAdding(undefined)}
                          query={ACCESS_CODES_QUERY}
                          queryArgument="code_Icontains"
                          queryField="accessCodes"
                          queryVariables={{ account_Isnull: true }}
                          renderMatch={(match) => <>{match.code}</>}
                        />
                      )}
                    </div>
                    <Input
                      placeholder="Notes"
                      value={edge.node.notes || ''}
                      onChange={(e) => {
                        setCodes((prev) =>
                          prev.map((edge, i) =>
                            i === index ? { node: { ...edge.node, notes: e.target.value } } : edge
                          )
                        );
                      }}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        if (edge.node.notes !== data.accessCodes?.edges[index]?.node.notes) {
                          accessCodeUpdate({
                            variables: { pk: edge.node.pk, updates: { notes: edge.node.notes } },
                          });
                        }
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <Button iconTrailing={<ChevronRightIcon />} onClick={() => setSelectedCode(edge.node.pk)}>
                      {edge.node.featuredProducts?.edges?.length}
                    </Button>
                  </span>
                ))}
              </>
            </div>
            <div className="AccessCodeModal__codes__addCode">
              <Button
                iconLeading={<PlusIcon />}
                onClick={() => {
                  setAdding('code');
                }}
                variant="outlined"
              >
                Add Access Code
              </Button>
            </div>
          </div>
          <div className="AccessCodeModal__products">
            {selectedCode > -1 ? (
              <>
                <>{productRow({ node: {} })}</>
                {codes
                  .find((code) => code.node.pk === selectedCode)
                  ?.node.featuredProducts?.edges.map((edge: any) => (
                    <div key={edge.node.pk}>{productRow(edge)}</div>
                  ))}
              </>
            ) : (
              <div className="AccessCodeModal__products__empty">Select an access code to view featured products.</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
