import * as classNames from 'classnames';
import * as React from 'react';
import './Radio.scss';

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  testId?: string;
};

export const Radio = React.forwardRef(
  ({ className, label, testId, ...rest }: RadioProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const containerClass = classNames('Radio__container');
    const radioClass = classNames('Radio', className);

    return (
      <>
        <label className={containerClass} data-testid={testId && `${testId}-container`}>
          {label}
          <input className={radioClass} data-testid={testId} ref={ref} type="radio" {...rest} />
          <span className="Radio__mark"></span>
        </label>
      </>
    );
  }
);
