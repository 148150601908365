import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Select, SelectProps } from '../@shared/Select/Select';

export const ACTIVE_PAYMENT_REQS_QUERY = gql`
  query activePaymentReqs {
    paymentReqs(active: true) {
      edges {
        node {
          id
          pk
          type
          altName
          active
        }
      }
    }
  }
`;

type PaymentReqSelectProps = Omit<SelectProps, 'options'> & {};

export const PaymentReqSelect = (props: PaymentReqSelectProps) => {
  const { data, loading } = useQuery(ACTIVE_PAYMENT_REQS_QUERY);

  if (loading || !data) return <div>Loading...</div>;

  const options = [
    ...data.paymentReqs.edges.map((edge: any) => ({
      value: edge.node.pk,
      label: edge.node.type,
    })),
  ];

  return <Select options={options} {...props} />;
};
