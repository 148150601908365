import * as React from 'react';
import { Modal, Input, Button, Select } from '~/src/components';
import { STATES_LIST } from '~/src/utils/statesList';

type CustomerInfo = {
  email?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
};

type CustomerInfoModalProps = {
  isOpen: boolean;
  customerData: any;
  onSave: (customerInfo: CustomerInfo) => void;
  onClose: () => void;
};

export const CustomerInfoModal = (props: CustomerInfoModalProps) => {
  const [customerData, setCustomerData] = React.useState<CustomerInfo>({});

  React.useEffect(() => {
    if (!props.customerData || !props.isOpen) return;
    setCustomerData(props.customerData);
  }, [props.isOpen]);

  function onSaveHandler() {
    props.onSave(customerData);
    onCloseHandler();
  }

  function onCloseHandler() {
    setCustomerData({});
    props.onClose();
  }

  return (
    <div className="EverynameDetails__modal">
      <Modal isOpen={props.isOpen} onClose={onCloseHandler}>
        <div className="EverynameDetails__modal__header">
          <p>Edit Customer Info</p>
        </div>
        <div className="EverynameDetails__modal__content">
          <span className="EverynameDetails__modal__content__row">
            <p>First Name</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, firstName: e.target.value }))}
                value={customerData.firstName || ''}
              />
            </div>
          </span>
          <span className="EverynameDetails__modal__content__row">
            <p>Last Name</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, lastName: e.target.value }))}
                value={customerData.lastName || ''}
              />
            </div>
          </span>
          <span className="EverynameDetails__modal__content__row">
            <p>Email</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, email: e.target.value }))}
                value={customerData.email || ''}
              />
            </div>
          </span>
          <div className="EverynameDetails__modal__content__divider"></div>
          <span className="EverynameDetails__modal__content__row">
            <p>Country / Region</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, country: e.target.value }))}
                value={customerData.country || ''}
              />
            </div>
          </span>
          <span className="EverynameDetails__modal__content__row">
            <p>Address</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, address1: e.target.value }))}
                value={customerData.address1 || ''}
              />
            </div>
          </span>
          <span className="EverynameDetails__modal__content__row">
            <p>City</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, city: e.target.value }))}
                value={customerData.city || ''}
              />
            </div>
          </span>
          <span className="EverynameDetails__modal__content__row">
            <p>State / Postal</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              {/* <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, state: e.target.value }))}
                value={customerData.state}
              /> */}
              <Select
                onChange={(e) => setCustomerData((prev) => ({ ...prev, state: e.target.value }))}
                options={[{ value: '', label: '' }, ...STATES_LIST.map((state) => ({ value: state, label: state }))]}
                value={customerData.state || ''}
              />
              <Input
                onChange={(e) => setCustomerData((prev) => ({ ...prev, zipcode: e.target.value }))}
                value={customerData.zipcode || ''}
              />
            </div>
          </span>
        </div>
        <div className="EverynameDetails__modal__buttons">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Close
          </Button>
          <Button color="primary" onClick={onSaveHandler} variant="raised">
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};
