import { XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { IconButton } from '~/src/components';
import { renderURL } from '~/src/utils/render';
import { ProductInfoModal } from '~/src/features/products/components';
import { formatPriceString } from '../../routes';
import './OrderTable.scss';

type OrderTableProps = {
  orderEdges: any[];
  onDeleteClick?: (id: string) => void;
};

const productDefault = {
  pk: '',
  name: '',
  description: '',
  minQty: '',
  price: '',
  id: '',
}

export const OrderTable = (props: OrderTableProps) => {
  const [productInfo, setProductInfo] = React.useState<{ isOpen: boolean; product: any }>({
    isOpen: false,
    product: productDefault,
  });

  return (
    <div className="EverynameDetails__tabs__table">
      <table className="data-table">
        <colgroup>
          <col style={{ width: '15%' }}></col>
          <col style={{ width: '25%' }}></col>
          <col style={{ width: '10%' }}></col>
          <col style={{ width: '10%' }}></col>
          <col style={{ width: '10%' }}></col>
          <col style={{ width: '25%' }}></col>
          {props.onDeleteClick && <col style={{ width: '5%' }}></col>}
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>product #</th>
            <th>quantity</th>
            <th>unit cost</th>
            <th>unit markup</th>
            <th>variable info</th>
            {props.onDeleteClick && <th></th>}
          </tr>
        </thead>
        <tbody>
          {props.orderEdges?.map((edge, index) => (
            <tr key={index}>
              <td>
                <img src={renderURL(edge.node.product?.pk)} />
              </td>
              <td>
                <a onClick={() => setProductInfo({isOpen: true, product: edge.node.product})}>{edge.node.product?.name}</a>
              </td>
              <td>{edge.node.quantity}</td>
              <td>{formatPriceString(edge.node.unitPrice)}</td>
              <td>{formatPriceString(edge.node.storeMarkup)}</td>
              <td>{edge.node.variation?.longName || 'None'}</td>
              {props.onDeleteClick && (
                <td>
                  <IconButton onClick={() => props.onDeleteClick?.(edge.node.pk)}>
                    <XMarkIcon />
                  </IconButton>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <ProductInfoModal
        isOpen={productInfo.isOpen}
        onClose={() => setProductInfo({ product: productDefault, isOpen: false })}
        product={productInfo.product}
      />
    </div>
  );
};
