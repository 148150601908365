import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useToast } from '~/src/features/toast';
import { DataTable, Spinner, Tabs, TabItem, Button, IconButton, Pagination, DebouncedInput } from '~/src/components';
import {
  DISPLAY_INSTANCE_QUERY,
  DISPLAY_INSTANCES_QUERY,
  DISPLAY_PANEL_CLONE_MUTATION,
  DISPLAY_PANEL_CREATE_MUTATION,
  DISPLAY_PANEL_DELETE_MUTATION,
  DISPLAY_DETAIL_UPDATE_MUTATION,
  DISPLAY_DETAIL_FINALIZE_MUTATION,
  DISPLAY_DETAIL_DELETE_MUTATION,
  DISPLAY_DETAIL_CREATE_MUTATION,
} from '../../api';
import { CreateInstanceModal, ConfirmationModal, DisplayPanel, EditInstanceModal } from '../../components';
import './DisplayInstances.scss';

const PAGE_SIZE = 20;

export const DisplayInstances = () => {
  const { error } = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = React.useState('');
  const [instanceModalOpen, setInstanceModalOpen] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });

  const [loadInstances, { data, loading }] = useLazyQuery(DISPLAY_INSTANCES_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      search: searchParams.get('search'),
    },
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadInstances({ fetchPolicy: 'network-only' }),
    onError: (err: any) => error(err.message),
  };
  const [createInstance] = useMutation(DISPLAY_DETAIL_CREATE_MUTATION, options);
  const [deleteInstance] = useMutation(DISPLAY_DETAIL_DELETE_MUTATION, options);

  React.useEffect(() => {
    searchParams.get('search') && setSearch(searchParams.get('search') || '');
    loadInstances();
  }, [searchParams]);

  if (!data || loading) {
    return (
      <div className="Displays__listPage">
        <Spinner message="Loading instances...." />
      </div>
    );
  }

  return (
    <div className="Displays__listPage">
      <div className="Displays__listPage__header">
        <h4 className="Displays__listPage__header__title">Display Instances</h4>
        <Button variant="raised" color="primary" onClick={() => setInstanceModalOpen(true)} iconLeading={<PlusIcon />}>
          Add Instance
        </Button>
      </div>
      <DebouncedInput
        fluid
        placeholder="Search for an instance by account..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onDebounce={(val) => {
          searchParams.delete('page');
          if (val) {
            searchParams.set('search', val);
          } else {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        className="mb-4"
      />
      <div className="Displays__listPage__list">
        <DataTable
          headers={[
            { label: 'id', size: 'md' },
            { label: 'account' },
            { label: 'template' },
            { label: 'state' },
            { label: '' },
          ]}
        >
          {data.displayDetails.edges.map((edge: any) => (
            <tr key={edge.node.pk}>
              <td>
                <Link to={`/displays/instances/${edge.node.pk}`}>{edge.node.pk}</Link>
              </td>
              <td>
                {edge.node.account && <Link to={`/accounts/${edge.node.account.pk}`}>{edge.node.account.name}</Link>}
              </td>
              <td>
                {edge.node.template && (
                  <Link to={`/displays/templates/${edge.node.template.pk}`}>{edge.node.template.name}</Link>
                )}
              </td>
              <td>
                <p>{edge.node.state}</p>
              </td>
              {/* <td>{edge.node.panelData}</td> */}
              <td>
                <IconButton
                  color="warn"
                  onClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      title: 'Delete instance',
                      message: 'Are you sure you want to delete this instance?',
                      onSubmit: () => deleteInstance({ variables: { pk: edge.node.pk } }),
                    });
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </DataTable>
      </div>
      <Pagination
        page={parseInt(searchParams.get('page') || '1')}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        }}
        hasNextPage={data.displayDetails.pageInfo.hasNextPage}
        totalNodes={data.displayDetails.totalNodes}
        totalNodesOnPage={data.displayDetails.totalNodesOnPage}
      />
      <CreateInstanceModal
        isOpen={instanceModalOpen}
        onClose={() => setInstanceModalOpen(false)}
        onSubmit={(data) => {
          createInstance({
            variables: { input: { template: data.model.pk, account: data.account?.pk } },
            onCompleted: (res) => navigate(`/displays/instances/${res?.displayDetailCreate?.displayDetail?.pk}`),
          });
        }}
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
        onSubmit={confirmationModal.onSubmit}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
    </div>
  );
};

export const DisplayInstanceDetails = () => {
  const [activePanelIndex, setActivePanelIndex] = React.useState<number>(0);
  const [activePanel, setActivePanel] = React.useState<any>();
  const [editInstanceOpen, setEditInstanceOpen] = React.useState(false);

  const { instanceId } = useParams();
  const { error } = useToast();

  const [loadDisplayInstance, { data, loading }] = useLazyQuery(DISPLAY_INSTANCE_QUERY, {
    variables: { pk: instanceId ? parseInt(instanceId) : undefined },
    onCompleted: (res) => setActivePanel(res.displayDetail?.panels.edges[activePanelIndex]?.node || undefined),
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadDisplayInstance(),
    onError: (err: any) => error(err.message),
  };
  const [updateInstance] = useMutation(DISPLAY_DETAIL_UPDATE_MUTATION, options);
  const [createPanel] = useMutation(DISPLAY_PANEL_CREATE_MUTATION, options);
  const [clonePanel] = useMutation(DISPLAY_PANEL_CLONE_MUTATION, options);
  const [deletePanel] = useMutation(DISPLAY_PANEL_DELETE_MUTATION, options);

  //todo: request new mutation
  const [finalizeInstance] = useMutation(DISPLAY_DETAIL_FINALIZE_MUTATION, options);

  React.useEffect(() => {
    if (!instanceId) return;
    loadDisplayInstance();
  }, [instanceId]);

  React.useEffect(() => {
    setActivePanel(data?.displayDetail?.panels.edges[activePanelIndex]?.node || undefined);
  }, [activePanelIndex]);

  if (!data || loading) {
    return (
      <div className="DisplayInstance">
        <Spinner message="Loading instance...." />
      </div>
    );
  }

  return (
    <div className="DisplayInstance">
      <div className="DisplayInstance__header">
        <h2 className="DisplayInstance__header__title">{data.displayDetail?.account?.name}</h2>
        <div className="DisplayInstance__header__buttons">
          <Button variant="raised" onClick={() => setEditInstanceOpen(true)}>
            Edit
          </Button>
          <Button
            color="primary"
            variant="raised"
            onClick={() => finalizeInstance({ variables: { pk: instanceId ? parseInt(instanceId) : undefined } })}
          >
            Mark instance as final
          </Button>
        </div>
      </div>
      <span className="DisplayInstance__tabs__container">
        <Tabs className="DisplayInstance__tabs__tabs">
          {data.displayDetail?.panels.edges
            .map((edge: any) => edge.node)
            .map((panel: any, index: number) => (
              <TabItem
                key={index}
                active={activePanelIndex === index}
                onClick={() => setActivePanelIndex(index)}
                className="DisplayInstance__tabItem"
              >
                Panel {index + 1}
              </TabItem>
            ))}
        </Tabs>
        <IconButton
          color="primary"
          onClick={() => createPanel({ variables: { displayPk: instanceId ? parseInt(instanceId) : undefined } })}
        >
          <PlusIcon />
        </IconButton>
      </span>
      {activePanel ? (
        <DisplayPanel
          displayId={instanceId ? parseInt(instanceId) : undefined}
          panel={activePanel}
          queryOptions={options}
          onPanelClone={() =>
            clonePanel({
              variables: { displayPk: instanceId ? parseInt(instanceId) : undefined, panel: activePanel.order },
            })
          }
          onPanelDelete={() => {
            deletePanel({
              variables: { displayPk: instanceId ? parseInt(instanceId) : undefined, panel: activePanel.order },
            }).then(() => {
              setActivePanelIndex(0);
            });
          }}
        />
      ) : (
        <p style={{ fontStyle: 'italic' }}>Click the 'plus' to add a panel</p>
      )}
      <EditInstanceModal
        instance={data.displayDetail}
        isOpen={editInstanceOpen}
        onClose={() => setEditInstanceOpen(false)}
        onSave={({ account, template }) =>
          updateInstance({
            variables: {
              pk: instanceId ? parseInt(instanceId) : undefined,
              input: { account: account.pk, template: template.pk },
            },
          })
        }
      />
    </div>
  );
};
