import * as React from 'react';
import { Button, Input, Modal } from '~/src/components';

type ShippingModalProps = {
  isOpen: boolean;
  shippingInfo: string;
  onClose: () => void;
  onSave: (newInfo: string) => void;
};

export const ShippingModal = (props: ShippingModalProps) => {
  const [shippingInfo, setShippingInfo] = React.useState('');

  React.useEffect(() => {
    if (!props.isOpen || !props.shippingInfo) return;
    setShippingInfo(props.shippingInfo);
  }, [props.isOpen]);

  function onSubmitHandler() {
    props.onSave(shippingInfo);
    onCloseHandler();
  }

  function onCloseHandler() {
    setShippingInfo('');
    props.onClose();
  }

  return (
    <div className="EverynameDetails__modal EverynameDetails__shippingModal">
      <Modal isOpen={props.isOpen} onClose={onCloseHandler}>
        <div className="EverynameDetails__modal__header EverynameDetails__shippingModal__header">
          <p>Shipping Information</p>
        </div>
        <div className="EverynameDetails__shippingModal__content">
          <span className="EverynameDetails__modal__content__row">
            <p>Tracking #</p>
            <div className="EverynameDetails__modal__content__row__inputs">
              <Input
                placeholder="9400-1000-0000-0000-0000-00"
                value={shippingInfo}
                onChange={(e) => setShippingInfo(e.target.value)}
              />
            </div>
          </span>
        </div>
        <div className="EverynameDetails__modal__buttons">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="primary" onClick={onSubmitHandler} variant="raised">
            Save & Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};
