import * as React from 'react';
import { Button, Checkbox, DatePicker, Input, Modal, SearchPopover, Textarea } from '~/src/components';
import { ARTISTS_QUERY } from '../../api';
import './ArtNotesModal.scss';

type ArtNotesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  onSave: (updates: any) => void;
};

type ProductType = {
  artist: any;
  instructions: string;
  revisionNotes: string;
  enterDate: Date | undefined;
  readyForProduction: Date | undefined;
  readyForReview: Date | undefined;
  needsRevisionDate: Date | undefined;
  approvalDate: Date | undefined;
};

const productDefaults: ProductType = {
  artist: {},
  instructions: '',
  revisionNotes: '',
  enterDate: undefined,
  readyForProduction: undefined,
  readyForReview: undefined,
  needsRevisionDate: undefined,
  approvalDate: undefined,
};

export const ArtNotesModal = (props: ArtNotesModalProps) => {
  const [product, setProduct] = React.useState<ProductType>(productDefaults);
  const [artistPopover, setArtistPopover] = React.useState(false);
  const [instructionsTextbox, setInstructionsTextbox] = React.useState(false);
  const [revisionNotesTextbox, setRevisionNotesTextbox] = React.useState(false);

  React.useEffect(() => {
    if (!props.product) return;
    setProduct({
      enterDate: props.product.enterDate ? new Date(props.product.enterDate) : undefined,
      artist: props.product.artist,
      readyForProduction: props.product.readyForProduction ? new Date(props.product.readyForProduction) : undefined,
      readyForReview: props.product.readyForReview ? new Date(props.product.readyForReview) : undefined,
      needsRevisionDate: props.product.needsRevisionDate ? new Date(props.product.needsRevisionDate) : undefined,
      approvalDate: props.product.approvalDate ? new Date(props.product.approvalDate) : undefined,
      instructions: props.product.instructions,
      revisionNotes: props.product.revisionNotes,
    });
    setInstructionsTextbox(!!props.product.instructions);
    setRevisionNotesTextbox(!!props.product.revisionNotes);
  }, [props.isOpen]);

  function dateStringer(date: Date | undefined) {
    if (!date) return null;
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:00:00+00:00`;
  }

  function onSubmitHandler() {
    props.onSave({
      ...product,
      readyForProduction: dateStringer(product.readyForProduction) || undefined, // nulling out this date nulls the product
      readyForReview: dateStringer(product.readyForReview),
      needsRevisionDate: dateStringer(product.needsRevisionDate),
      approvalDate: dateStringer(product.approvalDate),
      instructions: instructionsTextbox ? product.instructions : '',
      revisionNotes: revisionNotesTextbox ? product.revisionNotes : '',
      enterDate: dateStringer(product.enterDate),
      artist: product.artist?.pk,
    });
    onCloseHandler();
  }

  function onCloseHandler() {
    setProduct(productDefaults);
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ width: '820px', padding: '0', overflow: 'visible' }}>
      <div className="ProductDetails__artNotesModal">
        <div className="ProductDetails__artNotesModal__header">
          <p>Art notes</p>
        </div>
        <div className="ProductDetails__artNotesModal__body">
          <p>Art notes</p>
          <div className="ProductDetails__artNotesModal__body__datesContainer">
            <div className="ProductDetails__artNotesModal__body__row">
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Enter Date</p>
                <DatePicker
                  value={product.enterDate}
                  onChange={(date) => setProduct((prev) => ({ ...prev, enterDate: date }))}
                />
              </div>
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Artist</p>
                <div className="relative">
                  <Input
                    readOnly
                    value={product.artist ? `${product.artist?.user?.firstName} ${product.artist?.user?.lastName}` : ''}
                    onClick={() => setArtistPopover(true)}
                  />
                  <SearchPopover
                    isOpen={artistPopover}
                    onChange={(artist) => setProduct((prev) => ({ ...prev, artist }))}
                    onClose={() => setArtistPopover(false)}
                    popoverStyle={{ top: '39px' }}
                    query={ARTISTS_QUERY}
                    queryArgument="search"
                    queryField="employees"
                    renderMatch={(match) => (
                      <>
                        {match.user.firstName} {match.user.lastName}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Production ready date</p>
                <DatePicker
                  value={product.readyForProduction}
                  onChange={(date) => setProduct((prev) => ({ ...prev, readyForProduction: date }))}
                />
              </div>
            </div>
            <div className="ProductDetails__artNotesModal__body__row">
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Ready for review</p>
                <DatePicker
                  value={product.readyForReview}
                  onChange={(date) => setProduct((prev) => ({ ...prev, readyForReview: date }))}
                />
              </div>
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Ready for revisions</p>
                <DatePicker
                  value={product.needsRevisionDate}
                  onChange={(date) => setProduct((prev) => ({ ...prev, needsRevisionDate: date }))}
                />
              </div>
              <div className="ProductDetails__artNotesModal__body__inputField">
                <p>Approval date</p>
                <DatePicker
                  value={product.approvalDate}
                  onChange={(date) => setProduct((prev) => ({ ...prev, approvalDate: date }))}
                />
              </div>
            </div>
          </div>
          <div className="ProductDetails__artNotesModal__body__artistNotes">
            <div className="ProductDetails__artNotesModal__body__textAreaNotes">
              <Checkbox
                checked={instructionsTextbox}
                label="Instructions:"
                onChange={() => setInstructionsTextbox((prev) => !prev)}
              />
              <Textarea
                disabled={!instructionsTextbox}
                fluid
                onChange={(e) => setProduct((prev) => ({ ...prev, instructions: e.target.value }))}
                value={(instructionsTextbox && product.instructions) || ''}
              />
            </div>
            <div className="ProductDetails__artNotesModal__body__textAreaNotes">
              <Checkbox
                checked={revisionNotesTextbox}
                label="Revision notes:"
                onChange={() => setRevisionNotesTextbox((prev) => !prev)}
              />
              <Textarea
                disabled={!revisionNotesTextbox}
                fluid
                onChange={(e) => setProduct((prev) => ({ ...prev, revisionNotes: e.target.value }))}
                value={(revisionNotesTextbox && product.revisionNotes) || ''}
              />
            </div>
          </div>
        </div>
        <div className="ProductDetails__artNotesModal__buttons">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="primary" onClick={onSubmitHandler} variant="raised">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
