import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Modal } from '~/src/components';
import './RemoveTagModal.scss';

type RemoveTagModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tag: any;
  onSave: (tag: any) => void;
};

export const RemoveTagModal = (props: RemoveTagModalProps) => {
  function onSubmitHandler() {
    props.onSave(props.tag);
    onCloseHandler();
  }

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ padding: '0', width: '700px' }}>
      <div className="ProductDetails__removeTagModal">
        <div className="ProductDetails__removeTagModal__header">
          <p>Remove product tag</p>
        </div>
        <div className="ProductDetails__removeTagModal__body">
          <div className="ProductDetails__warningBanner">
            <span>
              <ExclamationTriangleIcon />
              <p>Warning</p>
            </span>
            <p>
              Are you sure you want to remove the product tag{' '}
              <span className="font-medium">{`${props.tag.name} | ${props.tag.parent?.name}`}</span>?
            </p>
          </div>
        </div>
        <div className="ProductDetails__removeTagModal__buttons">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="warn" className="ProductDetails__warningButton" onClick={onSubmitHandler} variant="raised">
            Remove Tag
          </Button>
        </div>
      </div>
    </Modal>
  );
};
