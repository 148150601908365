import * as React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from '~/src/components';
import { DisplayHistoryDrawer, EditDisplayModal } from '~/src/features/displays';
import './RepListTab.scss';

type RepListTabProps = { data: any; onEditModalSave: (data: any) => void };

export const RepListTab = (props: RepListTabProps) => {
  const [rep, setRep] = React.useState<any>({});
  const [editDisplayModal, setEditDisplayModal] = React.useState<{
    open: boolean;
    accountId: string;
    displayPk: string;
  }>({
    open: false,
    accountId: '',
    displayPk: '',
  });
  const [displayDrawer, setDisplayDrawer] = React.useState<{ open: boolean; id: string }>({
    open: false,
    id: '',
  });

  React.useEffect(() => {
    if (!props.data?.rep) return;
    setRep(props.data.rep);
  }, [props.data.rep]);

  function dateStringer(string: string | null) {
    if (!string) return '';
    let date = new Date(string);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  return (
    <div className="RepDetails__list">
      {rep?.account?.edges.length ? (
        rep?.account?.edges.map((account: any, index: number) => {
          if (!account.node.displaydetailsSet.edges.length) return null;
          return (
            <div className="RepDetails__list__table" key={index}>
              <div className="RepDetails__list__table__header">
                <p>{account.node.name}</p>
                <p>{`${account.node.city}, ${account.node.state}`}</p>
                <p>
                  Account # <Link to={`/accounts/${account.node.pk}`}>{account.node.pk}</Link>
                </p>
              </div>
              <table className="data-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>id</th>
                    <th>type</th>
                    <th>list template</th>
                    <th>product #</th>
                    <th>label</th>
                    <th>note</th>
                    <th>start date</th>
                    <th>end date</th>
                    <th>position data</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {account.node.displaydetailsSet.edges.map((display: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a
                            onClick={() =>
                              setEditDisplayModal({
                                open: true,
                                accountId: account.node.pk,
                                displayPk: display.node.pk,
                              })
                            }
                          >
                            Edit
                          </a>
                        </td>
                        <td>
                          <a onClick={() => setDisplayDrawer({ open: true, id: display.node.id })}>{display.node.pk}</a>
                        </td>
                        <td>{display.node.displayProduct?.name}</td>
                        <td>{display.node.namelist.name}</td>
                        <td>
                          {display.node.product.name}
                          {display.node.productSecondary ? `, ${display.node.productSecondary.name}` : ''}
                        </td>
                        <td></td>
                        <td></td>
                        <td>{dateStringer(display.node.startDate)}</td>
                        <td>
                          {display.node.endDate && <Badge color="warning" label={dateStringer(display.node.endDate)} />}
                        </td>
                        <td>
                          <Badge color="danger" label="None" />
                        </td>
                        <td>
                          <Button onClick={() => {}} size="sm" variant="outlined">
                            View Form
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })
      ) : (
        <div className='flex justify-center py-8' style={{ fontStyle: 'italic' }}>No accounts found</div>
      )}
      <EditDisplayModal
        isOpen={editDisplayModal.open}
        onClose={() => setEditDisplayModal({ open: false, displayPk: '', accountId: '' })}
        onSave={props.onEditModalSave}
        displayPk={editDisplayModal.displayPk}
        accountId={editDisplayModal.accountId}
      />
      <DisplayHistoryDrawer
        isOpen={displayDrawer.open}
        onClose={() => setDisplayDrawer({ open: false, id: '' })}
        displayId={displayDrawer.id}
      />
    </div>
  );
};
