// Use this function to fix date being off by one

// example:

// const date = new Date('2023-01-04')
// console.log(format(date, 'MM-dd-yyyy'));
// output: 01-03-2023

// console.log(format(tzOffset(date), 'MM-dd-yyyy'));
// output: 01-04-2023

export function tzOffset(date: Date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}
