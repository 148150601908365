import * as classNames from 'classnames';
import * as React from 'react';
import './Card.scss';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  fluid?: boolean;
  testId?: string;
};

export const Card = ({ className, fluid, testId, ...rest }: CardProps) => {
  const cardClass = classNames(
    'Card',
    {
      'Card--fluid': fluid,
    },
    className
  );

  return <div className={cardClass} data-testid={testId} {...rest} />;
};
