import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useToast } from '~/src/features/toast';
import { DataTable, Button, Spinner, Tabs, TabItem, IconButton, Pagination, DebouncedInput } from '~/src/components';
import {
  DISPLAY_TEMPLATE_QUERY,
  DISPLAY_TEMPLATES_QUERY,
  DISPLAY_TEMPLATE_CREATE_MUTATION,
  DISPLAY_TEMPLATE_UPDATE_MUTATION,
  DISPLAY_TEMPLATE_DELETE_MUTATION,
  DISPLAY_PANEL_CLONE_MUTATION,
  DISPLAY_PANEL_CREATE_MUTATION,
  DISPLAY_PANEL_DELETE_MUTATION,
} from '../../api';
import { CreateTemplateModal, ConfirmationModal, DisplayPanel, EditTemplateModal } from '../../components';
import './DisplayTemplates.scss';

const PAGE_SIZE = 20;

export const DisplayTemplates = () => {
  const { error } = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [createTemplateModalOpen, setCreateTemplateModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });

  const [loadTemplates, { data, loading }] = useLazyQuery(DISPLAY_TEMPLATES_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      search: searchParams.get('search'),
    },
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadTemplates({ fetchPolicy: 'network-only' }),
    onError: (err: any) => error(err.message),
  };
  const [createTemplate] = useMutation(DISPLAY_TEMPLATE_CREATE_MUTATION, options);
  const [deleteTemplate] = useMutation(DISPLAY_TEMPLATE_DELETE_MUTATION, options);

  React.useEffect(() => {
    searchParams.get('search') && setSearch(searchParams.get('search') || '');
    loadTemplates();
  }, [searchParams]);

  if (!data || loading) {
    return (
      <div className="Displays__listPage">
        <Spinner message="Loading templates..." />
      </div>
    );
  }

  return (
    <div className="Displays__listPage">
      <div className="Displays__listPage__header">
        <h4 className="Displays__listPage__header__title">Display Templates</h4>
        <Button
          variant="raised"
          color="primary"
          onClick={() => setCreateTemplateModalOpen(true)}
          iconLeading={<PlusIcon />}
        >
          Add Template
        </Button>
      </div>
      <DebouncedInput
        fluid
        placeholder="Search for an instance..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onDebounce={(val) => {
          searchParams.delete('page');
          if (val) {
            searchParams.set('search', val);
          } else {
            searchParams.delete('search');
          }
          setSearchParams(searchParams);
        }}
        className="mb-4"
      />
      <div className="Displays__listPage__list">
        <DataTable headers={[{ label: 'name' }, { label: 'panels' }, { label: 'panel data' }, { label: '' }]}>
          {data?.displayTemplates.edges.map((edge: any, index: number) => (
            <tr key={index}>
              <td>
                <Link to={`/displays/templates/${edge.node.pk}`}>{edge.node.name}</Link>
              </td>
              <td>{JSON.parse(edge.node.panelData || '{}').panels?.length}</td>
              {/* <td>{edge.node.panelData}</td> */}
              <td></td>
              <td>
                <IconButton
                  color="warn"
                  onClick={() => {
                    setConfirmationModal({
                      isOpen: true,
                      title: 'Delete template',
                      message: `Are you sure you want to delete "${edge.node.name}"?`,
                      onSubmit: () => deleteTemplate({ variables: { pk: edge.node.pk } }),
                    });
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </DataTable>
      </div>
      <Pagination
        page={parseInt(searchParams.get('page') || '1')}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => {
          searchParams.set('page', page.toString());
          setSearchParams(searchParams);
        }}
        hasNextPage={data.displayTemplates.pageInfo.hasNextPage}
        totalNodes={data.displayTemplates.totalNodes}
        totalNodesOnPage={data.displayTemplates.totalNodesOnPage}
      />
      <CreateTemplateModal
        isOpen={createTemplateModalOpen}
        onClose={() => setCreateTemplateModalOpen(false)}
        onSubmit={(name) =>
          createTemplate({
            variables: { input: { name } },
            onCompleted: (res) => navigate(`/displays/templates/${res?.displayTemplateCreate?.template?.pk}`),
          })
        }
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
        onSubmit={confirmationModal.onSubmit}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
    </div>
  );
};

export const DisplayTemplateDetails = () => {
  const [activePanelIndex, setActivePanelIndex] = React.useState<number>(0);
  const [activePanel, setActivePanel] = React.useState<any>();
  const [editTemplateModalOpen, setEditTemplateModalOpen] = React.useState(false);

  const { templateId } = useParams();
  const { error } = useToast();

  const [loadTemplate, { data, loading }] = useLazyQuery(DISPLAY_TEMPLATE_QUERY, {
    variables: { pk: templateId },
    onCompleted: (res) => setActivePanel(res.displayTemplate?.panels.edges[activePanelIndex]?.node || undefined),
    fetchPolicy: 'network-only',
  });
  const options = {
    onCompleted: () => loadTemplate(),
    onError: (err: any) => error(err.message),
  };
  const [editTemplate] = useMutation(DISPLAY_TEMPLATE_UPDATE_MUTATION, options);
  const [createPanel] = useMutation(DISPLAY_PANEL_CREATE_MUTATION, options);
  const [clonePanel] = useMutation(DISPLAY_PANEL_CLONE_MUTATION, options);
  const [deletePanel] = useMutation(DISPLAY_PANEL_DELETE_MUTATION, options);

  React.useEffect(() => {
    if (!templateId) return;
    loadTemplate();
  }, [templateId]);

  React.useEffect(() => {
    setActivePanel(data?.displayTemplate?.panels.edges[activePanelIndex]?.node || undefined);
  }, [activePanelIndex]);

  if (!data || loading) {
    return (
      <div className="DisplayTemplate">
        <Spinner message="Loading template...." />
      </div>
    );
  }

  return (
    <div className="DisplayTemplate">
      <div className="DisplayTemplate__header">
        <h2 className="DisplayTemplate__header__title">{data.displayTemplate?.name}</h2>
        <Button variant="raised" onClick={() => setEditTemplateModalOpen(true)}>
          Edit Template
        </Button>
      </div>
      <span className="DisplayTemplate__tabs__container">
        <Tabs className="DisplayTemplate__tabs__tabs">
          {data.displayTemplate?.panels.edges
            .map((edge: any) => edge.node)
            .map((panel: any, index: number) => (
              <TabItem
                key={index}
                active={activePanelIndex === index}
                onClick={() => setActivePanelIndex(index)}
                className="DisplayTemplate__tabItem"
              >
                Panel {index + 1}
              </TabItem>
            ))}
        </Tabs>
        <IconButton color="primary" onClick={() => createPanel({ variables: { templatePk: templateId } })}>
          <PlusIcon />
        </IconButton>
      </span>
      <>
        {activePanel ? (
          <DisplayPanel
            isTemplate
            displayId={templateId}
            panel={activePanel}
            queryOptions={options}
            onPanelClone={() =>
              clonePanel({
                variables: { templatePk: templateId, panel: activePanel.order },
              })
            }
            onPanelDelete={() => {
              deletePanel({
                variables: { templatePk: templateId, panel: activePanel.order },
              }).then(() => {
                setActivePanelIndex(0);
              });
            }}
          />
        ) : (
          <p style={{ fontStyle: 'italic' }}>Click the 'plus' to add a panel</p>
        )}
      </>
      <EditTemplateModal
        isOpen={editTemplateModalOpen}
        onClose={() => setEditTemplateModalOpen(false)}
        onSave={({ name }) => editTemplate({ variables: { pk: templateId, input: { name } } })}
        template={data.displayTemplate}
      />
    </div>
  );
};
