import { XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { IconButton } from '~/src/components';
import { useToast } from '../providers';
import './Toaster.scss';

export const Toaster = () => {
  const { toasts, clickToast } = useToast();

  return (
    <div className="Toaster">
      {toasts.map((toast, index) => {
        let toastClass = `Toast Toast--${toast.type}`;
        return (
          <div
            className={toastClass}
            key={toast.id}
            onClick={() => {
              clickToast(index);
            }}
          >
            {toast.message}
            <IconButton>
              <XMarkIcon />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};
