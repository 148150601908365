import { gql } from '@apollo/client';

export const REP_SALES_BY_DATE_QUERY = gql`
  query repSalesByDate($start: Date, $end: Date, $first: Int, $offset: Int) {
    reportRepSalesByDate(start: $start, end: $end, first: $first, offset: $offset) {
      edges {
        node {
          name
          type
          company
          area
          orders
          averageSale
          totalSales
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const REP_DISPLAYS_WITHOUT_REORDER_QUERY = gql`
  query RepDisplaysWithoutReorders($start: Date, $end: Date, $first: Int, $offset: Int) {
    reportRepsDisplaysWithoutReorders(start: $start, end: $end, first: $first, offset: $offset) {
      edges {
        node {
          displayId
          accountId
          accountName
          displayType
          listTemplate
          programType
          label
          featuredProd
          startDate
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const PRODUCTS_SOLD_BY_DATE_QUERY = gql`
  query productsSoldByDate($start: Date, $end: Date, $dateType: DateType, $first: Int, $offset: Int) {
    reportProductsSoldByDate(start: $start, end: $end, dateType: $dateType, first: $first, offset: $offset) {
      edges {
        node {
          product
          description
          group
          quantity
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const PRODUCT_GROUPS_SOLD_BY_DATE_QUERY = gql`
  query productGroupsByDate($start: Date, $end: Date, $dateType: DateType, $first: Int, $offset: Int) {
    reportProductGroupsByDate(start: $start, end: $end, dateType: $dateType, first: $first, offset: $offset) {
      edges {
        node {
          group
          totalQuantity
          totalPrice
          averagePrice
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const PRODUCTION_SUMMARY_BY_PRODUCT_GROUPS_QUERY = gql`
  query productionSummaryByProductGroups($search: String, $first: Int, $offset: Int) {
    reportProductionSummaryByProductGroups(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          group
          orders
          pieces
          average
          oldest
          subtotal
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const COMPLETED_ORDERS_NOT_SHIPPED_QUERY = gql`
  query completedOrdersNotShipped($first: Int, $offset: Int, $search: String, $sort: String) {
    orders(
      first: $first
      offset: $offset
      search: $search
      sort: $sort
      completionDate_Isnull: false
      shipDate_Isnull: true
    ) {
      edges {
        node {
          id
          pk
          invoiceTotal
          completionDate
          holdDate
          sendByDate
          arriveByDate
          paymentReq {
            id
            pk
            type
          }
          account {
            id
            pk
            name
          }
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const APPROVED_ORDERS_NOT_RELEASED_QUERY = gql`
  query approvedOrdersNotReleased($first: Int, $offset: Int, $search: String, $sort: String) {
    orders(
      first: $first
      offset: $offset
      search: $search
      sort: $sort
      approvedDate_Isnull: false
      releaseDate_Isnull: true
    ) {
      edges {
        node {
          id
          pk
          orderDate
          holdDate
          sendByDate
          invoiceTotal
          account {
            id
            pk
            name
          }
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const ORDERS_AWAITING_APPROVAL_QUERY = gql`
  query ordersAwaitingApproval($first: Int, $offset: Int, $search: String, $sort: String) {
    orders(first: $first, offset: $offset, search: $search, sort: $sort, approvedDate_Isnull: true) {
      edges {
        node {
          id
          pk
          account {
            id
            pk
            name
          }
          orderDate
          invoiceTotal
        }
      }
      pageInfo {
        hasNextPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;
