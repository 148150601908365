import { ChatBubbleLeftIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  IconButton,
  Spinner,
} from '~/src/components';
import { useToast } from '~/src/features/toast';
import { mediaURL } from '~/src/utils/media';
import { JobStatusChoices, JobStatuses } from '../../enums';
import { useAssignableGroups, usePieceworkerProfile, useTrainingMutations } from '../../hooks';
import './ProfileDrawer.scss';

export enum ProfileDrawerTabs {
  GENERAL = 'general',
  NOTES = 'notes',
  JOB_HISTORY = 'job-history',
}

export type ProfileDrawerProps = {
  isOpen: boolean;
  onClose?: () => void;
  tab?: ProfileDrawerTabs;
  userId: string;
};

export const ProfileDrawer = (props: ProfileDrawerProps) => {
  const [selectedTab, setSelectedTab] = React.useState<ProfileDrawerTabs>(ProfileDrawerTabs.GENERAL);
  const [trainingIsLocked, setTrainingIsLocked] = React.useState(true);

  const { error: errorToast } = useToast();
  const { getAssignableGroups, pieceworkGroups, pieceworkGroupsLoading } = useAssignableGroups();
  const { getPieceworker, pieceworker, pieceworkerLoading } = usePieceworkerProfile();
  const { createTraining, deleteTraining } = useTrainingMutations();

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    getAssignableGroups();
    getPieceworker({
      variables: {
        id: props.userId,
      },
    });
  }, [props.isOpen]);

  React.useEffect(() => {
    if (!props.tab) {
      return;
    }
    setSelectedTab(props.tab);
  }, [props.tab]);

  function selectedTabClick(tab: ProfileDrawerTabs) {
    setSelectedTab(tab);
  }

  function onClose() {
    setSelectedTab(ProfileDrawerTabs.GENERAL);
    setTrainingIsLocked(true);
    props.onClose?.();
  }

  if (!pieceworker || pieceworkerLoading) {
    return (
      <Drawer isOpen={props.isOpen} onClose={props.onClose} style={{ width: '768px' }}>
        <Spinner message="Loading pieceworker..." />
      </Drawer>
    );
  }

  function isPieceworkerTrained(groupPk: number) {
    return pieceworker.trainedGroups.edges.map((edge: any) => edge.node.group.pk).includes(groupPk);
  }

  function toggleGroupTrained(group: any) {
    if (isPieceworkerTrained(group.pk)) {
      const groupTraining = pieceworker.trainedGroups.edges.find((pgtEdge: any) => pgtEdge.node.group.pk === group.pk);
      deleteTraining({
        variables: {
          pk: groupTraining.node.pk,
        },
      })
        .then(() => {
          getPieceworker({
            fetchPolicy: 'no-cache',
            variables: {
              id: props.userId,
            },
          });
        })
        .catch((err) => {
          errorToast(err.message);
        });
    } else {
      createTraining({
        variables: {
          groupPk: group.pk,
          pieceworkerPk: pieceworker.pk,
        },
      })
        .then(() => {
          getPieceworker({
            fetchPolicy: 'no-cache',
            variables: {
              id: props.userId,
            },
          });
        })
        .catch((err) => {
          errorToast(err.message);
        });
    }
  }

  function getStatusColor(node: any) {
    switch (node.status) {
      case JobStatuses.READY:
        return '#5699de';
      case JobStatuses.PICKUP:
        return '#d68249';
      case JobStatuses.PRODUCTION:
        return '#633094';
      case JobStatuses.PAYMENT:
        return '#374151';
      case JobStatuses.COMPLETED:
        return '#16a34a';
      default:
        return '#5699de';
    }
  }

  function renderAssignableGroups() {
    if (pieceworkGroupsLoading) {
      return <Spinner message="Loading groups..." />;
    }
    return (
      <div className="ProfileDrawer__general__trained__checkboxes">
        {pieceworkGroups.edges.map((edge: any, index: number) => {
          return (
            <Checkbox
              key={index}
              checked={isPieceworkerTrained(edge.node.pk)}
              disabled={trainingIsLocked}
              label={edge.node.prefix}
              onChange={() => {
                toggleGroupTrained(edge.node);
              }}
            />
          );
        })}
      </div>
    );
  }

  function renderNotes() {
    return pieceworker.availabilities?.edges
      .filter((edge: any) => {
        return !!edge.node.notes;
      })
      .map((edge: any, index: number) => {
        return (
          <div key={index} className="ProfileDrawer__notes__note">
            <div className="flex justify-end">
              <p className="ProfileDrawer__notes__note__date">
                {format(new Date(edge.node.availabilityDatetime), 'M/d/yyyy')}
              </p>
            </div>
            <p className="ProfileDrawer__notes__note__text">{edge.node.notes}</p>
          </div>
        );
      });
  }

  function getBadgeLabel(status: JobStatuses) {
    return JobStatusChoices.find((obj) => obj.status === status)?.label || status;
  }

  function renderJobHistory() {
    return (
      <table className="data-table">
        <thead>
          <tr>
            <th>Job ID</th>
            <th>Picked Up</th>
            <th>Returned</th>
            <th>Job Status</th>
          </tr>
        </thead>
        <tbody>
          {pieceworker.assignedJobs.edges.map((edge: any, index: number) => {
            return (
              <tr key={index}>
                <td>
                  <Link to={`/piecework/jobs/${edge.node.pk}`}>{edge.node.jobNum}</Link>
                </td>
                <td>{edge.node.pickedUpDatetime && format(new Date(edge.node.pickedUpDatetime), 'M/d/yyyy')}</td>
                <td>{edge.node.returnedDatetime && format(new Date(edge.node.returnedDatetime), 'M/d/yyyy')}</td>
                <td>
                  <Badge
                    label={getBadgeLabel(edge.node.status)}
                    style={{
                      backgroundColor: getStatusColor(edge.node),
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <Drawer className="ProfileDrawer" isOpen={props.isOpen} onClose={onClose} style={{ width: '768px' }}>
      <DrawerHeader onClose={onClose} title="Profile" />
      <DrawerContent>
        <Card fluid>
          <div className="ProfileDrawer__user">
            <img className="ProfileDrawer__user__image" src={mediaURL(pieceworker.profile.profilePicture)} />
            <div className="ProfileDrawer__user__info">
              <div className="ProfileDrawer__user__info__name">
                {pieceworker.firstName} {pieceworker.lastName}
              </div>
              {/* <Badge label="Active" style={{ backgroundColor: '#258e59' }} /> */}
            </div>
            <div>
              <Button iconLeading={<ChatBubbleLeftIcon />} variant="outlined">
                Message
              </Button>
            </div>
          </div>
        </Card>
        <div className="ProfileDrawer__tabs">
          <Button
            color={selectedTab === ProfileDrawerTabs.GENERAL ? 'primary' : 'default'}
            fluid
            onClick={() => {
              selectedTabClick(ProfileDrawerTabs.GENERAL);
            }}
          >
            General
          </Button>
          <Button
            color={selectedTab === ProfileDrawerTabs.NOTES ? 'primary' : 'default'}
            fluid
            onClick={() => {
              selectedTabClick(ProfileDrawerTabs.NOTES);
            }}
          >
            Notes
          </Button>
          <Button
            color={selectedTab === ProfileDrawerTabs.JOB_HISTORY ? 'primary' : 'default'}
            fluid
            onClick={() => {
              selectedTabClick(ProfileDrawerTabs.JOB_HISTORY);
            }}
          >
            Job History
          </Button>
        </div>
        <Card fluid>
          {selectedTab === ProfileDrawerTabs.GENERAL && (
            <div className="ProfileDrawer__general">
              <label className="ProfileDrawer__general__label">Pieceworker</label>
              <p className="font-size-18">Contact Information</p>
              <div className="ProfileDrawer__general__contact">
                <div className="ProfileDrawer__general__contact__row">
                  <label>Full Name</label>
                  <p>
                    {pieceworker.firstName} {pieceworker.lastName}
                  </p>
                  {/* <Button color="primary">Update</Button> */}
                </div>
                <div className="ProfileDrawer__general__contact__row">
                  <label>Phone</label>
                  <p>{pieceworker.profile?.phone}</p>
                  {/* <Button color="primary">Update</Button> */}
                </div>
                <div className="ProfileDrawer__general__contact__row">
                  <label>Email</label>
                  <p>{pieceworker.email}</p>
                  {/* <Button color="primary">Update</Button> */}
                </div>
                <div className="ProfileDrawer__general__contact__row">
                  <label>Address</label>
                  <p>
                    {pieceworker.profile?.address} <br></br>
                    {pieceworker.profile?.city} {pieceworker.profile?.state} {pieceworker.profile?.zip}
                  </p>
                  {/* <Button color="primary">Update</Button> */}
                </div>
              </div>
              {/* <div className="ProfileDrawer__general__updateImage">
                  <img src={mediaURL(pieceworker.profile.profilePicture)} />
                  <p>Update picture</p>
                  <div className="flex-1"></div>
                  <div>
                    <Button outlined>Change</Button>
                  </div>
                </div> */}
              <hr className="divider" />
              <div className="ProfileDrawer__general__trained">
                <div className="ProfileDrawer__general__trained__header">
                  <p className="font-size-18">Trained</p>
                  <IconButton
                    onClick={() => {
                      setTrainingIsLocked(!trainingIsLocked);
                    }}
                    testId="lock-button"
                  >
                    <LockClosedIcon />
                  </IconButton>
                </div>
                {renderAssignableGroups()}
              </div>
            </div>
          )}
          {selectedTab === ProfileDrawerTabs.NOTES && (
            <div className="ProfileDrawer__notes">
              <p className="font-size-18">Notes</p>
              {renderNotes()}
            </div>
          )}
          {selectedTab === ProfileDrawerTabs.JOB_HISTORY && <>{renderJobHistory()}</>}
        </Card>
      </DrawerContent>
      <DrawerActions>
        <div className="flex-1"></div>
        <Button color="light" onClick={onClose} variant="raised">
          Cancel
        </Button>
        <Button color="primary" variant="raised">
          Save
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
