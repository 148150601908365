import { ArrowLeftOnRectangleIcon, BellIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import bucket from '~/src/assets/img/bucket.png';
import { useAuth } from '~/src/features/auth';
import { IconButton } from '~/src/components';
import './PieceworkerNav.scss';

export const PieceworkerNav = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  function logout() {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  }

  return (
    <div className="PieceworkerNav">
      <img
        className="PieceworkerNav__logo"
        src={bucket}
        onClick={() => {
          navigate('/');
        }}
      />
      <div className="PieceworkerNav__spacer"></div>
      <IconButton>
        <BellIcon />
      </IconButton>
      <IconButton
        testId="logout"
        onClick={() => {
          logout();
        }}
      >
        <ArrowLeftOnRectangleIcon />
      </IconButton>
    </div>
  );
};
