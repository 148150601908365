import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { Spinner } from '~/src/components';
import { ME_QUERY } from '../api';

type AuthContextType = {
  user: any;
  getUser: () => void;
  setUser: React.Dispatch<React.SetStateAction<any>>;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const token = localStorage.getItem('token');

  const [user, setUser] = React.useState(null);

  const apolloClient = useApolloClient();

  React.useEffect(() => {
    // When page is first opened, check for a previously logged in user.
    if (token) {
      getUser();
    }
  }, []);

  function getUser() {
    apolloClient
      .query({
        query: ME_QUERY,
      })
      .then((res: any) => {
        setUser(res.data.me);
      })
      .catch(() => {
        localStorage.removeItem('token');
        // This provider is not within a router so must use
        // window location here
        window.location.href = '/login';
      });
  }

  if (token && !user) {
    return (
      <div className="headspace">
        <Spinner message="Loading..." />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        getUser,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export type TestingAuthProviderProps = {
  user?: any;
  children: React.ReactNode;
};

export const TestingAuthProvider = (props: TestingAuthProviderProps) => {
  const [user, setUser] = React.useState(props.user ? props.user : null);

  return (
    <AuthContext.Provider
      value={{
        user,
        getUser: () => {},
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
