import * as React from 'react';
import { Input, Select, Toggle } from '~/src/components';
import { Layer } from '../VariableFieldsDrawer';
import './VariableFieldsLayer.scss';
import { VariableInputType, imageInputs, textInputs } from './inputs';

type VariableFieldProps = {
  input: VariableInputType;
  onChange: (e: any) => void;
  layer: any;
};

const VariableField = (props: VariableFieldProps) => {
  const value = props.input.name === 'name' ? props.layer?.name : props.layer?.parameters[props.input.name];
  const dependentOnValue = props.layer?.parameters[props.input.dependentOn?.name || ''];

  function testCondition(dependentOn: any) {
    switch (dependentOn?.condition) {
      case 'truthy':
        return !!dependentOnValue;
      case 'falsy':
        return !dependentOnValue;
      default:
        return true;
    }
  }

  function build(): React.ReactElement {
    const { dependentOn, required, ...inputProps } = props.input;

    return (
      <>
        {props.input.type === 'break' ? (
          <div className="VariableFields__layer__break"></div>
        ) : (
          <span className="VariableFields__keyValueSpan">
            <p>{props.input.label}</p>
            {(() => {
              switch (props.input.type) {
                case 'toggle':
                  return (
                    <Toggle
                      name={props.input.name}
                      checked={value || false}
                      onChange={() => props.onChange({ target: { name: props.input.name, value: !value } })}
                    />
                  );
                case 'select':
                  return (
                    <Select
                      {...inputProps}
                      onChange={props.onChange}
                      value={value || ''}
                      className={`${props.input.required && !value ? 'VariableFields__layer__required' : ''}`}
                    />
                  );
                case 'color':
                  return (
                    <div className="VariableFields__layer__colorContainer">
                      <Input
                        fluid
                        maxLength={7}
                        name={props.input.name}
                        onChange={(e) => props.onChange(e)}
                        placeholder="#000000"
                        style={{ border: value && !value?.match(/^#[0-9a-f]{6}$/i) ? '1px solid red' : '' }}
                        value={value || ''}
                      />
                      <Input
                        onChange={props.onChange}
                        value={value?.match(/^#[0-9a-fA-F]{6}$/) ? value : '#000000'}
                        {...inputProps}
                      />
                    </div>
                  );
                default:
                  return (
                    <Input
                      className={`${props.input.required && !value ? 'VariableFields__layer__required' : ''}`}
                      onChange={props.onChange}
                      value={value || ''}
                      {...inputProps}
                    />
                  );
              }
            })()}
          </span>
        )}
      </>
    );
  }

  const element = build();
  return props.input.dependentOn ? (testCondition(props.input.dependentOn) ? element : null) : element;
};

type VariableFieldsLayerProps = {
  layer: Layer;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const VariableFieldsLayer = (props: VariableFieldsLayerProps) => {
  return (
    <div className="VariableFields__layer">
      <span className="VariableFields__keyValueSpan">
        <p>Load template</p>
        <Input />
      </span>
      {props.layer?.type.name === 'text' &&
        textInputs.map((input, key) => (
          <VariableField key={key} input={input} onChange={props.onInputChange} layer={props.layer} />
        ))}
      {props.layer?.type.name === 'image' &&
        imageInputs.map((input, key) => (
          <VariableField key={key} input={input} onChange={props.onInputChange} layer={props.layer} />
        ))}
    </div>
  );
};
