import { useQuery } from '@apollo/client';
import { JOB_MATERIALS_QUERY } from '../api';

type useJobMaterialsProps = {
  jobPk: string;
};

export const useJobMaterials = (props: useJobMaterialsProps) => {
  const { data, loading, error } = useQuery(JOB_MATERIALS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      jobPk: props.jobPk,
    },
  });

  return {
    jobMaterials: data?.jobMaterials,
    jobMaterialsLoading: loading,
    jobMaterialsError: error,
  };
};
