import { useLazyQuery } from '@apollo/client';
import { BILLING_DETAILS_QUERY } from '../api';

type useBillingDetailsProps = {
  id: string;
};

export const useBillingDetails = (props: useBillingDetailsProps) => {
  const [getBillingDetails, { data, loading, error }] = useLazyQuery(BILLING_DETAILS_QUERY, {
    variables: {
      id: props.id,
    },
  });

  return {
    getBillingDetails,
    billingDetails: data?.billing,
    billingDetailsLoading: loading,
    billingDetailsError: error,
  };
};
