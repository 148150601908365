import * as React from 'react';
import { formatDateString } from '~/src/utils/formatting';
import './RepReordersTab.scss';
import { Link } from 'react-router-dom';

type RepReordersTabProps = { data: any };

export const RepReordersTab = (props: RepReordersTabProps) => {
  const { data } = props;

  return (
    <div className="RepDetails__reorders">
      {data.rep.account.edges.map((account: any, index: number) => {
        if (!account.node.displaydetailsSet.edges.length) return;
        return (
          <div className="RepDetails__reorders__table" key={index}>
            <div className="RepDetails__reorders__table__header">
              <div className="RepDetails__reorders__table__header--storeInfo">
                <p>
                  Account #<Link to={`/accounts/${account.node.pk}`}>{account.node.pk}</Link>
                </p>
                <p>
                  <span>{account.node.name}</span>&#8212;&nbsp;&nbsp;{account.node.city}, {account.node.state}
                </p>
              </div>
              <div className="RepDetails__reorders__table__header--orderInfo">
                <p>
                  Oldest Unpaid Invoice: <span>N/A</span>
                </p>
                <p>
                  Order Count: <span>N/A</span>
                </p>
              </div>
            </div>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Display ID</th>
                  <th>Display Type</th>
                  <th>Program Type</th>
                  <th>Featured Products</th>
                  <th>Last Shipped</th>
                  <th>Times Re-ordered</th>
                </tr>
              </thead>
              <tbody>
                {account.node.displaydetailsSet?.edges.map((edge: any, index: number) => (
                  <tr key={index}>
                    <td>{edge.node.pk}</td>
                    <td>{edge.node.displayProduct?.name}</td>
                    <td></td>
                    <td>
                      {edge.node.product?.name}
                      {edge.node.productSecondary ? `, ${edge.node.productSecondary.name}` : ''}
                    </td>
                    <td>{edge.node.lastShipDate ? formatDateString(edge.node.lastShipDate) : ''}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};
