import { XMarkIcon } from '@heroicons/react/24/solid';
import * as classNames from 'classnames';
import * as React from 'react';
import { IconButton } from '../IconButton';
import './Modal.scss';

type ModalProps = React.HTMLAttributes<HTMLDivElement> & {
  backdropClose?: boolean;
  closeButton?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  testId?: string;
};

export const Modal = ({
  backdropClose = true,
  children,
  className,
  closeButton = true,
  isOpen,
  onClose,
  testId,
  ...rest
}: ModalProps) => {
  const modalClass = classNames('Modal', className);

  return (
    <>
      {isOpen && (
        <>
          <div
            className={modalClass}
            onClick={() => {
              if (backdropClose && onClose) {
                onClose();
              }
            }}
            data-testid={testId && `${testId}-overlay`}
          >
            <div
              className="Modal__content"
              onClick={(e) => {
                e.stopPropagation();
              }}
              data-testid={testId}
              {...rest}
            >
              {closeButton && (
                <IconButton
                  className="Modal__close"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onClose) {
                      onClose();
                    }
                  }}
                >
                  <XMarkIcon />
                </IconButton>
              )}
              {children}
            </div>
          </div>
        </>
      )}
    </>
  );
};
