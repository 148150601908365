import * as React from 'react';
import { Badge, Button, Textarea } from '~/src/components';
import { useBillingUpdate } from '../../hooks';
import { EditBillingModal } from '../EditBillingModal';
import './InfoSection.scss';

type InfoSectionProps = {
  data: any;
  refetch: () => void;
};

export const InfoSection = (props: InfoSectionProps) => {
  const [data, setData] = React.useState<any>({});
  const [isEditModalOpen, setIsEditModalOpen] = React.useState<boolean>(false);
  const [isEditingNotes, setIsEditingNotes] = React.useState(false);
  const [newNotes, setNewNotes] = React.useState(props.data.notes || '');

  const { billingUpdate } = useBillingUpdate();

  React.useEffect(() => {
    if (!props.data) return;
    setData(props.data);
  }, [props.data]);

  function stringToCapitalCase(str: string) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function renderStatusBadge(status: string) {
    status = stringToCapitalCase(status);
    switch (status) {
      case 'Active':
        return <Badge color="success" label={status} />;
      case 'Inactive':
        return <Badge color="warning" label={status} />;
      default:
        return status ? <Badge color="light" label={status} /> : null;
    }
  }

  function getPrimaryContact() {
    return data.contacts?.edges.find((edge: any) => edge.node.primary);
  }

  function renderEditNotes() {
    const submitHandler = () => {
      billingUpdate({ variables: { pk: data.pk, updates: { notes: newNotes } }, onCompleted: props.refetch });
      setIsEditingNotes(false);
    };

    return (
      <form>
        <Textarea fluid onChange={(e) => setNewNotes(e.target.value)} value={newNotes} />
        <div className="InfoSection__notes__buttons">
          <Button
            color="light"
            fluid
            onClick={() => {
              setIsEditingNotes(false);
              setNewNotes(data.notes);
            }}
            size="sm"
            variant="raised"
          >
            Cancel
          </Button>
          <Button color="primary" fluid onClick={submitHandler} size="sm" variant="raised">
            Save
          </Button>
        </div>
      </form>
    );
  }

  function renderNotes() {
    if (!data.notes) return <p>No notes</p>;
    return (
      <div className="InfoSection__notes__body">
        <p>{data.notes}</p>
      </div>
    );
  }

  return (
    <div className="InfoSection">
      <div className="InfoSection__overview">
        <div className="InfoSection__overview__header">
          <span className="font-medium">Overview</span>
          <a className="InfoSection__overview__edit" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </a>
        </div>
        <div className="InfoSection__overview__body">
          <div className="InfoSection__field">
            <label>Id:</label>
            <p>{data.pk}</p>
          </div>
          <div className="InfoSection__field">
            <label>Billing:</label>
            <p>{data.name}</p>
          </div>
          <div className="InfoSection__field">
            <label>Address:</label>
            <p>
              {data.address}
              <br />
              {data.city}, {data.state}, {data.postal}
            </p>
          </div>
        </div>
      </div>
      <div className="InfoSection__status">
        <div className="InfoSection__field">
          <label>Status:</label>
          {data.statusChoice && renderStatusBadge(data.statusChoice)}
        </div>
        <div className="InfoSection__field">
          <label>Company Type:</label>
          <p>{data.companyType?.type}</p>
        </div>
      </div>
      <div className="InfoSection__primaryContact">
        <div className="InfoSection__primaryContact__header">
          <span className="font-medium">Primary Contact</span>
        </div>
        {getPrimaryContact() ? (
          <div className="InfoSection__primaryContact__body">
            <div className="InfoSection__field">
              <label>Name:</label>
              <b>{getPrimaryContact()?.name}</b>
            </div>
            <div className="InfoSection__field">
              <label>Phone:</label>
              <a href={`tel:${getPrimaryContact()?.phone}`}>{getPrimaryContact()?.phone}</a>
            </div>
            <div className="InfoSection__field">
              <label>Email:</label>
              <a href={`mailto:${getPrimaryContact()?.email}`}>{getPrimaryContact()?.email}</a>
            </div>
          </div>
        ) : (
          <p>No primary contact</p>
        )}
      </div>
      <div className="InfoSection__notes">
        <div className="InfoSection__notes__header">
          <span className="font-medium">Billing Notes</span>
          <a className="InfoSection__notes__edit" onClick={() => setIsEditingNotes(true)}>
            Edit
          </a>
        </div>
        {isEditingNotes ? renderEditNotes() : renderNotes()}
      </div>
      <EditBillingModal
        data={data}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSubmit={(formData) =>
          billingUpdate({ variables: { pk: data.pk, updates: { ...formData } } }).then(() => setIsEditModalOpen(false))
        }
      />
    </div>
  );
};
