import { gql } from '@apollo/client';

export const BILLING_LIST_QUERY = gql`
  query billingList($first: Int, $offset: Int, $search: String!, $sort: String, $status: String) {
    billings(first: $first, offset: $offset, search: $search, sort: $sort, status_Status_Iexact: $status) {
      totalNodesOnPage
      totalNodes
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pk
          name
          contact
          city
          state
          companyType {
            id
            pk
            type
            typeDescription
          }
          terms
          statusChoice
          openSubtotal
          pastDueSubtotal
        }
      }
    }
  }
`;

export const BILLING_DETAILS_QUERY = gql`
  query billingDetails($id: ID!) {
    billing(id: $id) {
      id
      pk
      name
      address
      city
      state
      postal
      notes
      companyType {
        id
        pk
        type
        typeDescription
      }
      statusChoice
      contact
      contacts {
        edges {
          node {
            name
            phone
            email
          }
        }
      }
      openSubtotal
      pastDueSubtotal
    }
  }
`;

export const BILLING_OPEN_ORDERS_QUERY = gql`
  query billingOpenOrders($first: Int, $id: ID!, $offset: Int, $sort: String) {
    orders(first: $first, account_Billing: $id, isOpen: true, offset: $offset, sort: $sort) {
      edges {
        node {
          id
          pk
          account {
            name
          }
          shipDate
          termsDays
          daysPastDue
          invoiceTotal
          paymentsTotal
          remainingTotal
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const STATUS_LIST_QUERY = gql`
  query statuses {
    statuses {
      edges {
        node {
          id
          pk
          status
        }
      }
    }
  }
`;

export const COMPANY_TYPE_LIST_QUERY = gql`
  query companyTypes {
    companyTypes {
      edges {
        node {
          id
          pk
          type
          typeDescription
        }
      }
    }
  }
`;

export const COLLECTION_DETAILS_QUERY = gql`
  query collectionDetails($billingId: ID) {
    collectionDetails(billing: $billingId) {
      edges {
        node {
          deDatetime
          order {
            id
            pk
          }
          actionNotes
          deNextActiondate
        }
      }
    }
  }
`;

export const OPEN_ORDERS_STATEMENT_QUERY = gql`
  query openOrdersStatement($billingId: Int!) {
    openOrdersStatementPrint(billing: $billingId)
    openOrdersStatementEmail(billing: $billingId)
  }
`;
