import * as classNames from 'classnames';
import * as React from 'react';
import './Checkbox.scss';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  testId?: string;
};

export const Checkbox = React.forwardRef(
  ({ className, checked, label, testId, ...rest }: CheckboxProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    let containerClass = 'Checkbox__container';
    let checkboxClass = classNames('Checkbox', className);

    return (
      <>
        <label className={containerClass} data-testid={testId && `${testId}-container`}>
          {label}
          <input checked={checked} className={checkboxClass} data-testid={testId} ref={ref} type="checkbox" {...rest} />
          <span className="Checkbox__mark"></span>
        </label>
      </>
    );
  }
);
