import * as classNames from 'classnames';
import * as React from 'react';
import { Button, ButtonProps } from '../Button';
import './MenuItem.scss';

type MenuItemProps = ButtonProps & {};

export const MenuItem = ({
  className,
  style = {
    borderRadius: '0',
    textAlign: 'left',
  },
  ...rest
}: MenuItemProps) => {
  const menuItemClass = classNames(className);

  return <Button className={menuItemClass} style={style} {...rest} />;
};
