import { gql } from '@apollo/client';
import * as React from 'react';
import { Input, SearchPopover } from '~/src/components';

const NAMELIST_QUERY = gql`
  query nameLists {
    nameLists {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

type NamelistSearchPopoverProps = {
  namelist: any;
  onChange: (display: any) => void;
};

export const NamelistSearchPopover = (props: NamelistSearchPopoverProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [namelist, setNamelist] = React.useState<any>({});

  React.useEffect(() => {
    if (!props.namelist) return;
    setNamelist(props.namelist);
  }, [props.namelist]);

  return (
    <div className="relative">
      <Input onClick={() => setPopoverOpen(true)} value={namelist?.name} readOnly />
      <SearchPopover
        isOpen={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        onChange={(namelist) => {
          setPopoverOpen(false);
          setNamelist(namelist);
          props.onChange(namelist);
        }}
        popoverStyle={{ position: 'absolute', maxHeight: '400px', overflowY: 'auto' }}
        query={NAMELIST_QUERY}
        queryArgument="search"
        queryField="nameLists"
        renderMatch={(match) => <>{match.name}</>}
      />
    </div>
  );
};
