import * as classNames from 'classnames';
import * as React from 'react';
import './Toggle.scss';

type ToggleProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: String;
  testId?: string;
};

export const Toggle = React.forwardRef(
  ({ checked, className, label, testId, ...rest }: ToggleProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const containerClass = 'Toggle__container';
    const toggleClass = classNames('Toggle', className);

    return (
      <>
        <label className={containerClass} data-testid={testId && `${testId}-container`}>
          {label}
          <input checked={checked} className={toggleClass} data-testid={testId} ref={ref} type="checkbox" {...rest} />
          <div className="Toggle__control"></div>
        </label>
      </>
    );
  }
);
