import { XMarkIcon } from '@heroicons/react/24/solid';
import * as classNames from 'classnames';
import * as React from 'react';
import { IconButton } from '../IconButton';

type DrawerHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
  title: string;
};

export const DrawerHeader = ({ className, onClose, title }: DrawerHeaderProps) => {
  const headerClass = classNames('Drawer__header', className);

  return (
    <div className={headerClass}>
      <div className="Drawer__title">{title}</div>
      <div className="Drawer__header__spacer"></div>
      <IconButton
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <XMarkIcon />
      </IconButton>
    </div>
  );
};
