import { gql } from '@apollo/client';

export const PRODUCTS_QUERY = gql`
  query products(
    $search: String
    $group: [ID]
    $offset: Int
    $first: Int
    $sort: String
    $cancelled: Boolean
    $active: Boolean
  ) {
    products(
      search: $search
      group: $group
      offset: $offset
      first: $first
      sort: $sort
      cancelled: $cancelled
      active: $active
    ) {
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pk
          name
          description
          readyForProduction
          lastOrderDate
          minQty
          price
          artFront {
            pk
            artNumber
          }
          artBack {
            pk
            artNumber
          }
          group {
            id
            pk
            name
            prefix
          }
          shape {
            id
            pk
            name
          }
          tags {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      pk
      name
      description
      upc
      acrInventoried
      notes
      active
      minQty
      browsable
      enterDate
      lastOrderDate
      instructions
      revisionNotes
      readyForReview
      approvalDate
      readyForProduction
      cancelledDate
      cancelledReason
      needsRevisionDate
      group {
        id
        pk
        name
        prefix
      }
      tags {
        edges {
          node {
            id
            pk
            name
            parent {
              id
              pk
              name
            }
          }
        }
      }
      artist {
        id
        user {
          firstName
          lastName
        }
      }
      shape {
        id
        pk
        name
      }
      artFront {
        id
        pk
        artNumber
        artDescription
        layers {
          edges {
            node {
              id
              pk
              name
              baseDir
              drawOrder
              customerFacing
              type {
                pk
                name
              }
              parameters
              variableImagePaths
            }
          }
        }
      }
      artBack {
        id
        pk
        artNumber
        artDescription
        layers {
          edges {
            node {
              id
              pk
              name
              baseDir
              drawOrder
              customerFacing
              type {
                pk
                name
              }
              parameters
              variableImagePaths
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_GROUPS_QUERY = gql`
  query groups {
    groups {
      edges {
        node {
          id
          pk
          name
          prefix
        }
      }
    }
  }
`;

export const GROUP_SHAPES_QUERY = gql`
  query groupShapes {
    groupShapes {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const ARTISTS_QUERY = gql`
  query artists($search: String) {
    employees(artist: true, search: $search) {
      edges {
        node {
          id
          pk
          user {
            id
            pk
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const TAGS_QUERY = gql`
  query tags($name: String) {
    tags(name: $name) {
      edges {
        node {
          id
          pk
          name
          parent {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const PRODUCT_ORDERS_QUERY = gql`
  query ProductOrders($productId: ID, $productPk: Decimal, $first: Int, $offset: Int, $sort: String) {
    orders(product: $productPk, first: $first, offset: $offset, sort: $sort) {
      edges {
        node {
          pk
          account {
            pk
            name
          }
          orderDate
          shipDate
          orderDetails(product: $productId) {
            edges {
              node {
                pk
                unitPrice
                quantity
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalNodes
      totalNodesOnPage
    }
  }
`;

export const PRODUCT_LAYERS_QUERY = gql`
  query productLayers($id: String!) {
    product(id: $id) {
      artFront {
        layers {
          edges {
            node {
              pk
              type {
                pk
                name
              }
              parameters
            }
          }
        }
      }
      artBack {
        layers {
          edges {
            node {
              pk
              type {
                pk
                name
              }
              parameters
            }
          }
        }
      }
    }
  }
`;
