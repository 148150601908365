import { useLazyQuery } from '@apollo/client';
import { JOB_DETAIL_QUERY } from '../api';

export const useJobDetailLazy = () => {
  const [getJob, { data, loading, error }] = useLazyQuery(JOB_DETAIL_QUERY, {
    fetchPolicy: 'no-cache',
  });

  return {
    getJob,
    job: data?.job,
    jobLoading: loading,
    jobError: error,
  };
};
