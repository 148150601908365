import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Modal, Textarea } from '~/src/components';
import './InvoiceNotes.scss';

type invoiceNotesProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (notes: string) => void;
  notes: string;
};

const defaultInvoiceNotesData = '';

export const InvoiceNotes = (props: invoiceNotesProps) => {
  const [notes, setNotes] = React.useState(defaultInvoiceNotesData);

  React.useEffect(() => {
    if (!props.notes) return;
    setNotes(props.notes);
  }, [props.notes]);

  function onCloseHandler() {
    setNotes(props.notes);
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSave(notes);
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="InvoiceNotes">
      <div className="InvoiceNotes__header">
        <div className="font-size-18 text-center">Invoice Notes</div>
      </div>
      <div className="InvoiceNotes__body">
        <div className="InvoiceNotes__body__warning">
          <div className="InvoiceNotes__body__warning__logo">
            <ExclamationTriangleIcon />
          </div>
          <div className="InvoiceNotes__body__warning__content">
            <p className="InvoiceNotes__body__warning__content__bold">Warning</p>
            <p className="InvoiceNotes__body__warning__content__standard">
              Any notes left in this area will be visible to the customer!
            </p>
          </div>
        </div>
        <Textarea
          fluid
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Leave notes for this invoice"
          value={notes}
        />
      </div>
      <div className="InvoiceNotes__buttons">
        <Button color="light" onClick={onCloseHandler} variant="raised">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmitHandler} variant="raised">
          Save
        </Button>
      </div>
    </Modal>
  );
};
