import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { EyeSlashIcon } from '@heroicons/react/24/solid';
import bucket from '~/src/assets/img/bucket.png';
import { Button, Container, Form, FormGroup, FormLabel, Input } from '~/src/components';
import { PASSWORD_RESET_MUTATION } from '../../api';
import './ResetPassword.scss';

export const ResetPassword = () => {
  const [error, setError] = React.useState('');
  const [passwordOne, setPasswordOne] = React.useState('');
  const [passwordTwo, setPasswordTwo] = React.useState('');

  const navigate = useNavigate();
  const { token } = useParams();
  const [passwordReset] = useMutation(PASSWORD_RESET_MUTATION);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (token) {
      passwordReset({
        variables: {
          token,
          passwordOne,
          passwordTwo,
        },
      })
        .then(() => {
          navigate('/login');
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }

  return (
    <Container className="reset-password">
      <div className="center-page">
        <img src={bucket} alt="" />
        <h2 className="center-page-title">Reset Password</h2>
        <p className="center-page-sub">Please update your password</p>
        <ul className="reset-password-hint">
          <li>Password must contain at least 8 characters</li>
          <li>Must contain at least one uppercase letter</li>
          <li>Must contain at least one number and one symbol</li>
        </ul>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel htmlFor={passwordOne}>New password</FormLabel>
            <Input
              fluid
              type="password"
              name="passwordOne"
              value={passwordOne}
              placeholder="Password"
              iconTrailing={<EyeSlashIcon />}
              onChange={(e) => {
                setPasswordOne(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Confirm password</FormLabel>
            <Input
              fluid
              type="password"
              name="passwordTwo"
              value={passwordTwo}
              placeholder="Password"
              iconTrailing={<EyeSlashIcon />}
              onChange={(e) => {
                setPasswordTwo(e.target.value);
              }}
            />
          </FormGroup>
          <p className="error">{error}</p>
          <Button color="primary" fluid type="submit" variant="raised">
            Update
          </Button>
        </Form>
        <Link to="/login">
          <Button className="center-page-link" color="primary" fluid>
            Back to Login
          </Button>
        </Link>
      </div>
    </Container>
  );
};
