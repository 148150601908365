import { gql } from '@apollo/client';

export const REPS_QUERY = gql`
  query searchReps(
    $first: Int
    $offset: Int
    $active: Boolean
    $search: String
    $sort: String
    $paymentOn: SalesRepsPaymentOnChoices
  ) {
    reps(first: $first, offset: $offset, active: $active, search: $search, sort: $sort, paymentOn: $paymentOn) {
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          pk
          shipCity
          shipState
          active
          company
          repType
          areaDescription
          user {
            firstName
            lastName
            email
            profile {
              cell
              phone
              profilePicture {
                file
                type
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const REP_QUERY = gql`
  query rep($repPk: Int!) {
    rep(repPk: $repPk) {
      id
      pk
      company
      repType
      areaDescription
      notes
      active
      commission
      paymentMethod
      paymentOn
      account {
        edges {
          node {
            id
            pk
            name
            city
            state
            lastOrderDate
            displaydetailsSet {
              edges {
                node {
                  id
                  pk
                  lastShipDate
                  startDate
                  endDate
                  product {
                    id
                    name
                    description
                  }
                  productSecondary {
                    id
                    name
                    description
                  }
                  displayProduct {
                    name
                  }
                  namelist {
                    name
                  }
                  upcType {
                    types
                  }
                }
              }
            }
          }
        }
      }
      user {
        id
        pk
        username
        firstName
        lastName
        email
        isActive
        profile {
          pk
          phone
          cell
          address
          city
          state
          zip
          profilePicture {
            file
            type
            name
          }
        }
      }
    }
  }
`;

export const DISPLAY_FOLLOWUPS_QUERY = gql`
  query displayFollowups($rep: Decimal!) {
    displayFollowUps(rep: $rep) {
      edges {
        node {
          pk
          actionNotes
          display {
            pk
          }
          created
          followupDate
        }
      }
    }
  }
`;

export const REP_ORDERS_SUMMARY_QUERY = gql`
  query repOrders(
    $rep: ID!
    $first: Int
    $offset: Int
    $completionDate_Lte: Date
    $completionDate_Gte: Date
    $shipDate_Lte: Date
    $shipDate_Gte: Date
    $orderDate_Lte: Date
    $orderDate_Gte: Date
    $releaseDate_Lte: Date
    $releaseDate_Gte: Date
    $approvedDate_Lte: Date
    $approvedDate_Gte: Date
  ) {
    orders(
      rep: $rep
      first: $first
      offset: $offset
      completionDate_Lte: $completionDate_Lte
      completionDate_Gte: $completionDate_Gte
      shipDate_Lte: $shipDate_Lte
      shipDate_Gte: $shipDate_Gte
      orderDate_Lte: $orderDate_Lte
      orderDate_Gte: $orderDate_Gte
      releaseDate_Lte: $releaseDate_Lte
      releaseDate_Gte: $releaseDate_Gte
      approvedDate_Lte: $approvedDate_Lte
      approvedDate_Gte: $approvedDate_Gte
    ) {
      edges {
        node {
          id
          pk
          account {
            pk
            name
            city
            state
          }
          poNumber
          orderDate
          shipDate
          paidDate
          invoiceTotal
        }
      }
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const REP_ID_QUERY = gql`
  query repID($repPk: Int!) {
    rep(repPk: $repPk) {
      id
      pk
      user {
        id
        username
      }
    }
  }
`;

export const REP_COMMISSION_REPORTS_QUERY = gql`
  query reportRepCommission($rep: Int!, $start: Date!, $end: Date!) {
    reportRepCommissionPdf(rep: $rep, start: $start, end: $end)
    reportRepCommissionShippedPdf(rep: $rep, start: $start, end: $end)
  }
`;

export const REP_SALES_HISTORY_REPORT_QUERY = gql`
  query repSalesHistory(
    $repPk: Int!
    $search: String
    $productType: SalesHistoryTypeChoices
    $startDate: Date
    $endDate: Date
    $offset: Int
    $first: Int
  ) {
    reportRepSalesHistory(
      repPk: $repPk
      search: $search
      productType: $productType
      start: $startDate
      end: $endDate
      offset: $offset
      first: $first
    ) {
      edges {
        node {
          product {
            id
            pk
            name
            description
            group {
              id
              pk
              name
              nonProduct
            }
          }
          display {
            id
            pk
            account {
              id
              pk
              name
            }
            namelist {
              id
              pk
              name
            }
            displayProduct {
              id
              pk
              name
            }
            label
            product {
              id
              pk
              name
            }
            startDate
            endDate
          }
          lastOrderedDate
          lastShippedDate
          totalQuantityOrdered
          total
        }
      }
      totalSales
      displaySales
      nonDisplaySales
      totalOrders
      averageSale
      totalNodes
      totalNodesOnPage
      pageInfo {
        hasNextPage
      }
    }
  }
`;
