import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Drawer, IconButton, Textarea } from '~/src/components';
import './OrderNotesDrawer.scss';

type OrderNotesDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSaveNote: (data: any) => void;
  onAddProductionNote: (data: any) => void;
  order: any;
  proceedToShipping: boolean;
  onShippingConfirmation: () => void;
};

type OrderNotesFormData = {
  notes: string;
  productionNote: string;
};

const OrderNotesFormDefaults: OrderNotesFormData = {
  notes: '',
  productionNote: '',
};

export const OrderNotesDrawer = (props: OrderNotesDrawerProps) => {
  const [isEditingOrderNotes, setIsEditingOrderNotes] = React.useState(false);
  const [formData, setFormData] = React.useState(OrderNotesFormDefaults);

  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, notes: props.order.notes }));
  }, [props.isOpen, props.order]);

  function handleClose() {
    setFormData(OrderNotesFormDefaults);
    setIsEditingOrderNotes(false);
    props.onClose();
  }

  function saveOrderNotes() {
    props.onSaveNote({
      notes: formData.notes,
    });
    setIsEditingOrderNotes(false);
  }

  function addProductionNote() {
    props.onAddProductionNote({
      note: formData.productionNote,
      order: props.order.pk,
    });
  }

  function formatDateTime(str: string) {
    const date = new Date(str);
    const correctedHours = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
    // MM/dd/yyyy hh/mm AM
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${correctedHours}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
  }

  return (
    <Drawer
      isOpen={props.isOpen}
      onClose={handleClose}
      style={{ width: '832px', display: 'flex', flexDirection: 'column', maxHeight: '100vh' }}
      backdrop
    >
      <div className="OrderNotesDrawer">
        <div className="OrderNotesDrawer__header">
          <div className="OrderNotesDrawer__header__title">
            <p>Notes</p>
            <label>Please review all notes before proceeding with the order.</label>
          </div>
          <div className="flex-1"></div>
          <IconButton onClick={handleClose}>
            <XMarkIcon />
          </IconButton>
        </div>
        <div className="OrderNotesDrawer__body">
          <div className="OrderNotesDrawer__body__left">
            <div className="OrderNotesDrawer__body__noteBox">
              <div className="OrderNotesDrawer__body__noteBox__header">
                <label>Orders</label>
                {!isEditingOrderNotes && (
                  <Button color="primary" onClick={() => setIsEditingOrderNotes(true)} size="sm">
                    Edit
                  </Button>
                )}
              </div>
              <div>
                {!isEditingOrderNotes &&
                  (props.order.notes ? (
                    <p>{props.order.notes}</p>
                  ) : (
                    <span className="font-italic">No notes specified.</span>
                  ))}
                {isEditingOrderNotes && (
                  <>
                    <Textarea
                      fluid
                      onChange={(e) => setFormData((prev) => ({ ...prev, notes: e.target.value }))}
                      value={formData.notes}
                    />
                    <div className="OrderNotesDrawer__body__noteBox__inputButtons">
                      <Button
                        color="light"
                        onClick={() => {
                          setIsEditingOrderNotes(false);
                          setFormData((prev) => ({ ...prev, notes: props.order.notes }));
                        }}
                        size="sm"
                        variant="raised"
                      >
                        Cancel
                      </Button>
                      <Button color="primary" onClick={saveOrderNotes} size="sm" variant="raised">
                        Save
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="OrderNotesDrawer__body__noteBox">
              <div className="OrderNotesDrawer__body__noteBox__header">
                <label>Billing</label>
              </div>
              {props.order.account?.billing?.notes ? (
                <p>{props.order.account?.billing?.notes}</p>
              ) : (
                <span className="font-italic">No notes specified.</span>
              )}
            </div>
            <div className="OrderNotesDrawer__body__noteBox">
              <div className="OrderNotesDrawer__body__noteBox__header">
                <label>Accounts</label>
              </div>
              {props.order.account?.notes ? (
                <p>{props.order.account?.notes}</p>
              ) : (
                <span className="font-italic">No notes specified.</span>
              )}
            </div>
            <div className="OrderNotesDrawer__body__noteBox">
              <div className="OrderNotesDrawer__body__noteBox__header">
                <label>Reps</label>
              </div>
              {props.order.rep?.notes ? (
                <p>{props.order.rep?.notes}</p>
              ) : (
                <span className="font-italic">No notes specified.</span>
              )}
            </div>
          </div>
          <div className="OrderNotesDrawer__body__right">
            <div className="OrderNotesDrawer__body__input">
              <p>New production note</p>
              <Textarea
                fluid
                onChange={(e) => setFormData((prev) => ({ ...prev, productionNote: e.target.value }))}
                value={formData.productionNote}
              />
              <Button
                color="primary"
                iconLeading={<PlusIcon />}
                onClick={() => {
                  addProductionNote();
                  setFormData((prev) => ({ ...prev, productionNote: '' }));
                }}
                variant="raised"
              >
                Add
              </Button>
            </div>
            {props.order?.productionNotes.edges
              .slice()
              .sort((edge1: any, edge2: any) => {
                // sort edges by most recently added
                const date1 = new Date(edge1.node.created);
                const date2 = new Date(edge2.node.created);
                return date1 > date2 ? -1 : date2 > date1 ? 1 : 0;
              })
              .map((edge: any) => (
                <div className="OrderNotesDrawer__body__noteBox" key={edge.node.id}>
                  <h5>{`${edge.node.author?.firstName} ${edge.node.author?.lastName}`}</h5>
                  <p>{edge.node.note}</p>
                  <span>
                    <label>Production</label>
                    {formatDateTime(edge.node.created)}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className="OrderNotesDrawer__buttons">
          <Button color="light" onClick={handleClose} variant="raised">
            Close
          </Button>
          {props.proceedToShipping && (
            <Button color="primary" onClick={props.onShippingConfirmation} variant="raised">
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};
