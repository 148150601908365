import { gql } from '@apollo/client';

export const DISPLAY_DETAIL_CREATE_MUTATION = gql`
  mutation displayDetailCreate($input: DisplayDetailInput) {
    displayDetailCreate(input: $input) {
      displayDetail {
        pk
      }
    }
  }
`;

export const DISPLAY_TEMPLATE_CREATE_MUTATION = gql`
  mutation displayTemplateCreate($input: TemplateInput) {
    displayTemplateCreate(input: $input) {
      template {
        pk
      }
    }
  }
`;

export const DISPLAY_DETAIL_UPDATE_MUTATION = gql`
  mutation displayDetailUpdate($pk: Int!, $input: DisplayDetailInput) {
    displayDetailUpdate(pk: $pk, input: $input) {
      displayDetail {
        pk
      }
    }
  }
`;

export const DISPLAY_TEMPLATE_UPDATE_MUTATION = gql`
  mutation displayTemplateUpdate($pk: UUID!, $input: TemplateInput) {
    displayTemplateUpdate(pk: $pk, input: $input) {
      template {
        pk
      }
    }
  }
`;

export const DISPLAY_DETAIL_DELETE_MUTATION = gql`
  mutation DisplayDetailDelete($pk: Int!) {
    displayDetailDelete(pk: $pk, confirm: true) {
      success
    }
  }
`;

export const DISPLAY_TEMPLATE_DELETE_MUTATION = gql`
  mutation DisplayTemplateDelete($pk: UUID!) {
    displayTemplateDelete(pk: $pk, confirm: true) {
      success
    }
  }
`;

export const DISPLAY_POSITION_DATA_CREATE = gql`
  mutation positionDataCreate($pk: Int, $data: JSONString!) {
    positionDataCreate(displayDetailPk: $pk, jsonData: $data) {
      positionData {
        id
        createdAt
        data
        pk
      }
    }
  }
`;

export const DISPLAY_LAYOUT_DATA_CREATE = gql`
  mutation layoutDataCreate($pk: Int, $data: JSONString!) {
    layoutDataCreate(displayDetailPk: $pk, jsonData: $data) {
      layoutData {
        id
        createdAt
        data
        pk
      }
    }
  }
`;

export const DISPLAY_DETAIL_ADD_PRODUCT_TO_ALL_MUTATION = gql`
  mutation displayDetailApplyProductToAll(
    $pk: Int!
    $productPk: Int!
    $section: Int
    $module: UUID
    $variables: JSONString
  ) {
    displayDetailApplyProductToAll(
      pk: $pk
      productPk: $productPk
      section: $section
      module: $module
      variables: $variables
    ) {
      displayDetail {
        pk
      }
    }
  }
`;

export const DISPLAY_DETAIL_APPLY_NAMELIST_MUTATION = gql`
  mutation displayDetailApplyNamelist($pk: Int!, $section: Int, $values: [String]!, $variableName: String!) {
    displayDetailApplyNamelist(pk: $pk, section: $section, values: $values, variableName: $variableName) {
      displayDetail {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_DETAIL_FINALIZE_MUTATION = gql`
  mutation displayMarkAsFinal($pk: Int!) {
    displayDetailFinalize(pk: $pk) {
      displayDetail {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_PANEL_CREATE_MUTATION = gql`
  mutation displayAddPanel($displayPk: Int, $templatePk: UUID) {
    displayPanelCreate(displayPk: $displayPk, templatePk: $templatePk) {
      displayModel {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_PANEL_CLONE_MUTATION = gql`
  mutation displayPanelClone($displayPk: Int, $templatePk: UUID, $panel: Int!) {
    displayPanelClone(displayPk: $displayPk, templatePk: $templatePk, panel: $panel) {
      displayModel {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_PANEL_DELETE_MUTATION = gql`
  mutation displayPanelDelete($displayPk: Int, $templatePk: UUID, $panel: Int!) {
    displayPanelDelete(displayPk: $displayPk, templatePk: $templatePk, panel: $panel) {
      displayModel {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_SECTION_CREATE_MUTATION = gql`
  mutation displaySectionCreate($displayPk: Int, $templatePk: UUID, $panel: Int!, $input: SectionInput) {
    displaySectionCreate(displayPk: $displayPk, templatePk: $templatePk, panel: $panel, input: $input) {
      displayModel {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_SECTION_DELETE_MUTATION = gql`
  mutation displaySectionDelete($displayPk: Int, $templatePk: UUID, $panel: Int!, $section: Int!) {
    displaySectionDelete(displayPk: $displayPk, templatePk: $templatePk, panel: $panel, section: $section) {
      displayModel {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_MODULE_CREATE_MUTATION = gql`
  mutation createDisplayModule($input: ModuleInput) {
    displayModuleCreate(input: $input) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_MODULE_UPDATE_MUTATION = gql`
  mutation displayModuleUpdate($pk: UUID!, $input: ModuleInput) {
    displayModuleUpdate(pk: $pk, input: $input) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_MODULE_CLONE_MUTATION = gql`
  mutation displayModuleClone($section: UUID!, $module: UUID!) {
    displayModuleClone(section: $section, module: $module) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_MODULE_DELETE_MUTATION = gql`
  mutation deleteModule($pk: UUID!) {
    displayModuleDelete(pk: $pk, confirm: true) {
      success
    }
  }
`;

export const DISPLAY_ROW_CREATE_MUTATION = gql`
  mutation displayModuleAddRow($modulePk: UUID!, $input: RowInput) {
    displayRowCreate(modulePk: $modulePk, input: $input) {
      displayModule {
        pk
      }
    }
  }
`;

export const DISPLAY_ROW_UPDATE_MUTATION = gql`
  mutation displayModuleEditRow($pk: UUID!, $row: Int!, $input: RowInput) {
    displayRowUpdate(modulePk: $pk, row: $row, input: $input) {
      displayModule {
        pk
      }
    }
  }
`;

export const DISPLAY_ROW_CLONE_MUTATION = gql`
  mutation displayRowClone($modulePk: UUID!, $row: Int!) {
    displayRowClone(modulePk: $modulePk, row: $row) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_ROW_DELETE_MUTATION = gql`
  mutation displayModuleDeleteRow($modulePk: UUID!, $row: Int!) {
    displayRowDelete(modulePk: $modulePk, row: $row) {
      displayModule {
        pk
      }
    }
  }
`;

export const DISPLAY_POSITION_CREATE_MUTATION = gql`
  mutation createPosition($modulePk: UUID!, $row: Int!, $input: PositionInput) {
    displayPositionCreate(modulePk: $modulePk, row: $row, input: $input) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_POSITION_UPDATE_MUTATION = gql`
  mutation updatePosition($modulePk: UUID!, $row: Int!, $position: Int!, $input: PositionInput) {
    displayPositionUpdate(modulePk: $modulePk, row: $row, position: $position, input: $input) {
      displayModule {
        id
        pk
      }
    }
  }
`;

export const DISPLAY_POSITION_DELETE_MUTATION = gql`
  mutation positionDelete($modulePk: UUID!, $row: Int!, $position: Int!) {
    displayPositionDelete(modulePk: $modulePk, row: $row, position: $position) {
      displayModule {
        id
        pk
      }
    }
  }
`;
