import { gql } from '@apollo/client';

export const ALL_PIECEWORKERS_QUERY = gql`
  query pieceworkers {
    pieceworkers {
      edges {
        node {
          id
          pk
          email
          firstName
          lastName
          availabilities {
            edges {
              node {
                id
                pk
                availabilityDatetime
                preferredTime
                notes
              }
            }
          }
        }
      }
    }
  }
`;

// Used to populate assignee dropdown in CreateJob route
export const AVAILABLE_PIECEWORKERS_QUERY = gql`
  query pieceworkers {
    pieceworkers(isAvailable: true) {
      edges {
        node {
          id
          pk
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const JOBS_QUERY = gql`
  query {
    jobs {
      edges {
        node {
          id
          pk
          jobNum
          assignee {
            id
            pk
            email
            firstName
            lastName
          }
          dueDate
          pickupDatetime
          status
        }
      }
    }
  }
`;

export const JOB_DETAIL_QUERY = gql`
  query job($id: ID!) {
    job(id: $id) {
      id
      pk
      jobNum
      assignee {
        id
        pk
        email
        firstName
        lastName
      }
      assignedBy {
        id
        pk
        email
        firstName
        lastName
      }
      totalPrice
      dueDate
      pickupDatetime
      pickedUpDatetime
      status
      tasks {
        edges {
          node {
            id
            pk
            created
            modified
            orderDetails {
              id
              pk
              quantity
              order {
                id
                pk
              }
              product {
                group {
                  name
                  productgrouptaskSet {
                    edges {
                      node {
                        id
                        pk
                        name
                        performanceOrder
                        alwaysRequired
                        laborPricePerPiece
                      }
                    }
                  }
                }
              }
            }
            splitOrder
            quantity
            returnedQuantity
            pricePerPiece
            subtotal
            approvalDatetime
            approvedBy {
              id
              pk
              email
              firstName
              lastName
            }
            assignedProductGroupTasks {
              edges {
                node {
                  id
                  pk
                  name
                  performanceOrder
                  alwaysRequired
                  laborPricePerPiece
                  materials {
                    edges {
                      node {
                        id
                        pk
                        material {
                          name
                          isTool
                        }
                        piecesRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const JOB_SUMMARY_QUERY = gql`
  query jobSummary($id: ID!) {
    job(id: $id) {
      id
      pk
      jobNum
      assignee {
        firstName
        lastName
      }
      pickupDatetime
      dueDate
      tasks {
        edges {
          node {
            id
            pk
            quantity
            orderDetails {
              id
              pk
              order {
                id
                pk
              }
              product {
                group {
                  prefix
                }
              }
            }
            assignedProductGroupTasks {
              edges {
                node {
                  id
                  pk
                  name
                  materials {
                    edges {
                      node {
                        id
                        pk
                        material {
                          name
                          isTool
                        }
                        piecesRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const JOB_TASK_ORDER_QUERY = gql`
  query orders($pkStartsWith: String) {
    orders(pkStartsWith: $pkStartsWith, pieceworkerAssignable: true) {
      edges {
        node {
          id
          pk
          account {
            name
          }
          orderDetails(pieceworkerAssignable: true) {
            edges {
              node {
                id
                pk
                order {
                  pk
                }
                product {
                  group {
                    name
                    productgrouptaskSet {
                      edges {
                        node {
                          id
                          pk
                          name
                          performanceOrder
                          alwaysRequired
                          laborPricePerPiece
                        }
                      }
                    }
                  }
                }
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export const PIECEWORK_ASSIGNABLE_GROUPS = gql`
  query pieceworkAssignableGroups {
    groups(active: true, pieceworkerAssignable: true) {
      edges {
        node {
          id
          pk
          prefix
          name
        }
      }
    }
  }
`;

export const PIECEWORKER_PROFILE_QUERY = gql`
  query pieceworkerProfile($id: ID!) {
    user(id: $id) {
      id
      pk
      firstName
      lastName
      email
      isActive
      profile {
        id
        pk
        phone
        address
        city
        state
        zip
        profilePicture {
          file
          type
          name
        }
      }
      availabilities {
        edges {
          node {
            id
            pk
            availabilityDatetime
            notes
          }
        }
      }
      assignedJobs {
        edges {
          node {
            id
            pk
            jobNum
            pickedUpDatetime
            returnedDatetime
            status
          }
        }
      }
      trainedGroups {
        edges {
          node {
            id
            pk
            group {
              id
              pk
              prefix
              name
            }
          }
        }
      }
    }
  }
`;

export const JOB_MATERIALS_QUERY = gql`
  query jobMaterials($jobPk: String!) {
    jobMaterials(jobPk: $jobPk) {
      material
      total
      units
    }
  }
`;
