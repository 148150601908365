import * as React from 'react';
import { useAuth } from '~/src/features/auth';
import { Toaster } from '~/src/features/toast';
import { AppRoutes } from '~/src/routes';
import { AdminLayout } from './AdminLayout';
import { PieceworkerLayout } from './PieceworkerLayout';

type AppLayoutProps = {};

export const AppLayout = (props: AppLayoutProps) => {
  const { user } = useAuth();

  return (
    <>
      {user && !user.isPieceworker && <AdminLayout />}
      {user && user.isPieceworker && <PieceworkerLayout />}
      {!user && (
        <>
          <Toaster />
          <AppRoutes />
        </>
      )}
    </>
  );
};
