import { useLazyQuery } from '@apollo/client';
import {
  ArrowTrendingUpIcon,
  ChartBarIcon,
  ChevronLeftIcon,
  MagnifyingGlassIcon,
  TableCellsIcon,
} from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, DatePicker, DebouncedInput, Pagination, Select, Spinner } from '~/src/components';
import { REP_ID_QUERY, REP_SALES_HISTORY_REPORT_QUERY } from '../../../api';
import { RepNav } from '../../../components';
import './RepSalesHistory.scss';

type RepSalesHistoryFilters = {
  search: string;
  productType: 'DISPLAYS' | 'PRODUCTS' | 'BOTH';
  timeFrame: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  page: number;
  pageSize: number;
};

const RepSalesHistoryFiltersDefaults: RepSalesHistoryFilters = {
  search: '',
  productType: 'DISPLAYS',
  timeFrame: 'all-time',
  startDate: undefined,
  endDate: undefined,
  page: 1,
  pageSize: 100,
};

export const RepSalesHistory = () => {
  const { repPk } = useParams();
  const [repId, setRepId] = React.useState('');
  const [activeFormat, setActiveFormat] = React.useState('table');
  const [searchInput, setSearchInput] = React.useState('');
  const [filters, setFilters] = React.useState(RepSalesHistoryFiltersDefaults);

  const [loadRepId, { data: repIdData }] = useLazyQuery(REP_ID_QUERY);
  const [loadSalesHistory, { data: salesHistoryData }] = useLazyQuery(REP_SALES_HISTORY_REPORT_QUERY);

  React.useEffect(() => {
    if (repPk) {
      !repId &&
        loadRepId({
          variables: { repPk: Number(repPk) },
          onCompleted: (data) => {
            setRepId(data.rep.id);
          },
        });

      let startDate = filters.startDate;
      if (filters.timeFrame === 'year-to-date') {
        const yearStart = new Date(new Date().getFullYear(), 0, 1);
        (!startDate || startDate < yearStart) && (startDate = yearStart);
      }
      loadSalesHistory({
        variables: {
          repPk: Number(repPk),
          search: filters.search,
          productType: filters.productType,
          startDate: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
          endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : undefined,
          first: filters.pageSize,
          offset: (filters.page - 1) * filters.pageSize,
        },
      });
    }
  }, [repId, filters]);

  if (!repIdData) return <Spinner message="Loading..." />;

  return (
    <div className="SalesHistory">
      <RepNav rep={repIdData?.rep || { rep: { pk: repPk } }} />
      <Container className="SalesHistory__container" style={{ padding: '24px' }}>
        <div className="SalesHistory__body">
          <div className="SalesHistory__backLink">
            <Link to={`/reps/${repPk}`} className="SalesHistory__backLink__link">
              <ChevronLeftIcon />
              <p>Sales History</p>
            </Link>
          </div>
          <div className="SalesHistory__navigation">
            <div className="SalesHistory__navigation__inputs">
              <DebouncedInput
                iconTrailing={<MagnifyingGlassIcon />}
                placeholder="Search by product/display"
                style={{ width: '300px' }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onDebounce={(search) => setFilters((prev) => ({ ...prev, search }))}
              />
              <Select
                value={filters.productType}
                onChange={(e) => setFilters((prev) => ({ ...prev, productType: e.target.value }))}
                options={[
                  { label: 'Display', value: 'DISPLAYS' },
                  { label: 'Non-Display', value: 'PRODUCTS' },
                ]}
              />
              <div className="SalesHistory__navigation__inputGroup">
                <p>Time frame</p>
                <Select
                  value={filters.timeFrame}
                  onChange={(e) => setFilters((prev) => ({ ...prev, timeFrame: e.target.value }))}
                  options={[
                    { label: 'All time', value: 'all-time' },
                    { label: 'Year-to-Date', value: 'year-to-date' },
                  ]}
                />
              </div>
              <div className="SalesHistory__navigation__inputGroup">
                <span className="flex align-center justify-between">
                  <p>Date range</p>
                  <a onClick={() => setFilters((prev) => ({ ...prev, startDate: undefined, endDate: undefined }))}>
                    Clear
                  </a>
                </span>
                <span className="SalesHistory__navigation__inputGroup__dateRange">
                  <DatePicker
                    value={filters.startDate}
                    onChange={(date) => setFilters((prev) => ({ ...prev, startDate: date }))}
                  />
                  <DatePicker
                    value={filters.endDate}
                    onChange={(date) => setFilters((prev) => ({ ...prev, endDate: date }))}
                  />
                </span>
              </div>
            </div>
            <ul className="SalesHistory__navigation__format">
              <li
                className={activeFormat === 'line' ? 'SalesHistory__navigation__activeFormatButton' : ''}
                onClick={() => setActiveFormat('line')}
              >
                <ArrowTrendingUpIcon />
              </li>
              <li
                className={activeFormat === 'bar' ? 'SalesHistory__navigation__activeFormatButton' : ''}
                onClick={() => setActiveFormat('bar')}
              >
                <ChartBarIcon />
              </li>
              <li
                className={activeFormat === 'table' ? 'SalesHistory__navigation__activeFormatButton' : ''}
                onClick={() => setActiveFormat('table')}
              >
                <TableCellsIcon />
              </li>
            </ul>
          </div>
          {!salesHistoryData ? (
            <Spinner message="Loading..." />
          ) : (
            <>
              <div className="SalesHistory__dataHeader">
                <div className="SalesHistory__dataHeader__title">
                  <div className="SalesHistory__dataHeader__title__symbol">
                    <ChartBarIcon />
                  </div>
                  <div>
                    <p>Total Sales</p>
                    <p>
                      {salesHistoryData.reportRepSalesHistory.totalSales &&
                        Number(salesHistoryData.reportRepSalesHistory.totalSales).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                    </p>
                  </div>
                </div>
                <div className="SalesHistory__dataHeader__column">
                  <span>
                    <p>Display:</p>
                    <p>
                      {salesHistoryData.reportRepSalesHistory.displaySales &&
                        Number(salesHistoryData.reportRepSalesHistory.displaySales).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                    </p>
                  </span>
                  <span>
                    <p>Non-display:</p>
                    <p>
                      {salesHistoryData.reportRepSalesHistory.nonDisplaySales &&
                        Number(salesHistoryData.reportRepSalesHistory.nonDisplaySales).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                    </p>
                  </span>
                </div>
                <div className="SalesHistory__dataHeader__column">
                  <span>
                    <p>Orders:</p>
                    <p>{salesHistoryData.reportRepSalesHistory.totalOrders}</p>
                  </span>
                  <span>
                    <p>Avg. Sale:</p>
                    <p>
                      {salesHistoryData.reportRepSalesHistory.averageSale &&
                        Number(salesHistoryData.reportRepSalesHistory.averageSale).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                    </p>
                  </span>
                </div>
              </div>
              {filters.productType === 'DISPLAYS' ? (
                <table className="data-table SalesHistory__displayTable">
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>account</th>
                      <th>list template</th>
                      <th>display type</th>
                      <th>label</th>
                      <th>featured products</th>
                      <th>start date</th>
                      <th>end date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesHistoryData.reportRepSalesHistory.edges
                      .map((edge: any) => edge.node.display)
                      .map((display: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/displays/instances/${display?.pk}`}>{display?.pk}</Link>
                          </td>
                          <td>
                            <Link to={`/accounts/${display?.account.pk}`}>{display?.account.pk}</Link>
                          </td>
                          <td>{display?.namelist?.name}</td>
                          <td>{display?.displayProduct?.name}</td>
                          <td>{display?.label ? display?.label : 'None'}</td>
                          <td>{display?.product?.name}</td>
                          <td>{display?.startDate && format(new Date(display?.startDate), 'M/d/yyyy')}</td>
                          <td>{display?.endDate && format(new Date(display?.endDate), 'M/d/yyyy')}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <table className="data-table SalesHistory__nonDisplayTable">
                  <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '10%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th></th>
                      <th>product #</th>
                      <th>description</th>
                      <th>product group</th>
                      <th>last ordered date</th>
                      <th>last shipped date</th>
                      <th>total quantity ordered</th>
                      <th>total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesHistoryData.reportRepSalesHistory.edges.map((edge: any, index: number) => (
                      <tr key={index}>
                        <td>
                          {edge.node.product &&
                            edge.node.product?.group &&
                            !edge.node.product?.group?.nonProduct && ( // product exists, has group, and not nonproduct group
                              <img
                                src={`https://staging.everyname.com/ops-api/images/${edge.node.product?.pk}/render/`}
                              />
                            )}
                        </td>
                        <td>
                          {!edge.node.product?.group || edge.node.product?.group?.nonProduct ? ( // no group or nonproduct group
                            <a>{edge.node.product?.name}</a>
                          ) : (
                            <Link to={`/products/${edge.node.product?.id}`}>{edge.node.product?.name}</Link>
                          )}
                        </td>
                        <td>{edge.node.product?.description}</td>
                        <td>{edge.node.product?.group?.name}</td>
                        <td>{edge.node.lastOrderedDate && format(new Date(edge.node.lastOrderedDate), 'M/d/yyyy')}</td>
                        <td>{edge.node.lastShippedDate && format(new Date(edge.node.lastShippedDate), 'M/d/yyyy')}</td>
                        <td>{edge.node.totalQuantityOrdered}</td>
                        <td>
                          {edge.node.total &&
                            Number(edge.node.total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <Pagination
                page={filters.page}
                pageSize={filters.pageSize}
                onPageChange={(page) => {
                  setFilters({ ...filters, page });
                }}
                hasNextPage={salesHistoryData?.reportRepSalesHistory.pageInfo.hasNextPage}
                totalNodes={salesHistoryData?.reportRepSalesHistory.totalNodes}
                totalNodesOnPage={salesHistoryData?.reportRepSalesHistory.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
