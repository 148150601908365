import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import { useAuth } from '~/src/features/auth';
import { adminRoutes } from './admin';
import { commonRoutes } from './common';
import { pieceworkerRoutes } from './pieceworker';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const { user } = useAuth();

  let routes: any[] = [];

  if (user) {
    if (user.isPieceworker) {
      routes = pieceworkerRoutes;
    } else {
      routes = adminRoutes;
    }
  } else {
    routes = publicRoutes;
  }

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
