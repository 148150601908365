import { ClipboardIcon, Cog6ToothIcon, MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import {
  Button,
  ButtonProps,
  Card,
  Checkbox,
  Container,
  DatePicker,
  Form,
  FormGroup,
  FormLabel,
  Input,
  InputProps,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Radio,
  Select,
  Spinner,
  TabItem,
  Tabs,
} from '~/src/components';
import { useToast } from '~/src/features/toast';
import './StyleGuide.scss';

export const StyleGuide = () => {
  const [activeTab, setActiveTab] = React.useState('Button');
  const [buttonOptions, setButtonOptions] = React.useState<ButtonProps>({
    color: undefined,
    disabled: false,
    fluid: false,
    iconLeading: null,
    iconTrailing: null,
    rounded: false,
    size: 'md',
    variant: 'default',
  });
  const [cardOptions, setCardOptions] = React.useState({
    fluid: false,
  });
  const [checkboxOptions, setCheckboxOptions] = React.useState({
    checked: false,
    disabled: false,
    label: 'Label',
    readOnly: false,
  });
  const [inputOptions, setInputOptions] = React.useState<InputProps>({
    disabled: false,
    filled: false,
    fluid: false,
    iconLeading: undefined,
    iconTrailing: undefined,
    inputSize: 'md',
    placeholder: 'Placeholder',
    readOnly: false,
  });
  const [modalOpen, setModalOpen] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [radioOptions, setRadioOptions] = React.useState({
    checked: false,
    disabled: false,
    label: 'Label',
    readOnly: false,
  });
  const [selectOptions, setSelectOptions] = React.useState({
    disabled: false,
    fluid: false,
    filled: false,
    options: [
      {
        label: '',
        value: '',
      },
      {
        label: 'Beef',
        value: 'beef',
      },
      {
        label: 'Pork',
        value: 'pork',
      },
      {
        label: 'Chicken',
        value: 'chicken',
      },
    ],
  });
  const [spinnerOptions, setSpinnerOptions] = React.useState({
    // filled: false,
    hasMessage: false,
  });
  const { success, error: errorToast, warning, info } = useToast();

  const tabs = [
    'Button',
    'Card',
    'Checkbox',
    'DatePicker',
    'Form',
    'Input',
    'Menu',
    'Modal',
    'Popover',
    'Radio',
    'Select',
    'Spinner',
    'Tabs',
    'Toaster',
  ];

  const handleBooleanOption = (getter: any, setter: any, field: any) => {
    return (e: any) => {
      setter((prev: any) => {
        return {
          ...prev,
          [field]: !getter[field],
        };
      });
    };
  };

  return (
    <div className="style-guide">
      <Container className="headspace">
        <Card fluid>
          <h1>Style Guide</h1>
          <Tabs>
            {tabs.map((tab, index) => (
              <TabItem
                key={index}
                active={activeTab === tab}
                onClick={() => {
                  setActiveTab(tab);
                }}
              >
                {tab}
              </TabItem>
            ))}
          </Tabs>
          {activeTab === 'Button' && (
            <>
              <h2>Button</h2>
              <div className="example">
                <div className="example-content">
                  <Button {...buttonOptions}>Button</Button>
                </div>
                <div className="example-options">
                  <FormLabel htmlFor="button-color">Color</FormLabel>
                  <Select
                    id="button-color"
                    fluid
                    className="example-options-select"
                    options={[
                      {
                        label: 'None',
                        value: null,
                      },
                      {
                        label: 'Primary',
                        value: 'primary',
                      },
                      {
                        label: 'Accent',
                        value: 'accent',
                      },
                      {
                        label: 'Warn',
                        value: 'warn',
                      },
                      {
                        label: 'Light',
                        value: 'light',
                      },
                      {
                        label: 'Dark',
                        value: 'dark',
                      },
                    ]}
                    value={buttonOptions.color}
                    onChange={(e) => {
                      setButtonOptions((prev) => {
                        return { ...prev, color: e.target.value };
                      });
                    }}
                  />
                  <FormLabel htmlFor="button-size">Size</FormLabel>
                  <Select
                    id="button-size"
                    fluid
                    className="example-options-select"
                    options={[
                      {
                        label: 'Small',
                        value: 'sm',
                      },
                      {
                        label: 'Medium',
                        value: 'md',
                      },
                      {
                        label: 'Large',
                        value: 'lg',
                      },
                    ]}
                    value={buttonOptions.size}
                    onChange={(e) => {
                      setButtonOptions((prev) => {
                        return { ...prev, size: e.target.value };
                      });
                    }}
                  />
                  <FormLabel htmlFor="button-size">Variant</FormLabel>
                  <Select
                    id="button-variant"
                    fluid
                    className="example-options-select"
                    options={[
                      {
                        label: 'Default',
                        value: 'default',
                      },
                      {
                        label: 'Raised',
                        value: 'raised',
                      },
                      {
                        label: 'Outlined',
                        value: 'outlined',
                      },
                    ]}
                    value={buttonOptions.variant}
                    onChange={(e) => {
                      setButtonOptions((prev) => {
                        return { ...prev, variant: e.target.value };
                      });
                    }}
                  />
                  <hr className="divider" />
                  <Checkbox
                    checked={buttonOptions.disabled}
                    label="Disabled"
                    onChange={handleBooleanOption(buttonOptions, setButtonOptions, 'disabled')}
                  />
                  <Checkbox
                    checked={buttonOptions.fluid}
                    label="Fluid"
                    onChange={handleBooleanOption(buttonOptions, setButtonOptions, 'fluid')}
                  />
                  <Checkbox
                    checked={!!buttonOptions.iconLeading}
                    label="Icon Leading"
                    onChange={(e) => {
                      setButtonOptions((prev) => {
                        return { ...prev, iconLeading: buttonOptions.iconLeading ? null : <MagnifyingGlassIcon /> };
                      });
                    }}
                  />
                  <Checkbox
                    checked={!!buttonOptions.iconTrailing}
                    label="Icon Trailing"
                    onChange={(e) => {
                      setButtonOptions((prev) => {
                        return { ...prev, iconTrailing: buttonOptions.iconTrailing ? null : <MagnifyingGlassIcon /> };
                      });
                    }}
                  />
                  <Checkbox
                    checked={buttonOptions.rounded}
                    label="Rounded"
                    onChange={handleBooleanOption(buttonOptions, setButtonOptions, 'rounded')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Card' && (
            <>
              <h2>Card</h2>
              <div className="example">
                <div className="example-content">
                  <Card fluid={cardOptions.fluid}>
                    <h4>Header</h4>
                    <p>Content</p>
                  </Card>
                </div>
                <div className="example-options">
                  <Checkbox
                    checked={cardOptions.fluid}
                    label="Fluid"
                    onChange={handleBooleanOption(cardOptions, setCardOptions, 'fluid')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Checkbox' && (
            <>
              <h2>Checkbox</h2>
              <div className="example">
                <div className="example-content">
                  <Checkbox onChange={() => {}} {...checkboxOptions} />
                </div>
                <div className="example-options">
                  <Checkbox
                    checked={checkboxOptions.checked}
                    label="Checked"
                    onChange={handleBooleanOption(checkboxOptions, setCheckboxOptions, 'checked')}
                  />
                  <Checkbox
                    checked={checkboxOptions.disabled}
                    label="Disabled"
                    onChange={handleBooleanOption(checkboxOptions, setCheckboxOptions, 'disabled')}
                  />
                  <Checkbox
                    checked={checkboxOptions.readOnly}
                    label="Read Only"
                    onChange={handleBooleanOption(checkboxOptions, setCheckboxOptions, 'readOnly')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'DatePicker' && (
            <>
              <h2>DatePicker</h2>
              <div className="example">
                <div className="example-content">
                  <DatePicker />
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Form' && (
            <>
              <h2>Form</h2>
              <div className="example">
                <div className="example-content">
                  <Form>
                    <FormGroup>
                      <FormLabel>Username</FormLabel>
                      <Input fluid />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Password</FormLabel>
                      <Input fluid type="password" />
                    </FormGroup>
                    <FormGroup className="text-right">
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </FormGroup>
                  </Form>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Input' && (
            <>
              <h2>Input</h2>
              <div className="example">
                <div className="example-content">
                  <Input {...inputOptions} />
                </div>
                <div className="example-options">
                  <FormLabel htmlFor="input-size">Size</FormLabel>
                  <Select
                    className="example-options-select"
                    fluid
                    id="input-size"
                    options={[
                      {
                        label: 'Small',
                        value: 'sm',
                      },
                      {
                        label: 'Medium',
                        value: 'md',
                      },
                      {
                        label: 'Large',
                        value: 'lg',
                      },
                    ]}
                    onChange={(e) => {
                      setInputOptions((prev) => {
                        return { ...prev, inputSize: e.target.value };
                      });
                    }}
                    value={inputOptions.inputSize}
                  />
                  <div className="divider"></div>
                  <Checkbox
                    checked={inputOptions.disabled}
                    label="Disabled"
                    onChange={handleBooleanOption(inputOptions, setInputOptions, 'disabled')}
                  />
                  <Checkbox
                    checked={inputOptions.filled}
                    label="Filled"
                    onChange={handleBooleanOption(inputOptions, setInputOptions, 'filled')}
                  />
                  <Checkbox
                    checked={inputOptions.fluid}
                    label="Fluid"
                    onChange={handleBooleanOption(inputOptions, setInputOptions, 'fluid')}
                  />
                  <Checkbox
                    checked={inputOptions.readOnly}
                    label="Read Only"
                    onChange={handleBooleanOption(inputOptions, setInputOptions, 'readOnly')}
                  />
                  <Checkbox
                    checked={!!inputOptions.iconLeading}
                    label="Icon Leading"
                    onChange={(e) => {
                      setInputOptions((prev) => {
                        return { ...prev, iconLeading: inputOptions.iconLeading ? undefined : <MagnifyingGlassIcon /> };
                      });
                    }}
                  />
                  <Checkbox
                    checked={!!inputOptions.iconTrailing}
                    label="Icon Trailing"
                    onChange={(e) => {
                      setInputOptions((prev) => {
                        return {
                          ...prev,
                          iconTrailing: inputOptions.iconTrailing ? undefined : <MagnifyingGlassIcon />,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Menu' && (
            <>
              <h2>Menu</h2>
              <div className="example">
                <div className="example-content">
                  <Menu>
                    <MenuItem iconLeading={<ClipboardIcon />}>Copy</MenuItem>
                    <MenuItem iconLeading={<PencilIcon />}>Edit</MenuItem>
                    <MenuItem iconLeading={<Cog6ToothIcon />}>Settings</MenuItem>
                    <div className="divider"></div>
                    <MenuItem color="warn" iconLeading={<TrashIcon />}>
                      Delete
                    </MenuItem>
                  </Menu>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Modal' && (
            <>
              <h2>Modal</h2>
              <div className="example">
                <div className="example-content">
                  <Button
                    color="primary"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  >
                    Open Modal
                  </Button>
                  <Modal
                    style={{
                      top: '5rem',
                      width: '480px',
                    }}
                    isOpen={modalOpen}
                    onClose={() => {
                      setModalOpen(false);
                    }}
                  >
                    <h1>Modal</h1>
                    <p>Content</p>
                  </Modal>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Popover' && (
            <>
              <h2>Popover</h2>
              <div className="example">
                <div className="example-content">
                  <div className="relative">
                    <Button
                      onClick={() => {
                        setPopoverOpen(true);
                      }}
                    >
                      Open Popover
                    </Button>
                    <Popover
                      isOpen={popoverOpen}
                      onClose={() => {
                        setPopoverOpen(false);
                      }}
                    >
                      <h4>Header</h4>
                      <p>Content</p>
                    </Popover>
                  </div>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Radio' && (
            <>
              <h2>Radio</h2>
              <div className="example">
                <div className="example-content">
                  <Radio {...radioOptions} value="" onChange={() => {}} />
                </div>
                <div className="example-options">
                  <Checkbox
                    checked={radioOptions.checked}
                    label="Checked"
                    onChange={handleBooleanOption(radioOptions, setRadioOptions, 'checked')}
                  />
                  <Checkbox
                    checked={radioOptions.disabled}
                    label="Disabled"
                    onChange={handleBooleanOption(radioOptions, setRadioOptions, 'disabled')}
                  />
                  <Checkbox
                    checked={radioOptions.readOnly}
                    label="Read Only"
                    onChange={handleBooleanOption(radioOptions, setRadioOptions, 'readOnly')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Select' && (
            <>
              <h2>Select</h2>
              <div className="example">
                <div className="example-content">
                  <Select {...selectOptions} />
                </div>
                <div className="example-options">
                  <Checkbox
                    checked={selectOptions.disabled}
                    label="Disabled"
                    onChange={handleBooleanOption(selectOptions, setSelectOptions, 'disabled')}
                  />
                  <Checkbox
                    checked={selectOptions.filled}
                    label="Filled"
                    onChange={handleBooleanOption(selectOptions, setSelectOptions, 'filled')}
                  />
                  <Checkbox
                    checked={selectOptions.fluid}
                    label="Fluid"
                    onChange={handleBooleanOption(selectOptions, setSelectOptions, 'fluid')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Spinner' && (
            <>
              <h2>Spinner</h2>
              <div className="example">
                <div className="example-content">
                  <Spinner message={spinnerOptions.hasMessage ? 'Fetching Updates...' : undefined} />
                </div>
                <div className="example-options">
                  <Checkbox
                    checked={spinnerOptions.hasMessage}
                    label="Message"
                    onChange={handleBooleanOption(spinnerOptions, setSpinnerOptions, 'hasMessage')}
                  />
                </div>
              </div>
            </>
          )}
          {activeTab === 'Tabs' && (
            <>
              <h2>Tabs</h2>
              <div className="example">
                <div className="example-content">
                  <Tabs>
                    <TabItem active>Tab 1</TabItem>
                    <TabItem>Tab 2</TabItem>
                    <TabItem>Tab 3</TabItem>
                  </Tabs>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
          {activeTab === 'Toaster' && (
            <>
              <h2>Toaster</h2>
              <p>Click to trigger toast</p>
              <div className="example">
                <div className="example-content">
                  <Button
                    onClick={() => {
                      info('Info');
                    }}
                  >
                    Info
                  </Button>
                  <Button
                    onClick={() => {
                      success('Success');
                    }}
                  >
                    Success
                  </Button>
                  <Button
                    onClick={() => {
                      warning('Warning');
                    }}
                  >
                    Warning
                  </Button>
                  <Button
                    onClick={() => {
                      errorToast('Error');
                    }}
                  >
                    Error
                  </Button>
                </div>
                <div className="example-options"></div>
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  );
};
