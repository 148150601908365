import * as React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_PIECEWORKERS_QUERY } from '../api';

export const usePieceworkers = () => {
  const [allPieceworkers, setAllPieceworkers] = React.useState([]);
  const [availablePieceworkers, setAvailablePieceworkers] = React.useState([]);

  const { loading } = useQuery(ALL_PIECEWORKERS_QUERY, {
    onCompleted: (data) => {
      setAllPieceworkers(data.pieceworkers.edges);
      setAvailablePieceworkers(
        data.pieceworkers.edges.filter((edge: any) => {
          return edge.node.availabilities.edges.length > 0;
        })
      );
    },
  });

  return {
    allPieceworkers,
    availablePieceworkers,
    pieceworkersLoading: loading,
  };
};
