import * as React from 'react';
import { Button, Modal } from '~/src/components';
import './ConfirmationModal.scss';

type ConfimrationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  title: string;
  message: string;
};

export const ConfirmationModal = (props: ConfimrationModalProps) => {
  function onCloseHandler() {
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={() => onCloseHandler()} className="DisplayModel__confirmationModal">
      <p className="DisplayModel__confirmationModal__title">{props.title}</p>
      <p className="DisplayModel__confirmationModal__message">{props.message}</p>
      <span>
        <Button variant="raised" color='light' onClick={() => onCloseHandler()}>
          Cancel
        </Button>
        <Button
          variant="raised"
          color="warn"
          onClick={() => {
            props.onSubmit?.();
            onCloseHandler();
          }}
        >
          Confirm
        </Button>
      </span>
    </Modal>
  );
};
