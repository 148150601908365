import { useLazyQuery } from '@apollo/client';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Container,
  DataTable,
  DatePicker,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  Spinner,
} from '~/src/components';
import { formatDateString, toFixed2 } from '~/src/utils/formatting';
import { ORDERS_QUERY } from '../../api';
import { CreateOrderDrawer } from '../../components';
import './Orders.scss';

type OrdersFilters = {
  orderDate_Gte: Date | undefined;
  orderDate_Lte: Date | undefined;
  page: number;
  pageSize: number;
  search: string;
  sort: string;
  status: string;
};

const defaultFilters: OrdersFilters = {
  orderDate_Gte: undefined,
  orderDate_Lte: undefined,
  page: 1,
  pageSize: 100,
  search: '',
  sort: '-id',
  status: '',
};

const tableHeaders = [
  { label: 'Invoice', sort: 'id', size: 'sm' },
  { label: 'Account', sort: 'account', size: 'md' },
  { label: 'City', sort: 'city', size: 'sm' },
  { label: 'State', sort: 'state', size: 'sm' },
  { label: 'Order Rep', sort: 'rep', size: 'md' },
  { label: 'PO Number', sort: 'po_number', size: 'md' },
  { label: 'Order Date', sort: 'order_date', size: 'sm' },
  { label: 'Ship Date', sort: 'ship_date', size: 'sm' },
  { label: 'Paid Date', sort: 'paid_date', size: 'sm' },
  { label: 'Invoice Total', sort: 'annotated_invoice_total', size: 'sm' },
  { label: 'Status', sort: 'status', size: 'sm' },
];

export const Orders = () => {
  const [searchInput, setSearchInput] = React.useState(defaultFilters.search);
  const [filters, setFilters] = React.useState(defaultFilters);

  const statusOptions = [
    '',
    'Awaiting Approval',
    'Ready to Ship',
    'Shipped',
    'In Production',
    'Paid',
    'Future Order',
    'Canceled',
    'Incomplete',
  ].map((status) => {
    return {
      label: status,
      value: status,
    };
  });

  const [createOrderOpen, setCreateOrderOpen] = React.useState(false);

  const [searchParams] = useSearchParams();
  const [searchOrders, { data, loading }] = useLazyQuery(ORDERS_QUERY, {
    variables: {
      first: filters.pageSize,
      offset: (filters.page - 1) * filters.pageSize,
      orderDate_Gte: filters.orderDate_Gte && format(filters.orderDate_Gte, 'yyyy-MM-dd'),
      orderDate_Lte: filters.orderDate_Lte && format(filters.orderDate_Lte, 'yyyy-MM-dd'),
      search: filters.search,
      sort: filters.sort,
      status: filters.status,
    },
  });

  React.useEffect(() => {
    const search = searchParams.get('search');
    if (!search) return;
    setSearchInput(search);
    setFilters((p) => ({ ...p, search }));
  }, [searchParams]);

  React.useEffect(() => {
    searchOrders();
  }, [filters]);

  function handleSearchDebounce(search: string) {
    setFilters((p) => ({ ...p, search, page: 1 }));
  }

  function setStatusCallback(e: React.ChangeEvent<HTMLInputElement>) {
    setFilters((p) => ({ ...p, status: e.target.value, page: 1 }));
  }

  function renderStatusBadge(status: string) {
    const COLOR_MAP: {
      [key: string]: string | undefined;
    } = {
      'Canceled': undefined,
      'Awaiting Approval': 'success',
      'Shipped': 'invertedSuccess',
      'Ready to Ship': 'invertedPurple',
      'Future Order': 'danger',
      'In Production': 'primary',
      'Paid': 'invertedSuccess',
      'Incomplete': 'invertedDanger',
    };

    return <Badge color={COLOR_MAP[status] as any} label={status} />;
  }

  function renderTableItems() {
    if (!data) return;
    return data.orders.edges.map((order: any, index: number) => {
      return (
        <tr key={index}>
          <td>
            <Link to={`/orders/${order.node.id}`}>{order.node.pk}</Link>
          </td>
          <td>{order.node.account?.name}</td>
          <td>{order.node.account?.city}</td>
          <td>{order.node.account?.state}</td>
          <td>
            {order.node.rep?.user.firstName} {order.node.rep?.user.lastName}
          </td>
          <td>{order.node.poNumber}</td>
          <td>{formatDateString(order.node.orderDate)}</td>
          <td>{formatDateString(order.node.shipDate)}</td>
          <td>{formatDateString(order.node.paidDate)}</td>
          <td>${toFixed2(order.node.invoiceTotal)}</td>
          <td>{renderStatusBadge(order.node.status)}</td>
        </tr>
      );
    });
  }

  return (
    <>
      <Nav />
      <Container className="Orders">
        <ListPageHeader title="View Orders" />
        <div className="Orders__filters">
          <DebouncedInput
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => setSearchInput(e.target.value)}
            onDebounce={(value) => handleSearchDebounce(value)}
            placeholder="Search"
            value={searchInput}
          />
          <div>
            <label>Order status</label>
            <Select options={statusOptions} onChange={setStatusCallback} />
          </div>
          <div>
            <label>Date range</label>
            <DatePicker
              onChange={(e) => setFilters((p) => ({ ...p, orderDate_Gte: e }))}
              value={filters.orderDate_Gte}
            />
          </div>
          <DatePicker value={filters.orderDate_Lte} onChange={(e) => setFilters((p) => ({ ...p, orderDate_Lte: e }))} />
          <div className="flex-1"></div>
          <Button color="primary" iconLeading={<PlusIcon />} onClick={() => setCreateOrderOpen(true)} variant="raised">
            New order
          </Button>
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading orders..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={filters.sort}
              onSortChange={(sort) => setFilters((p) => ({ ...p, sort, page: 1 }))}
            >
              {renderTableItems()}
            </DataTable>
            <Pagination
              page={filters.page}
              pageSize={filters.pageSize}
              onPageChange={(page) => setFilters((p) => ({ ...p, page }))}
              hasNextPage={data.orders?.pageInfo.hasNextPage}
              totalNodes={data.orders?.totalNodes}
              totalNodesOnPage={data.orders?.totalNodesOnPage}
            />
          </>
        )}
      </Container>
      <CreateOrderDrawer
        isOpen={createOrderOpen}
        onClose={() => {
          setCreateOrderOpen(false);
        }}
      />
    </>
  );
};
