import * as React from 'react';
import { useAuth } from '~/src/features/auth';
import { PieceworkerFooter, PieceworkerNav } from '~/src/features/pieceworker';
import { Toaster } from '~/src/features/toast';
import { AppRoutes } from '~/src/routes';
import './PieceworkerLayout.scss';

export const PieceworkerLayout = () => {
  const { user } = useAuth();

  return (
    <div className="PieceworkerLayout">
      <Toaster />
      {user && <PieceworkerNav />}
      <div className="PieceworkerLayout__routes">
        <AppRoutes />
      </div>
      {user && <PieceworkerFooter />}
    </div>
  );
};
