import { InputProps, SelectProps } from '~/src/components';

export type VariableInputType = (InputProps | SelectProps) & {
  label: string;
  name: string;
  type: 'break' | 'toggle' | 'select' | React.HTMLInputTypeAttribute;
  dependentOn?: { name: string; condition: 'truthy' | 'falsy'; value?: any };
  required?: boolean;
};

export const textInputs: VariableInputType[] = [
  { label: 'Layer name', name: 'name', type: 'text', maxLength: 30 },
  { label: 'Customer facing', name: 'customer_facing', type: 'toggle' },
  { label: 'Allow lowercase', name: 'allow_lowercase', type: 'toggle' },
  { label: 'Preview text', name: 'preview_text', type: 'text', required: true },
  { label: 'Savedown text', name: 'savedown_text', type: 'text' },
  { label: 'Text to show in catalog', name: 'catalog_text', type: 'text' },
  { label: '', name: '', type: 'break' },
  { label: 'Arced text', name: 'arced_text', type: 'toggle' },
  { label: 'Text height', name: 'height', type: 'number', max: '', required: true },
  { label: 'Width', name: 'width', type: 'number', required: true },
  { label: 'Rotation', name: 'text_rotation', type: 'number', dependentOn: { name: 'arced_text', condition: 'falsy' } },
  {
    label: 'Inner radius',
    name: 'radius',
    type: 'number',
    dependentOn: { name: 'arced_text', condition: 'truthy' },
    required: true
  },
  {
    label: 'Initial angle',
    name: 'angle_start',
    type: 'number',
    dependentOn: { name: 'arced_text', condition: 'truthy' },
    required: true
  },
  {
    label: 'Terminal angle',
    name: 'angle_end',
    type: 'number',
    dependentOn: { name: 'arced_text', condition: 'truthy' },
    required: true
  },
  {
    label: 'Orientation',
    name: 'orientation',
    type: 'select',
    options: [
      { label: '', value: '' },
      { label: 'clockwise', value: 'clockwise' },
      { label: 'anticlockwise', value: 'anticlockwise' },
    ],
    dependentOn: { name: 'arced_text', condition: 'truthy' },
    required: true
  },
  { label: '', name: '', type: 'break' },
  { label: 'Horizontal offset (center x)', name: 'anchor_x', type: 'number' },
  { label: 'Vertical offset (center y)', name: 'anchor_y', type: 'number' },
  {
    label: 'Font',
    name: 'font',
    type: 'select',
    options: [
      { label: 'Arial Unicode MS', value: 'Arial Unicode MS' },
      { label: 'BW-Aldine-Italics', value: 'BW-Aldine-Italics' },
      { label: 'BW-Aldine401-BT', value: 'BW-Aldine401-BT' },
      { label: 'BW-Amberland', value: 'BW-Amberland' },
      { label: 'BW-Arial', value: 'BW-Arial' },
      { label: 'BW-ArialRounded', value: 'BW-ArialRounded' },
      { label: 'BW-Berlin-Sans-FB', value: 'BW-Berlin-Sans-FB' },
      { label: 'BW-Berlin-Sans-FB-Demi', value: 'BW-Berlin-Sans-FB-Demi' },
      { label: 'BW-Book-Antiqua-Bold', value: 'BW-Book-Antiqua-Bold' },
      { label: 'BW-Compacta-Bd-BT', value: 'BW-Compacta-Bd-BT' },
      { label: 'BW-Corner', value: 'BW-Corner' },
      { label: 'BW-DomDiagonal Bd BT', value: 'BW-DomDiagonal Bd BT' },
      { label: 'BW-FlamencoD1994', value: 'BW-FlamencoD1994' },
      { label: 'BW-GeoSlabExtraBoldBT', value: 'BW-GeoSlabExtraBoldBT' },
      { label: 'BW-GoudyOldStyleBold', value: 'BW-GoudyOldStyleBold' },
      { label: 'BW-KabarettD', value: 'BW-KabarettD' },
      { label: 'BW-KabelUlt', value: 'BW-KabelUlt' },
      { label: 'BW-Mister-Earl-BT', value: 'BW-Mister-Earl-BT' },
      { label: 'BW-MyriadProB', value: 'BW-MyriadProB' },
      { label: 'BW-NewsGoth-XCn-BT', value: 'BW-NewsGoth-XCn-BT' },
      { label: 'BW-OldTowneNo536D', value: 'BW-OldTowneNo536D' },
      { label: 'BW-Penn', value: 'BW-Penn' },
      { label: 'BW-SerpentineDBol', value: 'BW-SerpentineDBol' },
      { label: 'BW-Stencil-BT', value: 'BW-Stencil-BT' },
      { label: 'BW-Trajan-Bold', value: 'BW-Trajan-Bold' },
      { label: 'BW-Weidemann', value: 'BW-Weidemann' },
      { label: 'Bw Distressed', value: 'Bw Distressed' },
      { label: 'C059', value: 'C059' },
      { label: 'D050000L', value: 'D050000L' },
      { label: 'DejaVu Sans', value: 'DejaVu Sans' },
      { label: 'DejaVu Sans Mono', value: 'DejaVu Sans Mono' },
      { label: 'DejaVu Serif', value: 'DejaVu Serif' },
      { label: 'Droid Sans Fallback', value: 'Droid Sans Fallback' },
      { label: 'Futura LtCn BT', value: 'Futura LtCn BT' },
      { label: 'GeoSlab703 XBd BT', value: 'GeoSlab703 XBd BT' },
      { label: 'Humanst531 UBlk BT', value: 'Humanst531 UBlk BT' },
      { label: 'Knoebels', value: 'Knoebels' },
      { label: 'Nimbus Mono PS', value: 'Nimbus Mono PS' },
      { label: 'Nimbus Roman', value: 'Nimbus Roman' },
      { label: 'Nimbus Sans', value: 'Nimbus Sans' },
      { label: 'Nimbus Sans Narrow', value: 'Nimbus Sans Narrow' },
      { label: 'Noto Mono', value: 'Noto Mono' },
      { label: 'Noto Sans Mono', value: 'Noto Sans Mono' },
      { label: 'P052', value: 'P052' },
      { label: 'Standard Symbols PS', value: 'Standard Symbols PS' },
      { label: 'URW Bookman', value: 'URW Bookman' },
      { label: 'URW Gothic', value: 'URW Gothic' },
      { label: 'Z003', value: 'Z003' },
    ],
  },
  {
    label: 'Alignment',
    name: 'alignment',
    type: 'select',
    options: [
      { label: 'center', value: 'center' },
      { label: 'left', value: 'left' },
      { label: 'right', value: 'right' },
    ],
  },
  { label: 'Text color', name: 'text_color', type: 'color' },
  { label: 'Stroke color', name: 'stroke_color', type: 'color' },
  { label: 'Stroke width', name: 'stroke_width', type: 'number' },
  { label: 'Shadow color', name: 'shadow_color', type: 'color' },
  { label: 'Horizontal shadow offset', name: 'shadow_x', type: 'number' },
  { label: 'Vertical shadow offset', name: 'shadow_y', type: 'number' },
];

export const imageInputs: any[] = [
  { label: 'Layer name', name: 'name', type: 'text', maxLength: 30 },
  { label: 'Customer facing', name: 'customer_facing', type: 'toggle' },
  { label: 'Image directory', name: 'base_dir', type: 'text' },
  { label: 'Default image', name: 'default_image', type: 'text' },
  { label: 'Horizontal offset (center x)', name: 'anchor_x', type: 'number' },
  { label: 'Vertical offset (center y)', name: 'anchor_y', type: 'number' },
];
