import * as React from 'react';

export type ToastType = {
  type: string;
  message: string;
  id: number;
};

type ToastContextType = {
  toasts: ToastType[];
  clickToast: (index: number) => void;
  success: (message: string) => void;
  error: (message: string) => void;
  warning: (message: string) => void;
  info: (message: string) => void;
};

export const ToastContext = React.createContext<ToastContextType | undefined>(undefined);

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = (props: ToastProviderProps) => {
  const [toasts, setToasts] = React.useState<ToastType[]>([]);
  const toastsRef = React.useRef(toasts);
  toastsRef.current = toasts;

  function toast(type: string) {
    return (message: string) => {
      const toastId = Date.now();
      setToasts((previousToasts: ToastType[]) => [
        ...previousToasts,
        {
          type,
          message,
          id: toastId,
        },
      ]);
      setTimeout(() => {
        removeToast(toastId);
      }, 5000);
    };
  }

  function removeToast(id: number) {
    setToasts(
      toastsRef.current.filter((toast) => {
        return toast.id !== id;
      })
    );
  }

  function clickToast(index: number) {
    let newArr = [...toasts];
    newArr.splice(index, 1);
    setToasts(newArr);
  }

  return (
    <ToastContext.Provider
      value={{
        toasts,
        clickToast,
        success: toast('success'),
        error: toast('error'),
        warning: toast('warning'),
        info: toast('info'),
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = React.useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
