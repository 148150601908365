import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { IconButton } from '~/src/components';
import './DropdownMenu.scss';

type DropdownMenuProps = {
  label?: string;
  children?: any;
};

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="Products__dropdownMenu">
      <div className="Products__dropdownMenu__title" onClick={() => setIsOpen((prev) => !prev)}>
        <p>{props.label}</p>
        {isOpen ? (
          <IconButton>
            <ChevronUpIcon />
          </IconButton>
        ) : (
          <IconButton>
            <ChevronDownIcon />
          </IconButton>
        )}
      </div>
      {isOpen && <div className="Products__dropdownMenu__menu">{props.children}</div>}
    </div>
  );
};
