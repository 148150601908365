import { gql } from '@apollo/client';

export const MY_PAYMENTS_YTD_QUERY = gql`
  query paymentsYTD {
    me {
      pieceworkPaymentLatest
      pieceworkPaymentYtd
    }
  }
`;

export const MY_AVAILABILITIES_QUERY = gql`
  query myAvailabilities {
    pieceworkerAvailabilities {
      edges {
        node {
          id
          pk
          availabilityDatetime
          preferredTime
          notes
        }
      }
    }
  }
`;

export const MY_AVAILABILITY_QUERY = gql`
  query myAvailability($id: ID!) {
    pieceworkerAvailability(id: $id) {
      id
      pk
      availabilityDatetime
      preferredTime
      notes
    }
  }
`;

export const MY_JOBS_QUERY = gql`
  query myJobs($isOpen: Boolean) {
    jobs(isOpen: $isOpen) {
      edges {
        node {
          id
          pk
          jobNum
          pickupDatetime
          dueDate
          returnedDatetime
        }
      }
    }
  }
`;

export const PIECEWORK_PAYMENTS_QUERY = gql`
  query pieceworkPayments($approvedDate_Gte: DateTime, $approvedDate_Lte: DateTime) {
    pieceworkPayments(approvedDate_Gte: $approvedDate_Gte, approvedDate_Lte: $approvedDate_Lte) {
      edges {
        node {
          id
          pk
          approvedDate
          paidDate
          amount
        }
      }
    }
  }
`;

export const JOB_DETAIL_QUERY = gql`
  query jobDetail($id: ID!) {
    job(id: $id) {
      id
      pk
      jobNum
      totalPrice
      pickedUpDatetime
      pickupDatetime
      dueDate
      tasks {
        edges {
          node {
            id
            pk
            created
            modified
            splitOrder
            quantity
            pricePerPiece
            subtotal
            orderDetails {
              id
              pk
              order {
                id
                pk
              }
              product {
                group {
                  prefix
                }
              }
            }
            assignedProductGroupTasks {
              edges {
                node {
                  id
                  pk
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
