import * as React from 'react';
import './App.scss';
import { AppLayout } from './layouts';
import { AppProvider } from './providers';

export const App = () => {
  return (
    <AppProvider>
      <AppLayout />
    </AppProvider>
  );
};
