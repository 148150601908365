import { useQuery } from '@apollo/client';
import * as React from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  Spinner,
  Toggle,
} from '~/src/components';
import { PRODUCT_GROUPS_QUERY } from '../../api';
import { DropdownMenu } from '../@shared/DropdownMenu';
import './FiltersDrawer.scss';

type FiltersDrawerPropsType = {
  isOpen: boolean;
  onClose: () => void;
  filters: FiltersType;
  onChange: (filters: FiltersType) => void;
};

type FiltersType = {
  groups: string[];
  cancelled: boolean;
  active: true | null;
};

const filtersDefaults: FiltersType = {
  groups: [],
  cancelled: false,
  active: true,
};

export const FiltersDrawer = (props: FiltersDrawerPropsType) => {
  const [filters, setFilters] = React.useState(filtersDefaults);
  const { data: groupsData, loading: groupsLoading } = useQuery(PRODUCT_GROUPS_QUERY);

  React.useEffect(() => {
    setFilters(props.filters);
  }, [props.filters, props.isOpen]);

  function onCloseHandler() {
    setFilters(filtersDefaults);
    props.onClose();
  }

  return (
    <Drawer backdrop className="ProductsList__filtersDrawer" isOpen={props.isOpen} onClose={onCloseHandler}>
      <DrawerHeader onClose={onCloseHandler} title="Product Filters" />
      <DrawerContent className="ProductsList__filtersDrawer__content">
        <DropdownMenu label="Product groups">
          <div className='ProductsList__filtersDrawer__content__viewAllToggle'>
            <Toggle
              label={'View all product groups'}
              checked={!filters.active}
              onChange={(e) => {
                setFilters((p) => ({ ...p, active: e.target.checked ? null : true }));
              }}
            />
          </div>
          {!groupsData || groupsLoading ? (
            <Spinner message="Loading product groups..." />
          ) : (
            groupsData.groups?.edges.map((edge: any, index: number) => (
              <Checkbox
                key={index}
                checked={filters.groups.includes(edge.node.pk)}
                label={`${edge.node.prefix} - ${edge.node.name}`}
                onChange={() => {
                  setFilters((prev) => ({
                    ...prev,
                    groups: filters.groups.includes(edge.node.pk)
                      ? filters.groups.filter((pk) => pk !== edge.node.pk)
                      : [...filters.groups, edge.node.pk],
                  }));
                }}
              />
            ))
          )}
        </DropdownMenu>
        <DropdownMenu label="Cancelled products">
          <Toggle
            checked={filters.cancelled}
            label="View cancelled products only"
            onChange={() => setFilters((prev) => ({ ...prev, cancelled: !filters.cancelled }))}
          />
        </DropdownMenu>
      </DrawerContent>
      <DrawerActions>
        <div className="flex-1"></div>
        <Button color="light" onClick={() => setFilters(props.filters)} variant="raised">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            props.filters.groups.every((item) => filters.groups.includes(item)) &&
            filters.groups.every((item) => props.filters.groups.includes(item)) &&
            filters.cancelled === props.filters.cancelled &&
            filters.active === props.filters.active
          }
          onClick={() => {
            props.onChange(filters);
          }}
          variant="raised"
        >
          Apply
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
