import * as React from 'react';
import {
  Badge,
  Button,
  DatePicker,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  Select,
  Textarea,
} from '~/src/components';
import { formatDateString } from '~/src/utils/formatting';
import './BillingCollectionDrawer.scss';

type BillingCollectionDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddNote: (data: any) => void;
  collectionsData?: any;
  orders?: any;
};

const BillingCollectionDefaults = {
  order: '',
  actionNotes: '',
  deNextActiondate: undefined,
};

export const BillingCollectionDrawer = (props: BillingCollectionDrawerProps) => {
  const [addDetailsForm, setAddDetailsForm] = React.useState<{
    order: string;
    actionNotes: string;
    deNextActiondate: Date | undefined;
  }>(BillingCollectionDefaults);

  function onAddHandler() {
    props.onAddNote({
      ...addDetailsForm,
      deNextActiondate: dateStringer(addDetailsForm.deNextActiondate),
    });
    setAddDetailsForm(BillingCollectionDefaults);
  }

  function onCloseHandler() {
    setAddDetailsForm(BillingCollectionDefaults);
    props.onClose();
  }

  function datetimeFormatter(string: string) {
    const date = new Date(string);
    const correctedHours = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
    // MM/dd/yyyy hh/mm AM
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${correctedHours}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
  }

  function dateStringer(date: Date | undefined) {
    if (!date) return;
    let year = date.getFullYear();
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function collectionNotes() {
    if (!props.collectionsData) return;
    return props.collectionsData.collectionDetails?.edges
      .slice()
      .sort((edge1: any, edge2: any) => {
        // sort edges by most recently added
        const date1 = new Date(edge1.node.deDatetime);
        const date2 = new Date(edge2.node.deDatetime);
        return date1 > date2 ? -1 : date2 > date1 ? 1 : 0;
      })
      .map((edge: any, index: number) => (
        <div className="BillingCollectionDrawer__note" key={index}>
          <div className="BillingCollectionDrawer__note__header">
            <p className="font-medium">{edge.node?.order?.pk}</p>
            <p>{datetimeFormatter(edge.node?.deDatetime)}</p>
          </div>
          <div className="BillingCollectionDrawer__note__body">
            <p>{edge.node?.actionNotes}</p>
            <div className="flex justify-end">
              {edge.node?.deNextActiondate && (
                <Badge color="light" label={formatDateString(edge.node?.deNextActiondate)} />
              )}
            </div>
          </div>
        </div>
      ));
  }

  return (
    <Drawer
      className="BillingCollectionDrawer"
      isOpen={props.isOpen}
      onClose={onCloseHandler}
      style={{ width: '896px' }}
    >
      <DrawerHeader onClose={onCloseHandler} title="Collection Details" />
      <DrawerContent className="BillingCollectionDrawer__content">
        <div className="BillingCollectionDrawer__left">
          <p className="font-medium">Add Details</p>
          <hr className="divider" />
          <div className="BillingCollectionDrawer__field">
            <label>Invoice #</label>
            <Select
              value={addDetailsForm.order}
              options={[
                { value: '', label: '' },
                ...(props.orders?.edges?.map((edge: any) => ({
                  value: edge.node.pk,
                  label: edge.node.pk,
                })) || []),
              ]}
              onChange={(e) => setAddDetailsForm((prev) => ({ ...prev, order: e.target.value }))}
            ></Select>
          </div>
          <div className="BillingCollectionDrawer__field">
            <label>Action notes</label>
            <Textarea
              onChange={(e) => setAddDetailsForm((prev) => ({ ...prev, actionNotes: e.target.value }))}
              value={addDetailsForm.actionNotes}
            />
          </div>
          <div className="BillingCollectionDrawer__field">
            <label>Follow-up date</label>
            <DatePicker
              value={addDetailsForm.deNextActiondate || undefined}
              onChange={(date) => date && setAddDetailsForm((prev) => ({ ...prev, deNextActiondate: date }))}
            />
          </div>
          <div className="flex justify-end">
            <Button color="primary" onClick={onAddHandler} disabled={!addDetailsForm.order} variant="raised">
              Create note
            </Button>
          </div>
        </div>
        <div className="BillingCollectionDrawer__right">{collectionNotes()}</div>
      </DrawerContent>
      <DrawerActions>
        <Button color="light" onClick={onCloseHandler} variant="raised">
          Close
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
