import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { groupBy } from 'lodash';
import * as React from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  IconButton,
  Input,
  SearchPopover,
  Select,
  Toggle,
} from '~/src/components';
import { JOB_TASK_ORDER_QUERY } from '../../api';
import './JobTaskDrawer.scss';

type JobTaskDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (taskData: any) => void;
  onRemove: () => void;
  initialTask?: any;
};

type TaskState = {
  orderDetails: any;
  splitOrder: boolean;
  quantity: number;
  assignedProductGroupTasks: any[];
};

const initialState: TaskState = {
  orderDetails: null,
  splitOrder: false,
  quantity: 0,
  assignedProductGroupTasks: [],
};

export const JobTaskDrawer = (props: JobTaskDrawerProps) => {
  const [order, setOrder] = React.useState<any>(null);
  const [task, setTask] = React.useState<TaskState>(initialState);
  const [orderIdPopoverOpen, setOrderIdPopoverOpen] = React.useState(false);
  const [pricesExpanded, setPricesExpanded] = React.useState(true);
  const [confirmRemove, setConfirmRemove] = React.useState(false);

  // Reset the state any time the drawer is opened
  React.useEffect(() => {
    if (props.isOpen && !props.initialTask) {
      setTask(initialState);
    } else if (props.isOpen && props.initialTask) {
      setTask({
        orderDetails: props.initialTask.orderDetails,
        splitOrder: props.initialTask.splitOrder,
        quantity: props.initialTask.quantity || 0,
        assignedProductGroupTasks: props.initialTask.assignedProductGroupTasks.map((t: any) => t.pk),
      });
    }
    setOrder(null);
    setOrderIdPopoverOpen(false);
    setPricesExpanded(true);
    setConfirmRemove(false);
  }, [props.isOpen]);

  // Set split order to false, and quantity to order detail quantity
  // any time order detail changes
  React.useEffect(() => {
    if (!task.orderDetails) {
      return;
    }
    setTask({
      ...task,
      splitOrder: false,
      quantity: task.orderDetails.quantity || 0,
    });
  }, [task.orderDetails]);

  function showTasks() {
    return task.orderDetails;
  }

  function orderDetailsOptions() {
    if (!order) {
      return [
        {
          label: '',
          value: '',
        },
      ];
    }
    return order.orderDetails.edges.map((edge: any) => {
      return {
        label: `${edge.node.pk} - ${edge.node.product.group.name}`,
        value: JSON.stringify(edge.node),
      };
    });
  }

  function calcPerUnitTotal() {
    let total = 0;
    task.assignedProductGroupTasks.forEach((taskId) => {
      const t = task.orderDetails?.product.group.productgrouptaskSet.edges
        .map((edge: any) => edge.node)
        .find((pgt: any) => {
          return pgt.pk === taskId;
        });
      total += parseFloat(t.laborPricePerPiece);
    });
    return total;
  }

  function calcSubTotal() {
    return task.quantity * calcPerUnitTotal();
  }

  function clickAdd() {
    let taskData = {
      orderDetails: task.orderDetails,
      splitOrder: task.splitOrder,
      quantity: task.quantity,
      pricePerPiece: calcPerUnitTotal(),
      assignedProductGroupTasks: task.assignedProductGroupTasks.map((taskId) =>
        task.orderDetails?.product.group.productgrouptaskSet.edges
          .map((edge: any) => edge.node)
          .find((pgt: any) => {
            return pgt.pk === taskId;
          })
      ),
    };
    props.onSubmit(taskData);
  }

  function clickRemove() {
    setConfirmRemove(true);
  }

  function clickConfirmRemove() {
    props.onRemove();
  }

  function groupByPerformanceOrder(taskSet: any[]) {
    return groupBy(taskSet, (task) => {
      return task.performanceOrder;
    });
  }

  function commonString(strings: string[]) {
    const wordArrays = strings.map((str) => str.split(' '));
    const commonWords = wordArrays.reduce((intersection, array) => {
      return intersection.filter((word) => array.includes(word));
    });
    return commonWords[0] || null;
  }

  function joinStrings(strings: string[]) {
    return strings.join(' / ');
  }

  function hasOneSelectedTask() {
    return task.assignedProductGroupTasks.length > 0;
  }

  function renderTaskGroup(taskGroup: any[]) {
    if (taskGroup.length === 1) {
      return (
        <div className="JobTaskDrawer__toggleField">
          <label>{taskGroup[0].name}</label>
          <Checkbox
            checked={task.assignedProductGroupTasks.includes(taskGroup[0].pk)}
            onChange={() => {
              if (task.assignedProductGroupTasks.includes(taskGroup[0].pk)) {
                setTask({
                  ...task,
                  assignedProductGroupTasks: task.assignedProductGroupTasks.filter((i) => i !== taskGroup[0].pk),
                });
                return;
              }
              setTask({
                ...task,
                assignedProductGroupTasks: [...task.assignedProductGroupTasks, taskGroup[0].pk],
              });
            }}
          />
        </div>
      );
    }
    const selectOptions = [
      {
        label: 'None',
        value: '',
      },
      ...taskGroup.map((group: any) => {
        return {
          label: `${group.name}`,
          value: `${group.pk}`,
        };
      }),
    ];
    const selectLabels = selectOptions.filter((so) => so.label !== 'None').map((so) => so.label);
    const outerLabel = commonString(selectLabels) || joinStrings(selectLabels);
    return (
      <div className="JobTaskDrawer__inputField">
        <label>{outerLabel}</label>
        <Select
          options={selectOptions}
          style={{ width: '224px' }}
          onChange={(e) => {
            const selectValues = selectOptions.map((so) => parseInt(so.value));
            const filteredOther = task.assignedProductGroupTasks.filter((i) => !selectValues.includes(i));
            // if value is empty string (blank option), remove all of the select values from selectedTaskIds
            if (e.target.value === '') {
              setTask({
                ...task,
                assignedProductGroupTasks: [...filteredOther],
              });
              return;
            }
            // otherwise, remove other ids from the selectedTaskIds, and add the current value
            setTask({
              ...task,
              assignedProductGroupTasks: [...filteredOther, parseInt(e.target.value)],
            });
          }}
        />
      </div>
    );
  }

  function renderProductGroupTasks() {
    const groupedByOrder = groupByPerformanceOrder(
      task.orderDetails?.product.group.productgrouptaskSet.edges.map((edge: any) => edge.node)
    );
    return (
      <>
        {Object.keys(groupedByOrder).map((key: string, idx) => {
          return <div key={idx}>{renderTaskGroup(groupedByOrder[key])}</div>;
        })}
      </>
    );
  }

  function renderPrices() {
    return (
      <div className="JobTaskDrawer__prices">
        <IconButton
          className="JobTaskDrawer__prices__expand"
          onClick={() => {
            setPricesExpanded(!pricesExpanded);
          }}
        >
          {pricesExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </IconButton>
        {pricesExpanded && (
          <div className="JobTaskDrawer__prices__expanded">
            {task.assignedProductGroupTasks.map((taskId: number, index: number) => {
              const t = task.orderDetails?.product.group.productgrouptaskSet.edges
                .map((edge: any) => edge.node)
                .find((pgt: any) => {
                  return pgt.pk === taskId;
                });
              return (
                <div className="JobTaskDrawer__prices__row" key={index}>
                  <label>{t.name}</label>
                  <p className="value">
                    {parseFloat(t.laborPricePerPiece).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </p>
                </div>
              );
            })}
            <div className="JobTaskDrawer__prices__row">
              <label>Per Unit Sub-total</label>
              <p className="value">
                {calcPerUnitTotal().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </p>
            </div>
          </div>
        )}
        <div className="JobTaskDrawer__prices__subtotal">
          <label>Sub-total</label>
          <p className="value">{calcSubTotal().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        </div>
      </div>
    );
  }

  return (
    <Drawer backdrop className="JobTaskDrawer" isOpen={props.isOpen} onClose={props.onClose} style={{ width: '504px' }}>
      <DrawerHeader onClose={props.onClose} title="Select tasks" />
      <DrawerContent className="JobTaskDrawer__content">
        <div className="JobTaskDrawer__inputField">
          <label>Invoice #</label>
          {props.initialTask && <Input disabled value={task.orderDetails?.order.pk || ''} />}
          {!props.initialTask && (
            <div className="relative">
              <Input
                iconTrailing={<MagnifyingGlassIcon />}
                onClick={() => {
                  setOrderIdPopoverOpen(true);
                }}
                readOnly
                style={{
                  width: '224px',
                }}
                value={order?.pk || ''}
              />
              <SearchPopover
                isOpen={orderIdPopoverOpen}
                onClose={() => {
                  setOrderIdPopoverOpen(false);
                }}
                onChange={(order) => {
                  setOrder(order);
                  setTask({
                    ...task,
                    orderDetails: order.orderDetails.edges[0].node,
                  });
                }}
                popoverStyle={{
                  maxWidth: '300px',
                  right: '0',
                }}
                query={JOB_TASK_ORDER_QUERY}
                queryArgument="pkStartsWith"
                queryField="orders"
                renderMatch={(match) => (
                  <>
                    {match.pk} - {match.account.name}
                  </>
                )}
                searchLabel="Search invoices"
              />
            </div>
          )}
        </div>
        <div className="JobTaskDrawer__inputField">
          <label>Order Detail #</label>
          {props.initialTask && <Input disabled value={task.orderDetails?.pk || ''} />}
          {!props.initialTask && (
            <Select
              options={orderDetailsOptions()}
              value={JSON.stringify(task.orderDetails)}
              onChange={(e) => {
                setTask({
                  ...task,
                  orderDetails: JSON.parse(e.target.value),
                });
              }}
              style={{
                width: '224px',
              }}
            />
          )}
        </div>
        {showTasks() && (
          <>
            <div className="JobTaskDrawer__toggleField">
              <label>Split Order</label>
              <Toggle
                checked={task.splitOrder}
                onChange={() => {
                  setTask({
                    ...task,
                    splitOrder: !task.splitOrder,
                  });
                }}
              />
            </div>
            <div className="JobTaskDrawer__inputField">
              <label>Quantity</label>
              <Input
                type="number"
                max={task.orderDetails?.quantity || 0}
                min={!task.orderDetails?.quantity ? 0 : 1}
                value={task.quantity}
                disabled={!task.splitOrder}
                onChange={(e) => {
                  setTask({
                    ...task,
                    quantity: parseInt(e.target.value),
                  });
                }}
                style={{
                  width: '160px',
                }}
              />
            </div>
            <hr className="divider" />
            {renderProductGroupTasks()}
          </>
        )}
      </DrawerContent>
      {hasOneSelectedTask() && <>{renderPrices()}</>}
      {confirmRemove && (
        <DrawerActions>
          <p className="m-0">
            <strong>Are you sure?</strong>
          </p>
          <div className="flex-1"></div>
          <Button
            onClick={() => {
              setConfirmRemove(false);
            }}
          >
            Cancel
          </Button>
          <Button color="warn" onClick={clickConfirmRemove} variant="raised">
            Remove Task
          </Button>
        </DrawerActions>
      )}
      {!confirmRemove && (
        <DrawerActions>
          {props.initialTask && (
            <Button color="warn" onClick={clickRemove}>
              Remove
            </Button>
          )}
          <div className="flex-1"></div>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button color="primary" disabled={!hasOneSelectedTask()} onClick={clickAdd} variant="raised">
            Add to Job
          </Button>
        </DrawerActions>
      )}
    </Drawer>
  );
};
