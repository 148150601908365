import { useLazyQuery, useMutation } from '@apollo/client';
import * as React from 'react';
import { Button, DatePicker, Input, Modal, Textarea } from '~/src/components';
import { csvToJson, jsonToCsv } from '~/src/utils/csv';
import { DISPLAY_DETAIL_QUERY, DISPLAY_LAYOUT_DATA_CREATE, DISPLAY_POSITION_DATA_CREATE } from '../../api/';
import { NamelistSearchPopover } from '../NamelistSearchPopover';
import { ProductSearchPopover } from '../ProductSearchPopover';
import './EditDisplayModal.scss';

type EditDisplayModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  displayPk?: any;
  accountId: any;
};

export type EditDisplayModalData = {
  displayProduct?: number;
  namelist?: number;
  product?: number;
  productSecondary?: number;
  label?: string;
  notes?: string;
  startDate?: Date;
  endDate?: Date;
  positionData?: any;
  layoutData?: any;
};

const EditDisplayModalDefaults: EditDisplayModalData = {
  displayProduct: undefined,
  namelist: undefined,
  product: undefined,
  productSecondary: undefined,
  label: '',
  notes: '',
  startDate: undefined,
  endDate: undefined,
  positionData: [],
  layoutData: [],
};

export const EditDisplayModal = (props: EditDisplayModalProps) => {
  const [display, setDisplay] = React.useState(EditDisplayModalDefaults);
  const [loadDisplayDetails, { data }] = useLazyQuery(DISPLAY_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (!res) return;
      setDisplay((prev) => ({
        ...prev,
        displayProduct: res.displayDetail?.displayProduct?.pk,
        namelist: res.displayDetail?.namelist?.pk,
        product: res.displayDetail?.product?.pk,
        productSecondary: res.displayDetail?.productSecondary?.pk,
        startDate: timezoneAdjuster(res.displayDetail?.startDate),
        endDate: timezoneAdjuster(res.displayDetail?.endDate),
        positionData: res.displayDetail?.positionData ? JSON.parse(res.displayDetail.positionData) : undefined,
        layoutData: res.displayDetail?.layoutData ? JSON.parse(res.displayDetail.layoutData) : undefined,
      }));
    },
  });
  const [createPositionData] = useMutation(DISPLAY_POSITION_DATA_CREATE, {
    onCompleted: () => loadDisplayDetails({ variables: { pk: props.displayPk } }),
  });
  const [createLayoutData] = useMutation(DISPLAY_LAYOUT_DATA_CREATE, {
    onCompleted: () => loadDisplayDetails({ variables: { pk: props.displayPk } }),
  });

  React.useEffect(() => {
    if (props.displayPk && props.isOpen) {
      loadDisplayDetails({ variables: { pk: props.displayPk } });
    }
  }, [props.displayPk]);

  function timezoneAdjuster(dateString: string | null) {
    if (!dateString) return undefined;
    let date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }

  function dateStringer(date: Date | undefined) {
    if (!date) return;
    let year = date.getFullYear();
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function onCloseHandler() {
    setDisplay(EditDisplayModalDefaults);
    props.onClose();
  }

  function onSubmitHandler() {
    const input = {
      ...display,
      startDate: dateStringer(display.startDate),
      endDate: dateStringer(display.endDate),
      account: props.accountId,
    };
    props.onSave({ pk: props.displayPk, input });
    onCloseHandler();
  }

  function downloadJson(data: any, fileName: string) {
    if (display.positionData?.length) {
      const blob = new Blob([data], { type: 'text/csv' });
      const a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={onCloseHandler}
      style={{ padding: '0px', maxWidth: '700px' }}
      className="EditDisplayModal"
    >
      <div className="EditDisplayModal__header">
        <h3>{props.displayPk ? 'Edit' : 'Add'} Display</h3>
      </div>
      <div className="EditDisplayModal__content">
        <div className="EditDisplayModal__content__field">
          <label htmlFor="type">Type</label>
          <ProductSearchPopover
            product={data?.displayDetail?.displayProduct}
            onChange={(product) => setDisplay((prev) => ({ ...prev, displayProduct: product.pk }))}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="listTemplate">List template</label>
          <NamelistSearchPopover
            namelist={data?.displayDetail?.namelist}
            onChange={(namelist: any) => setDisplay((prev) => ({ ...prev, namelist: namelist.pk }))}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="featuredProduct1">Featured Product 1</label>
          <ProductSearchPopover
            product={data?.displayDetail?.product}
            onChange={(product) => setDisplay((prev) => ({ ...prev, product: product.pk }))}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="featuredProduct2">Featured Product 2</label>
          <ProductSearchPopover
            product={data?.displayDetail?.productSecondary}
            onChange={(product) => setDisplay((prev) => ({ ...prev, productSecondary: product.pk }))}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="label">Label</label>
          <Input
            placeholder="Label"
            value={display.label}
            onChange={(e) => setDisplay((prev) => ({ ...prev, label: e.target.value }))}
          />
          {/* <Toggle></Toggle> */}
        </div>
        <div className="EditDisplayModal__content__field EditDisplayModal__content__field--notes">
          <label htmlFor="notes">Notes</label>
          <Textarea
            fluid
            className="EditDisplayModal__content__field--textboxNoResize"
            name="notes"
            onChange={(e) => setDisplay((prev) => ({ ...prev, notes: e.target.value }))}
            value={display.notes}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="startDate">Start Date</label>
          <DatePicker
            value={display.startDate || undefined}
            onChange={(date) => setDisplay((prev) => ({ ...prev, startDate: date }))}
            style={{ bottom: '100%' }}
          />
        </div>
        <div className="EditDisplayModal__content__field">
          <label htmlFor="endDate">End Date</label>
          <DatePicker
            value={display.endDate || undefined}
            onChange={(date) => setDisplay((prev) => ({ ...prev, endDate: date }))}
            style={{ bottom: '100%' }}
          />
        </div>
      </div>
      {/* <div className="EditDisplayModal__content__divider"></div>
      <div className="EditDisplayModal__content">
        <div className="EditDisplayModal__content__field">
          <label>Import Position Data</label>
          <div className="EditDisplayModal__content__field__importFile">
            <label htmlFor="importPositionData" className="Button--variant--raised Button--color--primary">
              Select a file
            </label>
            <input
              name="importPositionData"
              id="importPositionData"
              type="file"
              accept=".csv"
              onChange={(e) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  createPositionData({
                    variables: { data: JSON.stringify(csvToJson(e.target.result)), pk: props.displayPk },
                  });
                };
                e.target.files?.length && reader.readAsBinaryString(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="EditDisplayModal__content__field">
          <label>Export Position Data</label>
          <Button
            onClick={() => {
              if (Array.isArray(display.positionData)) {
                downloadJson(jsonToCsv(display.positionData), 'positiondata.csv');
              }
            }}
            variant="outlined"
          >
            Export
          </Button>
        </div>
      </div>
      <div className="EditDisplayModal__content__divider"></div>

      <div className="EditDisplayModal__content">
        <div className="EditDisplayModal__content__field">
          <label>Import Layout Data</label>
          <div className="EditDisplayModal__content__field__importFile">
            <label htmlFor="importLayoutData" className="Button--variant--outlined Button--color--primary">
              Select a file
            </label>
            <input
              name="importLayoutData"
              id="importLayoutData"
              type="file"
              accept=".csv"
              onChange={(e) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  createLayoutData({
                    variables: {
                      // data: JSON.stringify(csvToJson(e.target.result)),
                      data: '{"sections": 10, "panels": 10}',
                      pk: props.displayPk,
                    },
                  });
                };
                e.target.files?.length && reader.readAsBinaryString(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <div className="EditDisplayModal__content__field">
          <label>Export Layout Data</label>
          <Button
            onClick={() => {
              // display.layoutData?.length && downloadJson(jsonToCsv(display.layoutData), 'layoutdata.csv')
            }}
            variant="outlined"
          >
            Export
          </Button>
        </div>
      </div> */}
      <div className="EditDisplayModal__buttons">
        <Button color="light" onClick={onCloseHandler} variant="raised">
          Close
        </Button>
        <Button color="primary" onClick={onSubmitHandler} variant="raised">
          Save
        </Button>
      </div>
    </Modal>
  );
};
