import { gql } from '@apollo/client';

export const SEND_PASSWORD_RESET_MUTATION = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
    }
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
    }
  }
`;

export const TOKEN_AUTH_MUTATION = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      token
      user {
        id
        pk
        username
        email
        firstName
        lastName
        dateJoined
        lastLogin
        isPieceworker
        isPieceworkCoordinator
        profile {
          id
          pk
          initials
          phone
          fax
          cell
          address
          city
          state
          zip
          profilePicture {
            file
            type
            name
          }
        }
      }
    }
  }
`;
