import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ChevronDownIcon, EnvelopeIcon, PhoneIcon, PlusIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, Nav, Popover } from '~/src/components';
import { formatDateString, formatPhoneNumber } from '~/src/utils/formatting';
import { INVOICE_PDF_QUERY, ORDER_PAYMENTS_QUERY, PACKING_SLIP_QUERY, PAYMENT_CREATE_MUTATION, PAYMENT_UPDATE_MUTATION } from '../../api';
import { AddPaymentModal } from '../AddPaymentModal';
import { ShippingPreferences } from '../ShippingPreferences';
import './OrderDetailNav.scss';

type OrderDetailNavProps = {
  order: any;
  refetch?: () => void;
  proceedToShipping?: boolean;
  onShippingCancel?: () => void;
  onShippingConfirmation?: (data: any) => void;
};

export const OrderDetailNav = (props: OrderDetailNavProps) => {
  const [contactsOpen, setContactsOpen] = React.useState(false);
  const [repOpen, setRepOpen] = React.useState(false);
  const [shippingOpen, setShippingOpen] = React.useState(false);
  const [paymentsPopoverOpen, setPaymentsPopoverOpen] = React.useState(false);
  const [paymentsModal, setPaymentsModal] = React.useState<{
    open: boolean;
    payment: any;
  }>({ open: false, payment: null });
  const [shippingPreferencesOpen, setShippingPreferencesOpen] = React.useState<boolean>(false);

  const { data: paymentsData } = useQuery(ORDER_PAYMENTS_QUERY, { variables: { orderId: props.order.id } });
  let refetchQueries = [{ query: ORDER_PAYMENTS_QUERY, variables: { orderId: props.order.id } }];
  const [createPayment] = useMutation(PAYMENT_CREATE_MUTATION, { refetchQueries });
  const [updatePayment] = useMutation(PAYMENT_UPDATE_MUTATION, { refetchQueries });

  // React.useEffect(() => {
  //   if (props.proceedToShipping) setShippingPreferencesOpen(true);
  // }, [props.proceedToShipping]);

  const [loadInvoicePdf] = useLazyQuery(INVOICE_PDF_QUERY);
  const [loadPackingSlip] = useLazyQuery(PACKING_SLIP_QUERY);

  function downloadPdf(base64Pdf: string, filename: string) {
    base64Pdf = `data:application/pdf;base64,${base64Pdf}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Pdf;
    downloadLink.download = `${filename}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  }

  function contactsPopover() {
    return (
      <div className="ContactsPopover">
        <Popover
          isOpen={contactsOpen}
          onClose={() => {
            setContactsOpen(false);
          }}
          style={{ padding: '1.5rem 2rem', top: '2rem', width: '512px' }}
        >
          <div className="flex flex-column">
            <div className="flex gap-8">
              <div className="flex flex-column flex-1">
                <p className="color-gray-500">
                  Account: <span className="font-medium color-gray-900">{props.order?.account?.name}</span>
                </p>
                {props.order?.account?.contacts.edges.map((edge: any, index: number) => (
                  <div className="flex flex-column" key={index}>
                    <label>{edge.node.name}</label>
                    {/* <label>{edge.node.title}</label> */}
                    <label>
                      <a href={'tel:' + edge.node.phone}>{formatPhoneNumber(edge.node.phone)}</a>
                    </label>
                    <label>
                      <a href={'mailto:' + edge.node.email}>{edge.node.email}</a>
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex flex-column flex-1">
                <p className="color-gray-500">
                  Billing: <span className="font-medium color-gray-900">{props.order?.account?.billing?.name}</span>
                </p>
                {props.order?.account?.billing?.contacts.edges.map((edge: any, index: number) => (
                  <div className="flex flex-column" key={index}>
                    <label>{edge.node.name}</label>
                    {/* <label>{edge.node.title}</label> */}
                    <label>
                      <a href={'tel:' + edge.node.phone}>{formatPhoneNumber(edge.node.phone)}</a>
                    </label>
                    <label>
                      <a href={'mailto:' + edge.node.email}>{edge.node.email}</a>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }

  function repPopover() {
    return (
      <Popover
        isOpen={repOpen}
        onClose={() => {
          setRepOpen(false);
        }}
        style={{ padding: '1.5rem', top: '2rem', width: '224px' }}
      >
        <div className="RepPopover">
          <div className="flex-1">
            <div className="RepPopover__name">
              {props.order?.rep?.user.firstName} {props.order?.rep?.user.lastName}
            </div>
            <div>Terms: {props.order.paymentReq?.type}</div>
            <div>Terms (days) {props.order.termsDays}</div>
            <div className="RepPopover__actions">
              <a href={`tel:${props.order.rep?.user?.profile?.cell}`}>
                <Button fluid variant="outlined">
                  <PhoneIcon />
                </Button>
              </a>
              <a href={`mailto:${props.order.rep?.user?.email}`}>
                <Button fluid variant="outlined">
                  <EnvelopeIcon />
                </Button>
              </a>
            </div>
          </div>
          {/* <div className="flex-1">
            <Menu style={{ border: 'none' }}>
              <MenuItem>Print All</MenuItem>
              <MenuItem>Print Names</MenuItem>
              <MenuItem>Print Itemized</MenuItem>
              <MenuItem>Print Misc.</MenuItem>
              <MenuItem>Email Master</MenuItem>
            </Menu>
          </div> */}
        </div>
      </Popover>
    );
  }

  function shippingPopover() {
    return (
      <Popover
        isOpen={shippingOpen}
        onClose={() => {
          setShippingOpen(false);
        }}
        style={{ padding: '0', top: '2rem', width: '208px' }}
      >
        <Menu style={{ border: 'none' }}>
          <MenuItem>Print All</MenuItem>
          <MenuItem>Print Record</MenuItem>
          <MenuItem
            onClick={() => {
              loadInvoicePdf({ variables: { orderPk: Number(props.order.pk) } }).then((res) => {
                downloadPdf(res.data.invoicePdf, `invoice-${props.order.pk}`);
              });
            }}
          >
            Print Invoice
          </MenuItem>
          <MenuItem onClick={() => {
             loadPackingSlip({ variables: { orderPk: Number(props.order.pk) } }).then((res) => {
              downloadPdf(res.data.packingSlip, `${props.order.pk}-packing-slip`);
            });
          }}>Print Packing Slip</MenuItem>
          <MenuItem>Track Shipment</MenuItem>
          {/* <div className="divider"></div>
          <MenuItem
            onClick={() => {
              setShippingOpen(false);
              setShippingPreferencesOpen((prev) => !prev);
            }}
          >
            Shipping preferences
          </MenuItem> */}
        </Menu>
      </Popover>
    );
  }

  function paymentsPopover() {
    return (
      <Popover
        isOpen={paymentsPopoverOpen}
        onClose={() => {
          setPaymentsPopoverOpen(false);
        }}
        style={{ left: '-304px', padding: '1.5rem', top: '2rem', width: '608px' }}
      >
        <div className="PaymentsPopover">
          <p className="PaymentsPopover__header">Payment information</p>
          <table className="data-table">
            <thead>
              <tr>
                <th>Payment type</th>
                <th>Date</th>
                <th>Check# or last 4</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paymentsData?.payments?.edges?.map((edge: any) => (
                <tr key={edge.node?.id}>
                  <td>{edge.node?.paymentType?.type}</td>
                  <td>{edge.node?.payDate && formatDateString(edge.node?.payDate)}</td>
                  <td>{edge.node?.payLastFour}</td>
                  <td>{'$' + parseFloat(edge.node?.payAmount).toFixed(2)}</td>
                  <td>
                    <Button
                      color="primary"
                      className="PaymentsPopover__editButton"
                      onClick={() => {
                        setPaymentsPopoverOpen(false);
                        setPaymentsModal({ open: true, payment: edge.node });
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
              {!paymentsData?.payments?.edges?.length && (
                <tr>
                  <td colSpan={100}>No payment data added.</td>
                </tr>
              )}
            </tbody>
          </table>
          <Button
            color="primary"
            className="PaymentsPopover__addButton"
            iconLeading={<PlusIcon />}
            onClick={() => {
              setPaymentsPopoverOpen(false);
              setPaymentsModal({ open: true, payment: null });
            }}
          >
            Add payment
          </Button>
        </div>
      </Popover>
    );
  }

  if (!props.order) {
    return <></>;
  }

  return (
    <Nav title={`Invoice# ${props.order.pk}`}>
      <div className="Nav__item">
        <div
          className="Nav__item__link"
          onClick={() => {
            setContactsOpen(true);
          }}
        >
          <a>Contacts</a>
          <ChevronDownIcon />
        </div>
        {contactsPopover()}
      </div>
      <div className="Nav__item">
        <div className="Nav__item__link">
          <Link to={{ pathname: '/orders', search: `?search=${encodeURIComponent(props.order.account.name)}` }}>
            View Orders
          </Link>
        </div>
      </div>
      <div className="Nav__item">
        <div
          className="Nav__item__link"
          onClick={() => {
            setRepOpen(true);
          }}
        >
          <a>Order</a>
          <ChevronDownIcon />
        </div>
        {repPopover()}
      </div>
      <div className="Nav__item">
        <div
          className="Nav__item__link"
          onClick={() => {
            setShippingOpen(true);
          }}
        >
          <a>Shipping</a>
          <ChevronDownIcon />
        </div>
        {shippingPopover()}
      </div>
      <div className="Nav__item">
        <div
          className="Nav__item__link"
          onClick={() => {
            setPaymentsPopoverOpen(true);
          }}
        >
          <a>Payments</a>
          <ChevronDownIcon />
        </div>
        {paymentsPopover()}
      </div>
      <AddPaymentModal
        paymentToEdit={paymentsModal.payment}
        isOpen={paymentsModal.open}
        onClose={() => {
          setPaymentsModal({ open: false, payment: null });
          setPaymentsPopoverOpen(false);
        }}
        callback={(paymentObject) => {
          const payment = { ...paymentObject.payment, order: props.order.pk };
          paymentObject.paymentPk
            ? updatePayment({ variables: { paymentPk: paymentObject.paymentPk, payment } })
            : createPayment({ variables: { payment } });
          setPaymentsModal({ open: false, payment: null });
          setPaymentsPopoverOpen(false);
          props.refetch?.();
        }}
      />
      <ShippingPreferences
        order={props.order}
        refetch={() => props.refetch?.()}
        isOpen={shippingPreferencesOpen || !!props.proceedToShipping}
        onClose={() => {
          setShippingOpen(false);
          setShippingPreferencesOpen(false);
          props.onShippingCancel?.();
        }}
        proceedToShipping={!!props.proceedToShipping}
        onShippingConfirmation={(data) => props.onShippingConfirmation?.(data)}
      />
    </Nav>
  );
};
