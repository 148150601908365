import { gql } from '@apollo/client';

export const REP_UPDATE_MUTATION = gql`
  mutation repUpdate($pk: Int!, $rep: RepsInput!) {
    repUpdate(repPk: $pk, input: $rep) {
      rep {
        pk
      }
    }
  }
`;

export const REP_USER_UPDATE_MUTATION = gql`
  mutation userUpdate($pk: Int!, $email: String, $firstName: String, $lastName: String) {
    userUpdate(userPk: $pk, firstName: $firstName, lastName: $lastName, email: $email) {
      user {
        id
      }
    }
  }
`;

export const REP_USER_PROFILE_UPDATE_MUTATION = gql`
  mutation userProfileUpdate($pk: String!, $updates: UserProfileInput) {
    userProfileUpdate(profilePk: $pk, updates: $updates) {
      profile {
        id
      }
    }
  }
`;

export const DISPLAY_FOLLOWUP_CREATE_MUTATION = gql`
  mutation createDisplayFollowup($input: DisplayFollowUpInput) {
    displayFollowUpCreate(input: $input) {
      displayFollowUp {
        pk
        actionNotes
        display {
          pk
        }
        followupDate
      }
    }
  }
`;
