import * as React from 'react';
import { Button, Input, Modal, SearchPopover, Select, Toggle } from '~/src/components';
import { STATES_LIST } from '~/src/utils/statesList';
import { EMPLOYEES_QUERY, REPS_QUERY } from '../../api';
import './EditAccountModal.scss';

type EditAccountModalProps = {
  account: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (val: any) => void;
  statuses: any;
};

type EditAccountFormData = {
  address?: string;
  city?: string;
  postal?: string;
  state?: string;
  status?: string;
  isDistributor: boolean;
  rep: number;
  manager: number;
};

const defaultFormData: EditAccountFormData = {
  address: '',
  city: '',
  postal: '',
  state: '',
  status: '',
  isDistributor: false,
  rep: -1,
  manager: -1,
};

export const EditAccountModal = (props: EditAccountModalProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  const [repPopover, setRepPopover] = React.useState(false);
  const [repName, setRepName] = React.useState('');
  const [repManagerPopover, setRepManagerPopover] = React.useState(false);
  const [repManagerName, setRepManagerName] = React.useState('');

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData({
      address: props.account.address,
      city: props.account.city,
      postal: props.account.postal,
      state: props.account.state,
      status: props.account.status?.pk,
      isDistributor: props.account.isDistributor,
      rep: props.account.rep?.pk,
      manager: props.account?.manager?.pk,
    });
    setRepName(`${props.account.rep?.user?.firstName || ''} ${props.account.rep?.user?.lastName || ''}`);
    setRepManagerName(
      `${props.account?.manager?.user?.firstName || ''} ${props.account?.manager?.user?.lastName || ''}`
    );
  }, [props.isOpen]);

  function onInputChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const stringArr: string[] = e.target.name.split('-');
    setFormData({
      ...formData,
      [stringArr[0]]: e.target.value,
    });
  }

  function onClose() {
    setFormData(defaultFormData);
    props.onClose();
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.onSubmit(formData);
    onClose();
  }

  if (!props.account) {
    return <></>;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onClose} className="EditAccountModal">
      <div className="EditAccountModal__header">
        <div className="font-size-18 color-gray-900">Edit Account Info</div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="EditAccountModal__body">
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Account #</label>
            <Input disabled value={props.account.pk} readOnly />
          </div>
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Status</label>
            {props.statuses && (
              <Select
                value={formData.status}
                options={props.statuses?.edges.map((edge: any) => {
                  return {
                    label: edge.node.status,
                    value: edge.node.pk,
                  };
                })}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              />
            )}
          </div>
          <hr className="divider" />
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Address</label>
            <Input name="address-input" value={formData.address} onChange={onInputChangeHandler} />
          </div>
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">City</label>
            <Input name="city-input" value={formData.city} onChange={onInputChangeHandler} />
          </div>
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">State / Postal</label>
            <div className="flex gap-4">
              <Select
                value={formData.state}
                options={STATES_LIST.map((state) => {
                  return {
                    label: state,
                    value: state,
                  };
                })}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    state: e.target.value,
                  });
                }}
              />
              <Input name="postal-input" value={formData.postal} onChange={onInputChangeHandler} />
            </div>
          </div>
          <hr className="divider" />
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Rep</label>
            <div className="relative">
              <Input readOnly onClick={() => setRepPopover((prev) => !prev)} value={repName} />
              <SearchPopover
                popoverStyle={{ maxHeight: '400px', overflow: 'auto' }}
                isOpen={repPopover}
                onChange={(rep) => {
                  setFormData((prev) => ({ ...prev, rep: rep.pk }));
                  setRepName(`${rep.user.firstName || ''} ${rep.user.lastName || ''}`);
                }}
                onClose={() => setRepPopover((prev) => !prev)}
                query={REPS_QUERY}
                queryArgument="search"
                queryField="reps"
                renderMatch={(match) => (
                  <>
                    {match.user.firstName} {match.user.lastName}
                  </>
                )}
              />
            </div>
          </div>
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Account Manager</label>
            <div className="relative">
              <Input readOnly onClick={() => setRepManagerPopover((prev) => !prev)} value={repManagerName} />
              <SearchPopover
                isOpen={repManagerPopover}
                onChange={(employee) => {
                  setFormData((prev) => ({ ...prev, manager: employee.pk }));
                  setRepManagerName(`${employee.user.firstName || ''} ${employee.user.lastName || ''}`);
                }}
                onClose={() => setRepManagerPopover((prev) => !prev)}
                query={EMPLOYEES_QUERY}
                queryArgument="search"
                queryField="employees"
                renderMatch={(match) => (
                  <>
                    {match.user.firstName} {match.user.lastName}
                  </>
                )}
              />
            </div>
          </div>
          <div className="EditAccountModal__field">
            <label className="EditAccountModal__field__label">Distributor</label>
            <Toggle
              checked={formData.isDistributor}
              onChange={() => setFormData((prev) => ({ ...prev, isDistributor: !formData.isDistributor }))}
            />
          </div>
        </div>
        <div className="EditAccountModal__footer">
          <Button color="light" onClick={onClose} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
