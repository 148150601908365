import * as classNames from 'classnames';
import * as React from 'react';
import './FormLabel.scss';

type FormLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  testId?: string;
};

export const FormLabel = ({ className, testId, ...rest }: FormLabelProps) => {
  let labelClass = classNames('FormLabel', className);

  return (
    <>
      <label className={labelClass} data-testid={testId} {...rest} />
    </>
  );
};
