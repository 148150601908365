import { useQuery } from '@apollo/client';
import { JOB_SUMMARY_QUERY } from '../api';

type useJobSummaryProps = {
  id: string;
};

export const useJobSummary = (props: useJobSummaryProps) => {
  const { data, loading, error } = useQuery(JOB_SUMMARY_QUERY, {
    variables: {
      id: props.id,
    },
  });

  return {
    jobSummary: data?.job,
    jobSummaryLoading: loading,
    jobSummaryError: error,
  };
};
