import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Nav, Popover, SearchPopover } from '~/src/components';
import { ACCOUNT_SEARCH } from '../../api';
import './AccountNav.scss';

type AccountNavProps = {
  account: any;
};

export const AccountNav = (props: AccountNavProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [searchPopoverOpen, setSearchPopoverOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div className="AccountNav">
      <Nav title={props.account?.name}>
        {/* <div className="AccountNav__slash"></div> */}
        <div className="AccountNav__content">
          <div className="AccountNav__content__links">
            <div className="Nav__item">
              <div className="Nav__item__link">
                <Link to={`/accounts/${props.account?.pk}`}>Overview</Link>
              </div>
            </div>
            <div className="Nav__item">
              <div className="Nav__item__link" onClick={() => setPopoverOpen((prev) => !prev)}>
                <a>Reports</a>
                <ChevronDownIcon />
              </div>
              <Popover
                isOpen={popoverOpen}
                onClose={() => setPopoverOpen((prev) => !prev)}
                className="AccountNav__popover"
              >
                <Link to={`/accounts/${props.account?.pk}/reports/history-snapshot`}>History Snapshot</Link>
                <Link to={`/accounts/${props.account?.pk}/reports/product-summary`}>Product Summary</Link>
              </Popover>
            </div>
          </div>
          <div className="AccountNav__searchPopover">
            <Input
              iconLeading={<MagnifyingGlassIcon />}
              onClick={() => setSearchPopoverOpen(true)}
              readOnly
              placeholder={'Search accounts'}
              style={{ width: '320px' }}
            />
            <SearchPopover
              isOpen={searchPopoverOpen}
              onClose={() => setSearchPopoverOpen(false)}
              query={ACCOUNT_SEARCH}
              queryArgument={'search'}
              queryField={'accounts'}
              renderMatch={(match) => <>{match.name}</>}
              onChange={(node) => navigate(`/accounts/${node.pk}`)}
            />
          </div>
        </div>
      </Nav>
    </div>
  );
};
