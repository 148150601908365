import * as React from 'react';
import { useToast } from '~/src/features/toast';
import { STATES_LIST } from '~/src/utils/statesList';
import { formatPhoneNumber } from '~/src/utils/formatting';
import { convertBase64, resizeImage } from '~/src/utils/base64';
import avatarImg from '~/src/assets/img/Avatar_Placeholder.png';
import { Button, Input, Select, Toggle } from '~/src/components';
import { formatProfilePicture } from '../../routes/RepDetails';
import './RepGeneralTab.scss';

type RepGeneralTabProps = {
  rep: any;
  updateRep: (data: any) => void;
  updateRepUser: (data: any) => void;
  updateRepUserProfile: (data: any) => void;
  refetch: () => void;
};

type RepGeneralTabFormData = {
  commission: number;
  paymentOn: string;
  paymentMethod: string;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  cell: string | undefined;
  phone: string | undefined;
  address: string;
  city: string;
  state: string;
  zip: string;
  profilePicture?: {
    file: string;
    name: string;
    type: string;
  };
};

const RepGeneralTabDefaults: RepGeneralTabFormData = {
  commission: 0,
  paymentOn: 'NONE',
  paymentMethod: 'NONE',
  active: false,
  firstName: '',
  lastName: '',
  email: '',
  cell: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  profilePicture: undefined,
};

export const RepGeneralTab = (props: RepGeneralTabProps) => {
  const [userData, setUserData] = React.useState(RepGeneralTabDefaults);
  const [profilePictureKey, setProfilePictureKey] = React.useState(Math.random().toString(36));
  const { info, error } = useToast();

  React.useEffect(() => {
    if (!props.rep) return;
    setData();
    resetProfilePictureInput();
  }, [props.rep]);

  function setData() {
    setUserData((prev) => ({
      ...prev,
      commission: props.rep.commission || 0,
      paymentOn: props.rep.paymentOn || 'NONE',
      paymentMethod: props.rep.paymentMethod || 'NONE',
      active: props.rep.active || false,
      firstName: props.rep.user?.firstName || '',
      lastName: props.rep.user?.lastName || '',
      email: props.rep.user?.email || '',
      cell: props.rep.user?.profile?.cell ? formatPhoneNumber(props.rep.user?.profile?.cell) : '',
      phone: props.rep.user?.profile?.phone ? formatPhoneNumber(props.rep.user?.profile?.phone) : '',
      address: props.rep.user?.profile?.address || '',
      city: props.rep.user?.profile?.city || '',
      state: props.rep.user?.profile?.state || '',
      zip: props.rep.user?.profile?.zip || '',
      profilePicture: undefined,
    }));
  }

  function resetProfilePictureInput() {
    setProfilePictureKey(Math.random().toString(36));
  }

  function backendPhoneFormatting(string?: string) {
    if (!string) return undefined;
    let rawPhoneNumber = string
      ?.split(/(\d{3}).*(\d{3}).*(\d{4})/)
      .filter((str) => parseInt(str))
      .join('');
    return rawPhoneNumber || string;
  }

  return (
    <div className="RepDetails__general">
      <div className="RepDetails__general__personalInfo">
        <div>
          <span>
            <label htmlFor="firstName">First Name</label>
            <Input
              fluid
              name="firstName"
              onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
              value={userData.firstName}
            />
          </span>
          <span>
            <label htmlFor="lastName">Last Name</label>
            <Input
              fluid
              name="lastName"
              onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
              value={userData.lastName}
            />
          </span>
        </div>
        <div>
          <span>
            <label htmlFor="cellNumber">Cell</label>
            <Input
              fluid
              name="cellNumber"
              onChange={(e) => setUserData({ ...userData, cell: e.target.value })}
              value={userData.cell}
            />
          </span>
          <span>
            <label htmlFor="phoneNumber">Phone</label>
            <Input
              fluid
              name="phoneNumber"
              onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
              value={userData.phone}
            />
          </span>
        </div>
        <label htmlFor="email">Email</label>
        <Input
          fluid
          name="email"
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          type="email"
          value={userData.email}
        />
        <label htmlFor="addressStreet">Street Address</label>
        <Input
          fluid
          name="addressStreet"
          onChange={(e) => setUserData({ ...userData, address: e.target.value })}
          value={userData.address}
        />
        <div>
          <span>
            <label htmlFor="addressCity">City</label>
            <Input
              fluid
              name="addressCity"
              onChange={(e) => setUserData({ ...userData, city: e.target.value })}
              value={userData.city}
            />
          </span>
          <div>
            <span>
              <label htmlFor="addressState">State / Province</label>
              <Select
                fluid
                value={userData.state}
                options={STATES_LIST.map((state) => ({ value: state, label: state }))}
                onChange={(e) => setUserData({ ...userData, state: e.target.value })}
              ></Select>
            </span>
            <span>
              <label htmlFor="addressZip">Zip / Postal</label>
              <Input
                fluid
                name="addressZip"
                onChange={(e) => setUserData({ ...userData, zip: e.target.value })}
                value={userData.zip}
              />
            </span>
          </div>
        </div>
        {/* <label htmlFor="country">Country / Region</label>
        <Select name="country"></Select> */}
        <label className="RepDetails__general__personalInfo__photoLabel">Photo</label>
        <div className="RepDetails__general__personalInfo__photoContainer">
          <img
            className="RepDetails__general__personalInfo__photoContainer__photo"
            src={
              formatProfilePicture(userData.profilePicture) ||
              formatProfilePicture(props.rep.user?.profile?.profilePicture) ||
              avatarImg
            }
          />
          <input
            key={profilePictureKey}
            className="RepDetails__general__personalInfo__photoContainer__input"
            type="file"
            accept="image/*"
            onChange={async (e) => {
              const file = e.target.files?.[0];
              file
                ? await convertBase64(e.target.files?.[0])?.then(async (base64: string) => {
                    await resizeImage(base64, { contentType: file.type }).then((res) => {
                      setUserData({
                        ...userData,
                        profilePicture: {
                          file: res.b64,
                          name: file.name,
                          type: file.type,
                        },
                      });
                    });
                  })
                : setUserData({
                    ...userData,
                    profilePicture: { file: props.rep.user?.profile?.profilePicture, name: '', type: '' },
                  });
            }}
          />
        </div>
      </div>
      <div className="RepDetails__general__paymentInfo">
        <h3 className="RepDetails__general__paymentInfo__header">Payment Information</h3>
        <div className="RepDetails__general__paymentInfo__divider"></div>
        <label htmlFor="commission">Commission</label>
        <Input
          name="commission"
          onChange={(e) => setUserData({ ...userData, commission: parseInt(e.target.value) })}
          type="number"
          value={userData.commission?.toString()}
          style={{ width: '280px' }}
        />
        <label htmlFor="paymentOn">Payment on</label>
        <Select
          name="paymentOn"
          options={[
            { value: 'NONE', label: 'None' },
            { value: 'RECEIPT', label: 'Paid on Receipt' },
            { value: 'SHIPPED', label: 'Paid on Shipped' },
          ]}
          value={userData.paymentOn}
          onChange={(e) => setUserData((prev) => ({ ...prev, paymentOn: e.target.value }))}
          style={{ width: '280px' }}
        />
        <label htmlFor="paymentMethod">Payment method</label>
        <Select
          name="paymentMethod"
          options={[
            { value: 'NONE', label: 'None' },
            { value: 'CHECK', label: 'Check' },
            { value: 'DIRECT', label: 'Direct Deposit' },
          ]}
          value={userData.paymentMethod}
          onChange={(e) => setUserData((prev) => ({ ...prev, paymentMethod: e.target.value }))}
          style={{ width: '280px' }}
        />
        <div className="RepDetails__general__paymentInfo__status">
          <label htmlFor="status">Status</label>
          <div className="RepDetails__general__paymentInfo__status__statusContainer">
            <p>{userData.active ? 'Active' : 'Inactive'}</p>
            <Toggle
              checked={userData.active}
              onChange={() => setUserData((prev) => ({ ...prev, active: !userData.active }))}
            />
          </div>
        </div>
      </div>
      <div className="RepDetails__general__save">
        <div className="RepDetails__general__save__divider"></div>
        <div className="RepDetails__general__save__buttons">
          <Button color="light" onClick={setData} variant="raised">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              Promise.all([
                props.updateRep({
                  commission: userData.commission,
                  paymentMethod: userData.paymentMethod,
                  paymentOn: userData.paymentOn,
                  active: userData.active,
                }),
                props.updateRepUser({
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  email: userData.email,
                }),
                props.updateRepUserProfile({
                  cell: backendPhoneFormatting(userData.cell),
                  phone: backendPhoneFormatting(userData.phone),
                  address: userData.address,
                  city: userData.city,
                  state: userData.state,
                  zip: userData.zip,
                  ...(userData.profilePicture?.file !== props.rep.user?.profile?.profilePicture && {
                    profilePicture: userData.profilePicture,
                  }),
                }),
              ])
                .then(() => {
                  info('Saved!');
                  props.refetch();
                })
                .catch((err) => {
                  error(err.message);
                });
            }}
            variant="raised"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
