import * as classNames from 'classnames';
import * as React from 'react';
import './Form.scss';

type FormProps = React.FormHTMLAttributes<HTMLFormElement> & {
  testId?: string;
};

export const Form = ({ className, testId, ...rest }: FormProps) => {
  let formClass = classNames('Form', className);

  return (
    <>
      <form className={formClass} data-testid={testId} {...rest} />
    </>
  );
};
