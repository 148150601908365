import { useLazyQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Badge,
  Container,
  DataTable,
  DebouncedInput,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  SelectOption,
  Spinner,
} from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';
import { BILLING_LIST_QUERY } from '../../api';
import './BillingList.scss';

const tableHeaders = [
  { label: 'Billing Id', sort: 'id', size: 'sm' },
  { label: 'Name', sort: 'name', size: 'lg' },
  { label: 'Contact', sort: 'contact', size: 'md' },
  { label: 'City', sort: 'city', size: 'sm' },
  { label: 'State', sort: 'state', size: 'sm' },
  { label: 'Company Type', sort: 'company_type', size: 'md' },
  { label: 'Open Subtotal', sort: 'open_subtotal', size: 'md' },
  { label: 'Past Due', sort: 'past_due_subtotal', size: 'sm' },
  { label: 'Status', sort: 'status', size: 'sm' },
];

const statusOptions: SelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const PAGE_SIZE = 100;
const DEFAULT_SORT = 'name';

export const BillingList = () => {
  const [searchInput, setSearchInput] = React.useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBillings, { data, loading }] = useLazyQuery(BILLING_LIST_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      search: searchParams.get('search') || '',
      sort: searchParams.get('sort') || DEFAULT_SORT,
      status: searchParams.get('status'),
    },
  });

  React.useEffect(() => {
    const search = searchParams.get('search');
    if (search) {
      setSearchInput(search);
    }
    searchBillings();
  }, [searchParams]);

  function updateSearchParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  }

  function renderStatusBadge(status: string) {
    switch (status) {
      case 'Active':
        return <Badge color="success" label={status} />;
      case 'Inactive':
        return <Badge color="warning" label={status} />;
      default:
        return status ? <Badge color="light" label={status} /> : null;
    }
  }

  function renderTableData() {
    return data.billings.edges.map((edge: any) => (
      <tr key={edge.node.pk}>
        <td>
          <Link to={`/billing/${edge.node.pk}`}>{edge.node.pk}</Link>
        </td>
        <td>{edge.node.name}</td>
        <td>{edge.node.contact}</td>
        <td>{edge.node.city}</td>
        <td>{edge.node.state}</td>
        <td>{edge.node.companyType?.type}</td>
        <td>${toFixed2(edge.node.openSubtotal)}</td>
        <td>${toFixed2(edge.node.pastDueSubtotal)}</td>
        <td>{edge.node.status && renderStatusBadge(edge.node.status?.status)}</td>
      </tr>
    ));
  }

  return (
    <>
      <Nav />
      <Container>
        <ListPageHeader title="View Billing" />
        <div className="BillingList__filters">
          <DebouncedInput
            className="BillingList__filters__search"
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onDebounce={(value) => {
              updateSearchParams({ search: value, page: 1 });
            }}
            placeholder="Search"
            value={searchInput}
          />
          <div className="BillingList__filters__status">
            <label htmlFor="statusSelect">Status</label>
            <Select
              id="statusSelect"
              options={statusOptions}
              onChange={(e) => {
                updateSearchParams({ status: e.target.value, page: 1 });
              }}
              value={searchParams.get('status') || ''}
            />
          </div>
        </div>
        {loading || !data ? (
          <>
            <Container>
              <Spinner message="Loading billing..." />
            </Container>
          </>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={searchParams.get('sort') || DEFAULT_SORT}
              onSortChange={(sort) => {
                updateSearchParams({ sort, page: 1 });
              }}
            >
              {renderTableData()}
            </DataTable>
            <Pagination
              page={parseInt(searchParams.get('page') || '1')}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => {
                updateSearchParams({ page });
              }}
              hasNextPage={data.billings.pageInfo.hasNextPage}
              totalNodes={data.billings.totalNodes}
              totalNodesOnPage={data.billings.totalNodesOnPage}
            />
          </>
        )}
      </Container>
    </>
  );
};
