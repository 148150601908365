import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Container, Spinner } from '~/src/components';
import { PRODUCT_SUMMARY_QUERY } from '../../../api';
import { AccountNav } from '../../../components';
import './AccountProductSummary.scss';

// const excludedGroupPrefixes: any[] = []
// // ['ship', 'D', 'credit', 'EMD', 'EMS', 'EZP', 'misc', 'fees'];

export const AccountProductSummary = () => {
  const { accountId } = useParams();
  const [loadProductSummary, { data, loading }] = useLazyQuery(PRODUCT_SUMMARY_QUERY, {});

  React.useEffect(() => {
    if (!accountId) {
      return;
    }

    loadProductSummary({ variables: { accountId } });
  }, [accountId]);

  function productRow(history: any) {
    return (
      <div className="ProductSummary__products__row">
        <div className="ProductSummary__products__row__column">
          {!history.product.group?.nonProduct && (
            <img src={`https://staging.everyname.com/ops-api/images/${history.product.pk}/render/`} />
          )}
        </div>
        <div className="ProductSummary__products__row__column">
          <p className="ProductSummary__products__row__title">
            <span style={{ fontWeight: '500' }}>{history.product.name}</span> {history.product.group?.name}
          </p>
          <span className="ProductSummary__keyValueSpan">
            <p>Description:</p>
            <p>{history.product.description}</p>
          </span>
          <span className="ProductSummary__keyValueSpan">
            <p>Unit cost:</p>
            <p>{history.product.price}</p>
          </span>
        </div>
        <div className="ProductSummary__products__row__column">
          <span className="ProductSummary__keyValueSpan">
            <p>Batch quantity:</p>
            <p>{history.product.minQty}</p>
          </span>
          <span className="ProductSummary__keyValueSpan">
            <p># of times ordered:</p>
            <p>{history.orderCount}</p>
          </span>
          <span className="ProductSummary__keyValueSpan">
            <p>Total quantity ordered:</p>
            <p>{history.totalQuantity}</p>
          </span>
        </div>
        <div className="ProductSummary__products__row__column">
          <span className="ProductSummary__keyValueSpan">
            <p>First date shipped:</p>
            <p>{history.firstShipped ? format(new Date(history.firstShipped), 'M/d/yyyy') : ''}</p>
          </span>
          <span className="ProductSummary__keyValueSpan">
            <p>Last date ordered:</p>
            <p>{history.lastShipped ? format(new Date(history.lastShipped), 'M/d/yyyy') : ''}</p>
          </span>
        </div>
      </div>
    );
  }

  if (!data || loading) {
    return <Spinner message="Loading products summary..." />;
  }

  return (
    <div className="ProductSummary">
      <AccountNav account={data?.account} />
      <Container className="ProductSummary__container" style={{ padding: '24px' }}>
        <div className="ProductSummary__body">
          <div className="ProductSummary__header">
            <p>Product History Summary</p>
          </div>
          <div className="ProductSummary__products">
            {data.account.productHistory.length ? (
              data.account.productHistory.map((history: any, index: number) => (
                <div key={index}>{productRow(history)}</div>
              ))
            ) : (
              <p>No product ordered under this account.</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
