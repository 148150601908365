import { gql } from '@apollo/client';

export const PIECEWORK_PAYMENT_CREATE_MUTATION = gql`
  mutation pieceworkPaymentCreate($payment: PieceworkPaymentInput!) {
    pieceworkPaymentCreate(payment: $payment) {
      payment {
        id
        pk
      }
    }
  }
`;

export const PIECEWORK_PAYMENT_UPDATE_MUTATION = gql`
  mutation pieceworkPaymentUpdate($pieceworkPaymentPk: Int!, $payment: PieceworkPaymentInput!) {
    pieceworkPaymentUpdate(pieceworkPaymentPk: $pieceworkPaymentPk, payment: $payment) {
      payment {
        id
        pk
      }
    }
  }
`;
