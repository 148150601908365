import { useQuery } from '@apollo/client';
import { JOBS_QUERY } from '../api';

export const useJobs = () => {
  const { data, loading, error } = useQuery(JOBS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  return {
    jobs: data?.jobs,
    jobsLoading: loading,
    jobsError: error,
  };
};
