import * as React from 'react';
import { Error, StyleGuide } from '~/src/features/misc';

export const commonRoutes = [
  {
    path: '/style-guide',
    element: <StyleGuide />,
  },
  {
    path: '*',
    element: <Error />,
  },
];
