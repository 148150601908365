import * as React from 'react';
import * as classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Button, Drawer, Input, Spinner } from '~/src/components';
import { DISPLAY_INSTANCE_QUERY } from '../../api';
import './DisplayReorderDrawer.scss';
import { Link } from 'react-router-dom';

export type ReorderItem = {
  product: string;
  position: string;
  group: string;
  data: string;
  quantity: number;
};

type DisplayReorderDrawerProps = {
  isOpen: boolean;
  displayDetailPk?: any;
  onClose: () => void;
  onSave: (data: ReorderItem[], displayDetailPk: number) => void;
};

export const DisplayReorderDrawer = (props: DisplayReorderDrawerProps) => {
  const [activePanel, setActivePanel] = React.useState(0);
  const [tabsOverlay, setTabsOverlay] = React.useState<{ next: boolean; prev: boolean }>({ next: false, prev: false });
  const [reorderItems, setReorderItems] = React.useState<ReorderItem[]>([]);
  const [applyToAllValue, setApplyToAllValue] = React.useState(0);
  const tabsRef = React.useRef(new Map());

  const [loadDisplayDetails, { data, loading, error }] = useLazyQuery(DISPLAY_INSTANCE_QUERY);

  React.useEffect(() => {
    if (!props.isOpen || !props.displayDetailPk) return;
    loadDisplayDetails({ variables: { pk: props.displayDetailPk } }).catch(console.error);
  }, [props.isOpen, props.displayDetailPk]);

  function onSubmitHandler() {
    props.onSave(reorderItems, props.displayDetailPk);
    onCloseHandler();
  }

  function onCloseHandler() {
    scrollToTabIndex(0);
    setApplyToAllValue(0);
    setReorderItems([]);
    props.onClose();
  }

  // scrolling panel tab bar functions
  function scrollToTabIndex(index: number) {
    tabsRef.current.get(index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    setActivePanel(index);
    setApplyToAllValue(0);
  }

  function handleOnInViewChange(inView: boolean | null, index: number) {
    if (index >= tabsRef.current.size - 1) setTabsOverlay((p) => ({ ...p, next: inView === null ? false : !inView }));
    if (index === 0) setTabsOverlay((p) => ({ ...p, prev: inView === null ? false : !inView }));
  }
  // // //

  function getReorderValue(position: any, quantity: number) {
    const val: ReorderItem = {
      product: position.product.pk,
      position: position.pk,
      group: position.product.group.prefix,
      data: position.variables,
      quantity,
    };
    return val;
  }

  function handleApplyToAll(val: number) {
    setReorderItems([]);
    val !== 0 &&
      data.displayDetail.panels.edges.forEach((edge: any) => {
        const { positions } = getFlatPanelData(edge.node);
        setReorderItems((prev) => [...prev, ...(positions?.map((p: any) => getReorderValue(p, val)) || [])]);
      });
    setApplyToAllValue(0);
  }

  function getFlatPanelData(panel: any) {
    if (!panel) return {};
    const reducer: (arr: any[], attName: string) => any[] = (arr, attrName) =>
      arr.reduce((acc: any[], node: any) => {
        const nodeAttrArr = node[attrName]?.edges.map((e: any) => e.node) || [];
        return [...acc, ...nodeAttrArr];
      }, []);
    const sections: any[] = panel.sections.edges.map((e: any) => e.node);
    const modules = reducer(sections, 'modules');
    const rows = reducer(modules, 'rows');
    const positions = reducer(rows, 'positions');
    return { sections, modules, rows, positions };
  }

  function getDisplayProductInfo(): { groups: string[]; subtotal: number } {
    return data.displayDetail.panels.edges.reduce(
      (acc: { groups: string[]; subtotal: number }, curr: any) => {
        const panelProductInfo = getFlatPanelData(curr.node).positions?.reduce(
          (acc: { groups: string[]; subtotal: number }, p: any) => {
            const price = p.product.price;
            const group = p.product.group.prefix;
            return {
              subtotal: acc.subtotal + price,
              groups: acc.groups.includes(group) ? acc.groups : [...acc.groups, group],
            };
          },
          { groups: [], subtotal: 0 }
        );
        const groupsSet = new Set([...acc.groups, ...panelProductInfo.groups]);
        return { groups: Array.from(groupsSet), subtotal: acc.subtotal + panelProductInfo.subtotal };
      },
      { groups: [], subtotal: 0 }
    );
  }

  const getQuantity = (groupPrefix?: string) => {
    return reorderItems.reduce((prev: number, curr: any) => {
      if (!groupPrefix) return prev + curr.quantity;
      return curr.group == groupPrefix ? prev + curr.quantity : prev;
    }, 0);
  };

  function renderHeaderTotals() {
    const { groups, subtotal } = getDisplayProductInfo();

    return (
      <span className="DisplayReorderDrawer__header__totals">
        <span className="DisplayReorderDrawer__header__totals__group">
          {groups.map((group: string, idx: number) => (
            <span className="DisplayReorderDrawer__header__totals__fractionTotal" key={idx}>
              <p>{group}</p>
              <p>{getQuantity(group)}</p>
            </span>
          ))}
          <span className="DisplayReorderDrawer__header__totals__fractionTotal">
            <p>Total</p>
            <p>{getQuantity()}</p>
          </span>
        </span>
        <span className="DisplayReorderDrawer__header__totals__fractionTotal">
          <p>Sub-total</p>
          <p>{subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
        </span>
      </span>
    );
  }

  function renderPosition(position: any, idx: number) {
    const currentReorderValue = reorderItems.find((v) => v.position === position.pk);

    return (
      <div
        key={idx}
        className={classNames('DisplayReorderDrawer__position', {
          'DisplayReorderDrawer__position--first': idx === 0,
        })}
      >
        <Input
          className={classNames(
            'DisplayReorderDrawer__position__input',
            currentReorderValue?.quantity
              ? {
                  'DisplayReorderDrawer__position__input--blue':
                    currentReorderValue.quantity > 0 && currentReorderValue.quantity <= 20,
                  'DisplayReorderDrawer__position__input--yellow':
                    currentReorderValue.quantity > 20 && currentReorderValue.quantity < 100,
                  'DisplayReorderDrawer__position__input--red': currentReorderValue.quantity >= 100,
                }
              : ''
          )}
          value={currentReorderValue?.quantity || ''}
          onChange={(e) => {
            if (e.target.value.length > 3) return;
            const newReorderValue = getReorderValue(position, Number(e.target.value));
            currentReorderValue
              ? setReorderItems((prev) => prev.map((v) => (v.position === position.pk ? newReorderValue : v)))
              : setReorderItems((prev) => [...prev, newReorderValue]);
          }}
          placeholder="0"
        />
        <p className="DisplayReorderDrawer__position__label">{position.label}</p>
        {/* <p className="DisplayReorderDrawer__position__label">really long label with many charachters</p> */}
      </div>
    );
  }

  function renderPanel(panel: any) {
    if (!panel) return null;
    const { rows } = getFlatPanelData(panel);
    const longestRowLength = rows?.reduce((acc: any, row: any) => {
      return row.positions.edges.length > acc ? row.positions.edges.length : acc;
    }, 0);

    return (
      <>
        {panel.sections.edges.map((sectionEdge: any, sectionIndex: number) => (
          <div
            key={sectionIndex}
            className="DisplayReorderDrawer__section"
            style={
              longestRowLength && {
                gridTemplateColumns: `repeat(${longestRowLength}, 1fr)`,
              }
            }
          >
            {sectionEdge.node.modules.edges.map((moduleEdge: any, moduleIndex: number) => (
              <React.Fragment key={moduleIndex}>
                {moduleEdge.node.rows.edges.map((rowEdge: any, rowIndex: number) => (
                  <React.Fragment key={rowIndex}>
                    {rowEdge.node.positions.edges.map((pEdge: any, pIdx: number) => renderPosition(pEdge.node, pIdx))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        ))}
      </>
    );
  }

  return !props.displayDetailPk ? null : (
    <Drawer backdrop isOpen={props.isOpen} onClose={onCloseHandler} style={{ width: '1176px' }}>
      <div className="DisplayReorderDrawer">
        <XMarkIcon onClick={onCloseHandler} />
        {loading ? (
          <div className="flex flex-1 justify-center align-center">
            <Spinner message="Loading display details..." />
          </div>
        ) : !data ? (
          <div className="flex flex-1 justify-center align-center">
            <p className="" style={{ fontStyle: 'italic' }}>
              Failed to load display details.
            </p>
          </div>
        ) : (
          <>
            <div className="DisplayReorderDrawer__header">
              <div className="DisplayReorderDrawer__header__title">
                <p>{data.displayDetail.account.name}</p>
                <p>
                  Display ID <span>{data.displayDetail.pk}</span>
                </p>
              </div>
              {renderHeaderTotals()}
            </div>
            {data.displayDetail.panels.edges.length ? (
              <>
                <div className="DisplayReorderDrawer__subheader">
                  <span className="DisplayReorderDrawer__subheader__applyToAll">
                    <Input
                      placeholder="0"
                      value={applyToAllValue}
                      onChange={(e) => setApplyToAllValue(Number(e.target.value))}
                    />
                    <Button
                      onClick={() => handleApplyToAll(applyToAllValue)}
                      variant="outlined"
                      disabled={!data.displayDetail.panels.edges.length}
                    >
                      Apply to all
                    </Button>
                  </span>
                  {data.displayDetail.panels.edges.length ? (
                    <span className="DisplayReorderDrawer__subheader__navigation">
                      <ul>
                        {data.displayDetail.panels.edges.map((edge: any, idx: number) => (
                          <ObservedListItem
                            key={idx}
                            className={activePanel === idx ? 'DisplayReorderDrawer__activeListItem' : ''}
                            ref={(node) => (node ? tabsRef.current.set(idx, node) : tabsRef.current.delete(idx))}
                            onClick={() => scrollToTabIndex(idx)}
                            onInView={(inView) => handleOnInViewChange(inView, idx)}
                            options={{ threshold: 0.9 }}
                          >
                            <p>{idx + 1}</p>
                          </ObservedListItem>
                        ))}
                      </ul>
                      <div className="DisplayReorderDrawer__subheader__navigation__overlay">
                        {tabsOverlay.prev && (
                          <div
                            className="DisplayReorderDrawer__subheader__navigation__overlay__prev"
                            onClick={() => scrollToTabIndex(activePanel > 0 ? activePanel - 1 : activePanel)}
                          >
                            <ChevronLeftIcon />
                          </div>
                        )}
                        {tabsOverlay.next && (
                          <div
                            className="DisplayReorderDrawer__subheader__navigation__overlay__next"
                            onClick={() => {
                              scrollToTabIndex(
                                activePanel < data.displayDetail.panels.edges.length - 1 ? activePanel + 1 : activePanel
                              );
                            }}
                          >
                            <ChevronRightIcon />
                          </div>
                        )}
                      </div>
                    </span>
                  ) : (
                    <div className="flex align-center">
                      <p>No panels found.</p>
                    </div>
                  )}
                </div>
                <div className="DisplayReorderDrawer__body">
                  {renderPanel(data.displayDetail.panels.edges[activePanel]?.node)}
                </div>
              </>
            ) : (
              <div className="flex flex-1 justify-center align-center">
                <p>
                  This display doesn't have any panels yet. Click{' '}
                  <Link to={`/displays/instances/${props.displayDetailPk}`}>here</Link> to edit this display's panel
                  data.
                </p>
              </div>
            )}
          </>
        )}
        <div className="DisplayReorderDrawer__buttons">
          {loading || !data || error ? (
            <Button color="light" onClick={onCloseHandler} variant="raised" style={{ marginLeft: 'auto' }}>
              Cancel
            </Button>
          ) : (
            <>
              <Button variant="outlined">Print</Button>
              <span>
                <Button color="light" onClick={onCloseHandler} variant="raised">
                  Cancel
                </Button>
                <Button color="primary" onClick={onSubmitHandler} variant="raised" disabled={!reorderItems.length}>
                  Save
                </Button>
              </span>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};

type ObservedListItemProps = {
  className?: string;
  onClick: () => void;
  onInView: (inView: boolean | null) => void;
  options?: IntersectionObserverInit;
  children: any;
};

const ObservedListItem = React.forwardRef((props: ObservedListItemProps, ref: (node: HTMLLIElement | null) => void) => {
  const tabRef = React.useRef<any>(null);
  const callback = (entries: any) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      // when removing elements from DOM, send null instead of false
      const boundingRectEmpty = !entry.boundingClientRect.width && !entry.boundingClientRect.height;
      boundingRectEmpty ? props.onInView(null) : props.onInView(entry.isIntersecting);
    });
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(callback, props.options);
    tabRef.current && observer.observe(tabRef.current);
    return () => tabRef.current && observer.unobserve(tabRef.current);
  }, [tabRef.current]);

  return (
    <li
      ref={(node) => (ref(node), (tabRef.current = node))}
      onClick={props.onClick}
      children={props.children}
      className={props.className}
    />
  );
});
