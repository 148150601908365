import * as React from 'react';
import { Button, Modal, Toggle } from '~/src/components';
import './OrderModal.scss';

type OrderModalProps = {
  account: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
};

type OrderApprovalFormData = {
  approvalSigReq?: boolean;
  poNumberReq?: boolean;
};

const defaultFormData: OrderApprovalFormData = {
  approvalSigReq: false,
  poNumberReq: false,
};

export const OrderModal = (props: OrderModalProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData({
      approvalSigReq: props.account.approvalSigReq,
      poNumberReq: props.account.poNumberReq,
    });
  }, [props.isOpen]);

  function onClose() {
    setFormData(defaultFormData);
    props.onClose();
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    props.onSubmit(formData);
  }

  if (!props.account) {
    return <></>;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="OrderModal">
      <div className="OrderModal__header">
        <div className="font-size-18 color-gray-900">Order Approval</div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="OrderModal__body">
          <div className="OrderModal__field">
            <label className="OrderModal__field__label">PO Required</label>
            <Toggle
              checked={formData.poNumberReq}
              onChange={() => {
                setFormData({
                  ...formData,
                  poNumberReq: !formData.poNumberReq,
                });
              }}
            ></Toggle>
          </div>
          <div className="OrderModal__field">
            <label className="OrderModal__field__label">Signature Required</label>
            <Toggle
              checked={formData.approvalSigReq}
              onChange={() => {
                setFormData({
                  ...formData,
                  approvalSigReq: !formData.approvalSigReq,
                });
              }}
            ></Toggle>
          </div>
        </div>
        <div className="OrderModal__footer">
          <Button color="light" onClick={onClose} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
