import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Input, Nav, SearchPopover } from '~/src/components';
import { PRODUCTS_QUERY } from '../../api';
import './ProductNav.scss';

type ProductNavProps = {
  product: any;
  active?: 'overview' | 'reports';
};

export const ProductNav = (props: ProductNavProps) => {
  const [searchPopoverOpen, setSearchPopoverOpen] = React.useState(false);

  const navigate = useNavigate();

  return (
    <Nav searchPlaceholder="Search products">
      <div className="ProductDetails__nav">
        <h2>{`${props.product?.name}${props.product?.description ? `- ${props.product?.description}` : ''}`}</h2>
        <p>/</p>
        <Link
          to={`/products/${props.product?.id}`}
          className={`ProductDetails__nav__link${
            props.active === 'overview' ? ' ProductDetails__nav__link--active' : ''
          }`}
        >
          Overview
        </Link>
        <Link
          to={`/products/${props.product?.id}/history`}
          style={{ display: 'flex', alignItems: 'center' }}
          className={`ProductDetails__nav__link${
            props.active === 'reports' ? ' ProductDetails__nav__link--active' : ''
          }`}
        >
          Reports
          <ChevronDownIcon />
        </Link>
      </div>
      <div className="Nav__spacer"></div>
      <div className="AccountNav__searchPopover">
        <Input
          readOnly
          iconLeading={<MagnifyingGlassIcon />}
          onClick={() => setSearchPopoverOpen(true)}
          placeholder={'Search products'}
          style={{ width: '320px' }}
        />
        <SearchPopover
          isOpen={searchPopoverOpen}
          onClose={() => setSearchPopoverOpen(false)}
          query={PRODUCTS_QUERY}
          queryArgument={'search'}
          queryField={'products'}
          renderMatch={(match) => <>{match.name}</>}
          onChange={(node) => navigate(`/products/${node.id}`)}
        />
      </div>
    </Nav>
  );
};
