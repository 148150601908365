import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Select, SelectProps } from '../@shared';

export const CANCELLATION_REASONS_QUERY = gql`
  query cancelReasons {
    cancelReasons {
      edges {
        node {
          pk
          type
        }
      }
    }
  }
`;

type CancelReasonSelectProps = Omit<SelectProps, 'options'> & {};

export const CancelReasonSelect = (props: CancelReasonSelectProps) => {
  const { data, loading } = useQuery(CANCELLATION_REASONS_QUERY);

  if (loading || !data) return <div>Loading...</div>;

  const options = [
    {
      value: '',
      label: '',
    },
    ...data.cancelReasons.edges.map((edge: any) => ({
      value: edge.node.pk,
      label: edge.node.type,
    })),
  ];

  return <Select options={options} {...props} />;
};
