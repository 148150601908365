import * as React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { Button, CancelReasonSelect, DatePicker, Input, Modal, PriorityTypeSelect, Toggle } from '~/src/components';
import './InvoiceDetailsModal.scss';

type InvoiceDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (invoiceDetailsData: any) => void;
  order: any;
};

type InvoiceDetailsFormData = {
  [key: string]: any;
  holdDate?: Date;
  sendByDate?: Date;
  arriveByDate?: Date;
  priorityType?: number;
  orderDate?: Date;
  approvedDate?: Date;
  releaseDate?: Date;
  completionDate?: Date;
  shipDate?: Date;
  paidDate?: Date;
  poNumber?: string;
  cancelled?: boolean;
  cancelledDate?: Date;
  cancelReason?: number;
};

const InvoiceDetailsFormDefaults = {
  holdDate: undefined,
  sendByDate: undefined,
  arriveByDate: undefined,
  priorityType: -1,
  orderDate: undefined,
  approvedDate: undefined,
  releaseDate: undefined,
  completionDate: undefined,
  shipDate: undefined,
  paidDate: undefined,
  poNumber: '',
  cancelled: false,
  cancelledDate: undefined,
  cancelReason: -1,
};

export const InvoiceDetailsModal = (props: InvoiceDetailsModalProps) => {
  const [modalInfo, setModalInfo] = React.useState<InvoiceDetailsFormData>(InvoiceDetailsFormDefaults);

  React.useEffect(() => {
    setModalInfo({
      holdDate: timezoneAdjuster(props.order.holdDate),
      sendByDate: timezoneAdjuster(props.order.sendByDate),
      arriveByDate: timezoneAdjuster(props.order.arriveByDate),
      priorityType: props.order.priorityType?.pk,
      orderDate: timezoneAdjuster(props.order.orderDate),
      approvedDate: timezoneAdjuster(props.order.approvedDate),
      releaseDate: timezoneAdjuster(props.order.releaseDate),
      completionDate: timezoneAdjuster(props.order.completionDate),
      shipDate: timezoneAdjuster(props.order.shipDate),
      paidDate: timezoneAdjuster(props.order.paidDate),
      poNumber: props.order.poNumber,
      cancelled: props.order.cancelled,
      cancelledDate: timezoneAdjuster(props.order.cancelledDate),
      cancelReason: props.order.cancelReason?.pk,
    });
  }, [props.order, props.isOpen]);

  function timezoneAdjuster(dateString: string | null) {
    if (!dateString) return undefined;
    let date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }

  function dateStringer(date: Date | undefined) {
    if (!date) return null;
    let year = date.getFullYear();
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function onCloseHandler() {
    setModalInfo(InvoiceDetailsFormDefaults);
    props.onClose();
  }

  function onSubmitHandler() {
    let formattedOrderInput: { [key: string]: any } = {};
    Object.keys(modalInfo).forEach((key) =>
      key.includes('Date')
        ? (formattedOrderInput[key] = dateStringer(modalInfo[key] as Date))
        : (formattedOrderInput[key] = modalInfo[key])
    );
    props.onSave(formattedOrderInput);
    onCloseHandler();
  }

  return (
    <div className="InvoiceDetailsModal">
      <Modal
        isOpen={props.isOpen}
        onClose={onCloseHandler}
        style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}
      >
        <div className="modalHeader">
          <div className="font-size-18 text-center">Invoice Details</div>
        </div>
        <div className="modalBody">
          {!modalInfo.cancelled && (
            <div className="textDetailsContainer">
              <p className="font-medium font-size-18">Invoice Details</p>
              <p className="font-light color-gray-600">#{props.order.pk}</p>
            </div>
          )}
          <form onSubmit={onSubmitHandler}>
            {!modalInfo.cancelled && (
              <>
                <div className="flex justify-between gap-6">
                  <div className="inputForm">
                    <label htmlFor="holdInput">Hold</label>
                    <DatePicker
                      value={modalInfo.holdDate || undefined}
                      onChange={(date) => {
                        setModalInfo((prev) => ({ ...prev, holdDate: date }));
                      }}
                    />
                  </div>
                  <div className="inputForm">
                    <label htmlFor="sendByInput">Send by</label>
                    <DatePicker
                      value={modalInfo.sendByDate || undefined}
                      onChange={(date) => {
                        setModalInfo((prev) => ({ ...prev, sendByDate: date }));
                      }}
                    />
                  </div>
                  <div className="inputForm">
                    <label htmlFor="arriveByInput">Arrive by</label>
                    <DatePicker
                      value={modalInfo.arriveByDate || undefined}
                      onChange={(date) => setModalInfo((prev) => ({ ...prev, arriveByDate: date }))}
                      style={{ right: 0 }}
                    />
                  </div>
                </div>
                <hr />
              </>
            )}
            <div className="inputForm">
              <label htmlFor="priorityLevelInput">Priority level</label>
              <PriorityTypeSelect
                value={modalInfo.priorityType}
                onChange={(e) =>
                  setModalInfo((prev) => ({
                    ...prev,
                    priorityType: Number(e.target.value),
                  }))
                }
              />
            </div>
            <hr />
            <div className="flex justify-between gap-6 mb-6">
              <div className="inputForm">
                <label htmlFor="orderInInput">Order in</label>
                <DatePicker
                  value={modalInfo.orderDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, orderDate: date }));
                  }}
                />
              </div>
              <div className="inputForm">
                <label htmlFor="approvedInput">Approved</label>
                <DatePicker
                  value={modalInfo.approvedDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, approvedDate: date }));
                  }}
                />
              </div>
              <div className="inputForm">
                <label htmlFor="releasedInput">Released</label>
                <DatePicker
                  value={modalInfo.releaseDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, releaseDate: date }));
                  }}
                  style={{ right: 0 }}
                />
              </div>
            </div>
            <div className="flex justify-between gap-6">
              <div className="inputForm">
                <label htmlFor="completedInput">Completed</label>
                <DatePicker
                  value={modalInfo.completionDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, completionDate: date }));
                  }}
                />
              </div>
              <div className="inputForm">
                <label htmlFor="shippedInput">Shipped</label>
                <DatePicker
                  value={modalInfo.shipDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, shipDate: date }));
                  }}
                />
              </div>
              <div className="inputForm">
                <label htmlFor="paidInput">Paid</label>
                <DatePicker
                  value={modalInfo.paidDate || undefined}
                  onChange={(date) => {
                    setModalInfo((prev) => ({ ...prev, paidDate: date }));
                  }}
                  style={{ right: 0 }}
                />
              </div>
            </div>
            <hr />
            <div className="inputForm">
              <label htmlFor="purchasedOrderInput">Purchase order #</label>
              <Input
                name="purchasedOrderInput"
                id="purchasedOrderInput"
                type="text"
                value={modalInfo.poNumber || ''}
                onChange={(e) => setModalInfo((prev) => ({ ...prev, poNumber: e.target.value }))}
              />
            </div>
            <hr />
            <div className="flex justify-between gap-6 InvoiceDetailsModal__payments">
              <div className="inputForm">
                <label htmlFor="invoiceTotalInput">Invoice total</label>
                <Input
                  name="invoiceTotalInput"
                  id="invoiceTotalInput"
                  type="text"
                  iconLeading={<CurrencyDollarIcon />}
                  className="rtlInput"
                  value={parseFloat(props.order.invoiceTotal).toFixed(2) || ''}
                  disabled
                  readOnly
                />
              </div>
              <div className="inputForm">
                <label htmlFor="previousPaymentsInput">Previous payments</label>
                <Input
                  name="previousPaymentsInput"
                  id="previousPaymentsInput"
                  type="text"
                  iconLeading={<CurrencyDollarIcon />}
                  value={parseFloat(props.order.paymentsTotal).toFixed(2) || ''}
                  disabled
                  readOnly
                />
              </div>
              <div className="inputForm">
                <label htmlFor="paymentDueInput">Payment due</label>
                <Input
                  name="paymentDueInput"
                  id="paymentDueInput"
                  type="text"
                  iconLeading={<CurrencyDollarIcon />}
                  value={parseFloat(props.order.remainingTotal).toFixed(2) || ''}
                  disabled
                  readOnly
                />
              </div>
            </div>
            <hr />
            <div className="flex justify-between gap-6 InvoiceDetailsModal__cancelledFields">
              <div className="inputForm">
                <Toggle
                  checked={modalInfo.cancelled}
                  label="Cancel invoice"
                  onChange={() => setModalInfo((prev) => ({ ...prev, cancelled: !prev.cancelled }))}
                />
              </div>
              {modalInfo.cancelled && (
                <>
                  <div className="inputForm">
                    <label htmlFor="cancelledDate">Cancellation date</label>
                    <DatePicker
                      value={modalInfo.cancelledDate || undefined}
                      onChange={(date) => setModalInfo((prev) => ({ ...prev, cancelledDate: date }))}
                    />
                  </div>
                  <div className="inputForm">
                    <label htmlFor="cancelledReason">Reason</label>
                    <CancelReasonSelect
                      value={modalInfo.cancelReason}
                      onChange={(e) => setModalInfo((prev) => ({ ...prev, cancelReason: Number(e.target.value) }))}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
        <div className="InvoiceDetailsModal__buttons">
          <div className="button-container">
            <Button color="light" onClick={onCloseHandler} variant="raised">
              Close
            </Button>
            <Button color="primary" onClick={onSubmitHandler} variant="raised">
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
