import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Badge, Button, Container, Input, SearchPopover, Select, Spinner } from '~/src/components';
import { useToast } from '~/src/features/toast';
import { DisplayReorderDrawer, ReorderItem } from '~/src/features/displays';
import { formatDateString, toFixed2 } from '~/src/utils/formatting';
import {
  ORDER_QUERY,
  ORDER_UPDATE_MUTATION,
  ORDER_DETAIL_CREATE_MUTATION,
  ORDER_DETAIL_DELETE_MUTATION,
  PRODUCTION_NOTE_CREATE_MUTATION,
  SEARCH_PRODUCTS_QUERY,
} from '../../api';
import {
  CreateOrderDrawer,
  DisplayTable,
  InvoiceDetailsModal,
  InvoiceNotes,
  NonDisplayTable,
  OrderNotesDrawer,
  OrderInfoModal,
  OrderDetailNav,
  ApplyCreditsModal,
  AddCreditMemoModal,
} from '../../components/';
import './OrderDetail.scss';

export const OrderDetail = () => {
  const [editOrderOpen, setEditOrderOpen] = React.useState(false);
  const [invoiceDetailsOpen, setInvoiceDetailsOpen] = React.useState(false);
  const [orderNotesOpen, setOrderNotesOpen] = React.useState(false);
  const [orderInfoOpen, setOrderInfoOpen] = React.useState(false);
  const [productSearchOpen, setProductSearchOpen] = React.useState(false);
  const [invoiceNotesOpen, setInvoiceNotesOpen] = React.useState(false);
  const [applyCreditsModalOpen, setApplyCreditsModalOpen] = React.useState(false);
  const [addCreditMemoOpen, setAddCreditMemoOpen] = React.useState(false);
  const [reorderDrawerProps, setReorderDrawerProps] = React.useState<{ isOpen: boolean; displayDetailPk?: number }>({
    isOpen: false,
  });

  const [proceedToShipping, setProceedToShipping] = React.useState<'' | 'notes' | 'shipping'>('');

  const { orderId } = useParams();
  const { data, loading, refetch } = useQuery(ORDER_QUERY, { variables: { id: orderId } });
  const [orderUpdate] = useMutation(ORDER_UPDATE_MUTATION, { onCompleted: refetch });
  const [productionNoteCreate] = useMutation(PRODUCTION_NOTE_CREATE_MUTATION, { onCompleted: refetch });
  const [createOrderDetail] = useMutation(ORDER_DETAIL_CREATE_MUTATION, { onCompleted: refetch });
  const [deleteOrderDetail] = useMutation(ORDER_DETAIL_DELETE_MUTATION, {
    onCompleted: () => {
      refetch();
      success('Order detail deleted!');
    },
  });

  const { success, error } = useToast();

  function displayOrderDetails() {
    return data.order.orderDetails.edges.filter((edge: any) => edge.node.displayDetails);
  }

  function productOrderDetails() {
    return data.order.orderDetails.edges
      .filter((edge: any) => !edge.node.displayDetails)
      .filter((edge: any) => !edge.node.product?.group?.nonProduct);
  }

  function miscProductOrderDetails() {
    return data.order.orderDetails.edges
      .filter((edge: any) => !edge.node.displayDetails)
      .filter((edge: any) => edge.node.product?.group?.nonProduct);
  }

  if (loading) {
    return (
      <Container>
        <Spinner message="Loading..." />
      </Container>
    );
  }

  return (
    <div className="OrderDetailComponentWrapper">
      <OrderDetailNav
        order={data.order}
        refetch={refetch}
        proceedToShipping={proceedToShipping === 'shipping'}
        onShippingCancel={() => setProceedToShipping('')}
        onShippingConfirmation={(shippingData) => {
          setProceedToShipping('');
          orderUpdate({
            variables: {
              orderPk: data.order.pk,
              input: {
                completionDate: (() => {
                  let date = new Date();
                  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
                    .getDate()
                    .toString()
                    .padStart(2, '0')}`;
                })(),
              },
            },
            onCompleted: () => success('Shipment complete'),
            onError: () => error('Shipment unsuccessful'),
          });
        }}
      />
      <div className="OrderDetailGrayBackground">
        <Container className="OrderDetail">
          <div className="OrderDetail__card OrderDetail__card--info">
            <div className="OrderDetail__info">
              <div className="OrderDetail__account__header">
                <div className="font-medium">Account</div>
                <a onClick={() => setEditOrderOpen(true)}>Edit</a>
              </div>
              <div className="OrderDetail__account">
                <div className="OrderDetail__account__field">
                  <label className="OrderDetail__account__field__label">Name:</label>
                  <div className="OrderDetail__account__field__value">
                    <span className="font-medium">{data.order.account.name}</span>
                  </div>
                </div>
                <div className="OrderDetail__account__field">
                  <label className="OrderDetail__account__field__label">Billing:</label>
                  <div className="OrderDetail__account__field__value">
                    <Link to={`/billing/${data.order.account.billing.pk}`}>{data.order.account.billing.name}</Link>
                  </div>
                </div>
                <div className="OrderDetail__account__field">
                  <label className="OrderDetail__account__field__label">Address:</label>
                  <div className="OrderDetail__account__field__value text-right">
                    {data.order.account.address}
                    <br />
                    {data.order.account.city}, {data.order.account.state} {data.order.account.postal}
                  </div>
                </div>
                <div className="OrderDetail__account__field">
                  <label className="OrderDetail__account__field__label">Phone:</label>
                  <div className="OrderDetail__account__field__value">
                    <a href={`tel:${data.order.account.phone}`}>{data.order.account.phone}</a>
                  </div>
                </div>
                <div className="OrderDetail__account__field">
                  <label className="OrderDetail__account__field__label">Email:</label>
                  <div className="OrderDetail__account__field__value">
                    <a href={`mailto:${data.order.account.email}`}>{data.order.account.email}</a>
                  </div>
                </div>
              </div>
              <div
                className="OrderDetail__viewNotes"
                onClick={() => {
                  setOrderNotesOpen(true);
                }}
              >
                <div className="OrderDetail__viewNotes__label">View notes</div>
                {data.order.productionNotes?.edges.length > 0 && (
                  <Badge color="primary" label={data.order.productionNotes?.edges.length} />
                )}
              </div>
              <div className="OrderDetail__additional">
                <div className="OrderDetail__additional__field">
                  <label className="OrderDetail__additional__field__label">Account Rep:</label>
                  <div className="OrderDetail__additional__field__value">
                    {data.order.account.rep.user.firstName} {data.order.account.rep.user.lastName}
                  </div>
                </div>
                <div className="OrderDetail__additional__field">
                  <label className="OrderDetail__additional__field__label">Order Rep:</label>
                  <div className="OrderDetail__additional__field__value">
                    {data.order.rep?.user.firstName} {data.order.rep?.user.lastName}
                  </div>
                </div>
                <div className="OrderDetail__additional__field">
                  <label className="OrderDetail__additional__field__label">Terms:</label>
                  <div className="OrderDetail__additional__field__value">{data.order.paymentReq?.type}</div>
                </div>
                <div className="OrderDetail__additional__field">
                  <label className="OrderDetail__additional__field__label">Terms (days):</label>
                  <div className="OrderDetail__additional__field__value">{data.order.termsDays}</div>
                </div>
                <div className="OrderDetail__additional__modalButton">
                  <a onClick={() => setOrderInfoOpen(true)}>
                    <PencilIcon />
                  </a>
                </div>
              </div>
              <div className="OrderDetail__invoiceDetails">
                <div className="OrderDetail__invoiceDetails__header">
                  <div className="font-medium">Invoice details</div>
                  <a
                    onClick={() => {
                      setInvoiceDetailsOpen(true);
                    }}
                  >
                    Edit
                  </a>
                </div>
                <hr className="divider" />
                <label>Priority level: </label>
                <span>{data.order.priorityType?.priorityNumber}</span>
                <div className="OrderDetail__invoiceDetails__dates">
                  <div>
                    <label>Order in:</label>
                    <div>{formatDateString(data.order.orderDate)}</div>
                  </div>
                  <div>
                    <label>Approved:</label>
                    <div>{formatDateString(data.order.approvedDate)}</div>
                  </div>
                  <div>
                    <label>Released:</label>
                    <div>{formatDateString(data.order.releaseDate)}</div>
                  </div>
                </div>
                <div className="OrderDetail__invoiceDetails__dates">
                  <div>
                    <label>Completed:</label>
                    <div>
                      <p className="OrderDetail__invoiceDetails__dates__green">
                        {formatDateString(data.order.completionDate)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <label>Shipped:</label>
                    <div>{formatDateString(data.order.shipDate)}</div>
                  </div>
                  <div>
                    <label>Paid:</label>
                    <div>{formatDateString(data.order.paidDate)}</div>
                  </div>
                </div>
                <label>Purchase order # </label>
                <span>{data.order.poNumber}</span>
                <div>
                  <label>Required: </label>
                  <Badge
                    color="light"
                    label={data.order.account.poNumberReq ? 'Yes' : 'No'}
                    style={{ display: 'inline-block' }}
                  />
                </div>
                <div className="OrderDetail__invoiceDetails__totals">
                  <div>
                    <label>Invoice total:</label>
                    <div>${toFixed2(data.order.invoiceTotal)}</div>
                  </div>
                  <div>
                    <label>Payments:</label>
                    <div>${toFixed2(data.order.paymentsTotal)}</div>
                  </div>
                  <div>
                    <label>Payment due:</label>
                    <div>${toFixed2(data.order.remainingTotal)}</div>
                  </div>
                </div>
                <Button className="mr-4" fluid onClick={() => setInvoiceNotesOpen(true)} size="sm" variant="outlined">
                  Invoice notes
                </Button>
              </div>
            </div>
          </div>
          <div className="OrderDetail__card OrderDetail__card--main">
            <div className="OrderDetail__main">
              <span className="OrderDetail__main__header">
                <div className="flex gap-4">
                  <div>
                    <p className="font-medium mt-0 mb-1">Add Products</p>
                    <div className="OrderDetail__filters">
                      <div className="OrderDetail__filters__search">
                        <Input
                          iconTrailing={<PlusIcon />}
                          onClick={() => {
                            setProductSearchOpen(true);
                          }}
                          placeholder="Product"
                        />
                        <SearchPopover
                          isOpen={productSearchOpen}
                          onChange={(product) =>
                            createOrderDetail({ variables: { details: { order: data.order.pk, product: product.pk } } })
                          }
                          onClose={() => setProductSearchOpen(false)}
                          popoverStyle={{ maxHeight: '400px', overflowY: 'auto' }}
                          query={SEARCH_PRODUCTS_QUERY}
                          queryArgument="search"
                          queryVariables={
                            {
                              // includeDisplays: toggleDisplayNonDisplay,
                            }
                          }
                          queryField="products"
                          renderMatch={(match) => (
                            <>
                              {match.pk} - {match.name}
                            </>
                          )}
                          searchLabel="Search products"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium mt-0 mb-1">Start Reorder</p>
                    <Select
                      onChange={(e) => {
                        setReorderDrawerProps({ isOpen: true, displayDetailPk: Number(e.target.value) });
                        e.target.value = '';
                      }}
                      options={[
                        { label: 'Select a display...', value: '' },
                        ...(data.order.account.displaydetailsSet?.edges.map((edge: any) => ({
                          label: `${edge.node.displayProduct?.name} - ${edge.node.pk}`,
                          value: edge.node.pk,
                        })) || []),
                      ]}
                    />
                  </div>
                </div>
                <p className="font-size-18 color-gray-500 my-0">
                  Invoice total: <span className="color-gray-900">${toFixed2(data.order.invoiceTotal)}</span>
                </p>
              </span>
              <div className="OrderDetail__main__content">
                <div>
                  <p className="font-medium mt-0 mb-2">Display Reorder</p>
                  {displayOrderDetails().length ? (
                    <DisplayTable
                      order={data.order}
                      displayOrderDetails={displayOrderDetails()}
                      onDelete={(orderDetailPk) => deleteOrderDetail({ variables: { id: orderDetailPk } })}
                      onReorder={(displayDetailPk) =>
                        setReorderDrawerProps({ isOpen: true, displayDetailPk: Number(displayDetailPk) })
                      }
                      onRefetch={refetch}
                    />
                  ) : (
                    <p className="my-0 px-2" style={{ fontStyle: 'italic' }}>
                      No order details
                    </p>
                  )}
                </div>
                <div>
                  <p className="font-medium mt-0 mb-2">Order Products</p>
                  {productOrderDetails().length ? (
                    <NonDisplayTable
                      order={data.order}
                      productOrderDetails={productOrderDetails()}
                      onDelete={(orderDetailPk) => deleteOrderDetail({ variables: { id: orderDetailPk } })}
                      onRefetch={refetch}
                    />
                  ) : (
                    <p className="my-0 px-2" style={{ fontStyle: 'italic' }}>
                      No order details
                    </p>
                  )}
                </div>
                <div>
                  <p className="font-medium mt-0 mb-2">Misc</p>
                  {miscProductOrderDetails().length ? (
                    <NonDisplayTable
                      order={data.order}
                      productOrderDetails={miscProductOrderDetails()}
                      onDelete={(orderDetailPk) => deleteOrderDetail({ variables: { id: orderDetailPk } })}
                      onRefetch={refetch}
                    />
                  ) : (
                    <p className="my-0 px-2" style={{ fontStyle: 'italic' }}>
                      No order details
                    </p>
                  )}
                </div>
              </div>
              <div className="OrderDetail__main__buttons">
                <div className="flex align-center gap-4">
                  <Button className="mr-4" onClick={() => setApplyCreditsModalOpen(true)} size="sm" variant="outlined">
                    Apply EN Credits
                  </Button>
                  <Button onClick={() => setAddCreditMemoOpen(true)} size="sm" variant="outlined">
                    Add Credit Memos
                  </Button>
                </div>
                <Button color="primary" onClick={() => setProceedToShipping('notes')} variant="raised">
                  Proceed to Shipping
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <ApplyCreditsModal
        isOpen={applyCreditsModalOpen}
        onClose={() => setApplyCreditsModalOpen(false)}
        onSubmit={() => {}}
      />
      <AddCreditMemoModal
        order={data.order}
        isOpen={addCreditMemoOpen}
        onClose={() => setAddCreditMemoOpen(false)}
        // TODO
        onAddCreditMemo={() => {}}
      />
      <CreateOrderDrawer order={data.order} isOpen={editOrderOpen} onClose={() => setEditOrderOpen(false)} />
      <InvoiceDetailsModal
        order={data.order}
        isOpen={invoiceDetailsOpen}
        onClose={() => setInvoiceDetailsOpen(false)}
        onSave={(invoiceData) => orderUpdate({ variables: { orderPk: data.order.pk, input: invoiceData } })}
      />
      <InvoiceNotes
        isOpen={invoiceNotesOpen}
        onClose={() => setInvoiceNotesOpen(false)}
        onSave={(notes) => orderUpdate({ variables: { orderPk: data.order.pk, input: { invoiceNotes: notes } } })}
        notes={data.order.invoiceNotes}
      />
      <OrderNotesDrawer
        isOpen={orderNotesOpen || proceedToShipping === 'notes'}
        onClose={() => (setOrderNotesOpen(false), setProceedToShipping(''))}
        order={data.order}
        onSaveNote={(notesData) => orderUpdate({ variables: { orderPk: data.order.pk, input: notesData } })}
        onAddProductionNote={(productionNoteData) => productionNoteCreate({ variables: { input: productionNoteData } })}
        proceedToShipping={proceedToShipping === 'notes'}
        onShippingConfirmation={() => setProceedToShipping('shipping')}
      />
      <OrderInfoModal
        isOpen={orderInfoOpen}
        onClose={() => setOrderInfoOpen(false)}
        onSave={(orderInfo) => orderUpdate({ variables: { orderPk: data.order.pk, input: orderInfo } })}
        order={data.order}
      />
      <DisplayReorderDrawer
        {...reorderDrawerProps}
        onClose={() => setReorderDrawerProps({ isOpen: false })}
        onSave={(reorderItems, orderItemsDisplayDetail) => {
          if (!reorderItems.length) return;
          const orderItems = reorderItems.reduce((acc: Partial<ReorderItem>[], curr: ReorderItem) => {
            const { quantity, position, group, ...rest } = curr;
            return [...acc, ...Array.from({ length: quantity }, () => rest)];
          }, []);
          orderUpdate({
            variables: { orderPk: data.order.pk, input: { orderItems, orderItemsDisplayDetail } },
            onCompleted: () => success('Order updated!'),
          });
        }}
      />
    </div>
  );
};
