import * as classNames from 'classnames';
import * as React from 'react';
import { To } from 'react-router';
import { Link } from 'react-router-dom';
import './IconButton.scss';

export type IconButtonColor = 'default' | 'primary' | 'accent' | 'warn' | 'light' | 'dark';

type IconButton = React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> & {
  color?: IconButtonColor;
  navigateTo?: To;
  testId?: string;
};

export const IconButton = ({
  className,
  color = 'default',
  navigateTo,
  testId,
  type = 'button',
  ...rest
}: IconButton) => {
  const iconButtonClass = classNames(
    {
      IconButton: true,
      [`IconButton--color--${color}`]: color,
    },
    className
  );

  if (navigateTo) {
    return <Link className={iconButtonClass} data-testid={testId} to={navigateTo} type={type} {...rest}></Link>;
  }

  return <button className={iconButtonClass} data-testid={testId} type={type} {...rest}></button>;
};
