import { format } from 'date-fns';
import { QRCodeSVG } from 'qrcode.react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Container, Spinner } from '~/src/components';
import { formatDateString } from '~/src/utils/formatting';
import { useJobMaterials, useJobSummary } from '../../hooks';
import './PrintSummary.scss';

export const PrintSummary = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { jobSummary, jobSummaryLoading } = useJobSummary({ id: jobId as string });
  const { jobMaterials, jobMaterialsLoading } = useJobMaterials({ jobPk: jobId as string });

  React.useEffect(() => {
    if (!jobSummary || !jobMaterials) return;
    window.print();
  }, [jobSummary]);

  function pickupAsDate() {
    return new Date(jobSummary.pickupDatetime);
  }

  function jobTable() {
    return (
      <div className="PrintSummary__jobTable">
        <table className="PrintSummary__table PrintSummary__jobTable__table">
          <colgroup>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '10%' }}></col>
            <col style={{ width: '50%' }}></col>
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>Invoice</th>
              <th>Order Detail Id</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Tasks</th>
            </tr>
          </thead>
          <tbody>
            {jobSummary.tasks?.edges.map((edge: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Checkbox></Checkbox>
                  </td>
                  <td>{edge.node.orderDetails.order.pk}</td>
                  <td>{edge.node.orderDetails.pk}</td>
                  <td>{edge.node.orderDetails.product.group.prefix}</td>
                  <td>{edge.node.quantity}</td>
                  <td className="PrintSummary__jobTable__tasks">
                    {edge.node.assignedProductGroupTasks.edges.map((edge: any, idx: number) => (
                      <div key={idx}>{edge.node.name}</div>
                    ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function materialsTable() {
    return (
      <div className="PrintSummary__materialsTable">
        <table className="PrintSummary__table PrintSummary__materialsTable__table">
          <thead>
            <tr>
              <th colSpan={3}>Total Materials</th>
              {/* <th className="text-right">Pieces</th> */}
            </tr>
          </thead>
          <tbody>
            {jobMaterials.map((m: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <Checkbox></Checkbox>
                  </td>
                  <td>{m.material}</td>
                  <td>{m.units}</td>
                  <td className="text-right">{m.total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (jobSummaryLoading || jobMaterialsLoading) {
    return (
      <Container>
        <Spinner message="Loading Job..." />
      </Container>
    );
  }

  return (
    <Container>
      <div className="PrintSummary">
        <div className="PrintSummary__header">
          <div className="PrintSummary__header__overview">
            <h2>Piecework Job: #{jobSummary.jobNum}</h2>
            <div className="PrintSummary__header__overview__info">
              <h3>
                Assignee: {jobSummary.assignee?.firstName} {jobSummary.assignee?.lastName}
              </h3>
              <p>Pick-up Date: {format(pickupAsDate(), 'MM/dd/yyyy')}</p>
              <p>Pick-up Time: {format(pickupAsDate(), 'hh:ss a')}</p>
              <p>Return Date: {formatDateString(jobSummary.dueDate)}</p>
            </div>
          </div>
          <div className="PrintSummary__header__qrInfo">
            <p>
              Please verify that you have all the listed product for the orders listed below, and all the materials
              required to complete the job.
            </p>
            <p>Scan the QR code with your phone to confirm and accept the Piecework Job.</p>
          </div>
          <div className="PrintSummary__header__qrCode">
            <QRCodeSVG value={`${window.location.origin}/jobs/${jobId}`} size={136} />
          </div>
        </div>
        {jobTable()}
        {materialsTable()}
        <div className="PrintSummary__actions">
          <Button
            color="light"
            onClick={() => {
              navigate(`/piecework/jobs/${jobId}`);
            }}
            variant="raised"
          >
            Back
          </Button>
          <Button
            color="primary"
            onClick={() => {
              window.print();
            }}
            variant="raised"
          >
            Print
          </Button>
        </div>
      </div>
    </Container>
  );
};
