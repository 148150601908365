import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from '~/src/components';

export const Error = () => {
  const [seconds, setSeconds] = React.useState<number>(5);

  React.useEffect(() => {
    if (seconds === 0) {
      window.location.replace('/');
      setSeconds(0);
    }
    if (!seconds) return;

    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Container className="headspace text-center">
      <h1>Page Not Found</h1>
      <p>You will be redirected to the home page in {seconds} seconds</p>
      <div>
        <Link to="/">
          <Button color="primary">Or Go Home Now!</Button>
        </Link>
      </div>
    </Container>
  );
};
