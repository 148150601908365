import { gql } from '@apollo/client';

export const PRODUCT_UPDATE_MUTATION = gql`
  mutation productUpdate($pk: Int!, $updates: ProductInput!) {
    productUpdate(productPk: $pk, productDetails: $updates) {
      product {
        id
        pk
      }
    }
  }
`;

export const TAG_CREATE_MUTATION = gql`
  mutation tagCreate($details: TagInput!) {
    tagCreate(details: $details) {
      tag {
        id
        pk
      }
    }
  }
`;

export const LAYER_CREATE_MUTATION = gql`
  mutation layerCreate($layers: [LayerCreateInput]!) {
    layerCreate(layers: $layers) {
      layers {
        id
        pk
      }
    }
  }
`;

export const LAYER_UPDATE_MUTATION = gql`
  mutation layerUpdate($layers: [LayerUpdateInput]!) {
    layerUpdate(layers: $layers) {
      layers {
        id
        pk
      }
    }
  }
`;

export const LAYER_DELETE_MUTATION = gql`
  mutation layerDelete($pk: Int!) {
    layerDelete(pk: $pk) {
      success
    }
  }
`;
