import { useLazyQuery, useMutation } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { endOfDay, format, startOfDay, sub } from 'date-fns';
import * as React from 'react';
import { Container, DatePicker, DebouncedInput, Nav, Select } from '~/src/components';
import { ProfileDrawer } from '~/src/features/piecework-coordinator';
import {
  PAYROLL_PAID_QUERY,
  PAYROLL_PENDING_APPROVAL_QUERY,
  PAYROLL_PENDING_PAYMENT_QUERY,
  PIECEWORK_PAYMENT_CREATE_MUTATION,
  PIECEWORK_PAYMENT_UPDATE_MUTATION,
} from '../../api';
import {
  ApproveJobsTable,
  EditApprovalDate,
  PaymentApproval,
  PaymentConfirmation,
  PaymentsTable,
} from '../../components';
import './PayrollSummary.scss';

type PayrollSummaryFilters = {
  dateStart?: Date;
  dateEnd?: Date;
  search: String;
  paymentStatus: string;
};

const defaultFilters: PayrollSummaryFilters = {
  dateStart: startOfDay(sub(new Date(), { days: 14 })),
  dateEnd: endOfDay(new Date()),
  search: '',
  paymentStatus: 'completed',
};

export const PayrollSummary = () => {
  const paymentStatusOptions = [
    { label: 'Pending approval', value: 'completed' },
    { label: 'Pending payment', value: 'pending_payment' },
    { label: 'Approved and Paid', value: 'paid' },
  ];

  const [filters, setFilters] = React.useState(defaultFilters);
  const [searchInput, setSearchInput] = React.useState('');

  const [pendingApproval, setPendingApproval] = React.useState<any[]>([]);
  const [paymentsPending, setPaymentsPending] = React.useState<any[]>([]);
  const [paymentsPaid, setPaymentsPaid] = React.useState<any[]>([]);

  const [profileDrawerId, setProfileDrawerId] = React.useState<string | undefined>(undefined);
  const [paymentApprovalProps, setPaymentApprovalProps] = React.useState<any>({
    isOpen: false,
    jobs: [],
    pieceworker: null,
  });
  const [paymentConfirmationOpen, setPaymentConfirmationOpen] = React.useState<boolean>(false);
  const [editApprovalDateOpen, setEditApprovalDateOpen] = React.useState<boolean>(false);

  const [getPendingApproval] = useLazyQuery(PAYROLL_PENDING_APPROVAL_QUERY, {
    variables: {
      returnedDatetime_Lte: filters.dateEnd,
      returnedDatetime_Gte: filters.dateStart,
      search: filters.search,
    },
    onCompleted: (data) => {
      setPendingApproval(data.pieceworkers.edges);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [getPendingPayments] = useLazyQuery(PAYROLL_PENDING_PAYMENT_QUERY, {
    variables: {
      approvedDate_Lte: filters.dateEnd,
      approvedDate_Gte: filters.dateStart,
      search: filters.search,
    },
    onCompleted: (data) => {
      setPaymentsPending(data.pieceworkPayments.edges);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [getPaidPayments] = useLazyQuery(PAYROLL_PAID_QUERY, {
    variables: {
      approvedDate_Lte: filters.dateEnd,
      approvedDate_Gte: filters.dateStart,
      search: filters.search,
    },
    onCompleted: (data) => {
      setPaymentsPaid(data.pieceworkPayments.edges);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const refetchQueries = [
    {
      query: PAYROLL_PENDING_APPROVAL_QUERY,
      variables: {
        returnedDatetime_Lte: filters.dateEnd,
        returnedDatetime_Gte: filters.dateStart,
        search: filters.search,
      },
    },
    {
      query: PAYROLL_PENDING_PAYMENT_QUERY,
      variables: {
        approvedDate_Lte: filters.dateEnd,
        approvedDate_Gte: filters.dateStart,
        search: filters.search,
      },
    },
    {
      query: PAYROLL_PAID_QUERY,
      variables: {
        approvedDate_Lte: filters.dateEnd,
        approvedDate_Gte: filters.dateStart,
        search: filters.search,
      },
    },
  ];

  const [createPayment] = useMutation(PIECEWORK_PAYMENT_CREATE_MUTATION, {
    refetchQueries,
  });

  const [setPaidDate] = useMutation(PIECEWORK_PAYMENT_UPDATE_MUTATION, {
    refetchQueries,
  });

  React.useEffect(() => {
    getPendingApproval();
    getPendingPayments();
    getPaidPayments();
  }, [filters]);

  return (
    <div className="PayrollSummary">
      <Nav title="Piecework" />
      <Container>
        <div className="flex justify-between align-center">
          <h2 className="pageTitle">Payroll Summary</h2>
          <p>{new Intl.DateTimeFormat('en-US').format(new Date())}</p>
        </div>
        <div className="PayrollSummary__filters">
          <div className="PayrollSummary__filter">
            <label>Search</label>
            <DebouncedInput
              iconTrailing={<MagnifyingGlassIcon />}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onDebounce={(value) => {
                setFilters({
                  ...filters,
                  search: value,
                });
              }}
              placeholder="Search Pieceworkers"
              value={searchInput}
            />
          </div>
          <div className="PayrollSummary__filter">
            <label>Pay period</label>
            <div className="flex gap-4">
              <DatePicker
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    dateStart: e,
                  });
                }}
                value={filters.dateStart}
              />
              <DatePicker
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    dateEnd: e,
                  });
                }}
                value={filters.dateEnd}
              />
            </div>
          </div>
          <div className="PayrollSummary__filters__spacer"></div>
          <div className="PayrollSummary__filter">
            <label>Status</label>
            <Select
              options={paymentStatusOptions}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  paymentStatus: e.target.value,
                });
              }}
              value={filters.paymentStatus}
            />
          </div>
        </div>
        {filters.paymentStatus === 'completed' && (
          <ApproveJobsTable
            pendingApproval={pendingApproval}
            onOpenProfileDrawer={(id) => {
              setProfileDrawerId(id);
            }}
            onOpenPaymentApproval={({ jobs, pieceworker }) => {
              setPaymentApprovalProps({
                ...paymentApprovalProps,
                isOpen: true,
                jobs,
                pieceworker,
              });
            }}
          />
        )}
        {filters.paymentStatus === 'pending_payment' && (
          <PaymentsTable
            payments={paymentsPending}
            onOpenProfileDrawer={(id) => {
              setProfileDrawerId(id);
            }}
            onOpenPaymentConfirmation={(payment) => {
              setPaidDate({
                variables: {
                  pieceworkPaymentPk: payment.pk,
                  payment: {
                    paidDate: format(new Date(), 'yyyy-MM-dd'),
                  },
                },
              }).then(() => {
                setPaymentConfirmationOpen(true);
              });
            }}
          />
        )}
        {filters.paymentStatus === 'paid' && (
          <PaymentsTable
            payments={paymentsPaid}
            onOpenProfileDrawer={(id) => {
              setProfileDrawerId(id);
            }}
          />
        )}
      </Container>
      <EditApprovalDate
        isOpen={editApprovalDateOpen}
        onClose={() => {
          setEditApprovalDateOpen(false);
        }}
      />
      <PaymentApproval
        onApprove={(pieceworker, jobs) => {
          createPayment({
            variables: {
              payment: {
                pieceworker: pieceworker.pk,
                jobs: jobs.map((job) => job.pk),
              },
            },
          }).then(() => {
            setPaymentApprovalProps({
              ...paymentApprovalProps,
              isOpen: false,
              jobs: [],
              pieceworker: null,
            });
          });
        }}
        onClose={() => {
          setPaymentApprovalProps({
            ...paymentApprovalProps,
            isOpen: false,
            jobs: [],
            pieceworker: null,
          });
        }}
        {...paymentApprovalProps}
      />
      <PaymentConfirmation
        isOpen={paymentConfirmationOpen}
        onClose={() => {
          setPaymentConfirmationOpen(false);
        }}
      />
      <ProfileDrawer
        isOpen={!!profileDrawerId}
        onClose={() => {
          setProfileDrawerId(undefined);
        }}
        userId={profileDrawerId as string}
      />
    </div>
  );
};
