import * as React from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader } from '~/src/components';
import { ORDER_DETAILS_QUERY } from '../../api';
import './DisplayHistoryDrawer.scss';

type DisplayHistoryDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  displayId: string;
};

export const DisplayHistoryDrawer = (props: DisplayHistoryDrawerProps) => {
  const [loadOrderDetails, { data }] = useLazyQuery(ORDER_DETAILS_QUERY);

  React.useEffect(() => {
    if (!props.displayId) return;
    loadOrderDetails({ variables: { displayDetails: props.displayId } });
  }, [props.displayId]);

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <Drawer backdrop className="DisplayHistoryDrawer" isOpen={props.isOpen} onClose={onCloseHandler}>
      <DrawerHeader onClose={onCloseHandler} title="Display History" />
      <DrawerContent className="DisplayHistoryDrawer__content">
        <table className="data-table">
          <thead>
            <tr>
              <th>invoice #</th>
              <th>shipped date</th>
              <th>Units Count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.orderDetails.edges.map((orderDetail: any, index: number) => (
              <tr key={index}>
                <td>{orderDetail.node.order?.pk}</td>
                <td>{orderDetail.node.order?.shipDate && format(new Date(orderDetail.node.order?.shipDate ), 'M/d/yyyy')}</td>
                <td>{orderDetail.node.quantity}</td>
                <td>
                  <Link to={`/orders/${orderDetail.node.order?.id}`} className="DisplayHistoryDrawer__body__tableLink">
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DrawerContent>
      <DrawerActions>
        <div className="flex-1"></div>
        <Button color="light" onClick={onCloseHandler} variant="raised">
          Close
        </Button>
      </DrawerActions>
    </Drawer>
  );
};
