import { useMutation } from '@apollo/client';
import {
  CREATE_JOB_MUTATION,
  DELETE_JOB_MUTATION,
  JOB_TASK_CREATE_MUTATION,
  JOB_TASK_DELETE_MUTATION,
  JOB_TASK_UPDATE_MUTATION,
  JOB_TASK_VERIFY_MUTATION,
  UPDATE_JOB_MUTATION,
} from '../api';

export const useJobMutations = () => {
  const [createJob] = useMutation(CREATE_JOB_MUTATION);
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION);
  const [deleteJob] = useMutation(DELETE_JOB_MUTATION);

  const [createJobTask] = useMutation(JOB_TASK_CREATE_MUTATION);
  const [updateJobTask] = useMutation(JOB_TASK_UPDATE_MUTATION);
  const [deleteJobTask] = useMutation(JOB_TASK_DELETE_MUTATION);
  const [verifyJobTask] = useMutation(JOB_TASK_VERIFY_MUTATION);

  return {
    createJob,
    updateJob,
    deleteJob,

    createJobTask,
    updateJobTask,
    deleteJobTask,
    verifyJobTask,
  };
};
