import * as classNames from 'classnames';
import * as React from 'react';
import '../Input/Input.scss';

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  filled?: boolean;
  fluid?: boolean;
  iconLeading?: string;
  iconTrailing?: string;
  testId?: string;
};

export const Textarea = React.forwardRef(
  (
    { className, filled, fluid, iconLeading, iconTrailing, testId, ...rest }: TextareaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    function getContainerClass() {
      return classNames('Input__container', {
        'Input__container--fluid': fluid,
      });
    }

    function getClassNames() {
      return classNames(
        'Input--textarea',
        {
          'Input': !filled,
          'Input--filled': filled,
          'Input--fluid': fluid,
          'Input--iconLeading': iconLeading,
          'Input--iconTrailing': iconTrailing,
        },
        className
      );
    }

    return (
      <div className={getContainerClass()} data-testid={testId && `${testId}-container`}>
        {iconLeading && <span className="iconLeading material-icons">{iconLeading}</span>}
        {iconTrailing && <span className="iconTrailing material-icons">{iconTrailing}</span>}
        <textarea className={getClassNames()} data-testid={testId} ref={ref} {...rest} />
      </div>
    );
  }
);
