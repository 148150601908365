import * as React from 'react';
import './ListPageHeader.scss';

export type ListPageHeaderProps = {
  title: string;
};

export const ListPageHeader = (props: ListPageHeaderProps) => {
  return (
    <div className="ListPageHeader">
      <h4 className="ListPageHeader__title">{props.title}</h4>
      <p className="ListPageHeader__date">{new Intl.DateTimeFormat('en-US').format(new Date())}</p>
    </div>
  );
};
