import * as classNames from 'classnames';
import * as React from 'react';
import './Badge.scss';

type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  color?:
    | 'light'
    | 'primary'
    | 'purple'
    | 'success'
    | 'danger'
    | 'warning'
    | 'invertedPrimary'
    | 'invertedPurple'
    | 'invertedSuccess'
    | 'invertedDanger';
  label: string;
  testId?: string;
};

export const Badge = ({ className, color, label, testId, ...rest }: BadgeProps) => {
  const badgeClass = classNames(
    'Badge',
    {
      [`Badge--${color}`]: color,
    },
    className
  );

  return (
    <div className={badgeClass} data-testid={testId} {...rest}>
      {label}
    </div>
  );
};
