import * as React from 'react';
import { ACCOUNT_SEARCH } from '~/src/features/accounts';
import { Button, Input, Modal, SearchPopover } from '~/src/components';
import { DISPLAY_TEMPLATES_QUERY } from '~/src/features/displays/api';
import './CreateInstanceModal.scss';

type CreateInstanceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { model: any; account: any }) => void;
};

export const CreateInstanceModal = (props: CreateInstanceModalProps) => {
  const [accountPopover, setAccountPopover] = React.useState<{ isOpen: boolean; account?: any }>({ isOpen: false });
  const [modelPopover, setModelPopover] = React.useState<{ isOpen: boolean; model?: any }>({ isOpen: false });

  function onCloseHandler() {
    setModelPopover({ isOpen: false, model: undefined });
    setAccountPopover({ isOpen: false, account: undefined });
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSubmit({ model: modelPopover.model, account: accountPopover.account });
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="CreateInstanceModal">
      <p>Create a new instance</p>
      <div className="CreateInstanceModal__input">
        <p>Display Template</p>
        <div style={{ position: 'relative' }}>
          <Input
            readOnly
            value={modelPopover.model?.name || ''}
            placeholder="Set display model..."
            onClick={() => setModelPopover((p) => ({ ...p, isOpen: true }))}
          />
          <SearchPopover
            isOpen={modelPopover.isOpen}
            onChange={(model) => setModelPopover((p) => ({ ...p, model }))}
            onClose={() => setModelPopover((p) => ({ ...p, isOpen: false }))}
            renderMatch={(model) => <>{model.name}</>}
            query={DISPLAY_TEMPLATES_QUERY}
            queryArgument={'search'}
            queryField={'displayTemplates'}
          />
        </div>
      </div>
      <div className="CreateInstanceModal__input">
        <p>Account</p>
        <div style={{ position: 'relative' }}>
          <Input
            value={accountPopover.account?.name || ''}
            placeholder="Set account..."
            readOnly
            onClick={() => setAccountPopover((p) => ({ ...p, isOpen: true }))}
          />
          <SearchPopover
            isOpen={accountPopover.isOpen}
            onChange={(account) => setAccountPopover((p) => ({ ...p, account }))}
            onClose={() => setAccountPopover((p) => ({ ...p, isOpen: false }))}
            query={ACCOUNT_SEARCH}
            queryArgument={'search'}
            queryField={'accounts'}
            renderMatch={(acc) => (
              <>
                {acc.pk} - {acc.name}
              </>
            )}
          />
        </div>
      </div>
      <div className="CreateInstanceModal__buttons">
        <Button variant="raised" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button color="primary" variant="raised" disabled={!modelPopover.model} onClick={onSubmitHandler}>
          Create instance
        </Button>
      </div>
    </Modal>
  );
};
