import * as React from 'react';
import { AddProductModal, ConfirmationModal } from '..';
import './DisplayModule.scss';

type DisplayModuleProps = {
  module: any;
  onRemoveModule: () => void;
  onUpdatePosition: (data: { product: number | null; variables: string; positioning: any }) => void;
};

export const DisplayModule = ({ module, onRemoveModule, onUpdatePosition }: DisplayModuleProps) => {
  const [productModal, setProductModal] = React.useState<{
    isOpen: boolean;
    productPk?: number;
    variables?: any;
    positionData: { row: number; position: number } | null;
  }>({ isOpen: false, positionData: null });
  const [confirmationModal, setConfirmationModal] = React.useState<{
    isOpen: boolean;
    title: string;
    message: string;
    onSubmit?: () => void;
  }>({ isOpen: false, title: '', message: '' });

  const renderProduct = (position: any, row: number) => {
    const variables = JSON.parse(position.variables || '{}');

    return (
      <>
        {position.label && <p className="DisplayModule__item__product">{position.label}</p>}{' '}
        <img
          src={`https://staging.everyname.com/ops-api/images/${position.product?.pk}/render/?view=final_front`}
          alt={position.label}
          style={{ width: '100px' }}
        />
        <div className="DisplayModule__item__variables">
          {Object.keys(variables).map((key: string, index: number) => (
            <span key={index} className="DisplayModule__item__variable">
              <p className="DisplayModule__item__variable__key">{key}:</p>
              <p className="DisplayModule__item__variable__value">{variables[key]}</p>
            </span>
          ))}
        </div>
        <span className="DisplayModule__item__links">
          <a
            onClick={() => {
              setProductModal({
                isOpen: true,
                productPk: position.product?.pk,
                variables,
                positionData: { row, position: position.order },
              });
            }}
          >
            Change
          </a>
          |
          <a
            onClick={() =>
              setConfirmationModal({
                isOpen: true,
                title: 'Remove product',
                message: 'Are you sure you want to remove this product?',
                onSubmit: () => {
                  onUpdatePosition({ product: null, variables: '{}', positioning: { row, position: position.order } });
                },
              })
            }
          >
            Remove
          </a>
        </span>
      </>
    );
  };

  return (
    <>
      <div className="DisplayModule" key={module.number}>
        <span className="DisplayModule__title">
          <p>{module?.name}</p>
          <a
            onClick={() => {
              setConfirmationModal({
                isOpen: true,
                title: 'Remove module',
                message: 'Are you sure you want to remove this module?',
                onSubmit: () => onRemoveModule(),
              });
            }}
          >
            Remove Module
          </a>
        </span>
        <div className="DisplayModule__rows">
          {module.rows?.edges
            .map((edge: any) => edge.node)
            .map((row: any, idx: number) => (
              <div className="DisplayModule__row" key={idx}>
                {row.positions?.edges
                  .map((edge: any) => edge.node)
                  .map((position: any, i: number) => (
                    <div className="DisplayModule__item" key={i}>
                      <p className="DisplayModule__item__type">{position.positionType}</p>
                      <div className="DisplayModule__item__corner">
                        <p>{position.order}</p>
                      </div>
                      {
                        // const product = getProduct(section, module, row, item);
                        position.product ? (
                          renderProduct(position, row.order)
                        ) : (
                          <a
                            onClick={() => {
                              setProductModal({
                                isOpen: true,
                                positionData: {
                                  row: row.order,
                                  position: position.order,
                                },
                              });
                            }}
                          >
                            Set product
                          </a>
                        )
                      }
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
      <AddProductModal
        isOpen={productModal.isOpen}
        onClose={() => setProductModal({ isOpen: false, positionData: null })}
        productPk={productModal.productPk}
        variables={productModal.variables}
        groups={module.groups}
        onSubmit={(product, variables) => {
          onUpdatePosition({ product, variables: JSON.stringify(variables), positioning: productModal.positionData });
        }}
      />
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '' })}
        onSubmit={confirmationModal.onSubmit}
        title={confirmationModal.title}
        message={confirmationModal.message}
      />
    </>
  );
};
