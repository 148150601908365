import { gql } from '@apollo/client';

export const BILLING_UPDATE_MUTATION = gql`
  mutation billingUpdate($pk: ID!, $updates: BillingInput) {
    billingUpdate(pk: $pk, updates: $updates) {
      billing {
        id
        pk
        name
        address
        city
        state
        postal
        companyType {
          id
          pk
          type
          typeDescription
        }
        statusChoice
        contact
      }
    }
  }
`;

export const COLLECTION_DETAIL_CREATE_MUTATION = gql`
  mutation collectionDetailsCreate($detail: CollectionDetailInput!) {
    collectionDetailCreate(detail: $detail) {
      collectionDetail {
        id
      }
    }
  }
`;
