import { LockClosedIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Input } from '~/src/components';
import { useJobMutations } from '../../hooks';
import './VerifyTask.scss';

type VerifyTaskProps = {
  task: any;
  onVerify: () => void;
};

export const VerifyTask = (props: VerifyTaskProps) => {
  const [confirmed, setConfirmed] = React.useState(false);
  const [quantityLocked, setQuantityLocked] = React.useState(true);
  const [confirmQuantity, setConfirmQuantity] = React.useState(0);
  const { verifyJobTask } = useJobMutations();

  React.useEffect(() => {
    if (!props.task) {
      return;
    }
    setConfirmed(!!props.task.approvalDatetime);
    setConfirmQuantity(props.task.quantity);
  }, [props.task]);

  return (
    <tr>
      <td>{props.task.orderDetails.order.pk}</td>
      <td>{props.task.orderDetails.pk}</td>
      <td>{props.task.orderDetails.product.group.name}</td>
      <td>{props.task.quantity}</td>
      <td>
        <Input
          className="VerifyTask__quantityInput"
          type="number"
          iconButtonTrailing={{
            icon: <LockClosedIcon data-testid="lock-icon" />,
            onClick: () => {
              setQuantityLocked(!quantityLocked);
            },
          }}
          min={0}
          max={props.task.quantity}
          value={confirmQuantity}
          onChange={(e) => {
            setConfirmQuantity(parseInt(e.target.value));
          }}
          disabled={quantityLocked}
        />
      </td>
      <td>${props.task.pricePerPiece}</td>
      <td>${props.task.subtotal}</td>
      <td>
        {confirmed && <p className="VerifyTask__confirmed">Confirmed!</p>}
        {!confirmed && (
          <Button
            color="primary"
            onClick={() => {
              verifyJobTask({
                variables: {
                  jobTaskPk: props.task.pk,
                  returnedQuantity: confirmQuantity,
                },
              }).then(() => {
                props.onVerify();
              });
            }}
          >
            Confirm
          </Button>
        )}
      </td>
    </tr>
  );
};
