import * as React from 'react';
import { useMutation } from '@apollo/client';
import { CurrencyDollarIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { DebouncedInput, IconButton } from '~/src/components';
import { useToast } from '~src/features/toast';
import { ORDER_DETAIL_UPDATE_MUTATION } from '../../api';
import '../NonDisplayTable/NonDisplayTable.scss';

type ProductRowProps = {
  orderDetails: any;
  orderId: number;
  onDelete: (orderDetailPk: number) => void;
  openProductPreview: (product: any) => void;
  type?: 'display' | 'non-display' | 'misc';
  showDisplayType?: boolean;
  onRefetch: () => void;
};

type ProductRowFormData = {
  quantity?: number;
  unitPrice?: string;
  repComm?: string;
  acrylicTabs?: boolean;
};

const defaultFormData: ProductRowFormData = {
  quantity: 0,
  unitPrice: '0.00',
  repComm: '0',
  acrylicTabs: false,
};

export const ProductRow = ({ type = 'non-display', ...props }: ProductRowProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  const { error } = useToast();

  const [orderDetailUpdate, { loading }] = useMutation(ORDER_DETAIL_UPDATE_MUTATION, {
    onError: (err) => {
      error(err.message);
      setFormToProps();
    },
    onCompleted: () => props.onRefetch(),
  });

  React.useEffect(() => {
    if (!props.orderDetails) {
      return;
    }
    setFormToProps();
  }, [props.orderDetails]);

  const setFormToProps = React.useCallback(() => {
    const { quantity, unitPrice, repComm, acrylicTabs } = props.orderDetails;
    setFormData({
      ...formData,
      quantity: quantity || 0,
      unitPrice: unitPrice ? parseFloat(unitPrice).toFixed(2) : '0.00',
      repComm: repComm || '0',
      acrylicTabs,
    });
  }, [props.orderDetails]);

  function handleUpdate(updateData: Partial<ProductRowFormData>) {
    orderDetailUpdate({ variables: { orderDetailPk: props.orderDetails.pk, details: updateData } });
  }

  function calcSubtotal() {
    if (!formData.quantity || !formData.unitPrice) {
      return '0.00';
    }
    return (formData.quantity * parseFloat(formData.unitPrice)).toFixed(2);
  }

  return (
    <tr>
      <td>
        {type !== 'display' ? (
          <a>{props.orderDetails.pk}</a>
        ) : (
          props.showDisplayType && props.orderDetails.displayDetails?.displayProduct?.name
        )}
      </td>
      <td>
        <a onClick={() => props.openProductPreview(props.orderDetails.product)}>{props.orderDetails.product.name}</a>
      </td>
      <td>{props.orderDetails.product.description}</td>
      <td className="OrderDetails__orderDetailsTable__inputTd">
        <DebouncedInput
          fluid
          readOnly={type === 'display' || loading}
          onChange={(e) => type !== 'display' && setFormData({ ...formData, quantity: parseInt(e.target.value) })}
          onDebounce={(val) => handleUpdate({ quantity: parseInt(val) })}
          placeholder="50"
          type="number"
          value={formData.quantity}
          debounceTimer={2000}
        />
      </td>
      <td className="OrderDetails__orderDetailsTable__inputTd">
        <DebouncedInput
          fluid
          readOnly={loading}
          disabled={loading}
          iconLeading={<CurrencyDollarIcon />}
          onChange={(e) => setFormData({ ...formData, unitPrice: e.target.value })}
          onDebounce={(val) => handleUpdate({ unitPrice: val })}
          value={formData.unitPrice}
          debounceTimer={2000}
        />
      </td>
      <td className="OrderDetails__orderDetailsTable__inputTd">
        <DebouncedInput
          fluid
          readOnly={loading}
          onChange={(e) => setFormData({ ...formData, repComm: e.target.value })}
          onDebounce={(val) => handleUpdate({ repComm: val })}
          placeholder="5"
          value={formData.repComm}
          debounceTimer={2000}
        />
      </td>
      <td className="OrderDetails__orderDetailsTable__inputTd">
        <DebouncedInput fluid iconLeading={<CurrencyDollarIcon />} readOnly value={calcSubtotal()} />
      </td>
      <td className="OrderDetails__orderDetailsTable__inputTd">
        <div className="flex align-center">
          <IconButton
            className="OrderDetails__orderDetailsTable__iconButton"
            onClick={() => props.onDelete(props.orderDetails.pk)}
          >
            <XMarkIcon />
          </IconButton>
        </div>
      </td>
    </tr>
  );
};
