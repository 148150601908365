import { useMutation } from '@apollo/client';
import { CREATE_PIECEWORKER_TRAINING, DELETE_PIECEWORKER_TRAINING } from '../api';

export const useTrainingMutations = () => {
  const [createTraining] = useMutation(CREATE_PIECEWORKER_TRAINING);
  const [deleteTraining] = useMutation(DELETE_PIECEWORKER_TRAINING);

  return {
    createTraining,
    deleteTraining,
  };
};
