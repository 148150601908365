import { BanknotesIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Modal } from '~/src/components';
import './ApplyCreditsModal.scss';

type ApplyCreditsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const ApplyCreditsModal = (props: ApplyCreditsModalProps) => {
  function onClose() {
    props.onClose();
  }

  function onSubmit() {
    props.onSubmit();
    onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onClose} style={{ padding: '0' }}>
      <div className="ApplyCredits">
        <div className="ApplyCredits__header">
          <p>Apply EN Credits</p>
        </div>
        <div className="ApplyCredits__body">
          <div className="ApplyCredits__row">
            <div className="ApplyCredits__body__amountContainer">
              <div className="ApplyCredits__body__amountContainer__image">
                <BanknotesIcon />
              </div>
              <div className="ApplyCredits__body__amountContainer__amount">
                <p>Credit Amount</p>
                <p>$45.85</p>
              </div>
            </div>
            <Button color="primary" onClick={onSubmit} variant="raised">
              Approve and Apply Credits
            </Button>
          </div>
          <div className="ApplyCredits__row">
            <div className="ApplyCredits__body__table">
              <p>Summary</p>
              <div className="ApplyCredits__row">
                <span className="ApplyCredits__keyValueSpan">
                  <p>Total Units:</p>
                  <p>25</p>
                </span>
                <span className="ApplyCredits__keyValueSpan">
                  <p>Total Charges:</p>
                  <p>$45.85</p>
                </span>
              </div>
              <div className="ApplyCredits__body__table__tableContainer">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>product group</th>
                      <th>quantity</th>
                      <th>unit cost</th>
                      <th>unit retail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(2)].map((el: any, index: number) => (
                      <tr key={index}>
                        <td>Mini Shinerz</td>
                        <td>17</td>
                        <td>$1.85</td>
                        <td>$1.85</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="ApplyCredits__body__table">
              <p>Everyname Orders</p>
              <div className="ApplyCredits__row">
                <span className="ApplyCredits__keyValueSpan">
                  <p>Total EN Orders:</p>
                  <p>3</p>
                </span>
              </div>
              <div className="ApplyCredits__body__table__tableContainer">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>access code</th>
                      <th>custom order id</th>
                      <th>order date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((el: any, index: number) => (
                      <tr key={index}>
                        <td>ac99</td>
                        <td>10102</td>
                        <td>2/5/2023</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
