import { UserIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Button, DataTable, IconButton } from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';

type PaymentsTableProps = {
  payments: any[];
  onOpenProfileDrawer: (id: string) => void;
  onOpenPaymentConfirmation?: (pieceworker: any) => void;
};

const tableHeaders = [
  { label: 'Name', size: 'md' },
  { label: 'Jobs completed', size: 'md' },
  { label: 'Amount to be paid', size: 'md' },
  { label: 'Pay approval', size: 'sm' },
  { label: 'Mark as paid', size: 'sm' },
];

export const PaymentsTable = (props: PaymentsTableProps) => {
  function renderPayments() {
    return props.payments.map((edge, idx) => (
      <tr key={idx}>
        <td>
          <div className="flex align-center">
            <IconButton
              className="mr-4"
              onClick={() => {
                props.onOpenProfileDrawer(edge.node.pieceworker.id);
              }}
            >
              <UserIcon />
            </IconButton>
            <a
              onClick={() => {
                props.onOpenProfileDrawer(edge.node.pieceworker.id);
              }}
            >
              {edge.node.pieceworker.firstName} {edge.node.pieceworker.lastName}
            </a>
          </div>
        </td>
        <td>{edge.node.jobs.edges.length}</td>
        <td>${toFixed2(edge.node.amount)}</td>
        <td>{format(new Date(edge.node.approvedDate), 'MM/dd/yyyy')}</td>
        <td>
          {edge.node.paidDate ? (
            format(new Date(edge.node.paidDate), 'MM/dd/yyyy')
          ) : (
            <Button
              onClick={() => {
                if (!props.onOpenPaymentConfirmation) {
                  return;
                }
                props.onOpenPaymentConfirmation(edge.node);
              }}
              size="sm"
              variant="outlined"
            >
              Paid
            </Button>
          )}
        </td>
      </tr>
    ));
  }

  return (
    <>
      <DataTable headers={tableHeaders}>{renderPayments()}</DataTable>
    </>
  );
};
