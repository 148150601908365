import { useMutation, useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, DataTable, Toggle } from '~/src/components';
import {
  DISPLAY_DETAIL_CREATE_MUTATION,
  DISPLAY_DETAIL_UPDATE_MUTATION,
  DisplayHistoryDrawer,
  EditDisplayModal,
} from '~/src/features/displays';
import { formatDateString } from '~/src/utils/formatting';
import { ACCOUNT_DISPLAYS_QUERY } from '../../api';

type DisplayTableProps = {
  accountId: number;
};

const tableHeaders = [
  { label: '', size: 'sm' },
  { label: 'ID', size: 'sm' },
  { label: 'Type', size: 'sm' },
  { label: 'List template', size: 'sm' },
  { label: 'Featured', size: 'sm' },
  { label: 'Label', size: 'sm' },
  { label: 'Notes', size: 'lg' },
  { label: 'Start date', size: 'sm' },
  { label: 'End date', size: 'sm' },
  { label: '', size: 'sm' },
];

export const DisplayTable = (props: DisplayTableProps) => {
  const [displayModal, setDisplayModal] = React.useState({
    open: false,
    pk: '',
  });
  const [displayHistory, setDisplayHistory] = React.useState<{ open: boolean; id: string }>({
    open: false,
    id: '',
  });

  const [viewAll, setViewAll] = React.useState(false);

  const { data, refetch } = useQuery(ACCOUNT_DISPLAYS_QUERY, {
    variables: { account: props.accountId },
  });
  const [displayDetailUpdate] = useMutation(DISPLAY_DETAIL_UPDATE_MUTATION);
  const [displayDetailCreate] = useMutation(DISPLAY_DETAIL_CREATE_MUTATION);

  function renderTableDisplays() {
    const filteredDisplays = data?.accountDisplays?.filter((display: any) => viewAll || !display.endDate) || [];

    return filteredDisplays.length ? (
      filteredDisplays.map((display: any) => (
        <tr key={display.id}>
          <td>
            <Button onClick={() => setDisplayModal({ open: true, pk: display.pk })}>
              <a>Edit</a>
            </Button>
          </td>
          <td>
            <a onClick={() => setDisplayHistory({ open: true, id: display.id })}>{display.pk}</a>
          </td>
          <td>{display.displayProduct && display.displayProduct.name}</td>
          <td>{display.namelist?.name}</td>
          <td>{display.product && display.product.name}</td>
          <td>{display.label}</td>
          <td>{display.notes}</td>
          <td>{display.startDate && formatDateString(display.startDate)}</td>
          <td>{display.endDate && formatDateString(display.endDate)}</td>
          <td>
            <Button size="sm" variant="outlined">
              View Form
            </Button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={tableHeaders.length} className="text-center">
          <p style={{ fontStyle: 'italic' }}>No displays found.</p>
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="flex align-center justify-between">
        <h5>Displays</h5>
        <div className="flex align-center justify-between">
          <Button
            className="mr-4"
            iconLeading={<PlusIcon />}
            onClick={() => setDisplayModal({ open: true, pk: '' })}
            variant="outlined"
          >
            Add Display
          </Button>
          <Toggle checked={viewAll} label="View All" onChange={() => setViewAll(!viewAll)} />
        </div>
      </div>
      <DataTable headers={tableHeaders}>{renderTableDisplays()}</DataTable>
      <EditDisplayModal
        isOpen={displayModal.open}
        onClose={() => {
          setDisplayModal({ open: false, pk: '' });
        }}
        onSave={(data) => {
          // stripping out layout and position data until we can figure out how implement
          const newData = { pk: data.pk, input: { ...data.input, layoutData: undefined, positionData: undefined } };
          data.pk
            ? displayDetailUpdate({ variables: newData, onCompleted: refetch })
            : displayDetailCreate({ variables: newData, onCompleted: refetch });
        }}
        displayPk={displayModal.pk}
        accountId={props.accountId}
      />
      <DisplayHistoryDrawer
        isOpen={displayHistory.open}
        onClose={() => setDisplayHistory({ open: false, id: '' })}
        displayId={displayHistory.id}
      />
    </>
  );
};
