import { CurrencyDollarIcon, PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Container, Nav } from '~/src/components';
import { formatDateString } from '~/src/utils/formatting';
import { ProfileDrawer, ProfileDrawerProps } from '../../components';
import { JobStatusChoices, JobStatuses } from '../../enums';
import { useJobs } from '../../hooks';
import './Jobs.scss';

export const Jobs = () => {
  const [profileDrawerProps, setProfileDrawerProps] = React.useState<ProfileDrawerProps>({
    isOpen: false,
    onClose: closeProfileDrawer,
    userId: '',
  });

  const { jobs, jobsLoading } = useJobs();

  function openProfileDrawer(userId: string) {
    setProfileDrawerProps({
      ...profileDrawerProps,
      isOpen: true,
      userId,
    });
  }

  function closeProfileDrawer() {
    setProfileDrawerProps({
      ...profileDrawerProps,
      isOpen: false,
      userId: '',
    });
  }

  function getStatusColor(node: any) {
    switch (node.status) {
      case JobStatuses.READY:
        return '#5699de';
      case JobStatuses.PICKUP:
        return '#d68249';
      case JobStatuses.PRODUCTION:
        return '#633094';
      case JobStatuses.APPROVAL:
        return '#374151';
      case JobStatuses.COMPLETED:
        return '#16a34a';
      case JobStatuses.PAYMENT:
        return '#374151';
      case JobStatuses.PAID:
        return '#16a34a';
      default:
        return '#5699de';
    }
  }

  function renderStatusBadge(node: any) {
    const label = JobStatusChoices.find((obj) => obj.status === node.status)?.label || node.status;
    return <Badge label={label} style={{ backgroundColor: getStatusColor(node) }} />;
  }

  function renderButtonLink(node: any) {
    if (node.status === 'Ready to assign') {
      return (
        <Link to={`/piecework/edit-job/${node.pk}`}>
          <Button color="primary">Assign</Button>
        </Link>
      );
    }
    return (
      <Link to={`/piecework/jobs/${node.pk}`}>
        <Button color="primary">View</Button>
      </Link>
    );
  }

  function renderTableItems() {
    return jobs.edges.map((edge: any, idx: number) => (
      <tr key={idx}>
        <td>
          <Link to={`/piecework/jobs/${edge.node.pk}`}>{edge.node.jobNum}</Link>
        </td>
        <td>
          <a
            onClick={() => {
              openProfileDrawer(edge.node.assignee?.id);
            }}
          >
            {edge.node.assignee?.firstName} {edge.node.assignee?.lastName}
          </a>
        </td>
        <td>{edge.node.pickupDatetime && format(new Date(edge.node.pickupDatetime), 'M/d/yyyy')}</td>
        <td>{edge.node.pickupDatetime && format(new Date(edge.node.pickupDatetime), 'h:mm a')}</td>
        <td>{edge.node.dueDate && formatDateString(edge.node.dueDate)}</td>
        <td>{renderStatusBadge(edge.node)}</td>
        <td>{renderButtonLink(edge.node)}</td>
      </tr>
    ));
  }

  if (jobsLoading) {
    return null;
  }

  return (
    <div className="Jobs">
      <Nav title="Piecework" />
      <Container>
        <h4>Jobs</h4>
        <div className="Jobs__inlineButtons">
          <Button color="primary" className="mr-4" variant="raised">
            Jobs
          </Button>
          <Link to="/piecework/availability">
            <Button>Availability</Button>
          </Link>
          <div className="flex-1"></div>
          <Link to="/piecework/payroll-summary" className="mr-4">
            <Button iconLeading={<CurrencyDollarIcon />} variant="outlined">
              Payroll summary
            </Button>
          </Link>
          <Link to="/piecework/create-job">
            <Button iconLeading={<PlusIcon />} variant="outlined">
              Create new job
            </Button>
          </Link>
        </div>
        <table className="data-table">
          <thead>
            <tr>
              <th>Job #</th>
              <th>Assignee</th>
              <th>Pick up date</th>
              <th>Pick up time</th>
              <th>Due date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderTableItems()}</tbody>
        </table>
      </Container>
      <ProfileDrawer {...profileDrawerProps}></ProfileDrawer>
    </div>
  );
};
