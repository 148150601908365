import * as React from 'react';
import './TabItem.scss';

type TabItemProps = {
  active?: boolean;
  children?: any;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  testId?: string;
};

export const TabItem = (props: TabItemProps) => {
  let tabItemClass = 'TabItem';

  if (props.active) {
    tabItemClass += ` TabItem--active`;
  }

  if (props.className) {
    tabItemClass += ` ${props.className}`;
  }

  return (
    <>
      <div className={tabItemClass} style={props.style} onClick={props.onClick} data-testid={props.testId}>
        {props.children}
      </div>
    </>
  );
};
