import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Button, Input, Modal, SearchPopover } from '~/src/components';
import { DISPLAY_PRODUCTS_QUERY, DISPLAY_PRODUCT_QUERY } from '~/src/features/displays/api/queries';
import './AddProductModal.scss';

type AddProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (product: number, variables?: any) => void;
  productPk?: number;
  variables?: any;
  groups?: any;
};

export const AddProductModal = (props: AddProductModalProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [product, setProduct] = React.useState<any>(undefined);
  const [loadProduct] = useLazyQuery(DISPLAY_PRODUCT_QUERY);
  const variableForm = useForm();
  const variableFormFields = useFieldArray({
    control: variableForm.control,
    name: 'variables',
  });

  React.useEffect(() => {
    props.productPk &&
      loadProduct({
        variables: { pk: props.productPk },
        onCompleted: (data) => setProduct(data.productPk),
      });
  }, [props.isOpen]);

  React.useEffect(() => {
    if (!product) return;
    variableFormFields.replace([]);
    // distinguishing between artFront and artBack layers may be useful at some point
    const artFrontLayers = product.artFront?.layers?.edges.map((layer: any) => ({ ...layer.node, artFront: true }));
    const artBackLayers = product.artBack?.layers?.edges.map((layer: any) => ({ ...layer.node, artBack: true }));
    const variableLayers = [...(artFrontLayers || []), ...(artBackLayers || [])];
    // reversing and prepending will end with first input focused instead of last
    variableLayers.reverse().map((layer) => {
      variableFormFields.prepend({
        attributeName: layer.name,
        value: props.variables?.[layer.name] || '',
        type: layer.type.name,
        variableImagePaths: layer.variableImagePaths,
      });
    });
  }, [product, props.variables]);

  function onCloseHandler() {
    setProduct(null);
    setPopoverOpen(false);
    variableFormFields.replace([]);
    props.onClose();
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const variables = data.variables.reduce(
      (acc: any, variable: any) => variable.value ? ({ ...acc, [variable.attributeName]: variable.value }) : acc,
      {}
    );
    props.onSubmit(product.pk, variables);
    onCloseHandler();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={() => onCloseHandler()} className="DisplayInstance__addProductModal">
      <p>Set product at selected position</p>
      <form onSubmit={variableForm.handleSubmit(onSubmit)} className="DisplayInstance__addProductModal__form">
        <div style={{ position: 'relative' }}>
          <Input
            readOnly
            value={product ? `${product.pk} ${product?.name || ''}` : ''}
            placeholder="Search for a product..."
            onClick={() => setPopoverOpen(true)}
          />
          <SearchPopover
            popoverStyle={{ maxHeight: '400px', overflowY: 'auto' }}
            isOpen={popoverOpen}
            onChange={(product) => setProduct(product)}
            onClose={() => setPopoverOpen(false)}
            query={DISPLAY_PRODUCTS_QUERY}
            queryArgument={'search'}
            queryField={'products'}
            queryVariables={props.groups ? { group: props.groups } : {}}
            renderMatch={(product) => <>{product.name}</>}
          />
          {!!variableFormFields.fields.length && (
            <div className="DisplayInstance__addProductModal__variables">
              <p>Variables</p>
              {variableFormFields.fields.map((field, index) => (
                <div key={field.id} className="DisplayInstance__addProductModal__variable">
                  <Controller
                    control={variableForm.control}
                    name={`variables.${index}.attributeName`}
                    render={({ field }) => <p style={{ textTransform: 'capitalize' }}>{field.value}</p>}
                  />
                  <Controller
                    control={variableForm.control}
                    name={`variables.${index}.type`}
                    render={({ field }) =>
                      field.value === 'text' ? (
                        <Input
                          className="DisplayInstance__addProductModal__variable__input"
                          {...variableForm.register(`variables.${index}.value` as const)}
                        />
                      ) : (
                        <div className="DisplayInstance__addProductModal__variable__radio">
                          <Controller
                            control={variableForm.control}
                            name={`variables.${index}.variableImagePaths`}
                            render={({ field: imgField }) =>
                              !!imgField.value.length &&
                              imgField.value.map((pathStr: string) => {
                                const path = JSON.parse(pathStr);
                                // gets other key besides file_path, should be the same as layer.attributeName
                                const attrName = Object.keys(path).filter((key) => key !== 'file_path')[0];
                                return (
                                  <label
                                    className="DisplayInstance__addProductModal__variable__radio__option"
                                    htmlFor={path[attrName]}
                                  >
                                    <input
                                      type="radio"
                                      id={path[attrName]}
                                      value={path[attrName]}
                                      {...variableForm.register(`variables.${index}.value` as const)}
                                    />
                                    <img src={path['file_path']} alt={path[attrName]} />
                                  </label>
                                );
                              })
                            }
                          />
                        </div>
                      )
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <span className="DisplayInstance__addProductModal__form__buttons">
          <Button variant="raised" onClick={() => onCloseHandler()}>
            Cancel
          </Button>
          <Button variant="raised" color="primary" type="submit" disabled={!product}>
            Set product
          </Button>
        </span>
      </form>
    </Modal>
  );
};
