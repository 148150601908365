import * as React from 'react';
import { debounceRef } from '~/src/utils/debounceRef';
import { Input, InputProps } from '../Input';

export type DebouncedInputProps = InputProps & {
  debounceTimer?: number;
  onDebounce?: (e: string) => void;
};

export const DebouncedInput = (props: DebouncedInputProps) => {
  const { debounceTimer, onChange, onDebounce, ...rest } = props;

  const debounce = debounceRef((value: string) => {
    if (onDebounce) {
      onDebounce(value);
    }
  }, debounceTimer || 500);

  function onValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(e);
    }
    debounce(e.target.value);
  }

  return <Input {...rest} onChange={onValueChange} />;
};
