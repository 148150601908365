import * as React from 'react';
import { Modal, Button, Input } from '~/src/components';
import './CreateTemplateModal.scss';

type CreateTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
};

export const CreateTemplateModal = (props: CreateTemplateModalProps) => {
  const [name, setName] = React.useState('');

  function onCloseHandler() {
    setName('');
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSubmit(name);
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="CreateTemplateModal">
      <p>Create a new template</p>
      <div className="CreateTemplateModal__input">
        <p>Name</p>
        <Input placeholder="Set name..." value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="CreateTemplateModal__buttons">
        <Button variant="raised" onClick={onCloseHandler}>
          Cancel
        </Button>
        <Button color="primary" variant="raised" disabled={!name} onClick={onSubmitHandler}>
          Create template
        </Button>
      </div>
    </Modal>
  );
};
