import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { ForgotPassword, Login, ResetPassword } from '~/src/features/auth';

export const publicRoutes = [
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-pass/:token',
    element: <ResetPassword />,
  },
];
