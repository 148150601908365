import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '~/src/components';
import './ProductInfoModal.scss';

type ProductInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  product: any;
};

export const ProductInfoModal = (props: ProductInfoModalProps) => {
  React.useEffect(() => {
    if (!props.isOpen) return;
    console.log(props.product);
  }, [props.product, props.isOpen]);

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <Modal style={{ width: 'fit-content' }} isOpen={props.isOpen} onClose={onCloseHandler}>
      <div className="ProductsList__productInfoModal">
        <img src={`https://staging.everyname.com/ops-api/images/${props.product.pk}/render/`} />
        <div className="ProductsList__productInfoModal__text">
          <p>{props.product.name}</p>
          <p>{props.product.description}</p>
          {props.product.minQty && (
            <span>
              <p>Order in multiples of:</p>
              <p>{props.product.minQty}</p>
            </span>
          )}
          {props.product.price && (
            <span>
              <p>Unit Price:</p>
              <p>{props.product.price}</p>
            </span>
          )}
          <Link to={`/products/${props.product.id}`}>
            <ArrowTopRightOnSquareIcon /> View product information
          </Link>
        </div>
      </div>
    </Modal>
  );
};
