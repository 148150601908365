import { DocumentNode, useApolloClient } from '@apollo/client';
import * as React from 'react';
import { DebouncedInput, FormLabel, Menu, MenuItem, Popover, Spinner } from '~/src/components';

type SearchPopoverProps = {
  isOpen: boolean;
  onChange: (value: any) => void;
  onClose: () => void;
  popoverStyle?: React.CSSProperties;
  query: DocumentNode;
  queryArgument: string;
  queryField: string;
  queryVariables?: any;
  renderMatch: (match: any) => React.ReactNode;
  searchLabel?: string;
};

export const SearchPopover = (props: SearchPopoverProps) => {
  const [searchValue, setSearchValue] = React.useState('');
  const [matches, setMatches] = React.useState<any>([]);
  const [matchesLoading, setMatchesLoading] = React.useState(false);

  const client = useApolloClient();

  function getMatches(value: string) {
    setMatchesLoading(true);
    client
      .query({
        query: props.query,
        variables: {
          [props.queryArgument]: value,
          ...props.queryVariables,
        },
      })
      .then((res) => {
        setMatches(res.data[props.queryField].edges.map((edge: any) => edge.node));
      })
      .finally(() => {
        setMatchesLoading(false);
      });
  }

  function renderMatches() {
    if (matchesLoading) {
      return (
        <div style={{ marginTop: '1rem' }}>
          <Spinner />
        </div>
      );
    }

    if (!searchValue || matches.length < 1) {
      return null;
    }

    return (
      <Menu style={{ marginTop: '1rem' }}>
        {matches.map((match: any, idx: number) => {
          return (
            <MenuItem
              className="text-ellipsis"
              key={idx}
              onClick={() => {
                props.onChange(match);
                props.onClose();
              }}
            >
              {props.renderMatch(match)}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  return (
    <>
      <Popover isOpen={props.isOpen} onClose={props.onClose} style={props.popoverStyle}>
        <FormLabel className="font-size-12">{props.searchLabel ? props.searchLabel : 'Search'}:</FormLabel>
        <DebouncedInput
          autoFocus
          fluid
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onDebounce={(value) => {
            if (value.length > 0) {
              getMatches(value);
            } else {
              setMatches([]);
            }
          }}
          value={searchValue}
        />
        {renderMatches()}
      </Popover>
    </>
  );
};
