import * as React from 'react';
import { Input, Popover } from '~/src/components';
import './TypeaheadDropdown.scss';

type TypeaheadDropdownProps = {
  isOpen: boolean;
  onClose: () => void;
  options: { label: string; value: any }[];
  onChange: (option: { label: string; value: any }) => void;
  style?: React.CSSProperties;
};

export const TypeaheadDropdown = (props: TypeaheadDropdownProps) => {
  const [search, setSearch] = React.useState('');

  return (
    <Popover
      className="ProductDetails__searchPopoverNoquery"
      isOpen={props.isOpen}
      onClose={props.onClose}
      style={{ padding: 0, ...props.style }}
    >
      <Input
        className="ProductDetails__searchPopoverNoquery__popoverInput"
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search"
        value={search}
      />
      <div className="ProductDetails__searchPopoverNoquery__popoverOptions">
        {props.options
          ?.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
          .sort((option1, option2) => (option1.label < option2.label ? -1 : option2.label < option1.label ? 1 : 0))
          .map((option) => (
            <div
              key={option.value}
              id={option.value}
              className="ProductDetails__searchPopoverNoquery__popoverOptions__option"
              onClick={() => {
                setSearch('');
                props.onClose();
                props.onChange(option);
              }}
            >
              {option.label}
            </div>
          ))}
      </div>
    </Popover>
  );
};
