import * as React from 'react';
import { Button, Textarea } from '~/src/components';

type RepAreaProps = { value: string; label: string; updateData: (string: string) => void };

const RepAreaDefaults = '';

export const RepArea = (props: RepAreaProps) => {
  const [editing, setEditing] = React.useState(false);
  const [repAreaValue, setRepAreaValue] = React.useState(RepAreaDefaults);

  React.useEffect(() => {
    setRepAreaValue(props.value);
  }, [props.value]);

  return (
    <div className="RepDetails__info__repArea">
      <div className="RepDetails__info__repArea__header">
        <p>{props.label}</p>
        {!editing && <a onClick={() => setEditing((prev) => !prev)}>Edit</a>}
      </div>
      {editing ? (
        <div className="RepDetails__info__repArea__editing">
          <Textarea fluid onChange={(e) => setRepAreaValue(e.target.value)} value={repAreaValue} />
          <span className="RepDetails__info__repArea__editing--buttons">
            <Button
              color="light"
              fluid
              onClick={() => (setEditing(false), setRepAreaValue(props.value))}
              size="sm"
              variant="raised"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              fluid
              onClick={() => (setEditing(false), props.updateData(repAreaValue))}
              size="sm"
              variant="raised"
            >
              Save
            </Button>
          </span>
        </div>
      ) : (
        <div className="RepDetails__info__repArea__text">
          {repAreaValue ? (
            <p>{repAreaValue}</p>
          ) : (
            <span className="font-italic">
              <p>No notes specified</p>
            </span>
          )}
        </div>
      )}
    </div>
  );
};
