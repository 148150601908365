import { XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Modal } from '~/src/components';
import './AddCreditMemoModal.scss';
import { useLazyQuery } from '@apollo/client';
import { CREDIT_MEMOS_SEARCH_QUERY } from '~src/features/credit-memos/api/queries';

type AddCreditMemoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: any;
  onAddCreditMemo: (creditMemo: any) => void;
};

export const AddCreditMemoModal = (props: AddCreditMemoModalProps) => {
  const [loadCreditMemos, { data }] = useLazyQuery(CREDIT_MEMOS_SEARCH_QUERY, {
    variables: {
      account: props.order?.account?.id,
    },
  });

  React.useEffect(() => {
    if (!props.isOpen) return;
    loadCreditMemos();
  }, [props.isOpen])

  function onCloseHandler() {
    props.onClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ width: '660px', padding: '0' }}>
      <div className="AddCreditMemoModal">
        <div className="AddCreditMemoModal__header">
          <p>Add Credit Memo</p>
        </div>
        <div className="AddCreditMemoModal__body">
          <table className="data-table">
            <thead>
              <tr>
                <th>Invoice #</th>
                <th></th>
                <th>Amount</th>
                <th>Comm</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, index) => {
                return (
                  <tr key={index}>
                    <td>10658</td>
                    <td>Credit - Memo</td>
                    <td>($272.02)</td>
                    <td>20%</td>
                    <td>
                      {true ? (
                        <Button color="primary" onClick={() => {}} size="sm" variant="raised">
                          Add
                        </Button>
                      ) : (
                        <Button
                          color="light"
                          iconTrailing={<XMarkIcon />}
                          onClick={() => {}}
                          size="sm"
                          variant="raised"
                        >
                          Remove
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};
