export enum JobStatuses {
  READY = 'READY',
  PICKUP = 'PENDING_PICKUP',
  PRODUCTION = 'PRODUCTION',
  APPROVAL = 'PENDING_APPROVAL',
  COMPLETED = 'COMPLETED',
  PAYMENT = 'PENDING_PAYMENT_APPROVAL',
  PAID = 'PAID',
}

export const JobStatusChoices = [
  { status: JobStatuses.READY, label: 'Ready to assign' },
  { status: JobStatuses.PICKUP, label: 'Pending pickup' },
  { status: JobStatuses.PRODUCTION, label: 'Out for production' },
  { status: JobStatuses.APPROVAL, label: 'Pending approval' },
  { status: JobStatuses.COMPLETED, label: 'Job Completed' },
  { status: JobStatuses.PAYMENT, label: 'Pending payment' },
  { status: JobStatuses.PAID, label: 'Paid' },
];
