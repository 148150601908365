import * as React from 'react';
import { ProductPreview } from '../ProductPreview';
import { ProductRow } from '../ProductRow';
import './NonDisplayTable.scss';

type NonDisplayTableProps = {
  onDelete: (orderDetailPk: number) => void;
  order: any;
  productOrderDetails: any;
  onRefetch: () => void;
};

export const NonDisplayTable = (props: NonDisplayTableProps) => {
  const [productPreviewProps, setProductPreviewProps] = React.useState<{
    isOpen: boolean;
    product?: any;
  }>({ isOpen: false });

  function renderOrderDetails() {
    return props.productOrderDetails.map((edge: any, index: number) => {
      return (
        <ProductRow
          key={index}
          orderDetails={edge.node}
          orderId={props.order.id}
          onDelete={(orderDetailsPk) => props.onDelete(orderDetailsPk)}
          openProductPreview={(product) => setProductPreviewProps({ isOpen: true, product })}
          onRefetch={props.onRefetch}
        />
      );
    });
  }

  return (
    <div className="NonDisplayTable">
      <table className="data-table OrderDetails__orderDetailsTable">
        <colgroup className="NonDisplayTable__colgroup">
          <col className="NonDisplayTable__colgroup__medium" />
          <col className="NonDisplayTable__colgroup__medium" />
          <col className="NonDisplayTable__colgroup__grow" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>Order detail ID</th>
            <th>Product #</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit price</th>
            <th>Commission</th>
            <th>Sub-total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderOrderDetails()}</tbody>
      </table>
      <ProductPreview
        isOpen={productPreviewProps.isOpen}
        product={productPreviewProps.product}
        onClose={() => setProductPreviewProps({ isOpen: false })}
      />
    </div>
  );
};
