import * as React from 'react';
import { Button, Input, Modal, Select, Toggle } from '~/src/components';
import './ShippingModal.scss';

type ShippingModalProps = {
  account: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
  shippingMethods: any;
};

type ShippingFormData = {
  chargeShipping: boolean;
  shipThirdParty: boolean;
  thirdPartyShipAcct: string;
  shipping: string;
};

const defaultFormData: ShippingFormData = {
  chargeShipping: false,
  shipThirdParty: false,
  thirdPartyShipAcct: '',
  shipping: '',
};

export const ShippingModal = (props: ShippingModalProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    setFormData({
      chargeShipping: props.account.chargeShipping,
      shipThirdParty: props.account.shipThirdParty,
      thirdPartyShipAcct: props.account.thirdPartyShipAcct,
      shipping: props.account.shipping?.pk,
    });
  }, [props.isOpen]);

  function onCloseHandler() {
    setFormData(defaultFormData);
    props.onClose();
  }

  function onSubmitHandler(e: React.FormEvent) {
    e.preventDefault();
    props.onSubmit(formData);
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="ShippingModal">
      <div className="ShippingModal__header">
        <div className="font-size-18 color-gray-900">Shipping Preferences</div>
      </div>
      <form onSubmit={onSubmitHandler}>
        <div className="ShippingModal__body">
          <div className="ShippingModal__field">
            <label className="ShippingModal__field__label">Charge Shipping</label>
            <Toggle
              checked={formData.chargeShipping}
              onChange={() => {
                setFormData({
                  ...formData,
                  chargeShipping: !formData.chargeShipping,
                });
              }}
            />
          </div>
          <div className="ShippingModal__field">
            <label className="ShippingModal__field__label">Ship 3rd Party</label>
            <Toggle
              checked={formData.shipThirdParty}
              onChange={() => {
                setFormData({
                  ...formData,
                  shipThirdParty: !formData.shipThirdParty,
                });
              }}
            />
          </div>
          {formData.shipThirdParty && (
            <>
              <div className="ShippingModal__field">
                <label className="ShippingModal__field__label">Shipping Carrier</label>
                {props.shippingMethods && (
                  <Select
                    value={formData.shipping}
                    onChange={(e) => setFormData((prev) => ({ ...prev, shipping: e.target.value }))}
                    options={props.shippingMethods.edges.map((edge: any) => ({
                      value: edge.node.pk,
                      label: edge.node.method,
                    }))}
                  />
                )}
              </div>
              <div className="ShippingModal__field">
                <label className="ShippingModal__field__label">3rd Party Account Number</label>
                <Input
                  value={formData.thirdPartyShipAcct || ''}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      thirdPartyShipAcct: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="ShippingModal__footer">
          <Button color="light" onClick={onCloseHandler} variant="raised">
            Cancel
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
