type actions = 'open' | 'close';

type modals =
  | 'addContact'
  | 'editContact'
  | 'credit'
  | 'orderApproval'
  | 'shipping'
  | 'editNotes'
  | 'editAccount'
  | 'accessCode';

export function accountModalsReducer(state: any, action: { type: actions; modal?: modals; props?: any }) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        [action.modal as string]: {
          isOpen: true,
          ...action.props,
        },
      };
    case 'close':
      return {
        ...state,
        [action.modal as string]: {
          isOpen: false,
        },
      };
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}
