import { gql } from '@apollo/client';

export const ACCOUNT_ORDER_APPROVAL_MUTATION = gql`
  mutation updateOrderApproval($id: ID!, $updates: AccountUpdateInput) {
    accountUpdate(id: $id, updates: $updates) {
      account {
        poNumberReq
        approvalSigReq
      }
    }
  }
`;

export const SHIPPING_PREFERENCE_MUTATION = gql`
  mutation updateShippingPreference($id: ID!, $updates: AccountUpdateInput) {
    accountUpdate(id: $id, updates: $updates) {
      account {
        chargeShipping
        thirdPartyShipAcct
        shipThirdParty
        shipping {
          method
        }
      }
    }
  }
`;

export const EDIT_ACCOUNT_MUTATION = gql`
  mutation updateAccountInfo($id: ID!, $updates: AccountUpdateInput) {
    accountUpdate(id: $id, updates: $updates) {
      account {
        status {
          id
        }
        address
        city
        postal
        state
        rep {
          id
          user {
            firstName
            lastName
          }
          manager {
            user {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const EDIT_ACCOUNT_NOTES_MUTATION = gql`
  mutation updateAccountNotes($id: ID!, $updates: AccountUpdateInput) {
    accountUpdate(id: $id, updates: $updates) {
      account {
        notes
      }
    }
  }
`;

export const EDIT_CREDIT_AND_TERMS_MUTATION = gql`
  mutation updateAccountCreditAndTerms($id: ID!, $updates: AccountUpdateInput) {
    accountUpdate(id: $id, updates: $updates) {
      account {
        pk
      }
    }
  }
`;

export const CREATE_ACCOUNT_CONTACT = gql`
  mutation createAccountContact($id: ID!, $updates: CreateContactInput!) {
    accountContactCreate(accountId: $id, contactDetails: $updates) {
      contact {
        name
        title
        phone
        email
        primary
        sendInvoices
      }
    }
  }
`;

export const UPDATE_ACCOUNT_CONTACT = gql`
  mutation updateAccountContact($id: ID!, $updates: UpdateContactInput!) {
    accountContactUpdate(id: $id, contactDetails: $updates) {
      contact {
        name
        phone
        email
        title
        primary
        sendInvoices
      }
    }
  }
`;

export const ACCESS_CODE_UPDATE = gql`
  mutation accessCodeUpdate($pk: Int!, $updates: AccessCodeInput!) {
    accessCodeUpdate(pk: $pk, updates: $updates) {
      accessCode {
        id
        pk
        code
        notes
        featuredProducts {
          edges {
            node {
              id
              pk
              active
              product {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const FEATURED_CREATE = gql`
  mutation featuredCreate($featured: FeaturedInput!) {
    featuredCreate(featured: $featured) {
      featured {
        id
        pk
      }
    }
  }
`;

export const FEATURED_UPDATE = gql`
  mutation featuredUpdate($pk: Int!, $featured: FeaturedInput!) {
    featuredUpdate(pk: $pk, featured: $featured) {
      featured {
        id
        pk
      }
    }
  }
`;

export const FEATURED_DELETE = gql`
  mutation featuredDelete($pk: Int!) {
    featuredDelete(pk: $pk) {
      success
    }
  }
`;
