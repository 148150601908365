import * as React from 'react';
import { Button, Input, Modal } from '~/src/components';
import './EditModuleModal.scss';

type EditModuleModal = {
  module: any;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: { name: any }) => void;
};

export const EditModuleModal = (props: EditModuleModal) => {
  const [name, setName] = React.useState<any>(null);

  React.useEffect(() => {
    setName(props.module?.name);
  }, [props.isOpen]);

  function onCloseHandler() {
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSave({ name });
    onCloseHandler();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} className="EditModuleModal">
      <p className="EditModuleModal__title">Edit Module</p>
      <form>
        <div className="EditModuleModal__input">
          <p>Name:</p>
          <div style={{ position: 'relative' }}>
            <Input placeholder="Set name..." value={name || ''} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>
        <div className="EditModuleModal__buttons">
          <Button onClick={() => onCloseHandler()}>Cancel</Button>
          <Button variant="raised" color="primary" disabled={!name} onClick={() => onSubmitHandler()}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
