import * as classNames from 'classnames';
import * as React from 'react';
import './Container.scss';

type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  testId?: string;
};

export const Container = ({ className, testId, ...rest }: ContainerProps) => {
  let containerClass = classNames('Container', className);

  return <div className={containerClass} data-testid={testId} {...rest} />;
};
