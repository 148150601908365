import { gql } from '@apollo/client';

// Pagination & Search Accounts
export const ACCOUNT_SEARCH = gql`
  query searchAccounts($first: Int, $offset: Int, $search: String, $sort: String, $status: String) {
    accounts(first: $first, offset: $offset, search: $search, sort: $sort, status_Status_Iexact: $status) {
      totalNodes
      totalNodesOnPage
      edges {
        node {
          id
          name
          pk
          billing {
            name
          }
          phone
          contact
          city
          state
          lastOrderDate
          status {
            status
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

// Retrieve single account
export const ACCOUNT_QUERY = gql`
  query account($id: ID!) {
    account(id: $id) {
      id
      name
      shipping {
        id
        method
        active
        sortOrder
        trackingUrl
        pk
      }
      shippingInstructions
      creditApp
      creditAppHow
      chargeShipping
      paymentReq {
        active
        type
        pk
      }
      notes
      billing {
        id
        contact
        vendor
        name
        address
        city
        state
        postal
        phone
        fax
        terms
        email
        notes
        status {
          id
          status
        }
        companyType {
          id
          type
          typeDescription
        }
      }
      termsDays
      poNumberReq
      approvalSigReq
      rep {
        user {
          firstName
          lastName
        }
        id
        pk
        company
        shipAddress
        shipCity
        shipPostal
        notes
      }
      manager {
        id
        pk
        user {
          firstName
          lastName
        }
      }
      contact
      contactPosition
      isDistributor
      address
      city
      state
      country {
        name
        code
      }
      postal
      phone
      fax
      email
      shipThirdParty
      status {
        id
        status
      }
      accessCodes {
        edges {
          node {
            id
            pk
            code
            notes
            featuredProducts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
      pk
      lastOrderDate
      thirdPartyShipAcct
      contacts {
        edges {
          node {
            id
            pk
            created
            name
            phone
            email
            title
            primary
            sendInvoices
          }
        }
      }
    }
  }
`;

export const ACCOUNT_DISPLAYS_QUERY = gql`
  query getAccountDisplays($account: Int!, $hasEndDate: Boolean) {
    accountDisplays(account: $account, hasEndDate: $hasEndDate) {
      pk
      id
      upcType {
        types
      }
      namelist {
        name
      }
      displayProduct {
        id
        name
      }
      product {
        id
        notes
        upc
        name
      }
      startDate
      endDate
      hookData
      notes
      label
    }
  }
`;

export const SHIPPING_METHODS_QUERY = gql`
  query {
    shippings {
      edges {
        node {
          method
          pk
        }
      }
    }
  }
`;

export const STATUSES_QUERY = gql`
  query {
    statuses {
      edges {
        node {
          id
          pk
          status
        }
      }
    }
  }
`;

export const REPS_QUERY = gql`
  query reps($search: String) {
    reps(search: $search) {
      edges {
        node {
          id
          pk
          name
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const EMPLOYEES_QUERY = gql`
  query employees($search: String) {
    employees(search: $search, sales: true) {
      edges {
        node {
          id
          pk
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const ACCESS_CODES_QUERY = gql`
  query accessCodesQuery($accountPk: Decimal, $code_Icontains: String, $account_Isnull: Boolean) {
    accessCodes(accountPk: $accountPk, code_Icontains: $code_Icontains, account_Isnull: $account_Isnull) {
      edges {
        node {
          id
          pk
          code
          notes
          featuredProducts {
            edges {
              node {
                id
                pk
                active
                product {
                  id
                  pk
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FEATURED_PRODUCTS_QUERY = gql`
  query products($search: String) {
    products(search: $search) {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const ACCOUNT_NAV_QUERY = gql`
  query account($id: ID!) {
    account(id: $id) {
      pk
      id
      name
    }
  }
`;

export const ACCOUNT_HISTORY_QUERY = gql`
  query accountHistory($id: ID!) {
    account(id: $id) {
      id
      pk
      name
      termsDays
      accountHistory {
        firstOrderDate
        recentOrderDate
        averageOrderCost
        averagePay
        orderStats
        units
        dollarsSpent
      }
    }
  }
`;

export const PRODUCT_SUMMARY_QUERY = gql`
  query productSummary($accountId: ID!) {
    account(id: $accountId) {
      id
      pk
      name
      productHistory {
        product {
          id
          pk
          name
          group {
            name
            nonProduct
          }
          description
          price
          minQty
        }
        firstShipped
        lastShipped
        orderCount
        totalQuantity
      }
    }
  }
`;

export const HISTORY_SUMMARY_PDF_QUERY = gql`
  query accountSummaryHistoryPdf($accountPk: Int!) {
    accountSummaryHistoryPdf(accountPk: $accountPk)
  }
`;
