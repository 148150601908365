import { gql } from '@apollo/client';

export const CREDIT_MEMO_CREATE_MUTATION = gql`
  mutation creditMemoCreate($input: CreditMemoCreateInput!) {
    creditMemoCreate(input: $input) {
      creditMemo {
        id
        pk
      }
    }
  }
`;

export const CREDIT_MEMO_UPDATE_MUTATION = gql`
  mutation creditMemoUpdate($pk: Int!, $input: CreditMemoInput!) {
    creditMemoUpdate(pk: $pk, input: $input) {
      creditMemo {
        id
        pk
      }
    }
  }
`;
