import { CalculatorIcon, PresentationChartLineIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReportsNav, SalesSlugs, ProductsSlugs, ProductionSlugs, BookkeepingSlugs } from '../..';
import './ReportsSplash.scss';


export const ReportsSplash = () => {
  return (
    <div className="ReportsSplash">
      <ReportsNav />
      <div className="ReportsSplash__content">
        <div className="ReportsSplash__section">
          <div className="ReportsSplash__section__col ReportsSplash__section__col--icon">
            <span>
              <PresentationChartLineIcon />
            </span>
            <h2>Sales</h2>
          </div>
          <div className="ReportsSplash__section__contentContainer">
            <div className="ReportsSplash__section__col">
              <h3>Reps</h3>
              <Link to={`/reports/sales/${SalesSlugs.REP_SALES}`}>Rep Sales by Date Range</Link>
              <Link to={`/reports/sales/${SalesSlugs.REPS_DISPLAYS}`}>Displays Without Reorders</Link>
            </div>
            <div className="ReportsSplash__section__col">
              <h3>Accounts</h3>
              <Link to={`/reports/sales/${SalesSlugs.ACCOUNTS_YTD}`}>Accounts YTD Comp</Link>
              <Link to={`/reports/sales/${SalesSlugs.ACCOUNTS_12_MONTH}`}>Accounts 12-month by Sales</Link>
            </div>
            <div className="ReportsSplash__section__col">
              <h3>House Accounts</h3>
              <Link to={`/reports/sales/${SalesSlugs.LAST_ORDER}`}>Last Order</Link>
            </div>
          </div>
        </div>
        <div className="ReportsSplash__section">
          <div className="ReportsSplash__section__col ReportsSplash__section__col--icon">
            <span>
              <CalculatorIcon />
            </span>
            <h2>Bookkeeping</h2>
          </div>
          <div className="ReportsSplash__section__contentContainer">
            <div className="ReportsSplash__section__col">
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.COLLECTIONS}`}>Collections</Link>
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.COMMISSION}`}>Commission</Link>
              <Link to={`/reports/bookkeeping/${BookkeepingSlugs.FINANCES}`}>Finances</Link>
            </div>
          </div>
        </div>
        <div className="ReportsSplash__section">
          <div className="ReportsSplash__section__col ReportsSplash__section__col--icon">
            <span>
              <ShoppingCartIcon />
            </span>
            <h2>Products</h2>
          </div>
          <div className="ReportsSplash__section__contentContainer">
            <div className="ReportsSplash__section__col">
              <h3>By Product Numbers</h3>
              <Link to={`/reports/products/${ProductsSlugs.PRODUCTS}`}>Products Sold by Date</Link>
            </div>
            <div className="ReportsSplash__section__col">
              <h3>By Product Groups</h3>
              <Link to={`/reports/products/${ProductsSlugs.GROUPS}`}>Product Groups Sold by Date</Link>
            </div>
          </div>
        </div>
        <div className="ReportsSplash__section">
          <div className="ReportsSplash__section__col ReportsSplash__section__col--icon">
            <span>
              <TruckIcon />
            </span>
            <h2>Production</h2>
          </div>
          <div className="ReportsSplash__section__contentContainer">
            <div className="ReportsSplash__section__col">
              <h3>Order Status Queues</h3>
              <Link to={`/reports/production/${ProductionSlugs.SUMMARY}`}>Production Summary by Product Groups</Link>
              <Link to={`/reports/production/${ProductionSlugs.APPROVED}`}>Approved Orders Not Released</Link>
              <Link to={`/reports/production/${ProductionSlugs.COMPLETED}`}>Complete Order not shipped</Link>
              <Link to={`/reports/production/${ProductionSlugs.AWAITING_APPROVAL}`}>Orders Awaiting Approval</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
