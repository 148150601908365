import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { Select, SelectProps } from '../@shared/Select/Select';

export const PAYMENT_TYPES_QUERY = gql`
  query paymentTypes {
    paymentTypes {
      edges {
        node {
          id
          pk
          type
          active
        }
      }
    }
  }
`;

type PaymentTypeSelectProps = Omit<SelectProps, 'options'> & {};

export const PaymentTypeSelect = (props: PaymentTypeSelectProps) => {
  const { data, loading } = useQuery(PAYMENT_TYPES_QUERY);

  if (loading || !data) return <div>Loading...</div>;

  const options = [
    {
      value: '',
      label: '',
    },
    ...data.paymentTypes.edges.map((edge: any) => ({
      value: edge.node.pk,
      label: edge.node.type,
    })),
  ];

  return <Select options={options} {...props} />;
};
