import { gql } from '@apollo/client';
import * as React from 'react';
import { Input, SearchPopover } from '~/src/components';

const PRODUCTS_QUERY = gql`
  query products($search: String) {
    products(active: true, search: $search) {
      edges {
        node {
          name
          pk
          id
        }
      }
    }
  }
`;

type ProductSearchPopoverProps = {
  product: any;
  onChange: (product: any) => void;
};

export const ProductSearchPopover = (props: ProductSearchPopoverProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [product, setProduct] = React.useState<any>({});

  React.useEffect(() => {
    if (!props.product) return;
    setProduct(props.product);
  }, [props.product]);

  return (
    <div className="relative">
      <Input onClick={() => setPopoverOpen(true)} value={product.name} readOnly />
      <SearchPopover
        isOpen={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        onChange={(product) => {
          setPopoverOpen(false);
          setProduct(product);
          props.onChange(product);
        }}
        popoverStyle={{ position: 'absolute', maxHeight: '400px', overflowY: 'auto' }}
        query={PRODUCTS_QUERY}
        queryArgument="search"
        queryField="products"
        renderMatch={(match) => <>{match.name}</>}
      />
    </div>
  );
};
