import * as React from 'react';
import { Button, Input, Modal, Select, SelectOption } from '~/src/components';
import { STATES_LIST } from '~/src/utils/statesList';
import { useCompanyTypeList, useStatusList } from '../../hooks';
import './EditBillingModal.scss';

type EditBillingModalProps = {
  data: any;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EditBillingForm) => void;
};

type EditBillingForm = {
  address: string;
  city: string;
  state: string;
  postal: string;
  companyType: number;
  statusChoice: string;
};

const defaultFormData: EditBillingForm = {
  address: '',
  city: '',
  state: '',
  postal: '',
  companyType: -1,
  statusChoice: '',
};

export const EditBillingModal = (props: EditBillingModalProps) => {
  const states: SelectOption[] = STATES_LIST.map((state: string, idx: number) => {
    return { label: state, value: state };
  });

  const [formData, setFormData] = React.useState(defaultFormData);

  const { companyTypeList } = useCompanyTypeList();
  const { statusList } = useStatusList();

  React.useEffect(() => {
    setFormData({
      ...formData,
      address: props.data.address,
      city: props.data.city,
      state: props.data.state,
      postal: props.data.postal,
      companyType: props.data.companyType?.pk,
      statusChoice: props.data.statusChoice && stringToCapitalCase(props.data.statusChoice),
    });
  }, [props.data]);

  function stringToCapitalCase(str: string) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    props.onSubmit(formData);
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} className="EditBillingModal">
      <div className="EditBillingModal__header">
        <p className="EditBillingModal__title">Edit Billing Info</p>
      </div>
      <form className="EditBillingModal__form" onSubmit={onSubmit}>
        <div className="EditBillingModal__form__body">
          <div className="EditBillingModal__form__field">
            <label htmlFor="id">ID #</label>
            <Input id="id" type="text" disabled value={props.data.pk} />
          </div>
          <div className="EditBillingModal__form__field">
            <label htmlFor="address">Address</label>
            <Input
              id="address"
              type="text"
              value={formData.address}
              onChange={(e) => setFormData((prev) => ({ ...prev, address: e.target.value }))}
            />
          </div>
          <div className="EditBillingModal__form__field">
            <label htmlFor="city">City</label>
            <Input
              id="city"
              type="text"
              value={formData.city}
              onChange={(e) => setFormData((prev) => ({ ...prev, city: e.target.value }))}
            />
          </div>
          <div className="EditBillingModal__form__field">
            <label htmlFor="state">State/Postal</label>
            <div className="EditBillingModal__form__field__doubleInput">
              <Select
                id="state"
                options={states}
                value={formData.state}
                onChange={(e) => setFormData((prev) => ({ ...prev, state: e.target.value }))}
              />
              <Input
                id="postal"
                type="text"
                value={formData.postal}
                onChange={(e) => setFormData((prev) => ({ ...prev, postal: e.target.value }))}
              />
            </div>
          </div>
          <div className="EditBillingModal__form__field">
            <label htmlFor="company">Company Type</label>
            {companyTypeList && (
              <Select
                options={companyTypeList.edges.map((edge: any) => ({ label: edge.node.type, value: edge.node.pk }))}
                onChange={(e) => setFormData((prev) => ({ ...prev, companyType: Number(e.target.value) }))}
                value={formData.companyType}
              />
            )}
          </div>
          <div className="EditBillingModal__form__field">
            <label htmlFor="status">Status</label>
            {statusList && (
              <Select
                options={statusList.edges.map((edge: any) => ({ label: edge.node.status, value: edge.node.status }))}
                onChange={(e) => setFormData((prev) => ({ ...prev, statusChoice: e.target.value }))}
                value={formData.statusChoice}
              />
            )}
          </div>
        </div>
        <div className="EditBillingModal__form__buttons">
          <Button color="light" onClick={props.onClose} variant="raised">
            Close
          </Button>
          <Button color="primary" type="submit" variant="raised">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
