import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.API_HOST,
});

http.interceptors.request.use((config) => {
  if (localStorage.getItem('token')) {
    config.headers = { ...config.headers, Authorization: `Token ${localStorage.getItem('token')}` };
  }
  return config;
});
