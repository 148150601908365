import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { EyeSlashIcon, UserIcon } from '@heroicons/react/24/solid';
import bucket from '~/src/assets/img/bucket.png';
import { Button, Checkbox, Container, Form, FormGroup, FormLabel, Input } from '~/src/components';
import { TOKEN_AUTH_MUTATION } from '../../api';
import { useAuth } from '../../providers';

export const Login = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);

  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [tokenAuth] = useMutation(TOKEN_AUTH_MUTATION);

  function login(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    tokenAuth({
      variables: {
        username,
        password,
      },
    })
      .then((res: any) => {
        localStorage.setItem('token', res.data.tokenAuth.token);
        setUser(res.data.tokenAuth.user);
        navigate('/');
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  return (
    <Container>
      <div className="center-page">
        <img className="center-page-img" src={bucket} alt="" />
        <h2 className="center-page-title">Sign in to your account</h2>
        <Form onSubmit={login}>
          <FormGroup>
            <FormLabel htmlFor="username">Username</FormLabel>
            <Input
              fluid
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              iconTrailing={<UserIcon />}
              value={username}
              onChange={(e) => {
                setUsername(e.currentTarget.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              fluid
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              iconTrailing={<EyeSlashIcon />}
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />
          </FormGroup>
          <p className="error">{error}</p>
          <FormGroup>
            <div className="flex align-center justify-between">
              <div>
                <Checkbox
                  label="Remember me"
                  checked={rememberMe}
                  onChange={(e) => {
                    setRememberMe(!rememberMe);
                  }}
                />
              </div>
              <div>
                <Link className="font-size-12 font-semi-bold" to="/forgot-password">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </FormGroup>
          <Button color="primary" disabled={!username || !password} fluid type="submit" variant="raised">
            Login
          </Button>
        </Form>
      </div>
    </Container>
  );
};
